<template>
  <div>
    <validation-observer ref="suppliersValidationObs">
      <div class="row">
        <div class="col-12">
          <div class="pt-lg-0 pt-5 pb-15 text-center text-md-left">
            <h3 class="font-weight-bolder text-dark font-size-h2">{{ $t("Select Suppliers") }}</h3>
            <div class="text-muted font-weight-bold font-size-h4">
              {{ $t("Add suppliers to assignment") }}
            </div>
          </div>
        </div>
        <div class="col-12">
          <validation-provider v-slot="{ errors }" name="suppliers" vid="suppliers" rules="">
            <secondment-actor-table ref="selectableSuppliersTable" v-model="selectedSuppliersComputed"
              :countries="countries" title="Supplier" multi-select :actors="actors"
              :custom-columns="[{ key: 'actions', sortable: false, class: 'align-middle' }]">
              <template #topbar-end>
                <b-dropdown no-caret right no-flip text="Document" variant="light-primary"
                  toggle-class="font-weight-bolder py-3">
                  <template #button-content>
                    <div class="dropdown-button">
                      <span class="svg-icon menu-icon">
                        <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                      </span>
                      <span class="font-weight-bolder">{{ $t("Add suppliers") }}</span>
                    </div>
                  </template>
                  <div class="navi navi-hover min-w-md-200px">
                    <b-dropdown-text class="navi-item" tag="div">
                      <button v-b-modal.newSupplier class="w-100 text-left navi-link"
                        @click="setOrganizationType('company')">
                        <span class="navi-icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span class="navi-text">
                          {{ $t("Company") }}
                        </span>
                      </button>
                      <button v-b-modal.newSupplier class="w-100 text-left navi-link"
                        @click="setOrganizationType('physical_person')">
                        <span class="navi-icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span class="navi-text">
                          {{ $t("Physical person") }}
                        </span>
                      </button>
                    </b-dropdown-text>
                  </div>
                </b-dropdown>
              </template>

              <template #[`cell.actions`]="{ item }">
                <b-button :tooltip="$t('Edit supplier')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
                  @click="onEditSupplier(item)">
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg src="/media/svg/icons/Design/Edit.svg" />
                  </span>
                </b-button>
              </template>
            </secondment-actor-table>
            <p v-if="errors" class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
        <div v-if="secondmentSuppliers.length > 0" class="col-12">
          <div class="separator separator-dashed my-5"></div>
        </div>
        <div class="col-12">
          <secondment-suppliers-list :secondment-suppliers="secondmentSuppliers">
            <template #secondmentSupplier="{ secondmentSupplier }">
              <secondment-supplier-card :value="secondmentSupplier" :secondment-dates-all="secondmentDatesAll"
                @input="$emit('secondment-supplier-change', $event)">
              </secondment-supplier-card>
            </template>
          </secondment-suppliers-list>
        </div>
      </div>
    </validation-observer>

    <SecondmentActorCreateModal id="newSupplier" ref="newSupplier" :title="$t('Add new supplier')"
      :text="$t('Please select the kind of supplier you would like to add')"
      :back-btn-tooltip="$t('Back to supplier type choice')" :organization-type="organizationType"
      @hidden="onSecondmentActorCreateModalHidden" @close="closeModal" @created="refreshTableAndCloseModal">
    </SecondmentActorCreateModal>
  </div>
</template>

<script>
import icons from "@/core/config/icons.js";

import SecondmentActorTable from "@/view/components/tables/SecondmentActorTable.vue";
import SecondmentSuppliersList from "@/view/components/lists/SecondmentSuppliersList.vue";
import SecondmentSupplierCard from "@/view/components/cards/SecondmentSupplierCard.vue";
import SecondmentActorCreateModal from "@/view/components/modals/secondment/SecondmentActorCreateModal.vue";
import { successToast } from '@/core/helpers';

export default {
  components: {
    SecondmentActorTable,
    SecondmentSuppliersList,
    SecondmentSupplierCard,
    SecondmentActorCreateModal,
  },

  props: {
    selectedSuppliers: {
      type: Array,
      required: true,
    },
    secondmentSuppliers: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    countriesSelectable: {
      type: Array,
      required: true,
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    defaultCountryFilter: {
      type: String,
      default: "",
    },
    actors: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      legalPersonWizardErrors: null,
      icons,
      organizationType: ''
    };
  },

  computed: {
    selectedSuppliersComputed: {
      get() {
        return this.selectedSuppliers;
      },
      set(values) {
        this.$emit("selected-suppliers-change", values);
      },
    },

    secondmentSuppliersComputed: {
      get() {
        return this.secondmentSuppliers;
      },
      set(values) {
        this.$emit("secondment-suppliers-change", values);
      },
    },
  },

  methods: {
    onEditSupplier(actor) {
      let route = null;

      if (actor.kind == "PhysicalPerson") {
        route = { name: "detail-physical-person", params: { userID: actor.id } };
      } else {
        route = { name: "detail-legal-person", params: { companyID: actor.id } };
      }

      this.$emit("edit-supplier", route);
    },

    onSecondmentActorCreateModalHidden() {
      this.$refs.newSupplier.reset();
    },

    closeModal() {
      this.$bvModal.hide("newSupplier");
    },

    refresh() {
      this.$refs.selectableSuppliersTable.refresh();
    },

    refreshTableAndCloseModal() {
      successToast(this.$t("Supplier created"));
      this.closeModal();
      this.refresh();
    },

    async validate() {
      return this.$refs.suppliersValidationObs.validate();
    },

    setOrganizationType(name) {
      this.organizationType = name
    }
  },
};
</script>
