<template>
  <div>
    <form @submit.prevent="false">
      <div class=" d-flex align-items-center">
        <div class="col">
          <div class="mb-2">
            <span>
              {{ $t('Radius Distance') }}
            </span>
            <i v-b-tooltip.hover.right
              :title="$t('Change the radius to be able to load more places, the places will be loaded depending on the distance assigned')"
              :class="icons.lineAwesome.info">
            </i>
          </div>
          <div class="d-flex align-items-center">
            <div>
              <span class="font-weight-bold text-dark h2 ">{{ convertUnitOfMeasure(selectedRadius)
                }}</span>
            </div>
            <div v-if="isLoadingPlaces">
              <div class="spinner-border text-primary ml-4" role=" status">
                <span class="sr-only"></span>
              </div>
              <span class="ml-2">{{ $t('Loading more places') }}..</span>
            </div>
            <div>
            </div>
          </div>
        </div>
        <div class="col mt-2">
          <google-maps-autocomplete ref="autocomplete" v-model="placeVal" :placeholder="'Search a place'"
            :autocomplete-options="{ types: [vehiclesMap[vehicle].type], componentRestrictions: { country: country } }"
            @focus="focus = true" @blur="focus = false"></google-maps-autocomplete>
        </div>
      </div>
      <input v-model="selectedRadius" type="range" class="form-control-range" :min="minRadius" :max="maxRadius"
        :step="stepRadius" @input="debouncedDistance">
    </form>

    <div class="map-grid  mt-5">
      <div ref="panel"></div>
      <!-- Map appears here with added margin -->
      <div ref="map" class="border" :style="{
      height,
      width,
    }"></div>
    </div>
  </div>
</template>

<script>
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
import icons from "@/core/config/icons";

export default {
  components: {
    GoogleMapsAutocomplete
  },
  props: {
    mapOptions: {
      type: Object,
      default: () => ({}),
    },
    height: {
      type: String,
      default: "400px",
    },
    width: {
      type: String,
      default: "100%",
    },
    vehicle: {
      type: String,
      default: "train",
    },
    mapCenter: {
      type: Object,
      default: null,
    },
    field: {
      type: String,
      required: true,
    },
    country: {
      type: String,
      default: "",
    },

  },
  data() {
    return {
      pos: null,
      map: null,
      bounds: null,
      infoWindow: null,
      service: null,
      infoPane: null,
      vehiclesMap: {
        plane: {
          type: "airport",
          query: 'airport ',
          search: 'internacional OR nacional'
        },
        train: {
          query: 'train',
          type: 'train_station',
          search: 'train'
        },
        housing: {
          query: "lodging",
          type: 'lodging',
          search: 'lodging'
        },
        restaurant: {
          type: "restaurant",
          query: "restaurant",
        },
        food: {
          type: "restaurant",
          query: "restaurant",
        },
      },
      iconsMap: {
        plane: "plane.png",
        boat: "ferry.png",
        train: "station.png",
        housing: "hotel.png",
        restaurant: "restaurant.png",
        food: "restaurant.png",
      },
      icon: {
        base: "/media/google-markers/",
        icon: null,
      },
      defaultOptions: {
        center: { lat: 44.6499501, lng: 10.9285026 },
        zoom: 10,
        disableDefaultUI: true,
        zoomControl: true,
      },
      markers: [],
      googleMarkers: [],
      dragged: null,
      selectedRadius: 3000,
      minRadius: 1000,
      maxRadius: 9000,
      stepRadius: 1000,
      placeVal: {},
      debounceTimeout: null,
      isLoadingPlaces: false,
      icons,
      markersSaved: []
    };
  },
  computed: {
    mergedOptions() {
      let update = null;

      if (this.mapCenter) {
        update = {
          center: this.mapCenter,
        };
      }
      return {
        ...this.defaultOptions,
        ...this.mapOptions,
        ...update,
      };
    },
    filteredMarkers() {
      this.markers.forEach(marker => {
        const shouldShow = marker.title && marker.title.toLowerCase().includes(this.seachFilter.toLowerCase());
        marker.setVisible(shouldShow);
      });
      return this.markers.filter(marker => marker.getVisible());
    },
  },
  watch: {
    placeVal(newValue) {
      if (newValue && newValue.geometry && newValue.geometry.location) {
        let previousMarker = this.currentMarker;
        if (previousMarker) {
          previousMarker.setMap(null);
        }
        const marker = new google.maps.Marker({
          position: newValue.geometry.location,
          map: this.map,
          title: newValue.name,
        });
        this.currentMarker = marker;
        this.openWindow(this.currentMarker, newValue);
        this.bounds.extend(newValue.geometry.location);
        this.map.fitBounds(this.bounds);
      }
    },
    selectedRadius(newRadius) {
      this.getNearbyPlaces(this.mergedOptions.center, newRadius);
      if (this.circle) {
        this.circle.setMap(null);
      }
      this.circle = new google.maps.Circle({
        strokeColor: "#00A2FF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#87CEEB",
        fillOpacity: 0.35,
        center: this.mergedOptions.center,
        radius: Number(this.selectedRadius),
        map: this.map
      });
      this.adjustMapBounds()
    },
    mapCenter: {
      deep: true,
      handler() {
        if (!this.map) return;

        this.map.setOptions(this.mergedOptions);
        for (let i = 0; i < this.markers.length; i++) {
          this.markers[i].setMap(null);
        }
        this.markers = [];
        this.initMap();
      },
    },
  },
  mounted() {
    const element = this.$refs.map;
    this.map = new google.maps.Map(element, this.mergedOptions);
    this.initMap();
    this.showPanel()
  },
  methods: {
    debouncedDistance(event) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.selectedRadius = event.target.value;
      }, 300);
    },
    cleanPlaceVal() {
      this.placeVal = {};
    },
    openWindow(marker, place) {
      const request = {
        placeId: place.place_id,
        fields: ['name', 'formatted_address', 'geometry', 'website', 'photos', 'address_components', 'rating', 'international_phone_number']
      };
      this.service.getDetails(request, (placeResult, status) => {
        this.$emit('selected-place', {
          place_id: place.place_id,
          address_components: placeResult.address_components,
          geometry: placeResult.geometry,
          formatted_address: placeResult.formatted_address
        }, this.field);
        this.showDetails(placeResult, marker, status);
      });
    },
    initMap() {
      if (!this.map) return;
      //client marker
      new google.maps.Marker({
        position: this.mergedOptions.center,
        map: this.map,
        icon: '/media/google-markers/client.png',
        title: "Client"
      });
      this.bounds = new google.maps.LatLngBounds();
      this.currentInfoWindow = this.infoWindow;
      this.infoPane = this.$refs.panel;
      this.infowindow = new google.maps.InfoWindow();
      this.getNearbyPlaces(this.mergedOptions.center, Number(this.selectedRadius));
      this.circle = new google.maps.Circle({
        strokeColor: "#00A2FF",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "#87CEEB",
        fillOpacity: 0.35,
        center: this.mergedOptions.center,
        radius: Number(this.selectedRadius),
        map: this.map
      });
      this.adjustMapBounds()
    },

    clearGoogleMarkers() {
      this.googleMarkers.forEach((marker) => {
        // Removes the dragend listener.
        google.maps.event.clearListeners(marker, "dragend");
        // Removes click listener.
        google.maps.event.clearListeners(marker, "click");
        // Deletes the marker from the map.
        marker.setMap(null);
      });
      this.googleMarkers = [];
    },

    getNearbyPlaces(position, radius) {
      let request = {
        location: position,
        radius: radius,
        type: this.vehiclesMap[this.vehicle].type,
        keyword: this.vehiclesMap[this.vehicle].search,
      };

      this.service = new google.maps.places.PlacesService(this.map,);
      this.service.nearbySearch(request, this.nearbyCallback);
    },

    nearbyCallback(results, status, pagination) {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.createMarkers(results);
      }
      if (pagination.hasNextPage) {
        setTimeout(() => {
          pagination.nextPage();
        }, 1000);
        this.isLoadingPlaces = true;
      } else {
        this.isLoadingPlaces = false;
      }
    },

    createMarkers(places) {
      this.markersByRadius = this.markersByRadius || {};
      this.markersByRadius[this.selectedRadius] = this.markersByRadius[this.selectedRadius] || [];

      const mainMarker = new google.maps.Marker({
        position: this.mergedOptions.center,
        map: this.map,
        icon: '/media/google-markers/client.png',
        title: "Client"
      });
      this.markersByRadius[this.selectedRadius].push(mainMarker);
      let markersSaved = [...this.markersByRadius[this.selectedRadius]];
      places.forEach(place => {
        const distance = google.maps.geometry.spherical.computeDistanceBetween(place.geometry.location, this.mergedOptions.center);
        if (distance <= this.selectedRadius) {
          const positionString = place.geometry.location.toString();
          if (!markersSaved.some(marker => marker.getPosition().toString() === positionString)) {
            const marker = new google.maps.Marker({
              position: place.geometry.location,
              map: this.map,
              icon: place.center ? null : (this.iconsMap[this.vehicle] ? `${this.icon.base}${this.iconsMap[this.vehicle]}` : null),
              title: place.name
            });
            google.maps.event.addListener(marker, 'click', () => {
              const request = {
                placeId: place.place_id,
                fields: ['name', 'formatted_address', 'geometry', 'website', 'photos', 'address_components', 'rating', 'international_phone_number']
              };
              this.service.getDetails(request, (placeResult, status) => {
                this.$emit('selected-place', {
                  place_id: place.place_id,
                  address_components: placeResult.address_components,
                  geometry: placeResult.geometry,
                  formatted_address: placeResult.formatted_address
                }, this.field);
                this.showDetails(placeResult, marker, status);
              });
            });
            markersSaved.push(marker);
          }
        }
      });
    },
    adjustMapBounds() {
      const centerLatLng = new google.maps.LatLng(this.mergedOptions.center.lat, this.mergedOptions.center.lng);
      const circleRadius = this.selectedRadius
      const zoomLevel = this.calculateZoomLevel(circleRadius);

      this.map.setZoom(zoomLevel);
      this.map.setCenter(centerLatLng);
    },

    calculateZoomLevel(circleRadius) {
      if (circleRadius <= 1000) {
        return 15
      }
      if (circleRadius >= 2000 && circleRadius <= 2000) {
        return 14
      }
      if (circleRadius >= 3000 && circleRadius <= 5000) {
        return 13
      }
      if (circleRadius >= 6000 && circleRadius <= 8000) {
        return 12
      }
      if (circleRadius >= 9000 && circleRadius <= 10000) {
        return 11
      }
    },

    showDetails(placeResult, marker, status) {
      if (status == google.maps.places.PlacesServiceStatus.OK) {
        let placeInfowindow = new google.maps.InfoWindow();
        let rating = placeResult.rating ? placeResult.rating : "None"
        let phoneNumber = placeResult.international_phone_number ? placeResult.international_phone_number : "Not Number phone";
        placeInfowindow.setContent(
          "<div><strong>" + placeResult.name + "</strong><br>" + "Rating: " + rating + "<br>" + "Number Phone: " + phoneNumber + "</div>",
        );
        placeInfowindow.open(marker.map, marker);
        this.currentInfoWindow && this.currentInfoWindow.close();
        this.currentInfoWindow = placeInfowindow;
        this.showPanel(placeResult);
      } else {
        console.log("showDetails failed: " + status);
      }
    },

    showPanel(placeResult) {
      if (!placeResult || Object.keys(placeResult).length === 0) {
        this.clearInfoPane();
        let title = document.createElement("h4");
        let message = document.createElement("p");
        title.textContent = "No information to show";
        message.textContent = "Please click a icon on the map to view details.";
        this.infoPane.appendChild(title);
        this.infoPane.appendChild(message);
        this.infoPane.classList.add("open");
        return;
      }
      // If infoPane is already open, close it
      if (this.infoPane.classList.contains("open")) {
        this.infoPane.classList.remove("open");
      }

      // Clear the previous details
      while (this.infoPane.lastChild) {
        this.infoPane.removeChild(this.infoPane.lastChild);
      }

      /* TODO: Step 4E: Display a Place Photo with the Place Details */
      // Add the primary photo, if there is one
      if (placeResult.photos) {
        let firstPhoto = placeResult.photos[0];
        let photo = document.createElement("img");
        photo.classList.add("hero");
        photo.src = firstPhoto.getUrl();
        this.infoPane.appendChild(photo);
      }

      // Add place details with text formatting
      let name = document.createElement("h1");
      name.classList.add("place");
      name.textContent = placeResult.name;
      this.infoPane.appendChild(name);
      if (placeResult.rating) {
        let rating = document.createElement("p");
        rating.classList.add("details");
        rating.textContent = `Rating: ${placeResult.rating} \u272e`;
        this.infoPane.appendChild(rating);
      }
      if (placeResult.international_phone_number) {
        let numberPhone = document.createElement("p");
        numberPhone.classList.add("details");
        numberPhone.textContent = `Number Phone: ${placeResult.international_phone_number}`;
        this.infoPane.appendChild(numberPhone);
      }
      let address = document.createElement("p");
      address.classList.add("details");
      address.textContent = placeResult.formatted_address;
      this.infoPane.appendChild(address);
      if (placeResult.website || placeResult) {
        // Crear un contenedor para los botones
        let buttonsContainer = document.createElement("div");
        buttonsContainer.classList.add("buttons-container"); // Agregar una clase para aplicar estilos CSS
        if (placeResult.website) {
          let websiteLink = document.createElement("a");
          let websitePara = document.createElement("button");
          websiteLink.classList.add("btn");
          websiteLink.classList.add("btn-primary");
          websiteLink.setAttribute("role", "button");
          websiteLink.setAttribute("href", "button");
          let websiteUrl = "Visit website";
          websiteLink.title = placeResult.website;
          websiteLink.innerHTML = websiteUrl;
          websiteLink.target = "blank";
          websiteLink.href = placeResult.website;
          websitePara.appendChild(websiteLink);
          buttonsContainer.appendChild(websitePara);
        }

        if (placeResult) {
          let clearButton = document.createElement("button");
          clearButton.classList.add("btn");
          clearButton.classList.add("btn-danger");
          clearButton.classList.add("clear-button"); // Agregar una clase para aplicar estilos CSS al botón "Clear"
          clearButton.textContent = "Clear";

          clearButton.addEventListener("click", () => {
            this.clearInfoPane();
            this.currentInfoWindow.close()
            this.$emit('selected-place', {})
            let title = document.createElement("h4");
            let message = document.createElement("p");
            title.textContent = "No information to show";
            message.textContent = "Please click an icon on the map to view details.";
            this.infoPane.appendChild(title);
            this.infoPane.appendChild(message);
            this.infoPane.classList.add("open");
            return;
          });

          buttonsContainer.appendChild(clearButton);
        }

        // Agregar el contenedor de botones al infoPane
        this.infoPane.appendChild(buttonsContainer);
      }

      // Open the infoPane
      this.infoPane.classList.add("open");
    },
    clearInfoPane() {
      // Limpiar el panel de información
      while (this.infoPane.lastChild) {
        this.infoPane.removeChild(this.infoPane.lastChild);
      }
    },
    convertUnitOfMeasure(value) {
      return `${value / 1000} KM`
    },
  },
};
</script>

<style>
/* Always set the map height explicitly to define the size of the div
     * element that contains the map. */
#map {
  height: 70vh;
  min-height: 250px;
  background-color: grey;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* TODO: Step 4A1: Make a generic sidebar */
/* Styling for an info pane that slides out from the left.
     * Hidden by default. */
#panel {
  height: 100%;
  width: 0;
  background-color: white;
  position: fixed;
  z-index: 1;
  overflow-x: hidden;
  transition: all 0.2s ease-out;
}

.open {
  width: 250px;
  margin-right: 1.5rem;
}

/* Styling for place details */
.hero {
  width: 100%;
  height: auto;
  max-height: 166px;
  display: block;
}

.place {
  font-family: "open sans", arial, sans-serif;
  padding: 1rem 0;
  margin: 0;
}

.details {
  color: darkslategrey;
}

a {
  text-decoration: none;
  color: cadetblue;
}

.map-grid {
  display: grid;
  grid-template-columns: auto 1fr;
}

.buttons-container>* {
  margin-right: 10px;
}
</style>
