<template>
  <b-tabs v-if="travelPlans.length" id="plans-table-tab" v-model="tabIndex" content-class="mt-3">
    <b-tab v-for="(plan, i) in travelPlans" :key="i">
      <template #title>
        <secondment-travel-planner-tab-title :plan="plan" :readonly="!isEditMode"
          @create-travel-plan="$emit('create-travel-plan')" @clone-travel-plan="$emit('clone-travel-plan', $event)"
          @delete-travel-plan="$emit('delete-travel-plan', $event)">
          {{ $t("Travel Plan") }} {{ i + 1 }}
        </secondment-travel-planner-tab-title>
      </template>
      <div class="d-flex">
        <euro-select class="flex-grow-1" :value="plan.employees" :multiselect-props="{
          options: secondmentEmployees,
          label: 'name',
          trackBy: 'employee',
          placeholder: $t('Select your employees'),
          multiple: true,
        }" return-object custom-options :disabled="!isEditMode" @input="onTravelPlanEmployeesInput($event, plan)">
          <template #options="{ props: { option } }">
            <p class="mb-0" :class="[{ 'unplanned-employee': unplanned.includes(option.employee) }]">
              {{ option.name }}
            </p>
          </template>
        </euro-select>
      </div>
      <SecondmentTravelPlannerPlan :from-wizard="fromWizard" :plan="plan" :readonly="!isEditMode"
        :kml-layers-urls="kmlLayersUrls" :country="country" :google-client-address="googlePlaceClient">
      </SecondmentTravelPlannerPlan>
    </b-tab>
  </b-tabs>
</template>

<script>
import icons from "@/core/config/icons";
import RiskService from "@/core/services/risk/risk.service";
import SecondmentTravelPlannerTabTitle from "@/view/components/secondment-travel-planner/SecondmentTravelPlannerTabTitle.vue";
import SecondmentTravelPlannerPlan from "@/view/components/secondment-travel-planner/SecondmentTravelPlannerPlan.vue";
import AddressService from "@/core/services/address.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import physicalPersonAddressService from "@/core/services/physical-person/physical-person-address.service";
import { mapState } from 'vuex';
export default {
  components: {
    SecondmentTravelPlannerTabTitle,
    SecondmentTravelPlannerPlan,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    fromWizard: {
      type: Boolean,
      default: true,
    },
    travelPlans: {
      type: Array,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    country: {
      type: String,
      default: "",
    },
    clientAddressId: {
      type: Number,
      default: null,
    },
    client: {
      type: Object,
      default: () => { },
    },
    unplanned: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      icons,
      tabIndex: 0,
      isEditMode: true,
      kmlUrl: "",
      googlePlaceClient: null,
      clientEnums: {
        Company: {
          addresses: "companyaddress_set",
          addressService: CompanyAddressService,
        },
        PhysicalPerson: {
          addresses: "physicalpersonaddress_set",
          addressService: physicalPersonAddressService,
        },
      },
    };
  },

  computed: {
    ...mapState("capabilities", ["risk_map"]),
    kmlLayersUrls() {
      if (!this.kmlUrl) return [];

      return [this.kmlUrl];
    },

    currentTabPlan() {
      return this.travelPlans[this.tabIndex];
    },
  },

  watch: {
    country: {
      immediate: true,
      handler(country) {
        if (country) {
          this.getKmlLayerUrl(country);
        }
      },
    },

    clientAddressId: {
      immediate: true,
      async handler(id) {
        if (!id) return;
        const address = await AddressService.getByID(id);
        this.googlePlaceClient = address;
      },
    },
  },

  mounted() {
    if (this.disabled) {
      this.isEditMode = false;
    }
  },
  methods: {
    onTravelPlanEmployeesInput(newEmployees, currentTravelPlan) {
      currentTravelPlan.employees = newEmployees
      this.$emit("update:travelPlan", currentTravelPlan);
    },

    async getKmlLayerUrl(country) {
      if (!this.risk_map.includes(country)) {
        return
      }
      try {
        const { kml_url: url } = await RiskService.getCountryRiskMap(country);
        this.kmlUrl = url;
      } catch (err) {
        this.kmlUrl = "";
        console.log('====================================');
        console.log('@getKmlLayerUrl travel', err, err.status);
        console.log('====================================');
      }
    },
  },
};
</script>

<style scoped>
.bg-gray {
  background-color: #f4f4f42f;
}

::v-deep .multiselect__option:has(.unplanned-employee) {
  color: white;
  background: red;
}

::v-deep .multiselect__option:not(.multiselect__option--selected):not(.multiselect__option--higlight):hover {
  background: var(--primary);
}
</style>

<style>
#plans-table-tab .nav.nav-tabs .nav-item {
  z-index: 9;
}
</style>