<template>
  <div v-bind="bCardBindings" :body-class="bodyClass" class="width h-100 mb-4">
    <b-overlay rounded="sm" :show="isLoading">
      <div v-if="countryIso && !isLoading && countryMaxRiskLevel && countryMaxRiskLevel.max_risk > 0" class="my-6">
        <h3>{{ $t("Preliminary Information on Country Risk and Risk Procedures") }}</h3>
      </div>
      <div v-if="countryIso && !isLoading && countryMaxRiskLevel && countryMaxRiskLevel.max_risk > 0" class="row pb-4">
        <div class="col-4">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bold text-capitalize mb-2">
                {{ $t("Preliminary Information on Country Risk and Risk Procedures") }}:
              </div>
              <div>
                <div class="d-flex ">
                  <rectangle :label="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.label"
                    :color="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.color"></rectangle>
                  <span class="label label-inline label-lg font-weight-bold label-rounded ml-3">
                    <i class="mr-1" :class="icons.infoCircle"></i>
                    {{ $t("Showing risks from") }} {{ currentDay }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-5">
          <div class="d-flex align-items-center">
            <div>
              <div class="font-weight-bold text-capitalize mb-2">{{ $t("Worst risk areas in the country")
              }}:</div>
              <div class="d-flex flex-wrap m-n1">
                <rectangle v-for="(risk, idx) in countryMaxRiskLevel.max_risk_data" :key="idx" v-b-tooltip.hover
                  :title="riskLevelLegend[risk.risk - 1]?.label" :label="countriesMap[risk.country]?.name"
                  :color="riskLevelLegend[risk.risk - 1]?.color" class="m-1"></rectangle>
              </div>
            </div>
          </div>
        </div>
        <div class="col-3 pl-5 pt-4 text-right">
          <button v-if="riskMapAvailability" class="btn w-100 btn-light-primary font-weight-bolder text-uppercase"
            @click="openTravelAdvice">
            <i :class="icons.map"></i>
            {{ $t("Risks") }}
          </button>
          <button v-else class="btn btn-success font-weight-bolder text-uppercase"
            @click="$emit('swal-shop-risk-map', countryIso)">
            <span class="svg-icon svg-icon-md">
              <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
            </span>
            {{ $t("Subscribe risk map") }}
          </button>
        </div>
      </div>
    </b-overlay>

    <b-modal v-if="riskMapAvailability" :id="modalId" size="xl" hide-footer @hidden="onModalHidden">
      <template #modal-header>
        <div class="d-flex justify-content-between py-0 w-100">
          <div>
            <h3 class="mb-3 mt-1 font-weight-bolder text-uppercase">{{ $t("Risk Map Advice") }} {{
              countriesMap[countryIso].name
            }} </h3>
            <div v-for="(statistic, idx) in statistics" :key="idx"
              class="d-flex align-items-center justify-content-left pb-2">
              <div class="font-weight-bolder text-capitalize">{{
                statistic.label }}:</div>
              <div class="text-right font-weight-bold ml-3">
                <span v-if="typeof statistic.value == 'number'" class="font-weight-bolder"
                  :class="[statistic.value > 0 ? 'text-success' : 'text-danger']">
                  {{ statistic.value > 0 ? `+${statistic.value}` : statistic.value }}
                </span>
                <span v-else>
                  {{ statistic.value }}
                </span>
              </div>
            </div>
            <div v-if="countryMaxRiskLevel && countryMaxRiskLevel.max_risk > 0" class="mb-0">
              <div class="d-flex align-items-center">
                <span class="font-weight-bolder text-capitalize mr-2">{{ $t("General Max Risk Level Procedure of Country")
                }}:</span>
                <rectangle v-b-tooltip.hover :title="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]"
                  :label="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.label"
                  :color="riskLevelLegend[countryMaxRiskLevel.max_risk - 1]?.color"></rectangle>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-start">
            <b-button @click="closeTravelAdvice">
              {{ $t("Cancel") }}
            </b-button>
          </div>
        </div>
      </template>
      <b-row>
        <b-col cols="12" lg="12">
          <div class="form-group mb-6">
            <label class="font-weight-bolder">{{ $t("Map data overlay") }}</label>
            <div class="checkbox-inline">
              <label class="checkbox">
                <input v-model="riskOverlay" type="checkbox" />
                <span></span>
                {{ $t("Risk map") }}
              </label>
              <label class="checkbox">
                <input v-model="naturalDisastersOverlay" type="checkbox" />
                <span></span>
                {{ $t("Natural disasters") }}
              </label>
            </div>
          </div>
          <google-maps-map height="400px" class="border" :geocoder-options="gmapGeocoder" :kml-layers-urls="kmlUrl"
            :markers="markers" :directions="directions" :geo-json-layers="geoJsonLayers"
            @kml-click="onKmlClick"></google-maps-map>
          <ul class="level-legend mt-4">
            <li v-for="(levelLegend, idx) in riskLevelLegend" :key="idx" class="d-flex align-items-center">
              <rectangle :label="levelLegend.label" :color="levelLegend.color"></rectangle><span
                class="mr-2 font-weight-normal">{{
                  levelLegend.text }}</span>
            </li>
          </ul>
        </b-col>
        <b-col cols="12" lg="12">
          <div class="mb-1">
            <i :class="icons.map" class="pr-1"></i>
            {{ $t("Pointer location") }}:
          </div>
          <google-maps-autocomplete v-model="autocompleteStartLocation" :autocomplete-options="autocompleteOptions">
            <template #append>
              <b-btn variant="primary" :disabled="!canAddDestinations" @click="addDestination">
                <i :class="icons.add"></i>
              </b-btn>
            </template>
            <template #pointer-location>
              <div v-if="autocompleteStartLocation == null">
                <div class="text-muted small">{{ $t("Enter location or click on the map to see more information")
                }}</div>
              </div>
            </template>
          </google-maps-autocomplete>
          <google-maps-autocomplete v-for="(destination, idx) in destinations" :key="destination.key"
            v-model="destination.place" :label="idx == destinations.length - 1 ? 'Destination' : 'Waypoint'"
            :autocomplete-options="autocompleteOptions">
            <template #append>
              <b-btn variant="danger" @click="removeDestination(destination.key)">
                <i :class="icons.delete"></i>
              </b-btn>
            </template>
          </google-maps-autocomplete>
        </b-col>
        <b-col cols="12" lg="6">
          <div v-if="startLocationRisk">
            <div class="d-flex align-items-center mb-4">
              <div class="font-weight-bold mr-2">
                {{ $t("Selected location max risk level") }}:
              </div>
              <b-overlay rounded="sm" :show="startLocationRiskLoading" :opacity="0">
                <div v-if="startLocationRisk" class="d-flex justify-content-end font-weight-bold">
                  <rectangle v-b-tooltip.hover :label="riskLevelLegend[startLocationMaxRiskStatus - 1]?.label"
                    :title="riskLevelLegend[startLocationMaxRiskStatus - 1]"
                    :color="riskLevelLegend[startLocationMaxRiskStatus - 1]?.color"></rectangle>
                </div>
              </b-overlay>
            </div>
            <div v-if="startLocationRisk && startLocationMaxRiskCountries.length" class="mt-5">
              <rectangle v-for="country in startLocationMaxRiskCountries" :key="country.countryIso" v-b-tooltip.hover
                class="mb-2" :color="riskLevelLegend[country.value - 1]?.color"
                :label="countriesMap[country.countryIso].name">
              </rectangle>
            </div>
          </div>
        </b-col>
        <b-col cols="12" lg="6">
          <div v-if="startLocationWeather">
            <div>{{ $t("Selected location weather") }}:</div>
            <div>
              <b-overlay rounded="sm" :show="startLocationWeatherLoading" :opacity="0">
                <div v-if="startLocationWeather" class="font-weight-bold">
                  <img :src="`/media/weather/icons/${startLocationWeather?.WeatherIcon}.svg`" />
                  {{ startLocationWeather?.WeatherText }}
                  {{ startLocationWeather?.Temperature?.Metric?.Value }}°{{
                    startLocationWeather?.Temperature?.Metric?.Unit
                  }}
                </div>
              </b-overlay>
            </div>
          </div>

          <div v-if="airQuality" class="separator separator-dashed my-0"></div>
          <div v-if="airQuality">
            <div class="d-flex align-items-center mt-2">
              <div>
                <i class="fas fa-smog"></i>
                <span class="ml-2">{{ $t("Pollution Index") }}:</span>
              </div>
              <rectangle v-b-tooltip.hover class="ml-4" :title="airQuality.pollution_description" :color="airQualityColor"
                :label="airQuality.aqi + ''"></rectangle>
            </div>
            <div class="d-flex align-items-center mt-2">
              <div>
                <i class="fas fa-road"></i>
                <span class="ml-2">{{ $t("Distance from station") }}:</span>
              </div>
              <div class="ml-2">{{ airQuality.distance_from_station }} Km</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
import RiskService from "@/core/services/risk/risk.service";
import icons from "@/core/config/icons";
import NationCurrenciesService from "@/core/services/nation/nation-currencies.service";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import AccuWeatherService from "@/core/services/accuweather.service";
import DateService from "@/core/services/date.service";
import Swal from "sweetalert2";
import NaturalDisastersService from "@/core/services/natural-disasters.service";
import axios from "axios";

export default {
  components: {
    GoogleMapsMap,
    GoogleMapsAutocomplete,
    Rectangle,
  },

  props: {
    countryIso: {
      type: String,
      default: "",
    },
    bCardBindings: {
      type: Object,
      default: null,
    },
    activeSubs: {
      type: Object,
      default: () => { },
    },
    pendingSubs: {
      type: Object,
      default: () => { },
    },
    showEmptyMessage: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      isLoading: false,
      numbeoStatistics: null,
      exchangeRate: null,
      airQuality: null,
      kmlUrl: [],
      geoJsonLayers: [],
      naturalDisastersOverlay: false,
      riskOverlay: true,
      riskLevelLegend: [
        { code: 1, label: "A", text: this.$t("Low"), color: "#16a085" },
        { code: 2, label: "B", text: this.$t("Moderate"), color: "#f1c40f" },
        { code: 3, label: "C", text: this.$t("Medium"), color: "#f39c12" },
        { code: 4, label: "D", text: this.$t("High"), color: "#c0392b" },
      ],
      icons,
      autocompleteStartLocation: null,
      destinations: [],
      MAX_DESTINATIONS: 2,
      travelAdviceStats: [],
      startLocationRisk: null,
      startLocationRiskLoading: false,
      countryMaxRiskLevel: null,
      countryRiskLevel: null,
      startLocationWeather: null,
      startLocationWeatherLoading: null,
      postCategories: [],
      countriesToHighlight: [],
    };
  },

  computed: {
    ...mapGetters("user", ["getUserExpandedMainAddressCountryIso", "dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapState("capabilities", ["risk_map"]),

    currentDay() {
      return DateService.format(new Date(), this.dateFormat);
    },

    homeCountryIso() {
      return this.getUserExpandedMainAddressCountryIso;
    },

    airQualityColor() {
      const index = parseInt(this.airQuality?.aqi);
      let color = "grey";
      if (index < 50) color = "green";
      if (index > 50 && index <= 100) color = "yellow";
      if (index > 100 && index <= 150) color = "orange";
      if (index > 150 && index <= 200) color = "red";
      if (index > 200 && index <= 200) color = "purple";
      if (index > 300) color = "grey";
      return color;
    },

    bodyClass() {
      const out = {
        "d-flex align-items-center": !this.countryIso,
        "p-0": true,
      };
      return out;
    },
    statistics() {
      const out = [{ label: "Currency", value: this.countriesMap[this.countryIso]?.currency_name, icon: icons.bill }];

      if (this.exchangeRate) {
        out.push({
          label: "Exchange Rate",
          value: `1 ${this.countriesMap[this.homeCountryIso]?.currency_code} = ${this.exchangeRate.repr}`,
          icon: icons.exchange,
        });
      }

      if (this.numbeoStatistics) {
        for (const key of Object.keys(this.numbeoStatistics)) {
          out.push({
            label: key.replaceAll("_", " "),
            value: Math.round(this.numbeoStatistics[key].value * 100) / 100,
            icon: this.numbeoStatistics[key].icon,
          });
        }
      }

      return out;
    },
    gmapGeocoder() {
      return {
        componentRestrictions: {
          country: this.countryIso,
        },
      };
    },

    autocompleteOptions() {
      return {
        componentRestrictions: { country: this.countryIso },
      };
    },

    modalId() {
      return `modal-${this._uid}`;
    },

    markers() {
      let m = [];

      if (this.autocompleteStartLocation) {
        m = [
          {
            position: {
              lat: this.autocompleteStartLocation?.geometry?.location?.lat(),
              lng: this.autocompleteStartLocation?.geometry?.location?.lng(),
            },
            name: this.autocompleteStartLocation?.formatted_address,
          },
        ];
      }

      this.destinations.forEach((destination) => {
        if (!destination.place) return;

        m.push({
          position: {
            lat: destination?.place?.geometry?.location?.lat(),
            lng: destination?.place?.geometry?.location?.lng(),
          },
          name: destination?.place?.formatted_address,
        });
      });

      return m;
    },

    destinationsPlaces() {
      return this.destinations.map((d) => d.place);
    },

    directions() {
      const out = {};
      const places = [this.autocompleteStartLocation, ...this.destinationsPlaces];

      if (places.length < 2) return out;
      const lastPlaceIdx = places.length - 1;
      places.forEach((place, idx) => {
        if (!place) return;
        out.travelMode = google.maps.TravelMode.DRIVING;
        switch (idx) {
          case 0:
            out.origin = place.geometry.location;
            break;
          case lastPlaceIdx:
            out.destination = place.geometry.location;
            break;
          default:
            if (!out.waypoints) out.waypoints = [];
            out.waypoints.push({ location: place.geometry.location });
            break;
        }
      });

      return out;
    },

    canAddDestinations() {
      return this.MAX_DESTINATIONS > this.destinations.length;
    },

    startLocationMaxRiskStatus() {
      return this.startLocationRisk?.risk ?? 0;
    },

    startLocationMaxRiskCountries() {
      if (!this.startLocationRisk?.risk_status) {
        return [];
      }

      return Object.keys(this.startLocationRisk?.risk_status).reduce((acc, key) => {
        const value = this.startLocationRisk?.risk_status[key];
        acc.push({ countryIso: key, value: value });
        return acc;
      }, []);
    },

    riskMapAvailability() {
      return this.risk_map.includes(this.countryIso);
    }
  },

  watch: {
    countryIso() {
      this.getStatistics();
      this.getKmlLayerUrl();
    },

    async autocompleteStartLocation(startLocation) {
      if (startLocation) {
        this.loadStartLocationRisk(startLocation);
        this.loadStartLocationWeather(startLocation);
      }
    },
    riskOverlay() {
      this.getKmlLayerUrl();
    },
    async naturalDisastersOverlay(val) {
      if (val) {
        try {
          const CountryNaturalDisasters = await NaturalDisastersService.getNaturalDisasters(this.countryIso);
          // get urls of geoJSON files of all natural disasters for this country
          if (CountryNaturalDisasters.length > 0) {
            const geoJSONUrls = CountryNaturalDisasters.map((disaster) => disaster.properties.url);
            // get the geoJSON from geoJSONUrls .geometry url
            const geoJSONs = await Promise.all(geoJSONUrls.map((url) => axios.get(url.geometry)));
            this.geoJsonLayers = geoJSONs
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        this.geoJsonLayers = []
      }
    }
  },

  mounted() {
    if (this.countryIso) {
      this.getStatistics();
    }
  },

  methods: {
    ...mapActions("user", ["setUserCompanyAddress"]),
    goToShop() {
      this.$emit("go-to-shop", { country: this.countryIso, product: "RiskReport" });
    },
    async getStatistics() {
      if (!this.countryIso) return;

      this.isLoading = true;

      if (!this.homeCountryIso) {
        await this.setUserCompanyAddress().then(() => {
          if (!this.homeCountryIso) {
            Swal.fire({
              icon: "warning",
              title: "Please upload an address of your company to get comparison data",
            });
            return;
          }
        }).then(() => {
          try {
            this.exchangeRate = NationCurrenciesService.exchangeRate(
              this.countriesMap[this.homeCountryIso].currency_code,
              this.countriesMap[this.countryIso].currency_code,
            );
          } catch (err) {
            this.exchangeRate = null;
          }
        });
      }

      await RiskService.getCountryMaxRiskLevel(this.countryIso).then((countryMaxRiskLevel) => {
        if (countryMaxRiskLevel.max_risk === 4 || countryMaxRiskLevel.max_risk === 3) {
          this.$emit("max-risk-alert");
        }
        this.countryMaxRiskLevel = countryMaxRiskLevel
        this.$emit('update-selected-color', countryMaxRiskLevel.max_risk);
      }).catch(err => {
        console.error(err);
      })

      this.isLoading = false;
    },

    async getKmlLayerUrl() {
      if (!this.risk_map.includes(this.countryIso)) {
        return
      }
      try {
        if (this.riskOverlay) {
          const { kml_url: url } = await RiskService.getCountryRiskMap(this.countryIso);
          this.kmlUrl = [url];
        }
        else {
          this.kmlUrl = [];
        }
      } catch (err) {
        this.kmlUrl = [];
        console.log('====================================');
        console.log('@getKmlLayerUrl card', err);
        console.log('====================================');
      }
    },

    async openTravelAdvice() {
      await this.getKmlLayerUrl();
      this.$bvModal.show(this.modalId);
    },

    closeTravelAdvice() {
      this.$bvModal.hide(this.modalId);
      this.kmlUrl = [];
      this.autocompleteStartLocation = null;
      this.destinations = [];
      this.startLocationRisk = null;
      this.startLocationWeather = null;
      this.airQuality = null;
    },
    onModalHidden() {
      this.$bvModal.hide(this.modalId);
      this.kmlUrl = [];
      this.autocompleteStartLocation = null;
      this.destinations = [];
      this.startLocationRisk = null;
      this.startLocationWeather = null;
      this.airQuality = null;
      this.naturalDisastersOverlay = false;
      this.riskOverlay = true;
    },

    onKmlClick(payload) {
      this.autocompleteStartLocation = payload;
    },

    addDestination() {
      if (this.canAddDestination) {
        return;
      }
      const key = (Math.random() + 1).toString(36).substring(7);
      this.destinations.unshift({ key, place: null });
    },

    removeDestination(key) {
      const idx = this.destinations.findIndex((destination) => destination.key === key);
      if (idx == -1) return;
      this.destinations.splice(idx, 1);
    },

    async loadStartLocationRisk(startLocation) {
      this.startLocationRiskLoading = true;
      this.startLocationRisk = null;

      try {
        this.startLocationRisk = await RiskService.getPlaceRiskLevel(
          this.countryIso,
          startLocation.geometry.location.lat(),
          startLocation.geometry.location.lng(),
        );
        this.airQuality = await RiskService.getAirQualityData({
          lat: startLocation.geometry.location.lat(),
          lng: startLocation.geometry.location.lng(),
        });
      } catch (err) {
        console.error(err);
      } finally {
        this.startLocationRiskLoading = false;
      }
    },
    async loadStartLocationWeather(startLocation) {
      this.startLocationWeatherLoading = true;
      this.startLocationWeather = null;
      try {
        const weather = await AccuWeatherService.getWeather(
          startLocation.geometry.location.lat(),
          startLocation.geometry.location.lng(),
          localStorage.getItem("language") || "en",
        );
        this.startLocationWeather = weather[0];
      } catch (err) {
        console.log(err);
      } finally {
        this.startLocationWeatherLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.width {
  min-width: 150px;
}

ul {
  list-style: none;

  &.level-legend {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
  }
}
</style>