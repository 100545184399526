var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex m-n1"
  }, [_c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "passport",
      "document": _vm.item.availability_data.documents.passport,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "identity_document",
      "document": _vm.item.availability_data.documents.identity_document,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "postingcertificate",
      "posting-type": "",
      "posting-validity": [_vm.item.availability_data.documents.postingcertificate_art12, _vm.item.availability_data.documents.postingcertificate_art13],
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "europeanhealthinsurancecard",
      "document": _vm.item.availability_data.documents.europeanhealthinsurancecard,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  })], 1), _c('div', {
    staticClass: "d-flex m-n1"
  }, [_c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "certificate_suitability_for_work",
      "document": _vm.item.availability_data.documents.certificate_suitability_for_work,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "employment_contract",
      "document": _vm.item.availability_data.documents.employment_contract,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('EmployeeDocumentValidity', {
    attrs: {
      "document-type": "last_paycheck",
      "document": _vm.item.availability_data.documents.last_paycheck,
      "employee-date": _vm.getEmployeeDate(_vm.item.id),
      "secondment-date": _vm.getSecondmentDate
    }
  }), _c('driving-license-info', _vm._b({
    attrs: {
      "entity": _vm.item
    }
  }, 'driving-license-info', _vm.checkLicenseProps, false))], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }