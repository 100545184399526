<template>
  <div class="d-flex flex-column">
    <slot name="container-top"></slot>
    <div class="timeline-row" :class="rowClasses" :style="{ 'grid-auto-columns': columnsSize }">
      <div class="timeline-row-left">
        <slot name="container-left"></slot>
      </div>
      <div class="timeline-row-ruler">
        <slot name="marker">
          <timeline-marker-circle></timeline-marker-circle>
        </slot>
      </div>
      <div class="timeline-row-right">
        <slot name="container-right"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import TimelineMarkerCircle from "./TimelineMarkerCircle.vue";
export default {
  components: {
    TimelineMarkerCircle,
  },

  props: {
    columnsSize: {
      type: String,
      default: "minmax(0, 1fr) minmax(0, 0.2fr) minmax(0, 1fr)",
    },
    active: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    rowClasses() {
      const classes = [];
      if (this.active) {
        classes.push("active");
      }
      return classes;
    },
  },
};
</script>

<style lang="scss" scoped>
$markerSize: 30px;

.timeline-row {
  display: grid;
  grid-auto-flow: column;
  gap: 1rem;
  row-gap: 1rem;
  min-height: 5rem;

  & .timeline-row-left {
    text-align: right;
    width: 0;
  }
  & .timeline-row-right {
  }

  & .timeline-row-ruler {
    position: relative;
    z-index: 1;

    & .timeline-row-ruler-marker {
      height: $markerSize;
      width: $markerSize;
      border-radius: 50%;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;

      & .timeline-row-ruler-marker-icon {
        color: #505050;
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: $markerSize;
      bottom: 0;
      left: 50%;
      border: 1px solid #9f9f9f;
      transform: translate(-50%);
      z-index: -1;
    }
  }
}
</style>
