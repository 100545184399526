<template>
  <div>
    <validation-provider v-slot="{ errors }" name="client" vid="client" rules="required"
      :class="{ 'd-none': secondmentClientComputed }">
      <secondment-client-table ref="clientTable" v-model="selectedClientComputed" :countries="countries"
        :default-country-filter="defaultCountryFilter" :service="service" :actors="actors"
        @clients-change="$emit('clients-change', $event)">
        <template #topbar-end>
          <b-dropdown right :text="$t('Add new client')" variant="btn btn-light-primary font-weight-bolder">
            <template #button-content>
              <span class="svg-icon menu-icon">
                <inline-svg src="/media/svg/icons/Code/Plus.svg" />
              </span>
              {{ $t("Add new client") }}
            </template>
            <b-dropdown-item v-b-modal.newClient @click="organizationType = 'physical_person'">
              {{ $t("Add Physical Person") }}
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.newClient @click="organizationType = 'company'">
              {{ $t("Add Legal Person") }}
            </b-dropdown-item>
          </b-dropdown>
          <SecondmentActorCreateModal id="newClient" ref="newClient" :title="$t('Add new client')"
            :text="$t('Please select the kind of client you would like to add')"
            :back-btn-tooltip="$t('Back to client type choice')" :organization-type="organizationType"
            @hidden="onSecondmentActorCreateModalHidden" @close="closeModal" @created="refreshTableAndCloseModal">
          </SecondmentActorCreateModal>
        </template>
      </secondment-client-table>
      <p v-if="errors" class="text-danger">{{ errors[0] }}</p>
    </validation-provider>

    <b-card class="slider" :class="{ close: !secondmentClientComputed }">
      <template v-if="secondmentClientComputed">
        <b-card-title>
          <div class="d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <avatar :avatar-image="selectedClientComputed.logo" :avatar-text="selectedClientComputed.name[0]"></avatar>
              <div class="ml-3 font-weight-bolder">{{ selectedClientComputed.name }}</div>
            </div>
            <b-button variant="primary" @click="onSelectAnotherClientBtnClick">
              <i :class="icons.undo"></i>
              {{ $t("Select another client") }}
            </b-button>
          </div>
        </b-card-title>
        <div class="row">
          <div class="col-6">
            <div class="mb-5">
              <div class="d-flex align-items-center mb-4">
                <h5 class="mb-0">{{ $t("Destination") }}</h5>
                <b-btn variant="light-primary" class="btn-xs ml-4" @click="onClientAddressBtnClick">
                  <span class="svg-icon menu-icon">
                    <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                  </span>
                  <span class="font-weight-bolder">{{ $t("Add New Address") }}</span>
                </b-btn>
              </div>
              <div>
                <validation-provider v-slot="{ errors }" name="address" vid="address" rules="required_if:client" tag="div"
                  class="">
                  <euro-select :value="secondmentClientComputedAddress" placeholder="Work Address"
                    :options="addressSelectable" :error-messages="errors" :multiselect-props="{
                      'allow-empty': false,
                    }" @input="onSecondmentClientAddressInput">
                  </euro-select>
                </validation-provider>
              </div>
            </div>
            <div class="separator separator-dashed my-6"></div>
            <div class="border">
              <address-map :lat="clientAddressCenter?.latitude" :lng="clientAddressCenter?.longitude"
                height="450"></address-map>
            </div>
          </div>
          <div class="col-6">
            <div class="from row">
              <div class="col-12">
                <div class="d-flex justify-content-end">
                  <h5 class="pr-3 form-label mb-6">{{ $t("Allow Notifications") }}:</h5>
                  <b-form-checkbox v-model="secondmentClient.notify" switch size="lg"></b-form-checkbox>
                </div>
              </div>
              <div v-if="telephoneContact" class="col-6">
                <h5 class="mt-5">{{ $t("Telephone Contacts") }}</h5>
                <p>{{ telephoneContact.number }}</p>
              </div>
              <div v-if="emailContact" class="col-6">
                <h5 class="mt-5">{{ $t("Email Contacts") }}</h5>
                <p>{{ emailContact.email }}</p>
              </div>
              <div v-if="telephoneContact || emailContact" class="col-12">
                <div class="separator separator-dashed my-6"></div>
              </div>
              <div class="col-12">
                <euro-alert show variant="warning">
                  <div class="d-flex align-items-center">
                    <i :class="icons.infoCircle" class="mr-2" :style="{ color: '#fff' }"></i>
                    <span>{{ $t("Showing data at") }} {{ currentDate }}</span>
                  </div>
                </euro-alert>
              </div>
              <div class="col-12">
                <b-overlay :show="startLocationWeatherLoading">
                  <div v-if="!startLocationWeather" class="p-8"></div>
                  <div class="d-flex flex-wrap mt-5">
                    <div v-if="startLocationWeather"
                      class="d-flex justify-content-start align-items-center font-weight-bold border rounded pr-8 mr-4 mb-4">
                      <img :src="`/media/weather/icons/${startLocationWeather?.WeatherIcon}.svg`" />
                      {{ startLocationWeather?.WeatherText }} {{ startLocationWeather?.Temperature?.Metric?.Value }}°{{
                        startLocationWeather?.Temperature?.Metric?.Unit
                      }}
                      <span v-if="startLocationWeather.current_focus_data">
                        <rectangle v-b-tooltip.hover class="ml-2"
                          :title="`Starting location temperature: ${startLocationWeather.current_focus_data?.Temperature?.Metric?.Value}° ${startLocationWeather.current_focus_data?.Temperature?.Metric?.Unit}`"
                          :color="tempDiffColor"
                          :label="`${tempDiffSymbol}${Math.abs(tempDiffValue)}°${startLocationWeather.current_focus_data?.Temperature?.Metric?.Unit}`">
                        </rectangle>
                      </span>
                    </div>
                    <div v-if="airQuality && airQuality.distance_from_station <= 10"
                      class="d-flex justify-content-start align-items-center font-weight-bold border rounded p-6 mr-4 mb-4">
                      <div class="mr-4">
                        <img src="/media/svg/icons/Weather/Fog.svg" />
                      </div>
                      <span>{{ $t("Pollution Index") }}: <rectangle v-b-tooltip.hover
                          :title="airQuality.pollution_description" :color="airQualityColor" :label="airQuality.aqi + ''">
                        </rectangle></span>
                    </div>
                    <div v-if="airQuality && airQuality.distance_from_station <= 10"
                      class="d-flex justify-content-start align-items-center font-weight-bold border rounded p-6 mr-4 mb-4">
                      <div class="mr-4">
                        <img src="/media/svg/icons/Home/Ruller.svg" />
                      </div>
                      <span>{{ $t("Distance from station") }}: {{ airQuality.distance_from_station }} Km</span>
                    </div>
                  </div>
                </b-overlay>
              </div>
              <div v-if="startLocationRisk" class="col-12">
                <div class="separator separator-dashed my-6"></div>
              </div>
              <div v-if="startLocationRisk" class="col-12">
                <h5 class="mb-4">{{ $t("Destination max risk level") }}: <rectangle v-b-tooltip.hover
                    :title="riskLevelLegend[startLocationMaxRiskStatus - 1]?.label"
                    :label="riskLevelLegend[startLocationMaxRiskStatus - 1]?.label"
                    :color="riskLevelLegend[startLocationMaxRiskStatus - 1]?.color"></rectangle>
                </h5>
                <div v-if="startLocationRisk && startLocationMaxRiskCountries.length">
                  <div class="d-flex flex-wrap">
                    <div v-for="country in startLocationMaxRiskCountries" :key="country.countryIso">
                      <rectangle v-b-tooltip.hover :color="riskLevelLegend[country.value - 1]?.color"
                        :label="countriesMap[country.countryIso].name" :title="riskLevelLegend[country.value - 1]?.label"
                        class="mb-2">
                      </rectangle>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="contactPersons?.length" class="col-12">
                <div class="separator separator-dashed my-6"></div>
              </div>
              <div v-if="contactPersons?.length" class="col-12">
                <h5 class="mb-3">{{ $t("Contacts") }}</h5>
                <div>
                  <secondment-client-contact-card v-for="(contact, idx) in contactPersons" :key="idx" :name="contact.name"
                    :email="contact.email?.email" :telephone="contact.telephone?.number" :avatar-url="contact.avatar"
                    :avatar-backup="contact.avatarBackup"></secondment-client-contact-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <b-modal id="client-modal-address" title="Address Form" size="lg" hide-footer @hidden="onClientAddressModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center">
            <h3 class="mb-0">{{ $t("New address") }}</h3>
            <div class="d-flex align-items-center">
              <div class="mr-4">
                <b-button variant="success" @click="onClientAddressSubmit">
                  {{ $t("Add") }}
                </b-button>
              </div>
              <div>
                <b-button @click="closeClientAddressModal">
                  {{ $t('Close') }}
                </b-button>
              </div>
            </div>
          </div>
        </template>

        <validation-observer ref="addressAddValidation">
          <b-row>
            <b-col>
              <euro-select v-model="addToClient" label="Action" :options="saveOnClientOptions"></euro-select>
            </b-col>
            <b-col>
              <validation-provider v-slot="{ errors }" vid="address_kind">
                <euro-select v-model="clientAddressForm.kind" :label="$t('Kind')" :errors="errors"
                  :options="selectableKinds"></euro-select>
              </validation-provider>
            </b-col>
          </b-row>
          <validation-provider v-slot="{ errors }" vid="address_address" :rules="{ required: true }">
            <AddressInput v-model="clientAddressForm.address" :label="$t('Address')" :error-messages="errors"
              :address-center="clientAddressCenter?.address" :active-step="true"
              :manual-address="clientAddressForm.manual_address" @change-manual-address="changeManualAddress"
              @update-composed-address="updateComposedAddress"></AddressInput>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import AddressMap from "@/view/components/AddressMap.vue";
import SecondmentClientTable from "@/view/components/tables/SecondmentClientTable.vue";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import SecondmentClientContactCard from "@/view/components/cards/SecondmentClientContactCard.vue";
import SecondmentActorCreateModal from "@/view/components/modals/secondment/SecondmentActorCreateModal.vue";
import EuroAlert from "@/view/components/alerts/EuroAlert.vue";
import { addCompanyAddress } from "@/core/abstractions/company";
import { addPhysicalPersonAddress } from "@/core/abstractions/physicalPerson";
import DateService from "@/core/services/date.service";
import RiskService from "@/core/services/risk/risk.service";
import AddressService from "@/core/services/address.service";
import AccuWeatherService from "@/core/services/accuweather.service";
import { mapState, mapGetters } from "vuex";
import Swal from "sweetalert2";
import { addressValidationSwal } from "@/core/helpers/swal";
import icons from "@/core/config/icons.js";
import { successToast } from "@/core/helpers";
import eventBus from "@/core/services/event/eventBus.js"


export default {
  components: {
    AddressMap,
    SecondmentClientTable,
    SecondmentClientContactCard,
    Rectangle,
    SecondmentActorCreateModal,
    EuroAlert,
  },

  props: {
    wizardMode: {
      type: String,
      default: "create",
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    secondmentClient: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
    countriesSelectable: {
      type: Array,
      required: true,
    },
    isIntragroupWork: {
      type: Boolean,
      default: false,
    },
    defaultCountryFilter: {
      type: String,
      default: "",
    },
    service: {
      type: String,
      required: true,
    },
    actors: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      organizationType: 'company',
      addToClient: true,
      saveOnClientOptions: [
        {
          text: "Add to client",
          value: true,
        },
        {
          text: "Don't add to client",
          value: false,
        },
      ],
      canContinue: true,
      airQuality: null,
      startLocationRiskLoading: false,
      startLocationRisk: null,
      startLocationWeatherLoading: false,
      startLocationWeather: null,
      riskLevelLegend: [
        { code: 1, label: this.$t("Low"), color: "#16a085" },
        { code: 2, label: this.$t("Moderate"), color: "#f1c40f" },
        { code: 3, label: this.$t("Medium"), color: "#f39c12" },
        { code: 4, label: this.$t("High"), color: "#c0392b" },
      ],
      legalPersonWizardErrors: null,
      icons,
      clientAddressForm: {
        address: null,
        kind: 0,
        manual_address: false,
        composed_address: null
      },
    };
  },

  computed: {
    ...mapState("constants", ["physicalAddressKinds", "companyAddressKinds"]),
    ...mapState("capabilities", ["risk_map"]),
    ...mapGetters("user", ["dateFormat"]),
    ...mapGetters("constants", ["countriesMap"]),

    currentDate() {
      return DateService.format(new Date(), this.dateFormat);
    },

    selectableKinds() {
      return this.selectedClient.kind === "Company" || this.selectedClient.kind === 0
        ? this.companyAddressKinds.map((el) => ({ value: el.value, text: el.display_name }))
        : this.physicalAddressKinds.map((el) => ({ value: el.value, text: el.display_name }));
    },

    airQualityColor() {
      const index = parseInt(this.airQuality?.aqi);
      let color = "grey";
      if (index < 50) color = "#44bd32";
      if (index > 50 && index <= 100) color = "yellow";
      if (index > 100 && index <= 150) color = "orange";
      if (index > 150 && index <= 200) color = "red";
      if (index > 200 && index <= 200) color = "purple";
      if (index > 300) color = "grey";
      return color;
    },
    startLocationMaxRiskStatus() {
      return this.startLocationRisk?.risk ?? 0;
    },

    startLocationMaxRiskCountries() {
      if (!this.startLocationRisk?.risk_status) {
        return [];
      }

      return Object.keys(this.startLocationRisk?.risk_status).reduce((acc, key) => {
        const value = this.startLocationRisk?.risk_status[key];
        acc.push({ countryIso: key, value: value });
        return acc;
      }, []);
    },

    selectedClientComputed: {
      get() {
        return this.selectedClient;
      },
      set(val) {
        this.$emit("selected-client-change", val);
      },
    },

    secondmentClientComputed: {
      get() {
        return this.secondmentClient;
      },
      set(val) {
        this.$emit("secondment-client-change", val);
      },
    },

    clientAddreses() {
      let addresses = [];
      if (this.selectedClient) {
        addresses = this.selectedClient.addresses;
      }
      return addresses;
    },

    clientAddressCenter() {
      const addr = this.clientAddreses.find(
        addr => addr?.id == this.secondmentClient.address,
      );
      if (!addr) {
        return null;
      }
      return addr;
    },

    addressSelectable() {
      if (!this.selectedClientComputed) return [];
      return this.clientAddreses.map((x) => ({
        value: x.id,
        text: x.formatted,
      }));
    },

    emailContact() {
      if (!this.selectedClientComputed.contacts) return null;

      let emailContacts;
      if (this.selectedClientComputed.kind == "PhysicalPerson") {
        emailContacts = this.selectedClientComputed.contacts.extraemail_set;
      } else {
        emailContacts = this.selectedClientComputed.contacts.emailcompany_set;
      }

      if (!emailContacts || emailContacts.length == 0) return null;

      const email = emailContacts.find((email) => email?.main == true);

      return email ?? emailContacts[0];
    },

    telephoneContact() {
      if (!this.selectedClientComputed.contacts) return null;

      let telephoneContacts;
      if (this.selectedClientComputed.kind == "PhysicalPerson") {
        telephoneContacts = this.selectedClientComputed.contacts.telcontact_set;
      } else {
        telephoneContacts = this.selectedClientComputed.contacts.telcontactscompany_set;
      }

      if (!telephoneContacts || telephoneContacts.length == 0) return null;

      const tel = telephoneContacts.find((tel) => tel?.main == true);

      return tel ?? telephoneContacts[0];
    },

    contactPersons() {
      if (!this.selectedClientComputed.contact_persons) return null;

      return this.selectedClientComputed.contact_persons.map((cp) => {
        const mainTel = cp.telcontact_set.find((tel) => tel?.main == true);
        const mainMail = cp.extraemail_set.find((mail) => mail?.main == true);

        return {
          avatar: cp.avatar ?? "",
          name: cp.name ?? "",
          telephone: mainTel ?? cp.telcontact_set.length ? cp.telcontact_set[0] : "",
          mainMail: mainMail ?? cp.extraemail_set.length ? cp.extraemail_set[0] : "",
          avatarBackup: cp.name[0],
        };
      });
    },

    secondmentClientComputedAddress() {
      if (this.secondmentClientComputed.hasOwnProperty('address')) {
        return this.secondmentClientComputed.address
      }

      return null
    },
    tempDiffValue() {
      let diff = this.startLocationWeather?.Temperature?.Metric?.Value - this.startLocationWeather.current_focus_data?.Temperature?.Metric?.Value;
      return diff.toFixed(1);
    },
    tempDiffColor() {
      if (Math.abs(this.tempDiffValue) <= 5) {
        return 'green';
      } else if (this.tempDiffValue > 5 && this.tempDiffValue <= 10) {
        return 'orange';
      } else if (this.tempDiffValue > 10) {
        return 'red';
      } else if (this.tempDiffValue < 0 && this.tempDiffValue >= -5) {
        return 'lightblue';
      } else if (this.tempDiffValue < -5 && this.tempDiffValue >= -10) {
        return 'blue';
      } else {
        return 'darkblue';
      }
    },
    tempDiffSymbol() {
      return this.tempDiffValue > 0 ? '+' : '-';
    },
  },

  watch: {
    async "secondmentClientComputed.address"() {
      if (this.secondmentClientComputed) {
        await this.loadStartLocationRisk();
        await this.loadStartLocationWeather();
      }
    },
  },

  mounted() {
    if (this.secondmentClientComputed) {
      this.loadStartLocationRisk();
      this.loadStartLocationWeather();
    }
    eventBus.$on('reset-client-selected', () => {
      this.onSelectAnotherClientBtnClick();
    })
  },

  methods: {
    async loadStartLocationWeather() {
      if (!this.secondmentClientComputed?.address) return;
      this.startLocationWeatherLoading = true;
      this.startLocationWeather = null;
      if (this.clientAddressCenter != null) {
        try {
          const weather = await AccuWeatherService.getWeather(
            this.clientAddressCenter.latitude,
            this.clientAddressCenter.longitude,
            localStorage.getItem("language") || "en",
          );
          this.startLocationWeather = weather[0];
        } catch (err) {
          console.log('@loadStartLocationWeather', err);
        } finally {
          this.startLocationWeatherLoading = false;
        }
      } else {
        this.startLocationWeatherLoading = false;
      }
    },

    async loadStartLocationRisk() {
      if (!this.secondmentClientComputed?.address) return;
      this.startLocationRiskLoading = true;
      this.startLocationRisk = null;

      let selectedAddress = this.secondmentClientComputed?.editAddress || this.secondmentClientComputed.address;
      const address = await AddressService.getByID(selectedAddress);

      if (!address.hasOwnProperty('locality') || address.locality == null || !address.locality.hasOwnProperty('state') || !this.risk_map.includes(address.locality.state.country.code)) {
        this.startLocationRiskLoading = false;
        return
      }

      if (this.clientAddressCenter != null) {
        try {
          this.startLocationRisk = await RiskService.getPlaceRiskLevel(
            address.locality.state.country.code,
            this.clientAddressCenter.latitude,
            this.clientAddressCenter.longitude,
          );
          this.airQuality = await RiskService.getAirQualityData({
            lat: this.clientAddressCenter.latitude,
            lng: this.clientAddressCenter.longitude,
          });
        } catch (err) {
          console.error(err);
        } finally {
          this.startLocationRiskLoading = false;
        }
      } else {
        this.startLocationRiskLoading = false;
      }
    },

    async onDateFromSelected(e, item) {
      const { valid } = await this.$refs[`datesProvider`].validate(e);
      if (valid) {
        item.date_start = e;
      }
    },

    async onDateToSelected(e, item) {
      const { valid } = await this.$refs[`datesProvider`].validate(e);
      if (valid) {
        item.date_end = e;
      }
    },
    onClientAddressModalHidden() {
      this.clientAddressForm.address = null;
    },

    onClientAddressBtnClick() {
      this.$bvModal.show("client-modal-address");
    },

    closeClientAddressModal() {
      this.$bvModal.hide("client-modal-address");
    },

    async onClientAddressSubmit() {
      this.canContinue = true;

      const validator = this.$refs.addressAddValidation;
      if (validator) {
        const isValid = await validator.validate();
        if (!isValid) {
          await addressValidationSwal({
            text: this.$t("Seems like some fields are missing, do you want to continue?(You can modify them and then validate the address with the validate address button)"),
          }).then((res) => {
            if (!res.isConfirmed) {
              this.canContinue = false;
            }
          });
        }
      }

      if (this.canContinue) {
        try {
          let address = {};
          if (this.addToClient) {
            if (this.selectedClient.kind === "Company") {
              address = await addCompanyAddress({
                ...this.clientAddressForm,
                company: this.secondmentClient.client_object_id,
                main: false,
                description: "",
              });
            } else {
              address = await addPhysicalPersonAddress({
                ...this.clientAddressForm,
                persona: this.secondmentClient.client_object_id,
                main: false,
                description: "",
              });
            }
          } else {
            const newAddress = await AddressService.getOrCreateAddress([this.clientAddressForm]);
            address = { address: newAddress[0] }
          }

          this.selectedClient.addresses.push(address.address)
          this.secondmentClientComputed.address = address.address.id
          this.closeClientAddressModal();
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: this.$t("Error"),
            text: this.$t("Something went wrong!"),
            icon: "error",
            confirmButtonClass: "btn btn-danger",
            confirmButtonText: "Ok",
          });
        }
      }
    },

    onSecondmentActorCreateModalHidden() {
      this.$refs.newClient.reset();
    },

    closeModal() {
      this.$bvModal.hide("newClient");
    },

    refreshTableAndCloseModal() {
      successToast(this.$t("Client created"));
      this.closeModal();
      this.refresh();
    },

    refresh() {
      this.$refs.clientTable.refresh();
    },

    onSelectAnotherClientBtnClick() {
      this.secondmentClientComputed = null;
      this.selectedClientComputed = null;
    },

    onSecondmentClientAddressInput(addressId) {
      if (this.wizardMode === "edit") {
        Swal.fire({
          icon: "warning",
          text: "This will change the travel plan, do you want to continue?",
          confirmButtonClass: "btn btn-primary",
          confirmButtonText: this.$t("Continue"),
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          cancelButtonClass: "btn",
        }).then((res) => {
          if (res.isConfirmed) {
            this.secondmentClientComputed = { ...this.secondmentClientComputed, address: addressId };
          }
        });
      } else {
        this.secondmentClientComputed = { ...this.secondmentClientComputed, address: addressId };
      }
    },
    changeManualAddress(val) {
      this.clientAddressForm.manual_address = val
    },
    updateComposedAddress(val) {
      this.clientAddressForm.composed_address = val;
    }
  },
};
</script>

<style scoped>
.slider {
  height: 100%;
  transition: max-height 1s ease-in-out;
}

.slider.close {
  display: none;
  max-height: 0;
}

.table-container {
  transition: max-height 1s ease-in-out;
}

.table-container.close {
  display: none;
  max-height: 0;
}

.min-h {
  min-height: 760px;
}
</style>
