var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "rounded": "sm",
      "show": _vm.isLoading
    }
  }, [_c('gantt-chart', {
    attrs: {
      "data": _vm.data
    }
  }), !_vm.disableEdits ? _c('b-alert', {
    staticClass: "my-15 py-5",
    attrs: {
      "show": "",
      "variant": _vm.alert.variant
    }
  }, [_c('i', {
    staticClass: "mr-5",
    class: [_vm.alert.icon, _vm.alert.textColor]
  }), _c('span', {
    staticClass: "font-weight-bolder",
    class: [_vm.alert.textColor]
  }, [_vm._v(_vm._s(_vm.alert.message))])]) : _vm._e(), !_vm.disableEdits ? _c('vue-slider', {
    attrs: {
      "enable-cross": false,
      "min": _vm.minVal,
      "max": _vm.maxVal,
      "interval": _vm.interval,
      "tooltip-formatter": _vm.formatter,
      "tooltip": "always",
      "disabled": _vm.disableEdits
    },
    on: {
      "change": _vm.handleSliderChange
    },
    model: {
      value: _vm.secondmentEmployeeValues,
      callback: function callback($$v) {
        _vm.secondmentEmployeeValues = $$v;
      },
      expression: "secondmentEmployeeValues"
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }