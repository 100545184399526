<template>
  <form>
    <draggable v-model="orderedPlaces" draggable=".grabbable:not(.undraggable)" :options="{ disabled: readonly }"
      @start="drag = true" @end="drag = false">
      <div v-for="(place, idx) in day.places" :key="idx" class="grabbable"
        :class="{ undraggable: ['arrival', 'departure'].includes(place.place_kind) }">
        <TravelPlanInput v-if="place.place_kind" :ref="`place-${idx}`" :date="day.date" :secondment-employee="day"
          :field="place.place_kind" :title="place.place_kind" :map-letter="idx" with-risks :disabled="readonly"
          @set-place="({ place: newPlace }) => setTravelPlanDayProperty({ place, newPlace, idx })"
          @open-map="openPoiMap" @remove-place="$emit('remove-place', $event)" @get-risk="onGetRisk"></TravelPlanInput>
        <div v-if="!readonly && !['arrival', 'departure'].includes(place.place_kind)" class="drag-element text-center">
          &#8230;
        </div>
      </div>
    </draggable>

    <b-modal :id="`points-of-interest-modal-${_uid}`" size="xl" hide-footer>
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <h3 class="mb-0">
            {{ $t("Select a place") }}
          </h3>
          <div class="d-flex align-items-center">
            <b-button v-show="showSaveBotton" variant="success" class="mr-4" @click="saveChanges">{{ $t("Save Changes")
              }}</b-button>
            <b-button @click="cancel"> {{ $t("Cancel") }} </b-button>
          </div>
        </div>
      </template>
      <google-maps-points-of-interest ref="childComponentRef" :country="country" :field="field" :map-center="poiCenter"
        :vehicle="activeVehicle" @selected-place="handlePlaceSelect"
        @marker-dragend="handleDrag"></google-maps-points-of-interest>
    </b-modal>
  </form>
</template>

<script>
import TravelPlanInput from "@/view/components/inputs/TravelPlanInput.vue";
import GoogleMapsPointsOfInterest from "@/view/components/google-maps/GoogleMapsPointsOfInterest.vue";
import icons from "@/core/config/icons";
import Swal from "sweetalert2";
import RiskService from "@/core/services/risk/risk.service";
import Draggable from "vuedraggable";

export default {
  components: {
    TravelPlanInput,
    GoogleMapsPointsOfInterest,
    Draggable,
  },

  props: {
    day: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    country: {
      type: String,
      default: "",
    },
    googleClientAddress: {
      type: Object,
      default: null,
    },
    methodOfArrival: {
      type: String,
      default: "car",
    },
    methodOfDeparture: {
      type: String,
      default: "car",
    },
  },

  data() {
    return {
      activeVehicle: "train",
      riskStack: [],
      activeMapIdx: null,
      computedCoordinates: { lat: 44.6464176, lng: 10.930645 },
      icons,
      callsInterval: 0,
      vehicle: {
        place_of_arrival: this.methodOfArrival,
        place_of_departure: this.methodOfDeparture,
        lunch_place: "car",
        dinner_place: "car",
        housing: "car",
      },
      iconsMap: {
        car: icons.car,
        plane: icons.arrival,
        train: icons.train,
        housing: icons.hotel,
        restaurant: icons.food,
      },
      textMap: {
        place_of_arrival: "a place of arrival",
        place_of_departure: "a place of departure",
        lunch_place: "a lunch place",
        dinner_place: "a dinner place",
        housing: "an accomodation place",
      },
      field: "place_of_arrival",
      placeOfArrivalRisks: [],
      placeOfArrivalLoading: false,
      housingRisks: [],
      housingLoading: false,
      lunchPlaceRisks: [],
      lunchPlaceLoading: false,
      dinnerPlaceRisks: [],
      dinnerPlaceLoading: false,
      placeOfDepartureRisks: [],
      placeOfDepartureLoading: false,
      clientRisks: [],
      clientLoading: false,
      risks: {},
      placeSelected: {}
    };
  },

  computed: {
    showSaveBotton() {
      const keys = Object.keys(this.placeSelected)
      const values = Object.values(this.placeSelected)
      return keys.length > 0 && values.length > 0
    },
    // Returns an array of capital letters based on the number of items in the form.
    letters() {
      let numberOfInputs = this.form.length;
      if (this.placeOfArrival) {
        numberOfInputs++;
      }
      if (this.placeOfDeparture) {
        numberOfInputs++;
      }

      const A_LETTER_ASCII_CODE = 65;
      const out = [];

      for (let i = 0; i < numberOfInputs; i++) {
        out.push(String.fromCharCode(A_LETTER_ASCII_CODE + i));
      }
      return out;
    },

    poiCenter: {
      get() {
        return this.computedCoordinates;
      },
      set(val) {
        this.getCoordinates(val);
      },
    },

    RISK_LEGEND_MAP() {
      return RiskService.RISK_LEGEND_MAP;
    },

    orderedPlaces: {
      get() {
        return this.day.places.map((el, idx) => ({ ...el, order: el.order ?? idx })).sort((a, b) => a.order - b.order);
      },
      set(values) {
        this.day.places = values.map((el, idx) => ({ ...el, order: idx }));
        this.$emit(
          "update-orders",
          values.map((el, idx) => ({ ...el, order: idx })),
        );
      },
    },
  },

  watch: {
    googleClientAddress: {
      immediate: true,
      handler(val) {
        if (!val) return;
        let {
          latitude: lat,
          longitude: lng,
        } = val;
        this.computedCoordinates = { lat, lng };
      },
    },
  },

  beforeDestroy() {
    this.riskStack.length = 0;
  },

  methods: {
    async resolveStack() {
      const stack = this.riskStack.map((el) => el.handler);
      for (let el of stack) {
        await el();
      }
      this.riskStack.length = 0;
    },

    onGetRisk({ id, handler }) {
      if (this.riskStack.find((el) => el.id === id) || !handler) return;

      this.riskStack.push({ id, handler });
    },

    openPoiMap({ vehicle, field, idx }) {
      this.activeMapIdx = idx;
      this.openMap({ vehicle, field });
    },

    getCoordinates(val) {
      if (val) {
        let { lat, lng } = val;
        if (typeof lat === "function") lat = lat();
        if (typeof lng === "function") lng = lng();

        this.computedCoordinates = {
          lat,
          lng,
        };
      }
    },

    handlePlaceSelect(e) {
      if (this.activeMapIdx !== null) {
        this.placeSelected = e
      }
    },

    handleDrag(e) {
      this.poiCenter = e.position;
    },

    openMap({ vehicle, field }) {
      this.field = field;
      this.activeVehicle = vehicle;
      this.poiCenter = this.clientCoordinates;
      if (vehicle !== "car") this.$bvModal.show(`points-of-interest-modal-${this._uid}`);
    },
    saveChanges() {
      this.setTravelPlanDayProperty({
        place: this.orderedPlaces[this.activeMapIdx],
        newPlace: this.placeSelected,
        idx: this.activeMapIdx,
      });
      this.$bvModal.hide(`points-of-interest-modal-${this._uid}`);
      this.$refs.childComponentRef.cleanPlaceVal()
      this.placeSelected = {}
    },
    cancel() {
      this.$bvModal.hide(`points-of-interest-modal-${this._uid}`);
      this.$refs.childComponentRef.cleanPlaceVal()
      this.placeSelected = {}
    },

    async setTravelPlanDayProperty({ place, newPlace, idx }) {
      if (newPlace && newPlace.address_components) {
        const { address_components: addressComponents } = newPlace;
        const countryAddressComponent = addressComponents.find((comp) => comp.types.includes("country"));
        if (countryAddressComponent && countryAddressComponent.short_name !== this.country) {
          Swal.fire({
            icon: "warning",
            title: this.$t("Warning"),
            text: this.$t("The selected location is different from the original country."),
          });

          const [ref] = this.$refs[`place-${idx}`];
          if (ref) {
            await ref.reinitializeAutocomplete(place.place);
          }

          return;
        }

        if (
          !(
            newPlace.address_components.find((ac) => ac.types[0] == "locality")?.short_name ||
            newPlace.address_components.find((ac) => ac.types[0] == "administrative_area_level_1")?.short_name ||
            newPlace.address_components.find((ac) => ac.types[0] == "administrative_area_level_1")?.short_name
          )
        ) {
          Swal.fire({
            icon: "error",
            title: "Invalid Address",
            text: "Please select a valid address",
          });

          const [ref] = this.$refs[`place-${idx}`];
          if (ref) {
            await ref.reinitializeAutocomplete(place.place);
          }

          return;
        }
      }
      this.$emit("update-place", { newPlace, idx });
    },
  },
};
</script>

<style lang="scss" scoped>
.grabbable {
  padding: 0.2em;

  &:has(.drag-element:hover) {
    cursor: grab;
    scale: 1.03;
    -webkit-box-shadow: 0px 0px 5px 1px var(--primary);
    box-shadow: 0px 0px 5px 1px var(--primary);
    border-radius: 3px;
    background: #fff;
    position: relative;
    z-index: 8;
  }
}

i {
  cursor: pointer !important;
}

i.active {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 3px;
}

.map-letter {
  display: inline-block;
  background-color: var(--danger);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
}
</style>
