<template>
  <b-overlay rounded="sm" :show="isLoading">
    <gantt-chart :data="data"></gantt-chart>
    <b-alert v-if="!disableEdits" show :variant="alert.variant" class="my-15 py-5">
      <i class="mr-5" :class="[alert.icon, alert.textColor]"></i>
      <span class="font-weight-bolder" :class="[alert.textColor]">{{ alert.message }}</span>
    </b-alert>
    <vue-slider v-if="!disableEdits" v-model="secondmentEmployeeValues" :enable-cross="false" :min="minVal"
      :max="maxVal" :interval="interval" :tooltip-formatter="formatter" tooltip="always" :disabled="disableEdits"
      @change="handleSliderChange"></vue-slider>
  </b-overlay>
</template>

<script>
import GanttChart from "@/view/components/charts/GanttChart.vue";
import DateService from "@/core/services/date.service";
import AvailabilityService from "@/core/services/availability.service";

import _ from "lodash";
import { mapGetters } from "vuex";
import icons from "@/core/config/icons";
export default {
  components: {
    GanttChart,
  },

  props: {
    secondmentEmployee: {
      type: Object,
      required: true,
    },
    secondmentStartDate: {
      type: String,
      required: true,
    },
    secondmentEndDate: {
      type: String,
      required: true,
    },
    secondmentEmployeeAvailability: {
      type: Number,
      required: true,
    },
    disableEdits: {
      type: Boolean,
      default: false,
    },
    overlappingSecondments: {
      type: Array,
      default: () => ([]),
    },
  },

  data: () => ({
    interval: 1,
    data: [],
    isLoading: false,
    secondmentEmployeeValues: [],
  }),

  computed: {
    ...mapGetters('user', ['dateFormat']),
    minVal() {
      return 0;
    },
    maxVal() {
      return this.enumeratedDates.length - 1;
    },

    enumeratedDates() {
      const dates = DateService.enumerateDates(this.secondmentStartDate, this.secondmentEndDate)

      return dates;
    },

    marks() {
      const out = {};
      this.enumeratedDates.forEach((date, idx) => {
        out[idx] = {
          label: date,
          labelStyle: {
            fontSize: ".6rem",
          },
        };
      });

      return out;
    },

    employeeGanttData() {
      if (!this.data.length) return null;

      return this.data[0];
    },

    secondmentEmployeeDataComp() {
      return {
        date_start: this.secondmentEmployee.date_start,
        date_end: this.secondmentEmployee.date_end,
        available: this.secondmentEmployeeAvailability,
      };
    },

    alert() {
      switch (this.secondmentEmployeeAvailability) {
        case AvailabilityService.Enum.AVAILABLE:
          return {
            variant: "success",
            message: `${this.secondmentEmployee.name} is available during these dates.`,
            icon: icons.check,
            textColor: "text-light",
          };
        case AvailabilityService.Enum.NOT_AVAILABLE:
          return {
            variant: "danger",
            message: `${this.secondmentEmployee.name} is not available during these dates.`,
            icon: icons.warning,
            textColor: "text-light",
          };
        case AvailabilityService.Enum.PARTIAL:
          return {
            variant: "warning",
            message: `${this.secondmentEmployee.name} is partially available during these dates.`,
            icon: icons.warning,
            textColor: "text-dark",
          };
        default:
          return {
            variant: "",
            message: "Could not find the availability",
          };
      }
    },
  },

  watch: {
    secondmentEmployeeDataComp: {
      deep: true,
      async handler(val) {
        if (!this.employeeGanttData) return;
        await this.$nextTick();
        this.data.splice(0, 1, {
          ...this.employeeGanttData,
          dateStart: val.date_start,
          dateEnd: val.date_end,
          color: AvailabilityService.enumToColor(val.available),
        });
      },
    },
  },

  mounted() {
    this.loadGanttData();
    this.get()
  },

  methods: {
    get() {
      const fromIdx = this.enumeratedDates.findIndex(date => date == this.secondmentEmployee.date_start);
      const toIdx = this.enumeratedDates.findIndex(date => date == this.secondmentEmployee.date_end);
      this.secondmentEmployeeValues = [fromIdx, toIdx];
    },
    handleSliderChange: _.debounce(function (val) {
      let [from, to] = val;
      this.$emit("secondment-employee-change", {
        ...this.secondmentEmployee,
        date_start: this.enumeratedDates[from],
        date_end: this.enumeratedDates[to],
      });
    }, 300),
    formatter(dateIdx) {
      return DateService.format(this.enumeratedDates[dateIdx], this.dateFormat);
    },

    async loadGanttData() {
      this.isLoading = true;
      const ganttData = [];

      const employeeData = {
        label: `${this.secondmentEmployee.name} (current)`,
        dateStart: this.secondmentEmployee.date_start,
        dateEnd: this.secondmentEmployee.date_end,
        color: AvailabilityService.enumToColor(this.secondmentEmployeeAvailability),
      };
      const secondmentData = {
        label: "Secondment (current)",
        dateStart: this.secondmentStartDate,
        dateEnd: this.secondmentEndDate,
        color: "#03f4fc",
      };

      ganttData.push(employeeData, secondmentData);
      if (this.overlappingSecondments.length > 0) {
        this.overlappingSecondments.forEach(secondment => {
          ganttData.push({
            label: secondment.secondment_name,
            dateStart: secondment.date_start,
            dateEnd: secondment.date_end,
            color: "#4493F8",
          });
        });
      };

      this.data = ganttData
      this.isLoading = false;
    },
  },
};
</script>
