<template>
  <div>
    <div class="d-flex m-n1">
      <EmployeeDocumentValidity document-type="passport" :document="item.availability_data.documents.passport"
        :employee-date="getEmployeeDate(item.id)" :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <EmployeeDocumentValidity document-type="identity_document"
        :document="item.availability_data.documents.identity_document" :employee-date="getEmployeeDate(item.id)"
        :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <EmployeeDocumentValidity document-type="postingcertificate" posting-type
        :posting-validity="[item.availability_data.documents.postingcertificate_art12, item.availability_data.documents.postingcertificate_art13]"
        :employee-date="getEmployeeDate(item.id)" :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <EmployeeDocumentValidity document-type="europeanhealthinsurancecard"
        :document="item.availability_data.documents.europeanhealthinsurancecard" :employee-date="getEmployeeDate(item.id)"
        :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
    </div>
    <div class="d-flex m-n1">
      <EmployeeDocumentValidity document-type="certificate_suitability_for_work"
        :document="item.availability_data.documents.certificate_suitability_for_work"
        :employee-date="getEmployeeDate(item.id)" :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <EmployeeDocumentValidity document-type="employment_contract"
        :document="item.availability_data.documents.employment_contract" :employee-date="getEmployeeDate(item.id)"
        :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <EmployeeDocumentValidity document-type="last_paycheck" :document="item.availability_data.documents.last_paycheck"
        :employee-date="getEmployeeDate(item.id)" :secondment-date="getSecondmentDate">
      </EmployeeDocumentValidity>
      <driving-license-info v-bind="checkLicenseProps" :entity="item">
      </driving-license-info>
    </div>
  </div>
</template>

<script>
import EmployeeDocumentValidity from "@/view/components/tables/cells/EmployeeDocumentValidity.vue";
import DrivingLicenseInfo from "@/view/components/tables/cells/driving-license-info.vue";

export default {
  components: {
    EmployeeDocumentValidity,
    DrivingLicenseInfo,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    getSecondmentDate: {
      type: Object,
      required: true,
    },
    getEmployeesId: {
      type: Array,
      required: true,
    },
    checkLicenseProps: {
      type: Object,
      required: true,
    },
  },

  methods: {
    getEmployeeDate(id) {
      const employee = this.getEmployeesId.find(el => el == id)
      if (employee)
        return {
          start: employee.date_start,
          end: employee.date_end
        }
      else return {
        start: this.date_start,
        end: this.date_end
      }
    },
  },
}
</script>
