var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "sticky-custom bg-gray"
  }, [_vm.daysComputed.length > 1 ? _c('b-dropdown', {
    staticClass: "mb-5",
    attrs: {
      "id": "dropdown-".concat(_vm._uid),
      "text": "".concat(_vm.$t('Current day'), ": ").concat(_vm.formattedCurrentDay),
      "variant": "primary"
    }
  }, _vm._l(_vm.daysComputed, function (day, idx) {
    return _c('b-dropdown-item-button', {
      key: day.date,
      attrs: {
        "active": _vm.value == idx
      },
      on: {
        "click": function click($event) {
          _vm.currentDayIdx = idx;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.getDayDate(day)) + " ")]);
  }), 1) : _vm._e(), _c('span', {
    staticClass: "info-dates font-weight-bolder"
  }, [_c('span', [_vm._v(_vm._s(_vm.secondmentDatesText))])]), _c('google-maps-map', {
    attrs: {
      "map-options": _vm.mapOptions,
      "directions": _vm.directions,
      "kml-layers-urls": _vm.kmlLayersUrls
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }