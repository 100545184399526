<template>
  <b-card class="sticky-custom bg-gray">
    <b-dropdown v-if="daysComputed.length > 1" :id="`dropdown-${_uid}`"
      :text="`${$t('Current day')}: ${formattedCurrentDay}`" class="mb-5" variant="primary">
      <b-dropdown-item-button v-for="(day, idx) in daysComputed" :key="day.date" :active="value == idx"
        @click="currentDayIdx = idx">
        {{ getDayDate(day) }}
      </b-dropdown-item-button>
    </b-dropdown>
    <span class="info-dates font-weight-bolder">
      <span>{{ secondmentDatesText }}</span>
    </span>
    <google-maps-map :map-options="mapOptions" :directions="directions"
      :kml-layers-urls="kmlLayersUrls"></google-maps-map>
  </b-card>
</template>

<script>
import GoogleMapsMap from "@/view/components/google-maps/GoogleMapsMap.vue";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
export default {
  components: {
    GoogleMapsMap,
  },

  props: {
    value: {
      type: Number,
      required: true,
    },
    plan: {
      type: Object,
      required: true,
    },
    path: {
      type: Array,
      default: () => [],
    },
    kmlLayersUrls: {
      type: Array,
      default: () => [],
    },
    clientAddress: {
      type: Object,
      default: null,
    },
    planDay: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      DateService,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    daysComputed() {
      let dates = []
      let datesCheck = []
      // days in creazione
      // travel_days in edit
      const days = this.plan?.days || this.plan?.travel_days;
      days.forEach(day => {
        // date in creazione
        // travel_date in edit
        const date = day?.date || day?.travel_date;
        if (!datesCheck.includes(date)) {
          datesCheck.push(date)
          dates.push(day)
        }
      });

      return dates;
    },

    formattedCurrentDay() {
      return this.currentDay.date ? DateService.format(this.currentDay.date, this.dateFormat) : this.currentDay.date;
    },

    secondmentDatesText() {
      const days = this.plan?.days || this.plan?.travel_days;
      if (!days.length) {
        return "";
      }
      let dateFrom = ''
      let dateTo = ''

      if (days[0]?.date) {
        dateFrom = DateService.format(days[0].date, this.dateFormat);
        dateTo = DateService.format(days[days.length - 1].date, this.dateFormat);
      } else if (days[0]?.travel_date) {
        dateFrom = DateService.format(days[0].travel_date, this.dateFormat);
        dateTo = DateService.format(days[days.length - 1].travel_date, this.dateFormat);
      }

      return `${this.$t("From")}: ${dateFrom} ${this.$t("To")}: ${dateTo}`;
    },
    currentDayIdx: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },

    currentDay() {
      if (this.planDay) {
        return this.planDay;
      }
      return this.plan?.days[this.currentDayIdx];
    },

    center() {
      const {
        place_of_arrival: placeOfArrival,
        place_of_departure: placeOfDeparture,
        housing,
        lunch_place: lunchPlace,
        dinner_place: dinnerPlace,
      } = this.currentDay;

      if (placeOfArrival) {
        return {
          lat:
            typeof placeOfArrival.geometry.location.lat == "function"
              ? placeOfArrival.geometry.location.lat()
              : placeOfArrival.geometry.location.lat,
          lng:
            typeof placeOfArrival.geometry.location.lng == "function"
              ? placeOfArrival.geometry.location.lng()
              : placeOfArrival.geometry.location.lng,
        };
      }

      if (placeOfDeparture) {
        return {
          lat:
            typeof placeOfDeparture.geometry.location.lat == "function"
              ? placeOfDeparture.geometry.location.lat()
              : placeOfDeparture.geometry.location.lat,
          lng:
            typeof placeOfDeparture.geometry.location.lng == "function"
              ? placeOfDeparture.geometry.location.lng()
              : placeOfDeparture.geometry.location.lng,
        };
      }

      if (housing) {
        return {
          lat:
            typeof housing.geometry.location.lat == "function"
              ? housing.geometry.location.lat()
              : housing.geometry.location.lat,
          lng:
            typeof housing.geometry.location.lng == "function"
              ? housing.geometry.location.lng()
              : housing.geometry.location.lng,
        };
      }

      if (lunchPlace) {
        return {
          lat:
            typeof lunchPlace.geometry.location.lat == "function"
              ? lunchPlace.geometry.location.lat()
              : lunchPlace.geometry.location.lat,
          lng:
            typeof lunchPlace.geometry.location.lng == "function"
              ? lunchPlace.geometry.location.lng()
              : lunchPlace.geometry.location.lng,
        };
      }

      if (dinnerPlace) {
        return {
          lat:
            typeof dinnerPlace.geometry.location.lat == "function"
              ? dinnerPlace.geometry.location.lat()
              : dinnerPlace.geometry.location.lat,
          lng:
            typeof dinnerPlace.geometry.location.lng == "function"
              ? dinnerPlace.geometry.location.lng()
              : dinnerPlace.geometry.location.lng,
        };
      }

      if (this.clientAddress) {
        return {
          lat:
            this.clientAddress.latitude,
          lng:
            this.clientAddress.longitude,
        };
      }

      return null;
    },

    mapOptions() {
      const out = {};

      if (this.center) {
        out.center = this.center;
        out.zoom = 15;
      } else {
        out.zoom = 4;
      }

      return out;
    },

    markers() {
      let markers = [];

      const {
        place_of_arrival: placeOfArrival,
        place_of_departure: placeOfDeparture,
        housing,
        lunch_place: lunchPlace,
        dinner_place: dinnerPlace,
      } = this.currentDay;

      if (placeOfArrival) {
        const placeOfArrivalMarker = {
          position: {
            lat: placeOfArrival.geometry?.location?.lat(),
            lng: placeOfArrival.geometry?.location?.lng(),
          },
          title: placeOfArrival.name,
        };
        markers = [placeOfArrivalMarker, ...markers];
      }

      if (housing) {
        markers.push({
          position: {
            lat: housing.geometry?.location?.lat(),
            lng: housing.geometry?.location?.lng(),
          },
          title: housing.name,
        });
      }

      if (lunchPlace) {
        markers.push({
          position: {
            lat: lunchPlace.geometry?.location?.lat(),
            lng: lunchPlace.geometry?.location?.lng(),
          },
          title: lunchPlace.name,
        });
      }

      if (dinnerPlace) {
        markers.push({
          position: {
            lat: dinnerPlace.geometry?.location?.lat(),
            lng: dinnerPlace.geometry?.location?.lng(),
          },
          title: dinnerPlace.name,
        });
      }

      if (placeOfDeparture) {
        const placeOfDepartureMarker = {
          position: {
            lat: placeOfDeparture.geometry?.location?.lat(),
            lng: placeOfDeparture.geometry?.location?.lng(),
          },
          title: placeOfDeparture.name,
        };
        markers.push(placeOfDepartureMarker);
      }

      if (this.clientAddress) {
        markers.push({
          position: {
            lat: this.clientAddress?.geometry?.location?.lat(),
            lng: this.clientAddress?.geometry?.location?.lng(),
          },
          title: "Client",
        });
      }

      return markers;
    },

    directions() {
      const out = {};
      const directionsLatLng = [...this.path];

      // If there aren't at least 2 directions we simply return out because
      // there is not enough data to populate the google map.
      if (directionsLatLng.length < 2) {
        out.origin = directionsLatLng.splice(0, 1)[0];
        return out
      };

      out.origin = directionsLatLng.splice(0, 1)[0];
      out.destination = directionsLatLng.splice(directionsLatLng.length - 1, 1)[0];
      if (directionsLatLng.length) {
        out.waypoints = directionsLatLng.map((coord) => ({ location: coord }));
      }
      out.travelMode = google.maps.TravelMode.DRIVING;
      return out;
    },
  },

  methods: {
    getDayDate(day) {
      const date = day?.travel_date || day?.date;
      return DateService.format(date, this.dateFormat);
    },
  },
};
</script>

<style scoped>
.info-dates {
  display: inline-block;
  position: absolute;
  padding: 0 1em;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  translate: 0 -50%;
}

.info-dates>span {
  background: white;
  padding: 0 1em;
}

::v-deep .dropdown-menu {
  max-height: 223px;
  overflow-y: auto;
}
</style>
