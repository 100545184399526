var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card mb-4"
  }, [_c('div', {
    staticClass: "card-body p-2 d-flex align-items-center"
  }, [_c('div', [_c('avatar', {
    attrs: {
      "avatar-image": _vm.avatarUrl,
      "avatar-text": _vm.avatarBackup,
      "size": "70px"
    }
  })], 1), _c('div', {
    staticClass: "ml-4"
  }, [_c('div', {
    staticClass: "font-weight-bolder h6 text-center"
  }, [_vm._v(" " + _vm._s(_vm.name))]), _vm.telephone ? _c('div', {
    staticClass: "text-muted"
  }, [_vm._v("Phone: " + _vm._s(_vm.telephone))]) : _vm._e(), _vm.email ? _c('div', {
    staticClass: "text-muted"
  }, [_vm._v("Email: " + _vm._s(_vm.email))]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }