<template>
  <div>
    <b-card no-body class="p-8 w-100 secondment-employee-card" :border-variant="focus ? 'primary' : null">
      <div class="form row">
        <div class="col-12 mb-2">
          <div class="d-flex justify-content-end align-items-center">
            <b-btn variant="primary" size="sm" @click="onCopyToAllClick">
              {{ $t("COPY TO ALL") }}
            </b-btn>
          </div>
        </div>
        <div class="col-2">
          <div class="d-flex justify-content-center">
            <avatar :avatar-image="avatarImage" :avatar-text="avatarText" size="64px" class="mr-3"></avatar>
            <div>
              <span class="font-weight-bolder font-size-h5 text-dark-75">
                {{ secondmentEmployee.name }}
              </span>
            </div>
          </div>
          <div class="my-1 my-sm-0 my-md-1 d-none d-sm-block d-md-none">
            <p class="font-weight-bold h6 d-flex">
              {{ secondmentEmployee.name }}
              <span v-if="employee" class="ml-3">
                <i v-if="documentsValid" class="text-success" :class="icons.check"></i>
                <div v-else>
                  <i :id="`${_uid}-sm-doc-danger-${secondmentEmployee.employee}`" class="text-danger"
                    :class="icons.warning"></i>
                  <b-tooltip :target="`${_uid}-sm-doc-danger-${secondmentEmployee.employee}`" triggers="hover">
                    <p v-for="(error, idx) in documentsErrors" :key="idx" class="font-weight-bolder text-uppercase">
                      {{ error }}
                    </p>
                  </b-tooltip>
                </div>
              </span>
            </p>
          </div>
          <div>
            <EmployeeSalaryChip v-if="!isAfterSale" :salary="salary" class="w-100" show-salary-adjustment>
            </EmployeeSalaryChip>
          </div>
          <div>
            <employee-availability-chip :availability="availability" :hover-msg="$t('View Gantt')" :icon="icons.chart"
              class="pointer w-100" @click="onAvailabilityClick"></employee-availability-chip>
          </div>
          <div class="my-1 my-sm-0 my-md-1 d-md-none">
            <b-btn variant="primary" size="sm" block @click="onCopyToAllClick">
              {{ $t("COPY TO ALL") }}
            </b-btn>
          </div>
        </div>
        <div class="col-10">
          <div class="row">
            <validation-provider :ref="`datesProvider-${secondmentEmployee.employee}`" v-slot="{ errors }"
              :name="$t('Dates')" rules="required" :vid="`dates-${secondmentEmployee.employee}`" tag="div"
              class="col-6">
              <euro-double-date-picker :disabled="readonly" :date-from="secondmentEmployee.date_start"
                :date-to="secondmentEmployee.date_end" :enabled-dates="secondmentDays"
                :customized-dates="customizedDays" :error-messages="errors" label="Secondment Dates"
                @date-from-to-selected="onDateFromToSelected">
                <template #label>
                  {{ $t("Assignment Dates") }}*
                </template>
              </euro-double-date-picker>
            </validation-provider>
            <div class="col-6">
              <travel-plan-input :title="`${$t('Housing')}${(!singleDay ? '*' : '')}`"
                :secondment-employee="secondmentEmployee" hide-letters field="accommodation" :vehicles="['housing']"
                :restriction="{ country: country || '' }" :required="!singleDay" :alert="alertHousing"
                @set-place="({ field, place }) => setEmployeeProperty(field, place, 'alertHousing')"
                @open-map="({ vehicle, field }) => openMap(vehicle, field, 'alertHousing')">
              </travel-plan-input>
            </div>
            <div class="col-6">
              <travel-plan-input :title="`${$t('Place of Arrival in Assignment Country')}`" :alert="alertArrival"
                :restriction="{ country: country || '' }" :secondment-employee="secondmentEmployee" hide-letters
                field="arrival" @set-place="({ field, place }) => setEmployeeProperty(field, place, 'alertArrival')"
                @open-map="({ vehicle, field }) => openMap(vehicle, field, 'alertArrival')"></travel-plan-input>
            </div>
            <div class="col-6">
              <travel-plan-input :title="`${$t('Place of Departure from Assignment Country')}`" :alert="alertDeparture"
                :restriction="{ country: country || '' }" :secondment-employee="secondmentEmployee" hide-letters
                field="departure" @set-place="({ field, place }) => setEmployeeProperty(field, place, 'alertDeparture')"
                @open-map="({ vehicle, field }) => openMap(vehicle, field, 'alertDeparture')"></travel-plan-input>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-modal :id="`points-of-interest-modal-${employee.id}`" size="xl" hide-footer>
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center">
          <h3 class="mb-0">
            {{ $t("Select a place") }}
          </h3>
          <div class="d-flex align-items-center">
            <b-button v-show="showSaveBotton" variant="success" class="mr-4" @click="saveChanges">{{ $t("Save Changes")
              }}</b-button>
            <b-button @click="cancel"> {{ $t("Cancel") }} </b-button>
          </div>
        </div>
      </template>
      <google-maps-points-of-interest ref="childComponentRef" :search-term="searchTerm" :field="field"
        :map-center="poiCenter" :vehicle="vehicle" :country="country" @selected-place="handlePlaceSelect"
        @marker-dragend="handleDrag"></google-maps-points-of-interest>
    </b-modal>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import EmployeeAvailabilityChip from "@/view/components/chips/EmployeeAvailabilityChip.vue";
import EmployeeSalaryChip from "@/view/components/chips/EmployeeSalaryChip.vue";
import GoogleMapsPointsOfInterest from "@/view/components/google-maps/GoogleMapsPointsOfInterest.vue";
import TravelPlanInput from "@/view/components/inputs/TravelPlanInput.vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default {
  components: {
    EmployeeAvailabilityChip,
    GoogleMapsPointsOfInterest,
    EmployeeSalaryChip,
    TravelPlanInput,
  },

  props: {
    /**
     * The secondment employee.
     */
    clientAddress: {
      type: Object,
      default: () => null,
    },
    value: {
      type: Object,
      required: true,
    },
    secondmentId: {
      type: String,
      default: "",
    },
    employee: {
      type: Object,
      default: null,
    },
    availability: {
      type: Number,
      default: 0,
    },
    documentsStatus: {
      type: Object,
      default: null,
    },
    salary: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    secondmentDays: {
      type: Array,
      default: () => [],
    },
    customizedDays: {
      type: Array,
      default: () => [],
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    country: {
      type: String,
      required: true,
    },
    clientCoordinates: {
      type: [Object, null],
      default: () => null,
    },
  },

  data: () => ({
    icons,
    ganttData: [],
    focus: false,
    vehicle: "plane",
    poiCenter: { lat: 44.6464176, lng: 10.930645 },
    field: "arrival",
    alertHousing: "",
    alertArrival: "",
    alertDeparture: "",
    selectedAlert: "",
    searchTerm: '',
    selectedPlace: {},
    selectedField: {},
  }),

  computed: {
    ...mapGetters("user", ["isAfterSale"]),
    secondmentEmployee: {
      get() {
        return this.value;
      },
      set(secondmentEmployee) {
        this.$emit("input", secondmentEmployee);
      },
    },

    avatarText() {
      const [name, surname] = this.secondmentEmployee.name.split(" ");
      return `${name[0]}${surname[0]}`;
    },
    avatarImage() {
      return this.employee?.persona?.avatar_display;
    },

    documentsValid() {
      if (!this.documentsStatus) return false;
      for (const key of Object.keys(this.documentsStatus)) {
        if (this.documentsStatus[key] != "valid") return false;
      }
      return true;
    },

    documentsErrors() {
      const errors = [];
      const docs = this.documentsStatus;
      if (!docs) {
        errors.push(
          this.$t("failed to get documents status")
        );
        return errors;
      }

      for (const key of Object.keys(docs)) {
        if (docs[key] != "valid") {
          const msg = docs[key].replaceAll("-", " ");
          errors.push(msg);
        }
      }

      return errors;
    },
    singleDay() {
      return moment(this.secondmentDays[0]).isSame(this.secondmentDays[this.secondmentDays.length - 1], 'day')
    },
    showSaveBotton() {
      const keys = Object.keys(this.selectedPlace)
      const values = Object.values(this.selectedPlace)
      return keys.length > 0 && values.length > 0
    },
  },

  mounted() {
    this.$refs[
      `datesProvider-${this.secondmentEmployee.employee}`
    ].validate(this.secondmentEmployee.date_start); // Valdidate to enabled is required
  },

  methods: {
    handlePlaceSelect(e, field) {
      this.selectedPlace = e
      this.selectedField = field
    },
    handleDrag(e) {
      this.poiCenter = e.position;
    },
    openMap(vehicle, property, alertId) {
      this.selectedAlert = alertId;
      this.field = property;
      this.poiCenter = this.clientCoordinates;
      this.vehicle = vehicle;
      if (vehicle !== "car") {
        this.$bvModal.show(`points-of-interest-modal-${this.employee.id}`);
      } else {
        this.setEmployeeProperty(property, this.clientAddress, alertId);
      }
    },
    onCopyToAllClick() {
      this.$emit("copy-to-all", this.secondmentEmployee);
    },

    async setEmployeeProperty(key, value, alertId) {
      const dateInput = ['date_start', 'date_end']
      if (dateInput.includes(key)) {
        this.secondmentEmployee[key] = value
      } else {
        if (value && value.address_components) {
          const { address_components: addressComponents } = value;
          const countryAddressComponent = addressComponents.find((comp) =>
            comp.types.includes("country")
          );
          if (
            countryAddressComponent &&
            countryAddressComponent.short_name !== this.country
          ) {
            this.setAlert(alertId);
          } else {
            this.resetAlert(alertId);
          }
        }

        // Setto il place
        const places = this.secondmentEmployee.places;
        const field = places.find((el) => el.place_kind === key);
        if (field) {
          field.place = value;
        } else {
          places.push({
            place_kind: key,
            place: value,
          });
        }
      }
    },

    setAlert(alertId) {
      if (alertId == "alertHousing") {
        this.alertHousing = "Housing location different from assignment location";
      }
      if (alertId == "alertDeparture") {
        this.alertDeparture = "Departure location different from assignment location";
      }
      if (alertId == "alertArrival") {
        this.alertArrival = "Arrival location different from assignment location";
      }
    },
    resetAlert(alertId) {
      if (alertId == "alertHousing") {
        this.alertHousing = "";
      }
      if (alertId == "alertDeparture") {
        this.alertDeparture = "";
      }
      if (alertId == "alertArrival") {
        this.alertArrival = "";
      }
    },

    async onDateFromToSelected(date) {
      await this.$refs[
        `datesProvider-${this.secondmentEmployee.employee}`
      ].validate(date.from); // Valdidate to enabled is required
      this.setEmployeeProperty("date_start", date.from);
      this.setEmployeeProperty("date_end", date.to);
    },

    async onAvailabilityClick() {
      this.$emit("availability-click", this.secondmentEmployee);
    },

    onSecondmentGanttModalHidden() {
      this.ganttData = [];
    },

    generateRandomColor() {
      const seed = Math.random() * 100;

      return {
        r: Math.floor(((seed + Math.random() * 100) * 207) % 256),
        g: Math.floor(((seed + Math.random() * 100) * 112) % 256),
        b: Math.floor(((seed + Math.random() * 100) * 17) % 256),
      };
    },
    saveChanges() {
      this.setEmployeeProperty(this.selectedField, this.selectedPlace, this.selectedAlert);
      this.$bvModal.hide(`points-of-interest-modal-${this.employee.id}`);
      this.$refs.childComponentRef.cleanPlaceVal()
      this.selectedField = {}
      this.selectedPlace = {}
    },
    cancel() {
      this.$bvModal.hide(`points-of-interest-modal-${this.employee.id}`);
      this.$refs.childComponentRef.cleanPlaceVal()
      this.selectedPlace = {}
    },
  }
};
</script>

<style lang="scss" scoped>
.secondment-employee-card {
  min-height: 17rem;
}

::v-deep .asd__month .asd__week .asd__day--secondment {
  background-color: #ffd27d;
}

i {
  font-size: 1.6rem;
}

i.active {
  color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 3px;
}
</style>
