var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "contractorValidationObs"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5 pb-15 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v("Select Contractor")]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" Define contractor for secondment ")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": "contractor",
      "vid": "contractor",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('secondment-actor-table', {
          ref: "contractorTable",
          attrs: {
            "title": 'Contractor',
            "countries": _vm.countries,
            "actors": _vm.actors
          },
          scopedSlots: _vm._u([{
            key: "topbar-end",
            fn: function fn() {
              return [_c('b-dropdown', {
                attrs: {
                  "no-caret": "",
                  "right": "",
                  "no-flip": "",
                  "text": "Document",
                  "variant": "light-primary",
                  "toggle-class": "font-weight-bolder py-3"
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "dropdown-button"
                    }, [_c('span', {
                      staticClass: "svg-icon menu-icon"
                    }, [_c('inline-svg', {
                      attrs: {
                        "src": "/media/svg/icons/Code/Plus.svg"
                      }
                    })], 1), _c('span', {
                      staticClass: "font-weight-bolder"
                    }, [_vm._v(_vm._s(_vm.$t("Add contractor")))])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', {
                staticClass: "navi navi-hover min-w-md-200px"
              }, [_c('b-dropdown-text', {
                staticClass: "navi-item",
                attrs: {
                  "tag": "div"
                }
              }, [_c('button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newContractor",
                  modifiers: {
                    "newContractor": true
                  }
                }],
                staticClass: "w-100 text-left navi-link",
                on: {
                  "click": function click($event) {
                    return _vm.setOrganizationType('company');
                  }
                }
              }, [_c('span', {
                staticClass: "navi-icon"
              }, [_c('i', {
                staticClass: "fas fa-plus"
              })]), _c('span', {
                staticClass: "navi-text"
              }, [_vm._v(" " + _vm._s(_vm.$t("Company")) + " ")])]), _c('button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newContractor",
                  modifiers: {
                    "newContractor": true
                  }
                }],
                staticClass: "w-100 text-left navi-link",
                on: {
                  "click": function click($event) {
                    return _vm.setOrganizationType('physical_person');
                  }
                }
              }, [_c('span', {
                staticClass: "navi-icon"
              }, [_c('i', {
                staticClass: "fas fa-plus"
              })]), _c('span', {
                staticClass: "navi-text"
              }, [_vm._v(" " + _vm._s(_vm.$t("Physical person")) + " ")])])])], 1)])];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedContractorComputed,
            callback: function callback($$v) {
              _vm.selectedContractorComputed = $$v;
            },
            expression: "selectedContractorComputed"
          }
        }), errors ? _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]) : _vm._e()];
      }
    }])
  })], 1)]), _c('SecondmentActorCreateModal', {
    ref: "newContractor",
    attrs: {
      "id": "newContractor",
      "title": _vm.$t('Add new contractor'),
      "text": _vm.$t('Please select the kind of contractor you would like to add'),
      "back-btn-tooltip": _vm.$t('Back to contractor type choice'),
      "organization-type": _vm.organizationType
    },
    on: {
      "hidden": _vm.onSecondmentActorCreateModalHidden,
      "close": _vm.closeModal,
      "created": _vm.refreshTableAndCloseModal
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }