<template>
  <div class="timeline-row-ruler-marker" :style="colorStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "gray",
    },
  },

  computed: {
    colorStyle() {
      return { border: `3px solid ${this.color}` };
    },
  },
};
</script>
