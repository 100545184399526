var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_c('b-overlay', {
    attrs: {
      "show": !_vm.planDay
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.planDay ? _c('timeline', {
    ref: "timeline-".concat(_vm.currentDayIdx),
    staticClass: "timeline-row active",
    attrs: {
      "columns-size": "auto minmax(0, 0.2fr) minmax(0, 1fr)"
    },
    scopedSlots: _vm._u([{
      key: "marker",
      fn: function fn() {
        return [_c('SecondmentTravelPlannerTimelineMarker', {
          class: {
            'my-pulse-animation': _vm.isLoadingPathRisk
          },
          attrs: {
            "day": _vm.currentDay,
            "risk": _vm.pathRisk
          }
        }, [_vm._v(" " + _vm._s(_vm.currentDayIdx + 1) + " ")])];
      },
      proxy: true
    }, {
      key: "container-top",
      fn: function fn() {
        return [_c('div', [_c('p', {
          staticClass: "font-weight-bolder mb-5"
        }, [_vm._v(" " + _vm._s(_vm.currentDay.date ? _vm.DateService.format(_vm.currentDay.date, _vm.dateFormat) : _vm.currentDay.date) + " ")]), _c('div', {
          staticClass: "d-flex justify-content-center align-items-center"
        }, [_c('risk-map-date-alert', {
          attrs: {
            "date": _vm.computedAlertDate
          }
        })], 1)])];
      },
      proxy: true
    }, {
      key: "container-right",
      fn: function fn() {
        return [_vm.planDay ? _c('SecondmentTravelPlannerTimelineForm', {
          attrs: {
            "readonly": _vm.readonly,
            "day": _vm.planDay,
            "country": _vm.country,
            "method-of-arrival": _vm.plan.method_of_arrival,
            "method-of-departure": _vm.plan.method_of_departure,
            "google-client-address": _vm.googleClientAddress
          },
          on: {
            "update:day": function updateDay($event) {
              _vm.planDay = $event;
            },
            "update-orders": function updateOrders($event) {
              _vm.currentDay.places = $event;
            },
            "update-place": function updatePlace(_ref) {
              var newPlace = _ref.newPlace,
                idx = _ref.idx;
              return _vm.currentDay.places[idx].place = newPlace;
            },
            "remove-place": _vm.removePlace,
            "update:housing": function updateHousing($event) {
              _vm.currentDay.housing = $event;
              _vm.planDay.housing = $event;
            },
            "update:place_of_arrival": function updatePlace_of_arrival($event) {
              _vm.currentDay.place_of_arrival = $event;
            },
            "update:place_of_departure": function updatePlace_of_departure($event) {
              _vm.currentDay.place_of_departure = $event;
            },
            "update:lunch_place": function updateLunch_place($event) {
              _vm.currentDay.lunch_place = $event;
            },
            "update:dinner_place": function updateDinner_place($event) {
              _vm.currentDay.dinner_place = $event;
            },
            "update:housing_order": function updateHousing_order($event) {
              _vm.currentDay.accommodation_place_order = $event;
            },
            "update:place_of_arrival_order": function updatePlace_of_arrival_order($event) {
              _vm.currentDay.place_of_arrival_order = $event;
            },
            "update:place_of_departure_order": function updatePlace_of_departure_order($event) {
              _vm.currentDay.place_of_departure_order = $event;
            },
            "update:lunch_place_order": function updateLunch_place_order($event) {
              _vm.currentDay.lunch_place_order = $event;
            },
            "update:dinner_place_order": function updateDinner_place_order($event) {
              _vm.currentDay.dinner_place_order = $event;
            },
            "update:client_order": function updateClient_order($event) {
              _vm.currentDay.client_order = $event;
            },
            "update:client2_order": function updateClient2_order($event) {
              _vm.currentDay.client2_order = $event;
            },
            "update-plan": _vm.updatePlan,
            "focus": function focus($event) {
              return _vm.onFocus(_vm.currentDayIdx);
            }
          }
        }) : _vm._e(), !_vm.readonly ? _c('div', {
          staticClass: "py-3 text-center"
        }, [_c('b-dropdown', {
          staticClass: "m-2",
          attrs: {
            "dropup": "",
            "text": "Add place",
            "variant": "outline-primary"
          }
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.addPlace('client');
            }
          }
        }, [_vm._v("Client")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.addPlace('lunch');
            }
          }
        }, [_vm._v("Lunch")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.addPlace('dinner');
            }
          }
        }, [_vm._v("Dinner")]), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.addPlace('other');
            }
          }
        }, [_vm._v("Other")])], 1)], 1) : _vm._e()];
      },
      proxy: true
    }], null, false, 440116807)
  }) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_vm.planDay ? _c('SecondmentTravelPlannerMap', {
    attrs: {
      "plan": _vm.plan,
      "plan-day": _vm.planDay,
      "kml-layers-urls": _vm.kmlLayersUrls,
      "path": _vm.path,
      "client-address": _vm.googleClientAddress
    },
    model: {
      value: _vm.currentDayIdx,
      callback: function callback($$v) {
        _vm.currentDayIdx = $$v;
      },
      expression: "currentDayIdx"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }