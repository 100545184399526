<template>
  <validation-provider v-slot="{ errors }" :rules="required ? 'required' : null" :name="title"
    :vid="`${field}-${secondmentEmployee.employee}`">
    <google-maps-autocomplete ref="autocomplete" :value="placeVal" :error-messages="errors" :autocomplete-options="{
      // types: ['administrative_area_level_1', 'locality'],
      componentRestrictions: restriction
    }" :disabled="disabled || field === 'client'" @input="(e) => $emit('set-place', { field, place: e })"
      @focus="focus = true" @blur="focus = false">
      <template #label>
        <div class="d-flex flex-column m-n1 mt-n3 position-relative">
          <span v-if="!hideLetters" class="map-letter">{{ alphabet[mapLetter] }}</span>
          <div class="d-flex">
            <span class="m-1">{{ title }}</span>
            <div v-for="(v, idx) in vehicles" :key="`${_uid}-${idx}`" class="p-1">
              <i v-b-tooltip.hover :title="v" :class="[icons[v]]" class="m-1"
                @click="!disabled ? $emit('open-map', { vehicle: v, field, idx: mapLetter }) : null"></i>
            </div>
            <div v-if="!disabled && !['arrival', 'departure', 'accommodation'].includes(field)" v-b-tooltip.hover
              :title="$t('Clear')" class="d-inline-block px-1 ml-auto mr-5"
              @click="$emit('remove-place', { idx: mapLetter })">
              <i :class="icons.close"></i>
            </div>
          </div>
          <div>
            <b-overlay v-if="withRisks" :show="riskLoading" rounded="sm">
              <div class="d-flex text-dark flex-wrap">
                <rectangle v-for="countryRisk in risks" :key="countryRisk.countryIso"
                  :color="RISK_LEGEND_MAP[countryRisk.value]?.color" :label="countriesMap[countryRisk.countryIso]?.name"
                  class="mr-2 mb-2">
                </rectangle>
              </div>
            </b-overlay>
          </div>
        </div>
      </template>
    </google-maps-autocomplete>
    <div v-if="alert" class="alert alert-light" role="alert">
      <span class="svg-icon svg-icon-md svg-icon-dark">
        <inline-svg src="/media/svg/icons/Code/Warning-2.svg" />
      </span>
      {{ alert }}
    </div>
  </validation-provider>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import RiskService from "@/core/services/risk/risk.service";
import DateService from "@/core/services/date.service";
import GoogleMapsAutocomplete from "@/view/components/google-maps/GoogleMapsAutocomplete.vue";
import Rectangle from "@/view/components/shapes/Rectangle.vue";
import icons from "@/core/config/icons";

export default {
  components: {
    GoogleMapsAutocomplete,
    Rectangle,
  },
  props: {
    secondmentEmployee: {
      type: Object,
      required: true,
    },

    field: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    date: {
      type: String,
      default: new Date().toDateString(),
    },

    withRisks: {
      type: Boolean,
      default: false,
    },

    mapLetter: {
      type: Number,
      default: 0,
    },

    hideLetters: {
      type: Boolean,
      default: false,
    },

    required: {
      type: Boolean,
      default: false,
    },

    alert: {
      type: String,
      default: ""
    },
    restriction: {
      type: [Object, String],
      default: () => { }
    }
  },

  data() {
    return {
      icons,
      placeVal: null,
      vehicle: "plane",
      riskRequest: _.debounce(
        (val) => this.$emit("get-risk", { id: this._uid, handler: this.getPlaceRiskLevel.bind(this, val) }),
        500,
      ),
      riskLoading: false,
      risks: [],
      alphabet: Array.from(Array(26))
        .map((e, i) => i + 65)
        .map((x) => String.fromCharCode(x)),
    };
  },

  computed: {
    ...mapState("addresses", ["apiAddresses"]),
    ...mapState("capabilities", ["risk_map"]),
    ...mapGetters("constants", ["countriesMap"]),
    vehicles() {
      switch (this.field) {
        case "arrival":
          return ["train", "plane"];
        case "departure":
          return ["train", "plane"];
        case "lunch":
          return ["food"];
        case "dinner":
          return ["food"];
        case "accommodation":
          return ["housing"];
      }
      return [];
    },

    RISK_LEGEND_MAP() {
      return RiskService.RISK_LEGEND_MAP;
    },
  },

  watch: {
    secondmentEmployee: {
      immediate: true,
      deep: true,
      async handler(val) {
        const place = val?.places?.find((el) => el?.place_kind === this.field);
        if (!place?.place) return;

        let address = val?.places.find((el) => el?.place_kind === this.field)?.place;
        if (!address) return;

        if (typeof address == "number") {
          if (!this.apiAddresses[address]) {
            await this.getApiAddress(address);
          }
          address = this.apiAddresses[address];
        }

        if (!address?.place_id) {
          await this.getGoogleAddressFromApi(address);
          address = this.apiAddresses[address.id];
        }

        this.placeVal = address;
        await this.reinitializeAutocomplete(address)
      },
    },

    placeVal: {
      immediate: true,
      handler(val) {
        if (!this.withRisks) return;
        if (!val) {
          this.risks = [];
          return;
        }

        try {
          this.riskRequest(val);
        } catch (err) {
          console.error(err);
          this.risks = [];
        } finally {
          this.riskLoading = false;
        }
      },
    },
  },

  methods: {
    ...mapActions("addresses", ["getApiAddress", "getGoogleAddressFromApi"]),
    async reinitializeAutocomplete(place) {
      let address = place;

      if (typeof address == "number") {
        if (!this.apiAddresses[address]) {
          await this.getApiAddress(address);
        }
        address = this.apiAddresses[address];
      }
      await this.$nextTick();
      await this.$refs.autocomplete.reinitializeAutocomplete(address?.formatted || address?.formatted_address);
    },

    async getPlaceRiskLevel(place) {
      const countryIso = place?.address_components.find((ac) => ac.types.includes("country"))?.short_name ?? null;
      if (countryIso == null || !this.risk_map.includes(countryIso)) {
        return
      }

      this.riskLoading = true;
      try {
        const lat =
          typeof place?.geometry?.location?.lat == "function"
            ? place?.geometry?.location?.lat()
            : place?.geometry?.location?.lat;
        const lng =
          typeof place?.geometry?.location?.lng == "function"
            ? place?.geometry?.location?.lng()
            : place?.geometry?.location?.lng;

        if (!lat || !lng || !countryIso) throw new Error();

        const date = DateService.isBefore(this.date, new Date()) ? this.date : moment().format("YYYY-MM-DD");

        if (this.risks[countryIso]) {
          if (this.risks[countryIso][place.place_id]) {
            if (this.risks[countryIso][place.place_id][date]) return this.risks[countryIso][place.place_id][date];
          }
        }
        const out = await RiskService.getPlaceRiskLevel(countryIso, lat.toFixed(2), lng.toFixed(2), date);
        const returnVal = Object.keys(out?.risk_status).reduce((acc, key) => {
          const value = out?.risk_status[key];
          acc.push({ countryIso: key, value: value });
          return acc;
        }, []);

        const oldVal = this.risks?.[countryIso]?.[place.place_id];

        this.risks[countryIso] = {
          ...this.risks[countryIso],
          [place.place_id]: {
            [date]: returnVal,
          },
        };

        if (oldVal) {
          this.risks[countryIso][place.place_id] = {
            ...this.risks[countryIso][place.place_id],
            ...oldVal,
          };
        }

        this.risks = returnVal;
      } catch (err) {
        console.log(err);
      } finally {
        this.riskLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.map-letter {
  display: inline-block;
  background-color: var(--danger);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  top: 0;
  right: 0;
  translate: 50% -50%;
}
</style>
