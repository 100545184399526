<template>
  <div>
    <b-modal hide-footer hide-header v-bind="{ ...$attrs, ...commonAttrs }" v-on="$listeners">
      <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
        <h3 class="mb-0">{{ title }}</h3>
        <div>
          <b-button @click="$emit('close')">
            {{ $t('Close') }}
          </b-button>
        </div>
      </div>
      <LegalPersonCreateWizard v-if="organizationType == 'company'" :error-messages="legalPersonErrorMessages"
        @submit="onLegalPersonSubmit"></LegalPersonCreateWizard>
      <PhysicalPersonCreateWizard v-if="organizationType == 'physical_person'" :nations="countries"
        :error-messages="physicalPersonErrorMessages" @submit="onPhysicalPersonSubmit"></PhysicalPersonCreateWizard>
    </b-modal>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import LegalPersonCreateWizard from "@/view/components/wizards/LegalPersonCreateWizard.vue";
import PhysicalPersonCreateWizard from "@/view/components/wizards/PhysicalPersonCreateWizard.vue";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import { mapState } from "vuex";
import { backendErrorSwal } from "@/core/helpers/swal";
import CompanyService from "@/core/services/company/company.service";
import Swal from "sweetalert2";

export default {
  components: {
    PhysicalPersonCreateWizard,
    LegalPersonCreateWizard,
  },

  inheritAttrs: true,
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    backBtnTooltip: {
      type: String,
      default: "",
    },
    organizationType: {
      type: String,
      required: true
    }
  },

  data: () => ({
    legalPersonErrorMessages: null,
    physicalPersonErrorMessages: null,
  }),

  computed: {
    ...mapState("constants", ["countries"]),
    icons() {
      return icons;
    },

    commonAttrs() {
      return {
        "hide-footer": true,
        size: "xl",
        "header-bg-variant": "primary",
      };
    },
  },

  methods: {
    reset() {
      this.legalPersonErrorMessages = null;
      this.physicalPersonErrorMessages = null;
    },

    async onLegalPersonSubmit(legalPerson) {
      if (legalPerson.physical_person_contact_list?.id) {
        const id = legalPerson.physical_person_contact_list.id;
        legalPerson.physical_person_contact_list = [
          {
            role: legalPerson.physical_person_contact_list.role,
            physicalperson: id,
          },
        ];
      } else if (legalPerson.physical_person_contact_list?.name) {
        delete legalPerson.physical_person_contact_list.roleName;
        const res = await PhysicalPersonService.create(legalPerson.physical_person_contact_list);
        legalPerson.physical_person_contact_list = [
          {
            physicalperson: res.id,
            role: legalPerson.physical_person_contact_list.role,
          },
        ];
      }

      try {
        await CompanyService.create(legalPerson);
        this.$emit("created");
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 400) {
          this.legalPersonErrorMessages = err.response.data;
          const fields = Object.keys(err.response.data);
          const errors = Object.keys(err.response.data[fields[0]][0]);
          const msg = err.response.data[fields[0]][0][errors[0]][0];
          backendErrorSwal(msg, msg);
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      }
    },

    async onPhysicalPersonSubmit(physicalPerson) {
      try {
        await PhysicalPersonService.create(physicalPerson);
        this.$emit("created");
      } catch (err) {
        console.log(err)
        if (err?.response?.status === 400) {
          this.physicalPersonErrorMessages = err.response.data;
          const socialError = err.response.data?.socialcontact_set["0"];
          if (socialError?.persona)
            Swal.fire(
              this.$t("Unable to create Physical Person"),
              this.$t("Field persona is required to associate social contacts"),
              this.$t("error"),
            );
        } else {
          backendErrorSwal(err, err?.response?.data?.detail);
        }
      }
    },
  },
};
</script>
