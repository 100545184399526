var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-provider', {
    attrs: {
      "rules": _vm.required ? 'required' : null,
      "name": _vm.title,
      "vid": "".concat(_vm.field, "-").concat(_vm.secondmentEmployee.employee)
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('google-maps-autocomplete', {
          ref: "autocomplete",
          attrs: {
            "value": _vm.placeVal,
            "error-messages": errors,
            "autocomplete-options": {
              // types: ['administrative_area_level_1', 'locality'],
              componentRestrictions: _vm.restriction
            },
            "disabled": _vm.disabled || _vm.field === 'client'
          },
          on: {
            "input": function input(e) {
              return _vm.$emit('set-place', {
                field: _vm.field,
                place: e
              });
            },
            "focus": function focus($event) {
              _vm.focus = true;
            },
            "blur": function blur($event) {
              _vm.focus = false;
            }
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_c('div', {
                staticClass: "d-flex flex-column m-n1 mt-n3 position-relative"
              }, [!_vm.hideLetters ? _c('span', {
                staticClass: "map-letter"
              }, [_vm._v(_vm._s(_vm.alphabet[_vm.mapLetter]))]) : _vm._e(), _c('div', {
                staticClass: "d-flex"
              }, [_c('span', {
                staticClass: "m-1"
              }, [_vm._v(_vm._s(_vm.title))]), _vm._l(_vm.vehicles, function (v, idx) {
                return _c('div', {
                  key: "".concat(_vm._uid, "-").concat(idx),
                  staticClass: "p-1"
                }, [_c('i', {
                  directives: [{
                    name: "b-tooltip",
                    rawName: "v-b-tooltip.hover",
                    modifiers: {
                      "hover": true
                    }
                  }],
                  staticClass: "m-1",
                  class: [_vm.icons[v]],
                  attrs: {
                    "title": v
                  },
                  on: {
                    "click": function click($event) {
                      !_vm.disabled ? _vm.$emit('open-map', {
                        vehicle: v,
                        field: _vm.field,
                        idx: _vm.mapLetter
                      }) : null;
                    }
                  }
                })]);
              }), !_vm.disabled && !['arrival', 'departure', 'accommodation'].includes(_vm.field) ? _c('div', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "d-inline-block px-1 ml-auto mr-5",
                attrs: {
                  "title": _vm.$t('Clear')
                },
                on: {
                  "click": function click($event) {
                    return _vm.$emit('remove-place', {
                      idx: _vm.mapLetter
                    });
                  }
                }
              }, [_c('i', {
                class: _vm.icons.close
              })]) : _vm._e()], 2), _c('div', [_vm.withRisks ? _c('b-overlay', {
                attrs: {
                  "show": _vm.riskLoading,
                  "rounded": "sm"
                }
              }, [_c('div', {
                staticClass: "d-flex text-dark flex-wrap"
              }, _vm._l(_vm.risks, function (countryRisk) {
                var _vm$RISK_LEGEND_MAP$c, _vm$countriesMap$coun;
                return _c('rectangle', {
                  key: countryRisk.countryIso,
                  staticClass: "mr-2 mb-2",
                  attrs: {
                    "color": (_vm$RISK_LEGEND_MAP$c = _vm.RISK_LEGEND_MAP[countryRisk.value]) === null || _vm$RISK_LEGEND_MAP$c === void 0 ? void 0 : _vm$RISK_LEGEND_MAP$c.color,
                    "label": (_vm$countriesMap$coun = _vm.countriesMap[countryRisk.countryIso]) === null || _vm$countriesMap$coun === void 0 ? void 0 : _vm$countriesMap$coun.name
                  }
                });
              }), 1)]) : _vm._e()], 1)])];
            },
            proxy: true
          }], null, true)
        }), _vm.alert ? _c('div', {
          staticClass: "alert alert-light",
          attrs: {
            "role": "alert"
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-dark"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Code/Warning-2.svg"
          }
        })], 1), _vm._v(" " + _vm._s(_vm.alert) + " ")]) : _vm._e()];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }