var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "font-weight-bold label label-inline m-1 py-4",
    class: _vm.labelClasses,
    staticStyle: {
      "min-width": "39px"
    },
    attrs: {
      "title": _vm.tooltipText
    }
  }, [_c('i', {
    class: _vm.selectedDocumentIcon,
    style: _vm.iconStyle,
    on: {
      "click": _vm.showGantt
    }
  }), _c('b-modal', {
    ref: "ganttModal",
    attrs: {
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', [_vm._v(_vm._s(_vm.getDocumentText))]), _c('b-button', {
          on: {
            "click": _vm.closeModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_vm.ganttData ? _c('gantt-chart', {
    attrs: {
      "data": _vm.ganttData
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }