<template>
  <div :id="`availability-chip-${_uid}`"
    class="label label-lg font-weight-bold label-inline text-nowrap mt-2 text-uppercase cursor-pointer" :class="{
      'label-light-success': availability == AvailabilityEnum.AVAILABLE,
      'label-light-danger': availability == AvailabilityEnum.NOT_AVAILABLE,
      'label-warning': availability == AvailabilityEnum.PARTIAL,
    }" @click="$emit('click', $event)">
    <i :class="[icon, { 'mr-2': !iconOnly }]" :style="iconStyle"></i>
    <span v-if="!iconOnly">{{ datesTooltip ? dates : computedTooltipMessage }}</span>
    <b-tooltip :target="`availability-chip-${_uid}`" noninteractive :placement="tooltipPlacement">
      <strong>{{ computedTooltipMessage }}</strong>
      <br />
      <div v-if="dates">
        {{ dates }}
      </div>
      <span>{{ $t("Click to view Gantt") }}</span>
    </b-tooltip>
  </div>
</template>

<script>
import AvailabilityService from "@/core/services/availability.service";
export default {
  props: {
    availability: {
      type: Number,
      required:true
    },
    hoverMsg: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    dates: {
      type: String,
      default: "",
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
    datesTooltip: {
      type: Boolean,
      default: false,
    }
  },

  data: () => ({
    AvailabilityEnum: AvailabilityService.Enum,
    colors: {
      'null': '#999',
      '0': '#f44336',
      '1': '#00A9BD',
      '2': '#FFEECB'
    },
  }),

  computed: {
    computedTooltipMessage() {
      const msg = this.availabilityEnumToString(this.availability);
      return msg;
    },
    iconStyle() {
      return {
        cursor: 'pointer',
        color: this.colors[this.availability],
        display: 'inline-block',
      };
    },
  },

  methods: {
    availabilityEnumToString(v) {
      return AvailabilityService.enumToString(v);
    },
  },
};
</script>
