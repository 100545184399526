<template>
  <validation-observer ref="plannerValidationObs">
    <div class="row">
      <div class="col-12">
        <div class="pt-lg-0 pt-5 pb-15 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">{{ $t('Set travel planner') }}</h3>
          <div class="text-muted font-weight-bold font-size-h4">
            {{ $t('Manage your employees daily journeys') }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <euro-alert :show="showAlert" variant="danger">
          <div class="d-flex align-items-center">
            <i :class="icons.infoCircle" :style="{ color: 'white' }"></i>
            <p class="mb-0"> {{ $t('You have') }} + {{ unplanned.length }} +
              {{ $t('employees marked in red inside the option select menu), add them to a plan to continue') }} </p>
          </div>
        </euro-alert>
      </div>
      <div class="col-12">
        <SecondmentTravelPlanner :travel-plans="travelPlans" :secondment-employees="secondmentEmployees"
          :country="country" :client-address-id="clientAddressId" :client="client" :unplanned="unplanned"
          @delete-travel-plan="$emit('delete-travel-plan', $event)"
          @clone-travel-plan="$emit('clone-travel-plan', $event)" @create-travel-plan="$emit('create-travel-plan')">
        </SecondmentTravelPlanner>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import SecondmentTravelPlanner from "@/view/components/secondment-travel-planner";
import EuroAlert from "@/view/components/alerts/EuroAlert.vue";
import icons from "@/core/config/icons.js";

export default {
  components: {
    SecondmentTravelPlanner,
    EuroAlert,
  },

  props: {
    client: {
      type: Object,
      default: () => { },
    },
    travelPlans: {
      type: Array,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    country: {
      type: String,
      default: "",
    },
    clientAddressId: {
      type: Number,
      default: null,
    },
    wizardMode: {
      type: String,
      default: "create",
    },
  },

  data() {
    return {
      icons,
    };
  },

  computed: {
    showAlert() {
      return !!(this.wizardMode === "edit" && this.unplanned.length);
    },

    travelPlansComputed() {
      return this.travelPlans.map((pl) => {
        const employees = pl.employees
          .map((secEmp) => {
            const id = typeof secEmp == 'object' ? secEmp.employee : secEmp

            return this.secondmentEmployees.find((secEmp) => secEmp.id == id || secEmp.employee == id)
          })
          .filter((el) => el);
        return {
          ...pl,
          employees,
        };
      });
    },

    unplanned() {
      const secondmentEmployeesId = this.secondmentEmployees.map((emp) => emp.employee).filter((el) => el);

      let travelPlanEmployees = [];

      this.travelPlansComputed.forEach((plan) => {
        travelPlanEmployees = [...travelPlanEmployees, ...plan.employees];
      });

      const diff = secondmentEmployeesId.reduce((acc, curr) => {
        const found = travelPlanEmployees.find((em) => (typeof em == "object" ? em.employee == curr : em == curr));
        if (found) return acc;
        return [...acc, curr];
      }, []);
      return diff;
    },
  },

  methods: {
    validate() {
      return this.$refs.plannerValidationObs.validate();
    },
  },
};
</script>
