var render = function render(){
  var _vm$clientAddressCent, _vm$clientAddressCent2, _vm$startLocationWeat, _vm$startLocationWeat2, _vm$startLocationWeat3, _vm$startLocationWeat4, _vm$startLocationWeat5, _vm$startLocationWeat6, _vm$startLocationWeat7, _vm$startLocationWeat8, _vm$startLocationWeat9, _vm$startLocationWeat10, _vm$startLocationWeat11, _vm$startLocationWeat12, _vm$startLocationWeat13, _vm$startLocationWeat14, _vm$startLocationWeat15, _vm$startLocationWeat16, _vm$startLocationWeat17, _vm$riskLevelLegend, _vm$riskLevelLegend2, _vm$riskLevelLegend3, _vm$contactPersons, _vm$contactPersons2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-provider', {
    class: {
      'd-none': _vm.secondmentClientComputed
    },
    attrs: {
      "name": "client",
      "vid": "client",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('secondment-client-table', {
          ref: "clientTable",
          attrs: {
            "countries": _vm.countries,
            "default-country-filter": _vm.defaultCountryFilter,
            "service": _vm.service,
            "actors": _vm.actors
          },
          on: {
            "clients-change": function clientsChange($event) {
              return _vm.$emit('clients-change', $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "topbar-end",
            fn: function fn() {
              return [_c('b-dropdown', {
                attrs: {
                  "right": "",
                  "text": _vm.$t('Add new client'),
                  "variant": "btn btn-light-primary font-weight-bolder"
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('span', {
                      staticClass: "svg-icon menu-icon"
                    }, [_c('inline-svg', {
                      attrs: {
                        "src": "/media/svg/icons/Code/Plus.svg"
                      }
                    })], 1), _vm._v(" " + _vm._s(_vm.$t("Add new client")) + " ")];
                  },
                  proxy: true
                }], null, true)
              }, [_c('b-dropdown-item', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newClient",
                  modifiers: {
                    "newClient": true
                  }
                }],
                on: {
                  "click": function click($event) {
                    _vm.organizationType = 'physical_person';
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Add Physical Person")) + " ")]), _c('b-dropdown-item', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newClient",
                  modifiers: {
                    "newClient": true
                  }
                }],
                on: {
                  "click": function click($event) {
                    _vm.organizationType = 'company';
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Add Legal Person")) + " ")])], 1), _c('SecondmentActorCreateModal', {
                ref: "newClient",
                attrs: {
                  "id": "newClient",
                  "title": _vm.$t('Add new client'),
                  "text": _vm.$t('Please select the kind of client you would like to add'),
                  "back-btn-tooltip": _vm.$t('Back to client type choice'),
                  "organization-type": _vm.organizationType
                },
                on: {
                  "hidden": _vm.onSecondmentActorCreateModalHidden,
                  "close": _vm.closeModal,
                  "created": _vm.refreshTableAndCloseModal
                }
              })];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.selectedClientComputed,
            callback: function callback($$v) {
              _vm.selectedClientComputed = $$v;
            },
            expression: "selectedClientComputed"
          }
        }), errors ? _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]) : _vm._e()];
      }
    }])
  }), _c('b-card', {
    staticClass: "slider",
    class: {
      close: !_vm.secondmentClientComputed
    }
  }, [_vm.secondmentClientComputed ? [_c('b-card-title', [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('avatar', {
    attrs: {
      "avatar-image": _vm.selectedClientComputed.logo,
      "avatar-text": _vm.selectedClientComputed.name[0]
    }
  }), _c('div', {
    staticClass: "ml-3 font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.selectedClientComputed.name))])], 1), _c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onSelectAnotherClientBtnClick
    }
  }, [_c('i', {
    class: _vm.icons.undo
  }), _vm._v(" " + _vm._s(_vm.$t("Select another client")) + " ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "mb-5"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-4"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Destination")))]), _c('b-btn', {
    staticClass: "btn-xs ml-4",
    attrs: {
      "variant": "light-primary"
    },
    on: {
      "click": _vm.onClientAddressBtnClick
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Code/Plus.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Add New Address")))])])], 1), _c('div', [_c('validation-provider', {
    attrs: {
      "name": "address",
      "vid": "address",
      "rules": "required_if:client",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('euro-select', {
          attrs: {
            "value": _vm.secondmentClientComputedAddress,
            "placeholder": "Work Address",
            "options": _vm.addressSelectable,
            "error-messages": errors,
            "multiselect-props": {
              'allow-empty': false
            }
          },
          on: {
            "input": _vm.onSecondmentClientAddressInput
          }
        })];
      }
    }], null, false, 635689230)
  })], 1)]), _c('div', {
    staticClass: "separator separator-dashed my-6"
  }), _c('div', {
    staticClass: "border"
  }, [_c('address-map', {
    attrs: {
      "lat": (_vm$clientAddressCent = _vm.clientAddressCenter) === null || _vm$clientAddressCent === void 0 ? void 0 : _vm$clientAddressCent.latitude,
      "lng": (_vm$clientAddressCent2 = _vm.clientAddressCenter) === null || _vm$clientAddressCent2 === void 0 ? void 0 : _vm$clientAddressCent2.longitude,
      "height": "450"
    }
  })], 1)]), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "from row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('h5', {
    staticClass: "pr-3 form-label mb-6"
  }, [_vm._v(_vm._s(_vm.$t("Allow Notifications")) + ":")]), _c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    model: {
      value: _vm.secondmentClient.notify,
      callback: function callback($$v) {
        _vm.$set(_vm.secondmentClient, "notify", $$v);
      },
      expression: "secondmentClient.notify"
    }
  })], 1)]), _vm.telephoneContact ? _c('div', {
    staticClass: "col-6"
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("Telephone Contacts")))]), _c('p', [_vm._v(_vm._s(_vm.telephoneContact.number))])]) : _vm._e(), _vm.emailContact ? _c('div', {
    staticClass: "col-6"
  }, [_c('h5', {
    staticClass: "mt-5"
  }, [_vm._v(_vm._s(_vm.$t("Email Contacts")))]), _c('p', [_vm._v(_vm._s(_vm.emailContact.email))])]) : _vm._e(), _vm.telephoneContact || _vm.emailContact ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-6"
  })]) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('euro-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('i', {
    staticClass: "mr-2",
    class: _vm.icons.infoCircle,
    style: {
      color: '#fff'
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t("Showing data at")) + " " + _vm._s(_vm.currentDate))])])])], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.startLocationWeatherLoading
    }
  }, [!_vm.startLocationWeather ? _c('div', {
    staticClass: "p-8"
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex flex-wrap mt-5"
  }, [_vm.startLocationWeather ? _c('div', {
    staticClass: "d-flex justify-content-start align-items-center font-weight-bold border rounded pr-8 mr-4 mb-4"
  }, [_c('img', {
    attrs: {
      "src": "/media/weather/icons/".concat((_vm$startLocationWeat = _vm.startLocationWeather) === null || _vm$startLocationWeat === void 0 ? void 0 : _vm$startLocationWeat.WeatherIcon, ".svg")
    }
  }), _vm._v(" " + _vm._s((_vm$startLocationWeat2 = _vm.startLocationWeather) === null || _vm$startLocationWeat2 === void 0 ? void 0 : _vm$startLocationWeat2.WeatherText) + " " + _vm._s((_vm$startLocationWeat3 = _vm.startLocationWeather) === null || _vm$startLocationWeat3 === void 0 ? void 0 : (_vm$startLocationWeat4 = _vm$startLocationWeat3.Temperature) === null || _vm$startLocationWeat4 === void 0 ? void 0 : (_vm$startLocationWeat5 = _vm$startLocationWeat4.Metric) === null || _vm$startLocationWeat5 === void 0 ? void 0 : _vm$startLocationWeat5.Value) + "°" + _vm._s((_vm$startLocationWeat6 = _vm.startLocationWeather) === null || _vm$startLocationWeat6 === void 0 ? void 0 : (_vm$startLocationWeat7 = _vm$startLocationWeat6.Temperature) === null || _vm$startLocationWeat7 === void 0 ? void 0 : (_vm$startLocationWeat8 = _vm$startLocationWeat7.Metric) === null || _vm$startLocationWeat8 === void 0 ? void 0 : _vm$startLocationWeat8.Unit) + " "), _vm.startLocationWeather.current_focus_data ? _c('span', [_c('rectangle', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "ml-2",
    attrs: {
      "title": "Starting location temperature: ".concat((_vm$startLocationWeat9 = _vm.startLocationWeather.current_focus_data) === null || _vm$startLocationWeat9 === void 0 ? void 0 : (_vm$startLocationWeat10 = _vm$startLocationWeat9.Temperature) === null || _vm$startLocationWeat10 === void 0 ? void 0 : (_vm$startLocationWeat11 = _vm$startLocationWeat10.Metric) === null || _vm$startLocationWeat11 === void 0 ? void 0 : _vm$startLocationWeat11.Value, "\xB0 ").concat((_vm$startLocationWeat12 = _vm.startLocationWeather.current_focus_data) === null || _vm$startLocationWeat12 === void 0 ? void 0 : (_vm$startLocationWeat13 = _vm$startLocationWeat12.Temperature) === null || _vm$startLocationWeat13 === void 0 ? void 0 : (_vm$startLocationWeat14 = _vm$startLocationWeat13.Metric) === null || _vm$startLocationWeat14 === void 0 ? void 0 : _vm$startLocationWeat14.Unit),
      "color": _vm.tempDiffColor,
      "label": "".concat(_vm.tempDiffSymbol).concat(Math.abs(_vm.tempDiffValue), "\xB0").concat((_vm$startLocationWeat15 = _vm.startLocationWeather.current_focus_data) === null || _vm$startLocationWeat15 === void 0 ? void 0 : (_vm$startLocationWeat16 = _vm$startLocationWeat15.Temperature) === null || _vm$startLocationWeat16 === void 0 ? void 0 : (_vm$startLocationWeat17 = _vm$startLocationWeat16.Metric) === null || _vm$startLocationWeat17 === void 0 ? void 0 : _vm$startLocationWeat17.Unit)
    }
  })], 1) : _vm._e()]) : _vm._e(), _vm.airQuality && _vm.airQuality.distance_from_station <= 10 ? _c('div', {
    staticClass: "d-flex justify-content-start align-items-center font-weight-bold border rounded p-6 mr-4 mb-4"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('img', {
    attrs: {
      "src": "/media/svg/icons/Weather/Fog.svg"
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.$t("Pollution Index")) + ": "), _c('rectangle', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.airQuality.pollution_description,
      "color": _vm.airQualityColor,
      "label": _vm.airQuality.aqi + ''
    }
  })], 1)]) : _vm._e(), _vm.airQuality && _vm.airQuality.distance_from_station <= 10 ? _c('div', {
    staticClass: "d-flex justify-content-start align-items-center font-weight-bold border rounded p-6 mr-4 mb-4"
  }, [_c('div', {
    staticClass: "mr-4"
  }, [_c('img', {
    attrs: {
      "src": "/media/svg/icons/Home/Ruller.svg"
    }
  })]), _c('span', [_vm._v(_vm._s(_vm.$t("Distance from station")) + ": " + _vm._s(_vm.airQuality.distance_from_station) + " Km")])]) : _vm._e()])])], 1), _vm.startLocationRisk ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-6"
  })]) : _vm._e(), _vm.startLocationRisk ? _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-4"
  }, [_vm._v(_vm._s(_vm.$t("Destination max risk level")) + ": "), _c('rectangle', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": (_vm$riskLevelLegend = _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1]) === null || _vm$riskLevelLegend === void 0 ? void 0 : _vm$riskLevelLegend.label,
      "label": (_vm$riskLevelLegend2 = _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1]) === null || _vm$riskLevelLegend2 === void 0 ? void 0 : _vm$riskLevelLegend2.label,
      "color": (_vm$riskLevelLegend3 = _vm.riskLevelLegend[_vm.startLocationMaxRiskStatus - 1]) === null || _vm$riskLevelLegend3 === void 0 ? void 0 : _vm$riskLevelLegend3.color
    }
  })], 1), _vm.startLocationRisk && _vm.startLocationMaxRiskCountries.length ? _c('div', [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(_vm.startLocationMaxRiskCountries, function (country) {
    var _vm$riskLevelLegend4, _vm$riskLevelLegend5;
    return _c('div', {
      key: country.countryIso
    }, [_c('rectangle', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "mb-2",
      attrs: {
        "color": (_vm$riskLevelLegend4 = _vm.riskLevelLegend[country.value - 1]) === null || _vm$riskLevelLegend4 === void 0 ? void 0 : _vm$riskLevelLegend4.color,
        "label": _vm.countriesMap[country.countryIso].name,
        "title": (_vm$riskLevelLegend5 = _vm.riskLevelLegend[country.value - 1]) === null || _vm$riskLevelLegend5 === void 0 ? void 0 : _vm$riskLevelLegend5.label
      }
    })], 1);
  }), 0)]) : _vm._e()]) : _vm._e(), (_vm$contactPersons = _vm.contactPersons) !== null && _vm$contactPersons !== void 0 && _vm$contactPersons.length ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-6"
  })]) : _vm._e(), (_vm$contactPersons2 = _vm.contactPersons) !== null && _vm$contactPersons2 !== void 0 && _vm$contactPersons2.length ? _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-3"
  }, [_vm._v(_vm._s(_vm.$t("Contacts")))]), _c('div', _vm._l(_vm.contactPersons, function (contact, idx) {
    var _contact$email, _contact$telephone;
    return _c('secondment-client-contact-card', {
      key: idx,
      attrs: {
        "name": contact.name,
        "email": (_contact$email = contact.email) === null || _contact$email === void 0 ? void 0 : _contact$email.email,
        "telephone": (_contact$telephone = contact.telephone) === null || _contact$telephone === void 0 ? void 0 : _contact$telephone.number,
        "avatar-url": contact.avatar,
        "avatar-backup": contact.avatarBackup
      }
    });
  }), 1)]) : _vm._e()])])])] : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "client-modal-address",
      "title": "Address Form",
      "size": "lg",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.onClientAddressModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.$t("New address")))]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "mr-4"
        }, [_c('b-button', {
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.onClientAddressSubmit
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Add")) + " ")])], 1), _c('div', [_c('b-button', {
          on: {
            "click": _vm.closeClientAddressModal
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)])])];
      },
      proxy: true
    }])
  }, [_c('validation-observer', {
    ref: "addressAddValidation"
  }, [_c('b-row', [_c('b-col', [_c('euro-select', {
    attrs: {
      "label": "Action",
      "options": _vm.saveOnClientOptions
    },
    model: {
      value: _vm.addToClient,
      callback: function callback($$v) {
        _vm.addToClient = $$v;
      },
      expression: "addToClient"
    }
  })], 1), _c('b-col', [_c('validation-provider', {
    attrs: {
      "vid": "address_kind"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-select', {
          attrs: {
            "label": _vm.$t('Kind'),
            "errors": errors,
            "options": _vm.selectableKinds
          },
          model: {
            value: _vm.clientAddressForm.kind,
            callback: function callback($$v) {
              _vm.$set(_vm.clientAddressForm, "kind", $$v);
            },
            expression: "clientAddressForm.kind"
          }
        })];
      }
    }])
  })], 1)], 1), _c('validation-provider', {
    attrs: {
      "vid": "address_address",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var _vm$clientAddressCent3;
        var errors = _ref4.errors;
        return [_c('AddressInput', {
          attrs: {
            "label": _vm.$t('Address'),
            "error-messages": errors,
            "address-center": (_vm$clientAddressCent3 = _vm.clientAddressCenter) === null || _vm$clientAddressCent3 === void 0 ? void 0 : _vm$clientAddressCent3.address,
            "active-step": true,
            "manual-address": _vm.clientAddressForm.manual_address
          },
          on: {
            "change-manual-address": _vm.changeManualAddress,
            "update-composed-address": _vm.updateComposedAddress
          },
          model: {
            value: _vm.clientAddressForm.address,
            callback: function callback($$v) {
              _vm.$set(_vm.clientAddressForm, "address", $$v);
            },
            expression: "clientAddressForm.address"
          }
        })];
      }
    }])
  })], 1)], 1)], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }