var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "p-8 w-100 secondment-employee-card",
    attrs: {
      "no-body": "",
      "border-variant": _vm.focus ? 'primary' : null
    }
  }, [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12 mb-2"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center"
  }, [_c('b-btn', {
    attrs: {
      "variant": "primary",
      "size": "sm"
    },
    on: {
      "click": _vm.onCopyToAllClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("COPY TO ALL")) + " ")])], 1)]), _c('div', {
    staticClass: "col-2"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('avatar', {
    staticClass: "mr-3",
    attrs: {
      "avatar-image": _vm.avatarImage,
      "avatar-text": _vm.avatarText,
      "size": "64px"
    }
  }), _c('div', [_c('span', {
    staticClass: "font-weight-bolder font-size-h5 text-dark-75"
  }, [_vm._v(" " + _vm._s(_vm.secondmentEmployee.name) + " ")])])], 1), _c('div', {
    staticClass: "my-1 my-sm-0 my-md-1 d-none d-sm-block d-md-none"
  }, [_c('p', {
    staticClass: "font-weight-bold h6 d-flex"
  }, [_vm._v(" " + _vm._s(_vm.secondmentEmployee.name) + " "), _vm.employee ? _c('span', {
    staticClass: "ml-3"
  }, [_vm.documentsValid ? _c('i', {
    staticClass: "text-success",
    class: _vm.icons.check
  }) : _c('div', [_c('i', {
    staticClass: "text-danger",
    class: _vm.icons.warning,
    attrs: {
      "id": "".concat(_vm._uid, "-sm-doc-danger-").concat(_vm.secondmentEmployee.employee)
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "".concat(_vm._uid, "-sm-doc-danger-").concat(_vm.secondmentEmployee.employee),
      "triggers": "hover"
    }
  }, _vm._l(_vm.documentsErrors, function (error, idx) {
    return _c('p', {
      key: idx,
      staticClass: "font-weight-bolder text-uppercase"
    }, [_vm._v(" " + _vm._s(error) + " ")]);
  }), 0)], 1)]) : _vm._e()])]), _c('div', [!_vm.isAfterSale ? _c('EmployeeSalaryChip', {
    staticClass: "w-100",
    attrs: {
      "salary": _vm.salary,
      "show-salary-adjustment": ""
    }
  }) : _vm._e()], 1), _c('div', [_c('employee-availability-chip', {
    staticClass: "pointer w-100",
    attrs: {
      "availability": _vm.availability,
      "hover-msg": _vm.$t('View Gantt'),
      "icon": _vm.icons.chart
    },
    on: {
      "click": _vm.onAvailabilityClick
    }
  })], 1), _c('div', {
    staticClass: "my-1 my-sm-0 my-md-1 d-md-none"
  }, [_c('b-btn', {
    attrs: {
      "variant": "primary",
      "size": "sm",
      "block": ""
    },
    on: {
      "click": _vm.onCopyToAllClick
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("COPY TO ALL")) + " ")])], 1)]), _c('div', {
    staticClass: "col-10"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('validation-provider', {
    ref: "datesProvider-".concat(_vm.secondmentEmployee.employee),
    staticClass: "col-6",
    attrs: {
      "name": _vm.$t('Dates'),
      "rules": "required",
      "vid": "dates-".concat(_vm.secondmentEmployee.employee),
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-double-date-picker', {
          attrs: {
            "disabled": _vm.readonly,
            "date-from": _vm.secondmentEmployee.date_start,
            "date-to": _vm.secondmentEmployee.date_end,
            "enabled-dates": _vm.secondmentDays,
            "customized-dates": _vm.customizedDays,
            "error-messages": errors,
            "label": "Secondment Dates"
          },
          on: {
            "date-from-to-selected": _vm.onDateFromToSelected
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t("Assignment Dates")) + "* ")];
            },
            proxy: true
          }], null, true)
        })];
      }
    }])
  }), _c('div', {
    staticClass: "col-6"
  }, [_c('travel-plan-input', {
    attrs: {
      "title": "".concat(_vm.$t('Housing')).concat(!_vm.singleDay ? '*' : ''),
      "secondment-employee": _vm.secondmentEmployee,
      "hide-letters": "",
      "field": "accommodation",
      "vehicles": ['housing'],
      "restriction": {
        country: _vm.country || ''
      },
      "required": !_vm.singleDay,
      "alert": _vm.alertHousing
    },
    on: {
      "set-place": function setPlace(_ref2) {
        var field = _ref2.field,
          place = _ref2.place;
        return _vm.setEmployeeProperty(field, place, 'alertHousing');
      },
      "open-map": function openMap(_ref3) {
        var vehicle = _ref3.vehicle,
          field = _ref3.field;
        return _vm.openMap(vehicle, field, 'alertHousing');
      }
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('travel-plan-input', {
    attrs: {
      "title": "".concat(_vm.$t('Place of Arrival in Assignment Country')),
      "alert": _vm.alertArrival,
      "restriction": {
        country: _vm.country || ''
      },
      "secondment-employee": _vm.secondmentEmployee,
      "hide-letters": "",
      "field": "arrival"
    },
    on: {
      "set-place": function setPlace(_ref4) {
        var field = _ref4.field,
          place = _ref4.place;
        return _vm.setEmployeeProperty(field, place, 'alertArrival');
      },
      "open-map": function openMap(_ref5) {
        var vehicle = _ref5.vehicle,
          field = _ref5.field;
        return _vm.openMap(vehicle, field, 'alertArrival');
      }
    }
  })], 1), _c('div', {
    staticClass: "col-6"
  }, [_c('travel-plan-input', {
    attrs: {
      "title": "".concat(_vm.$t('Place of Departure from Assignment Country')),
      "alert": _vm.alertDeparture,
      "restriction": {
        country: _vm.country || ''
      },
      "secondment-employee": _vm.secondmentEmployee,
      "hide-letters": "",
      "field": "departure"
    },
    on: {
      "set-place": function setPlace(_ref6) {
        var field = _ref6.field,
          place = _ref6.place;
        return _vm.setEmployeeProperty(field, place, 'alertDeparture');
      },
      "open-map": function openMap(_ref7) {
        var vehicle = _ref7.vehicle,
          field = _ref7.field;
        return _vm.openMap(vehicle, field, 'alertDeparture');
      }
    }
  })], 1)], 1)])])]), _c('b-modal', {
    attrs: {
      "id": "points-of-interest-modal-".concat(_vm.employee.id),
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t("Select a place")) + " ")]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showSaveBotton,
            expression: "showSaveBotton"
          }],
          staticClass: "mr-4",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.saveChanges
          }
        }, [_vm._v(_vm._s(_vm.$t("Save Changes")))]), _c('b-button', {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('google-maps-points-of-interest', {
    ref: "childComponentRef",
    attrs: {
      "search-term": _vm.searchTerm,
      "field": _vm.field,
      "map-center": _vm.poiCenter,
      "vehicle": _vm.vehicle,
      "country": _vm.country
    },
    on: {
      "selected-place": _vm.handlePlaceSelect,
      "marker-dragend": _vm.handleDrag
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }