var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('validation-observer', {
    ref: "suppliersValidationObs"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5 pb-15 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(_vm._s(_vm.$t("Select Suppliers")))]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Add suppliers to assignment")) + " ")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": "suppliers",
      "vid": "suppliers",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('secondment-actor-table', {
          ref: "selectableSuppliersTable",
          attrs: {
            "countries": _vm.countries,
            "title": "Supplier",
            "multi-select": "",
            "actors": _vm.actors,
            "custom-columns": [{
              key: 'actions',
              sortable: false,
              class: 'align-middle'
            }]
          },
          scopedSlots: _vm._u([{
            key: "topbar-end",
            fn: function fn() {
              return [_c('b-dropdown', {
                attrs: {
                  "no-caret": "",
                  "right": "",
                  "no-flip": "",
                  "text": "Document",
                  "variant": "light-primary",
                  "toggle-class": "font-weight-bolder py-3"
                },
                scopedSlots: _vm._u([{
                  key: "button-content",
                  fn: function fn() {
                    return [_c('div', {
                      staticClass: "dropdown-button"
                    }, [_c('span', {
                      staticClass: "svg-icon menu-icon"
                    }, [_c('inline-svg', {
                      attrs: {
                        "src": "/media/svg/icons/Code/Plus.svg"
                      }
                    })], 1), _c('span', {
                      staticClass: "font-weight-bolder"
                    }, [_vm._v(_vm._s(_vm.$t("Add suppliers")))])])];
                  },
                  proxy: true
                }], null, true)
              }, [_c('div', {
                staticClass: "navi navi-hover min-w-md-200px"
              }, [_c('b-dropdown-text', {
                staticClass: "navi-item",
                attrs: {
                  "tag": "div"
                }
              }, [_c('button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newSupplier",
                  modifiers: {
                    "newSupplier": true
                  }
                }],
                staticClass: "w-100 text-left navi-link",
                on: {
                  "click": function click($event) {
                    return _vm.setOrganizationType('company');
                  }
                }
              }, [_c('span', {
                staticClass: "navi-icon"
              }, [_c('i', {
                staticClass: "fas fa-plus"
              })]), _c('span', {
                staticClass: "navi-text"
              }, [_vm._v(" " + _vm._s(_vm.$t("Company")) + " ")])]), _c('button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newSupplier",
                  modifiers: {
                    "newSupplier": true
                  }
                }],
                staticClass: "w-100 text-left navi-link",
                on: {
                  "click": function click($event) {
                    return _vm.setOrganizationType('physical_person');
                  }
                }
              }, [_c('span', {
                staticClass: "navi-icon"
              }, [_c('i', {
                staticClass: "fas fa-plus"
              })]), _c('span', {
                staticClass: "navi-text"
              }, [_vm._v(" " + _vm._s(_vm.$t("Physical person")) + " ")])])])], 1)])];
            },
            proxy: true
          }, {
            key: "cell.actions",
            fn: function fn(_ref2) {
              var item = _ref2.item;
              return [_c('b-button', {
                staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
                attrs: {
                  "tooltip": _vm.$t('Edit supplier')
                },
                on: {
                  "click": function click($event) {
                    return _vm.onEditSupplier(item);
                  }
                }
              }, [_c('span', {
                staticClass: "svg-icon svg-icon-md svg-icon-primary"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Design/Edit.svg"
                }
              })], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.selectedSuppliersComputed,
            callback: function callback($$v) {
              _vm.selectedSuppliersComputed = $$v;
            },
            expression: "selectedSuppliersComputed"
          }
        }), errors ? _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]) : _vm._e()];
      }
    }])
  })], 1), _vm.secondmentSuppliers.length > 0 ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-5"
  })]) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('secondment-suppliers-list', {
    attrs: {
      "secondment-suppliers": _vm.secondmentSuppliers
    },
    scopedSlots: _vm._u([{
      key: "secondmentSupplier",
      fn: function fn(_ref3) {
        var secondmentSupplier = _ref3.secondmentSupplier;
        return [_c('secondment-supplier-card', {
          attrs: {
            "value": secondmentSupplier,
            "secondment-dates-all": _vm.secondmentDatesAll
          },
          on: {
            "input": function input($event) {
              return _vm.$emit('secondment-supplier-change', $event);
            }
          }
        })];
      }
    }])
  })], 1)])]), _c('SecondmentActorCreateModal', {
    ref: "newSupplier",
    attrs: {
      "id": "newSupplier",
      "title": _vm.$t('Add new supplier'),
      "text": _vm.$t('Please select the kind of supplier you would like to add'),
      "back-btn-tooltip": _vm.$t('Back to supplier type choice'),
      "organization-type": _vm.organizationType
    },
    on: {
      "hidden": _vm.onSecondmentActorCreateModalHidden,
      "close": _vm.closeModal,
      "created": _vm.refreshTableAndCloseModal
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }