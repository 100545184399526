var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_vm._t("container-top"), _c('div', {
    staticClass: "timeline-row",
    class: _vm.rowClasses,
    style: {
      'grid-auto-columns': _vm.columnsSize
    }
  }, [_c('div', {
    staticClass: "timeline-row-left"
  }, [_vm._t("container-left")], 2), _c('div', {
    staticClass: "timeline-row-ruler"
  }, [_vm._t("marker", function () {
    return [_c('timeline-marker-circle')];
  })], 2), _c('div', {
    staticClass: "timeline-row-right"
  }, [_vm._t("container-right")], 2)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }