<template>
  <b-modal :id="id" hide-footer size="xl" @hidden="$emit('hidden', $event)">
    <template #modal-header>
      <div class=" w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
        <h3>{{ $t("Assignment participation Gantt chart") }}: {{ secondmentSupplier.name }}</h3>
        <b-button @click="$emit('close')">
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </template>
    <gantt-chart :data="data"></gantt-chart>
    <vue-slider v-model="secondmentSupplierValues" :enable-cross="false" :min="minVal" :max="maxVal" :interval="interval"
      :tooltip-formatter="formatter" tooltip="always" :disabled="disabled"></vue-slider>
  </b-modal>
</template>

<script>
import DateService from "@/core/services/date.service";
import GanttChart from "@/view/components/charts/GanttChart.vue";

import { mapGetters } from "vuex";
import _ from "lodash";
export default {
  components: {
    GanttChart,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    secondmentSupplier: {
      type: Object,
      required: true,
    },
    secondmentStartDate: {
      type: String,
      required: true,
    },
    secondmentEndDate: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    data: [],
    interval: 1,
  }),

  computed: {
    ...mapGetters("user", ["dateFormat"]),

    minVal() {
      return 0;
    },

    maxVal() {
      return this.enumeratedDates.length - 1;
    },

    enumeratedDates() {
      let start = "";
      let end = "";

      if (DateService.isBefore(this.secondmentStartDate, this.secondmentSupplier.date_start)) {
        start = this.secondmentStartDate;
      } else {
        start = this.secondmentSupplier.date_start;
      }

      if (DateService.isBefore(this.secondmentEndDate, this.secondmentSupplier.date_end)) {
        end = this.secondmentSupplier.date_end;
      } else {
        end = this.secondmentEndDate;
      }

      return DateService.enumerateDates(start, end);
    },

    marks() {
      const out = {};
      this.enumeratedDates.forEach((date, idx) => {
        out[idx] = {
          label: date,
          labelStyle: {
            fontSize: ".6rem",
          },
        };
      });
      console.log("marks", out)

      return out;
    },

    secondmentSupplierValues: {
      get() {
        const fromIdx = this.enumeratedDates.findIndex(date => date == this.secondmentSupplier.date_start);
        const toIdx = this.enumeratedDates.findIndex(date => date == this.secondmentSupplier.date_end);

        return [fromIdx, toIdx];
      },
      set: _.debounce(function (val) {
        const [from, to] = val;

        this.$emit("secondment-supplier-change", {
          ...this.secondmentSupplier,
          date_start: this.enumeratedDates[from],
          date_end: this.enumeratedDates[to],
        });
      }, 300),
    },

    supplierGanttData() {
      if (!this.data.length) return null;

      return this.data[0];
    },

    secondmentSupplierDataComp() {
      return {
        date_start: this.secondmentSupplier.date_start,
        date_end: this.secondmentSupplier.date_end,
      };
    },
  },

  watch: {
    secondmentSupplierDataComp: {
      deep: true,
      async handler(val) {
        if (!this.supplierGanttData) return;
        await this.$nextTick();
        this.data.splice(0, 1, {
          ...this.supplierGanttData,
          dateStart: val.date_start,
          dateEnd: val.date_end,
        });
      },
    },
  },
  mounted() {
    this.loadGanttData();
  },

  methods: {
    formatter(dateIdx) {
      return DateService.format(this.enumeratedDates[dateIdx], this.dateFormat);
    },

    loadGanttData() {
      const secondmentData = {
        label: "Secondment",
        dateStart: this.secondmentStartDate,
        dateEnd: this.secondmentEndDate,
        color: "#03f4fc",
      };
      const supplierData = {
        label: this.secondmentSupplier.name,
        dateStart: this.secondmentSupplier.date_start,
        dateEnd: this.secondmentSupplier.date_end,
        color: "#77eb34",
      };
      this.data.push(supplierData, secondmentData);
    },
  },
};
</script>
