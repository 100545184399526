var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.plan.employees.length ? _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": _vm.makeTooltip
    }
  }, [_vm._t("default")], 2) : _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "text-danger",
    attrs: {
      "title": _vm.$t('Travel plans with no employees will be deleted.')
    }
  }, [_vm._t("default")], 2), _c('b-dropdown', {
    attrs: {
      "disabled": _vm.readonly,
      "size": "sm",
      "variant": "link",
      "toggle-class": "text-decoration-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('i', {
          class: [_vm.icons.ellipsisV, !_vm.readonly ? 'text-dark' : '']
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.readonly
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onCloneBtnClick(_vm.plan);
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("Clone")))]), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.readonly || _vm.plan.employees.length > 0
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.onDeleteBtnClick(_vm.plan);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Delete")) + " ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }