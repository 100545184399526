<template>
  <timeline-marker-circle :color="color">
    <slot></slot>
  </timeline-marker-circle>
</template>

<script>
import TimelineMarkerCircle from "@/view/components/timeline/TimelineMarkerCircle.vue";
import RiskService from "@/core/services/risk/risk.service";
export default {
  components: {
    TimelineMarkerCircle,
  },

  props: {
    day: {
      type: Object,
      default: () => {},
    },
    risk: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    color() {
      const riskLegend = RiskService.RISK_LEGEND_MAP[this.risk];
      return riskLegend?.color ?? 'grey'
    },
  },
};
</script>
