var render = function render(){
  var _vm$licenses;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.secondmentStart && _vm.secondmentEnd ? _c('div', {
    staticClass: "font-weight-bold label label-inline m-1 py-4",
    class: _vm.backgroundColor,
    staticStyle: {
      "min-width": "39px"
    },
    attrs: {
      "id": "license-".concat(_vm.uid)
    }
  }, [_c('i', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm.icons.car,
    style: {
      cursor: 'pointer',
      color: _vm.badgeColor,
      display: 'inline-block'
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": "license-".concat(_vm.uid),
      "triggers": "hover"
    }
  }, [(_vm$licenses = _vm.licenses) !== null && _vm$licenses !== void 0 && _vm$licenses.length ? _c('ul', _vm._l(_vm.licenses, function (license, idx) {
    return _c('li', {
      key: idx,
      staticClass: "d-flex flex-column"
    }, [_c('strong', [_vm._v("Category: " + _vm._s(license.category))]), _c('span', [_c('strong', [_vm._v("From:")]), _vm._v(" " + _vm._s(_vm.DateService.format(license.date_from, _vm.dateFormat)))]), _c('span', [_c('strong', [_vm._v("To:")]), _vm._v(" " + _vm._s(_vm.DateService.format(license.date_to, _vm.dateFormat)))]), license.status ? _c('b-badge', {
      staticClass: "mt-2",
      attrs: {
        "variant": license.status === 'valid' ? 'success' : 'danger'
      }
    }, [_vm._v(_vm._s(license.status))]) : _vm._e()], 1);
  }), 0) : _c('span', [_vm._v("No License found")])])], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }