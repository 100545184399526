<template>
  <div>
    <datatable ref="datatableRef" :table-props="tableProps" :total="total" :options.sync="tableOptions"
      :per-page-options="perPageOptions" :tr-class="trClasses">
      <template #topbar>
        <div class="d-flex justify-content-between align-items-center">
          <div v-if="showToggle">
            <label class="checkbox">
              <input v-model="selectedOnly" type="checkbox" @change="refresh" />
              <span class="mr-3"></span>
              {{ $t("Show only selected employees") }}
            </label>
          </div>
          <div class="d-flex">
            <div>
              <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="w-100 mb-0" :debounce="300"
                :style="searchBoxStyle">
                <b-input-group-prepend>
                  <span
                    class="input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"><i
                      :class="`${icons.search}`"></i></span>
                </b-input-group-prepend>
              </euro-input>
            </div>
            <div v-if="$slots['topbar-end']">
              <slot name="topbar-end"></slot>
            </div>
          </div>
        </div>
      </template>

      <template #[`header._selected`]>
        <div></div>
      </template>

      <template #[`header.knownlanguage_set`]>
        {{ $t("Languages") }}
      </template>

      <template v-if="!readOnly" #[`cell._selected`]="{ item }">
        <b-form-checkbox size="lg" :checked="isSelected(item.id)" class="mt-3"
          @change="onCheckboxSelect(item)"></b-form-checkbox>
      </template>

      <template #[`cell.name`]="{ item }">
        <div class="h-100">
          <div class="d-flex align-items-center">
            <avatar :avatar-image="item?.persona?.avatar || item.avatar"
              :avatar-text="item?.persona?.name[0] || item.name[0]"></avatar>

            <div class="ml-3 font-weight-bolder">
              <div v-if="selectedEmployees.length !== 0 && manager == item.id">
                {{ item?.persona?.name || item.name }} {{ item?.persona?.surname || item.surname }}
                <i class="fas fa-hard-hat ml-3 text-primary" :title="$t('Assignment Manager')"></i>
              </div>
              <div v-else>{{ item?.persona?.name || item.name }} {{ item?.persona?.surname || item.surname }}</div>
            </div>
          </div>
        </div>
      </template>

      <template #[`cell.type`]="{ item }">
        <div class="h-100">
          <span v-if="item.agency == null" class="font-weight-bold label label-inline label-light-success">
            {{ $t("Employee") }}
          </span>
          <span v-else class="font-weight-bold label label-inline label-light-warning">{{ $t("Temporary Worker")
            }}</span>
        </div>
      </template>

      <template #[`cell.availability`]="{ item }">
        <EmployeeAvailabilityChip :availability="getEmployeeAvailability(item)" :hover-msg="$t('View Gantt')"
          :dates="getEmployeeDates(item)" dates-tooltip tooltip-placement="right" class="pointer"
          @click="onAvailabilityClick(item)"></EmployeeAvailabilityChip>
      </template>
      <template v-if="!isAfterSale" #[`cell.salary`]="{ item }">
        <EmployeeSalaryChip icon-only tooltip-placement="left" :salary="item.salary_adjustment"
          @add-contract="$emit('add-contract', item?.persona?.id)"></EmployeeSalaryChip>
      </template>

      <template #[`cell.cost_reversal`]="{ item }">
        <div v-if="!readOnly" class="d-flex">
          <b-btn v-b-modal.changeCostReversal size="sm" variant="light-primary" :disabled="!isSelected(item.id)" block
            @click="editCostReversal(item)">
            <span class="svg-icon menu-icon">
              <inline-svg src="/media/svg/icons/Design/Edit.svg" />
            </span>
            <span class="font-weight-bolder">{{ costalReversalCompany(item.cost_reversal) }}</span>
          </b-btn>
        </div>
        <div v-else>{{ costalReversalCompany(item.cost_reversal) }}</div>
      </template>

      <template #[`cell.actions`]="{ item }">
        <b-button v-b-tooltip.hover :title="$t('Edit employee')" class="btn btn-icon btn-light btn-hover-primary btn-sm"
          @click="onEditBtnClick(item)">
          <span class="svg-icon svg-icon-md svg-icon-primary">
            <inline-svg src="/media/svg/icons/Design/Edit.svg" />
          </span>
        </b-button>
      </template>

      <template #[`cell.documents`]="{ item }">
        <EmployeeDocumentsAvailabilityChip :item="item" :get-secondment-date="getSecondmentDate"
          :get-employees-id="getEmployeesId" :check-license-props="checkLicenseProps">
        </EmployeeDocumentsAvailabilityChip>
      </template>

      <template #[`cell.schengen`]="{ item }">
        <b-button v-b-tooltip.hover :title="schengenInfo(item).message" class="btn btn-icon btn-sm"
          :class="schengenInfo(item).status">
          <span class="svg-icon svg-icon-md">
            <inline-svg src="/media/svg/icons/Communication/Clipboard-check.svg" />
          </span>
        </b-button>
      </template>
    </datatable>

    <SecondmentParticipationModal v-if="gantt.secondmentEmployee" :id="gantModalId" :availability="gantt.availability"
      :secondment-employee="gantt.secondmentEmployee" :secondment-end-date="dateTo" :secondment-start-date="dateFrom"
      :disable-edits="disableEditGant" :overlapping-secondments="gantt.overlappingSecondments" @close="$bvModal.hide(gantModalId)" @hidden="onGanttModalHidden"
      @secondment-employee-change="onSecondmentEmployeeChange"></SecondmentParticipationModal>

    <b-modal v-if="!readOnly" id="changeCostReversal" hide-footer>
      <template #modal-header="{ close }">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h4 class="mb-0">{{ $t("Cost reversal") }}</h4>
            <span class="text-muted font-weight-bold font-size-sm mt-1">
              {{ $t("Change the cost reversal for the selected user ") }}
            </span>
          </div>
          <div>
            <b-button size="sm" @click="close">
              {{ $t("Close") }}
            </b-button>
          </div>
        </div>
      </template>

      <euro-select v-model="employeeToEdit.cost_reversal" :multiselect-props="formattedIntragroupCompanies"
        class="m-0 p-0"
        @input="$emit('change-cost-reversal', { employee_id: employeeToEdit.id, cost_reversal: $event })"></euro-select>
    </b-modal>
  </div>
</template>

<script>
import Datatable from "@/view/components/tables/Datatable.vue";
import { SERVICE_TYPE } from "@/core/abstractions/secondment";
import icons from "@/core/config/icons.js";
import EmployeeAvailabilityChip from "@/view/components/chips/EmployeeAvailabilityChip.vue";
import SecondmentParticipationModal from "@/view/components/modals/SecondmentParticipationModal.vue";
import EmployeeSalaryChip from "@/view/components/chips/EmployeeSalaryChip.vue";
import EmployeeService from "@/core/services/employee/employee.service";
import { mapGetters } from "vuex";
import DateService from "@/core/services/date.service";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
import EmployeeDocumentsAvailabilityChip from "@/view/components/chips/EmployeeDocumentsAvailabilityChip.vue";
import moment from "moment";
import AvailabilityService from "@/core/services/availability.service";

export default {
  components: {
    Datatable,
    SecondmentParticipationModal,
    EmployeeAvailabilityChip,
    EmployeeSalaryChip,
    EmployeeDocumentsAvailabilityChip
  },

  props: {
    readOnly: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of employees.
     */
    value: {
      type: Array,
      default: () => [],
    },
    dateFrom: {
      type: String,
      required: true,
    },
    dateTo: {
      type: String,
      required: true,
    },
    documentsData: {
      type: Object,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    showToggle: {
      type: Boolean,
      default: true,
    },
    selectedFirst: {
      type: Boolean,
      default: false,
    },
    manager: {
      type: String,
      default: "",
    },
    intragroupClients: {
      type: Array,
      default: () => [],
    },
    selectedServiceComp: {
      type: String,
      default: null
    },
    clients: {
      type: Array,
      default: () => []
    },
    costCompanyGroup: {
      type: Array,
      default: () => []
    },
    selectedCountry: {
      type: String,
      default: null
    },
    secondmentId: {
      type: String,
      default: null
    },
    disableEditGant: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      initialized: true,
      employees: [],
      tableEmployees: [],
      selectedOnly: this.selectedFirst,
      icons,
      isLoading: false,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      total: 0,
      searchFilter: "",
      gantt: {
        secondmentEmployee: null,
      },
      employeeToEdit: { cost_reversal: null, id: null },
      moment,
    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat", "getCurrentManagedCompany", "getCompanyGroups", "isAfterSale"]),
    ...mapGetters("constants", ["schengenGroup"]),

    fields() {
      return [
        { key: "_selected", show: true },
        { label: this.$t("Name"), key: "name", sortable: true, show: true },
        { label: this.$t("Type"), key: "type", show: true },
        { label: this.$t("Availability"), key: "availability", show: true },
        { label: this.$t("Salary"), key: "salary", show: !this.isAfterSale },
        {
          label: this.$t("Documents"),
          key: "documents",
          tdclass: "documents-cell",
          sortable: false,
          show: true
        },
        {
          label: this.$t("Cost reversal"),
          key: "cost_reversal",
          tdclass: "documents-cell",
          sortable: false,
          show: this.showCostReversal
        },
        {
          label: this.$t("Schengen"),
          key: "schengen",
          tdclass: "documents-cell",
          sortable: false,
          show: this.schengenCountry
        },
        { label: "Actions", key: "actions", class: "align-end col-actions", show: true },
      ].filter(item => item.show)
    },
    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
        filter: this.searchFilter
      };
    },

    showCostReversal() {
      return this.costCompanyGroup.length && (
        (this.selectedServiceComp === SERVICE_TYPE.INTRAGROUP || this.costCompanyGroup.length && !this.readOnly) ||
        ((this.readOnly) && this.employees.filter(el => el.cost_reversal != null).length)
      )
    },

    clientOptions() {
      let result = [{ value: null, text: this.getCurrentManagedCompany.company_name }]
      this.costCompanyGroup.map(el => { result.push({ value: el.id, text: el.name }) })

      return result
    },
    formattedIntragroupCompanies() {
      const options = this.clientOptions;
      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("Type"),
      };
    },

    searchBoxStyle() {
      return this.showToggle ? {} : { maxWidth: "30em", margin: "1em auto" };
    },

    checkLicenseProps() {
      return {
        secondmentStart: this.secondmentStart,
        secondmentEnd: this.secondmentEnd,
      };
    },

    selectedEmployees: {
      get() {
        return this.value;
      },
      async set(values) {
        await this.$emit("input", values);
      },
    },

    gantModalId() {
      return `gantt-${this._uid}`;
    },

    schengenCountry() {
      return !!this.schengenGroup.find(nation => nation.country == this.selectedCountry)
    },
    getEmployeesId() {
      return this.secondmentEmployees.map(el => el.employee)
    },
    getSecondmentDate() {
      return {
        start: this.dateFrom,
        end: this.dateTo
      }
    }
  },

  watch: {
    dateFrom: {
      immediate: true,
      handler(value,oldValue) {
        this.secondmentStart = value;
        if(value != oldValue && oldValue != null){
          this.selectedOnly=false;
          this.selectedEmployees=[];
        }
        this.refresh();
      },
    },
    dateTo: {
      immediate: true,
      handler(value, oldValue) {
        this.secondmentEnd = value;
        if(value != oldValue && oldValue != null){
          this.selectedOnly=false;
          this.selectedEmployees=[];
        }
        this.refresh();
      },
    },
  },

  methods: {
    getEmployeeDates(employee) {
      const emp = this.secondmentEmployees.find(se => se.employee === employee?.id);
      if (emp) {
        employee.date_end = emp.date_end;
        employee.date_start = emp.date_start;
      }
      let msg = `${DateService.format(employee.date_start, this.dateFormat)} ⮕ ${DateService.format(
        employee.date_end,
        this.dateFormat,
      )}`;
      return msg;
    },

    getEmployeeAvailability(employee) {
      const emp = this.secondmentEmployees.find(se => se.employee === employee?.id);
      if (emp) {
        employee.date_start = emp.date_start
        employee.date_end = emp.date_end
      }
      return this.getAvailabilityEnum(employee)
    },

    itemProvider(ctx, callback) {
      try {
        if (!this.dateFrom || !this.dateTo) {
          callback([]);
        } else {
          let datas = {
            page: ctx.currentPage,
            perPage: ctx.perPage,
            search: this.searchFilter,
            sortBy: ctx.sortBy || "name",
            sortDesc: ctx.sortDesc,
            expand: 'persona,company,employeecontract_set,employeecontract_set.national_contract_level,agency,salary_adjustment,availability_data',
            dateStart: this.dateFrom,
            dateEnd: this.dateTo,
            country: this.selectedCountry
          }
          if (this.selectedOnly) {
            const selected = [];

            this.selectedEmployees.forEach((employee) => {
              selected.push(employee.employee || employee.id);
            });

            if (selected.length) {
              datas['ids'] = selected.filter(id => id !== undefined);
              datas['secondmentId'] = this.secondmentId
            } else {
              callback([]);
              return
            }
          }

          EmployeeService.getAll(datas).then(r => {
            this.total = r.count;
            let result = r.results.map(item => {
              let emp = this.secondmentEmployees.find(el => { return el.employee == item.id });
              item.cost_reversal = emp ? emp.cost_reversal : null;
              return item
            })

            // create object for availability
            this.employees = this.getAvailabilityEmployees(result);

            callback(this.employees);
          });
        }
      } catch (err) {
        console.log(err);
        callback([]);
      }
    },

    refresh() {
      const table = this.$refs?.datatableRef;
      if (table) table.refresh();
    },

    onCheckboxSelect(employee) {
      if (this.isSelected(employee.id)) {
        this.selectedEmployees = this.selectedEmployees.filter(el => el.id !== employee.id);
        employee.date_end = this.dateTo;
        employee.date_start = this.dateFrom;
      } else {
        this.selectedEmployees = [...this.selectedEmployees, employee];
      }
    },

    trClasses(employee) {
      if (!employee) return;
      if (this.isSelected(employee.id)) return "table-success";
    },

    onEditBtnClick(employee) {
      if (this.$route.name == "detail-secondment-employees") {
        this.$router.push({
          name: "detail-physical-person",
          params: { userID: employee.persona.id }
        });
      } else {
        this.$emit("edit-employee", { name: "detail-physical-person", params: { userID: employee.persona.id } });
      }
    },

    isSelected(employeeId) {
      return this.selectedEmployees.find(el => el.id == employeeId) ? true : false;
    },

    async onAvailabilityClick(employee) {
      if (!this.readOnly && !this.isSelected(employee.id)) {
        this.selectedEmployees = [...this.selectedEmployees, employee];
      }
      // Next tick to wait for props to update.
      await this.$nextTick();
      this.gantt.secondmentEmployee = this.secondmentEmployees.find(se => se.employee == employee.id);
      this.gantt.availability = this.getAvailabilityEnum(employee)
      this.gantt.overlappingSecondments = AvailabilityService.checkOverlappingSecondments(this.dateFrom, this.dateTo, employee.availability_data.busy_dates);
      await this.$nextTick();
      this.$bvModal.show(this.gantModalId);
    },

    onGanttModalHidden() {
      this.gantt.secondmentEmployee = null;
      this.gantt.availability = 0;
      this.gantt.overlappingSecondments = [];
    },

    onSecondmentEmployeeChange(secondmentEmployee) {
      let employee = this.employees.find(el => (el.id == secondmentEmployee.employee));
      employee.date_end = secondmentEmployee.date_end;
      employee.date_start = secondmentEmployee.date_start;
      this.gantt.secondmentEmployee = secondmentEmployee;
      this.gantt.availability = this.getAvailabilityEnum(employee);
      this.$emit("secondment-employee-change", secondmentEmployee);
    },

    costalReversalCompany(costal_reversal) {
      let group_name = this.getCurrentManagedCompany.company_name;
      this.getCompanyGroups.forEach(el => {
        if (el.id === costal_reversal) {
          group_name = el.name;
        }
      });
      return group_name;
    },
    editCostReversal(item) {
      this.employeeToEdit = item;
    },
    schengenInfo(employee) {
      switch (employee.availability_data.schengen_check?.status) {
        case 'exceeds-limit-country':
          return {
            status: 'btn-light-danger',
            message: employee.availability_data.schengen_check.message
          }
        case 'exceeds-limit':
          return {
            status: 'btn-light-warning',
            message: employee.availability_data.schengen_check.message
          }
        case 'within-limit':
          return {
            status: 'btn-light-success',
            message: employee.availability_data.schengen_check.message
          }
        default:
          return {
            status: 'btn-light-secondary',
            message: this.$t("Not in Schengen Area")
          }
      }
    },

    getAvailabilityEnum(employee) {
      return AvailabilityService.checkAvailability(employee.date_start, employee.date_end, employee.availability_data.busy_dates);
    },

    getAvailabilityEmployees(employees) {
      return employees.map(employee => {
        let secEmp = this.secondmentEmployees.find(el => (el.employee == employee.id));
        if (secEmp) {
          employee.date_end = secEmp.date_end;
          employee.date_start = secEmp.date_start;
        }
        else {
          employee.date_end = this.dateTo;
          employee.date_start = this.dateFrom;
        }
        return employee;
      });
    }
  }
};
</script>

<style scoped>
::v-deep .documents-cell div {
  padding-left: 1em;
}
</style>
