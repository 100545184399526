var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "plannerValidationObs"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5 pb-15 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(_vm._s(_vm.$t('Set travel planner')))]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t('Manage your employees daily journeys')) + " ")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('euro-alert', {
    attrs: {
      "show": _vm.showAlert,
      "variant": "danger"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('i', {
    class: _vm.icons.infoCircle,
    style: {
      color: 'white'
    }
  }), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('You have')) + " + " + _vm._s(_vm.unplanned.length) + " + " + _vm._s(_vm.$t('employees marked in red inside the option select menu), add them to a plan to continue')) + " ")])])])], 1), _c('div', {
    staticClass: "col-12"
  }, [_c('SecondmentTravelPlanner', {
    attrs: {
      "travel-plans": _vm.travelPlans,
      "secondment-employees": _vm.secondmentEmployees,
      "country": _vm.country,
      "client-address-id": _vm.clientAddressId,
      "client": _vm.client,
      "unplanned": _vm.unplanned
    },
    on: {
      "delete-travel-plan": function deleteTravelPlan($event) {
        return _vm.$emit('delete-travel-plan', $event);
      },
      "clone-travel-plan": function cloneTravelPlan($event) {
        return _vm.$emit('clone-travel-plan', $event);
      },
      "create-travel-plan": function createTravelPlan($event) {
        return _vm.$emit('create-travel-plan');
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }