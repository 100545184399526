var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "label label-lg font-weight-bold label-inline text-nowrap mt-2 text-uppercase cursor-pointer",
    class: {
      'label-light-success': _vm.availability == _vm.AvailabilityEnum.AVAILABLE,
      'label-light-danger': _vm.availability == _vm.AvailabilityEnum.NOT_AVAILABLE,
      'label-warning': _vm.availability == _vm.AvailabilityEnum.PARTIAL
    },
    attrs: {
      "id": "availability-chip-".concat(_vm._uid)
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('click', $event);
      }
    }
  }, [_c('i', {
    class: [_vm.icon, {
      'mr-2': !_vm.iconOnly
    }],
    style: _vm.iconStyle
  }), !_vm.iconOnly ? _c('span', [_vm._v(_vm._s(_vm.datesTooltip ? _vm.dates : _vm.computedTooltipMessage))]) : _vm._e(), _c('b-tooltip', {
    attrs: {
      "target": "availability-chip-".concat(_vm._uid),
      "noninteractive": "",
      "placement": _vm.tooltipPlacement
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.computedTooltipMessage))]), _c('br'), _vm.dates ? _c('div', [_vm._v(" " + _vm._s(_vm.dates) + " ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.$t("Click to view Gantt")))])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }