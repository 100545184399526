import DateService from "../services/date.service";
import { cloneDeep, isEqualWith } from "lodash";

function arePlansEqual(plan, otherPlan) {
  if (plan.method_of_arrival !== otherPlan.method_of_arrival) {
    return false;
  }

  if (plan.method_of_departure !== otherPlan.method_of_departure) {
    return false;
  }

  if (plan.travel_days.length !== otherPlan.travel_days.length) {
    return false;
  }

  const tpCompareProperties = [
    "travel_date",
    "client_order",
    "accomodation_place_order",
    "lunch_place_order",
    "dinner_place_order",
    "place_of_arrival",
    "place_of_departure",
    "lunch_place",
    "dinner_place",
    "accommodation_place",
  ];

  const placesCompareProperties = ["place_kind", "order", "description", "place"];

  for (let i = 0; i < plan.travel_days.length - 1; i++) {
    const day = plan.travel_days[i];
    const otherDay = otherPlan.travel_days[i];
    for (let j = 0; j < tpCompareProperties.length - 1; j++) {
      const property = tpCompareProperties[j];
      if (day[property] !== otherDay[property]) {
        return false;
      }
    }
    const sortedDayPlaces = day.places.toSorted(({ place: a }, { place: b }) => a - b);
    const sortedOtherdayPlaces = otherDay.places.toSorted(({ place: a }, { place: b }) => a - b);
    for (let k = 0; k < day.places.length - 1; k++) {
      const place = sortedDayPlaces[k];
      const otherPlace = sortedOtherdayPlaces[k];

      for (let l = 0; l < placesCompareProperties.length - 1; l++) {
        const placeProperty = placesCompareProperties[l];
        if (place[placeProperty] !== otherPlace[placeProperty]) {
          return false;
        }
      }
    }
  }

  return true;
}

function groupTravelPlans(travelPlans) {
  return cloneDeep(travelPlans).reduce((acc, plan) => {
    const planWithSameDays = acc.find(tp => isEqualWith(tp, plan, arePlansEqual));

    if (!planWithSameDays) {
      acc.push(plan);
    } else {
      planWithSameDays.employees = [...planWithSameDays.employees, ...plan.employees];
    }

    return acc;
  }, []);
}

function moveTravelPlansStartAndEndDates(travelPlans, fromDate, toDate) {
  return groupTravelPlans(
    cloneDeep(travelPlans).reduce((acc, plan) => {
      const firstPlanDate = plan.travel_days[0].travel_date;
      const planLastDate = plan.travel_days[plan.travel_days.length - 1].travel_date;

      if (DateService.isAfter(firstPlanDate, toDate) || DateService.isBefore(planLastDate, fromDate)) {
        return acc;
      }

      if (DateService.isAfter(fromDate, firstPlanDate)) {
        const newFirstDayIdx = plan.travel_days.findIndex(day => day.travel_date === fromDate);
        const planId = plan.travel_days[newFirstDayIdx].id;
        plan.travel_days[newFirstDayIdx] = plan.travel_days[0];
        plan.travel_days[newFirstDayIdx].travel_date = fromDate;
        plan.travel_days[newFirstDayIdx].id = planId;
        plan.travel_days = plan.travel_days.slice(newFirstDayIdx);
      }

      if (DateService.isBefore(toDate, planLastDate)) {
        const lastDayIdx = plan.travel_days.findIndex(day => day.travel_date === toDate);
        const planId = plan.travel_days[lastDayIdx].id;
        plan.travel_days[lastDayIdx] = plan.travel_days[plan.travel_days.length - 1];
        plan.travel_days[lastDayIdx].travel_date = toDate;
        plan.travel_days[lastDayIdx].id = planId;
        plan.travel_days = plan.travel_days.slice(0, lastDayIdx + 1);
      }

      acc.push(plan);
      return acc;
    }, []),
  );
}

/**
 * Appends to the start of the travel days all the days that are missing to the given date.
 * @returns A new copy of the travel days with the days appended.
 */
function appendTravelDaysToDate(travelDays, date) {
  let travelDaysCopy = cloneDeep(travelDays);
  const firstDay = travelDaysCopy[0];
  const firstDayDate = firstDay.travel_date;

  const newDates = DateService.enumerateDates(date, firstDayDate);
  newDates.pop();

  const arrivalPlaceIdx = firstDay.places.find(place => place.kind === "arrival");
  const [arrivalPlace] = firstDay.places.splice(arrivalPlaceIdx, 1);

  const newTravelDays = newDates.map(newDate => {
    // Clone deep so we avoid problems with nested code references.
    const clonedFirstDay = cloneDeep(firstDay);
    // Delete the IDs of the new travel days.
    delete clonedFirstDay.id;
    return { ...clonedFirstDay, travel_date: newDate };
  });
  newTravelDays[0].places.push(arrivalPlace);

  return [...newTravelDays, ...travelDaysCopy];
}

/**
 * Removes from the start of the travel days all the days up to the given date.
 * @returns A new copy of the travel days with days removed.
 */
function removeTravelDaysFromStartToDate(travelDays, date) {
  let travelDaysCopy = cloneDeep(travelDays);

  const newFirstDayIdx = travelDaysCopy.findIndex(day => day.travel_date === date);
  travelDaysCopy[newFirstDayIdx] = travelDaysCopy[0];
  travelDaysCopy[newFirstDayIdx].travel_date = date;
  return travelDaysCopy.slice(newFirstDayIdx);
}

/**
 * Adds to the end of the travel days all the missing days to the given date.
 * @returns a copy of the travelDays with the days added to the end.
 */
function addTravelDaysFromEndToDate(travelDays, date) {
  const travelDaysCopy = cloneDeep(travelDays);

  const lastDay = travelDaysCopy[travelDaysCopy.length - 1];
  const lastDayDate = lastDay.travel_date;
  const departurePlaceIdx = lastDay.places.findIndex(place => place.place_kind === "departure");
  const [departurePlace] = lastDay.places.splice(departurePlaceIdx, 1);

  const newDates = DateService.enumerateDates(lastDayDate, date);
  newDates.splice(0, 1);
  const newTravelDays = newDates.map(newDate => {
    // Clone deep so we avoid problems with nested code references.
    const clonedLastDay = cloneDeep(lastDay);
    // Delete the IDs of the new travel days.
    delete clonedLastDay.id;
    return { ...clonedLastDay, travel_date: newDate };
  });
  newTravelDays[newTravelDays.length - 1].places.push(departurePlace);

  return [...travelDaysCopy, ...newTravelDays];
}

/**
 * Removes from the back of travelDays all the days up to the given date.
 * @returns a copy of travelDays with the days removed.
 */
function removeTravelDaysFromEndToDate(travelDays, date) {
  const travelDaysCopy = cloneDeep(travelDays);

  const lastDayIdx = travelDaysCopy.findIndex(day => day.travel_date === date);
  travelDays[lastDayIdx] = travelDaysCopy[travelDaysCopy.length - 1];
  travelDays[lastDayIdx].travel_date = date;
  return travelDays.slice(0, lastDayIdx + 1);
}

/**
 * Creates a deep copy of the travel plan with all IDs deleted.
 * @returns a deep clone of the travelPlan with all the ids deleted.
 */
function cloneTravelPlan(travelPlan) {
  const travelPlanCopy = cloneDeep(travelPlan);

  delete travelPlanCopy.id;
  travelPlanCopy.travel_days.forEach(day => {
    delete day.id;

    day.places.forEach(place => {
      delete place.id;
    });
  });

  return travelPlanCopy;
}

function updateSecondmentEmployeeTravelPlanDates(travelPlans, secondmentEmployee) {
  const clonedTravelPlans = cloneDeep(travelPlans);
  // Find if the secondment employee already has a travel plan.
  const existingTravelPlan = clonedTravelPlans.find(travelPlan => travelPlan.employees.includes(secondmentEmployee.id));
  if (!existingTravelPlan) {
    return clonedTravelPlans;
  }

  existingTravelPlan.employees.splice(existingTravelPlan.employees.findIndex(emp => emp === secondmentEmployee.id));
  const newTravelPlan = cloneTravelPlan(existingTravelPlan);
  delete newTravelPlan.id;
  newTravelPlan.employees = [secondmentEmployee.id];

  const newDateFrom = secondmentEmployee.date_start;
  const firstPlan = newTravelPlan.travel_days[0];
  const firstPlanDate = firstPlan.travel_date;

  if (DateService.isBefore(firstPlanDate, newDateFrom)) {
    newTravelPlan.travel_days = removeTravelDaysFromStartToDate(newTravelPlan.travel_days, newDateFrom);
  } else if (DateService.isAfter(firstPlanDate, newDateFrom)) {
    newTravelPlan.travel_days = appendTravelDaysToDate(newTravelPlan.travel_days, newDateFrom);
  }

  const lastPlan = newTravelPlan.travel_days[newTravelPlan.travel_days.length - 1];
  const lastPlanDate = lastPlan.travel_date;
  const newDateTo = secondmentEmployee.date_end;

  if (DateService.isBefore(lastPlanDate, newDateTo)) {
    newTravelPlan.travel_days = addTravelDaysFromEndToDate(newTravelPlan.travel_days, newDateTo);
  } else if (DateService.isAfter(lastPlanDate, newDateTo)) {
    newTravelPlan.travel_days = removeTravelDaysFromEndToDate(newTravelPlan.travel_days, newDateTo);
  }

  clonedTravelPlans.push(newTravelPlan);

  return groupTravelPlans(clonedTravelPlans).filter(travelPlan => travelPlan.employees.length);
}

export { moveTravelPlansStartAndEndDates, arePlansEqual, groupTravelPlans, updateSecondmentEmployeeTravelPlanDates };
