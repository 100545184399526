<template>
  <wizard-left ref="wizard" :clickable-steps="false">
    <template #wizard-nav-left>
      <wizard-nav-step-left :title="$t('General Information')" :desc="$t('Name, Country, ELF')"
        icon="Home/Building.svg" />
      <wizard-nav-step-left :title="$t('Contacts')" :desc="$t('Phone, Email, Social, Website')"
        icon="Communication/Call.svg" />
      <wizard-nav-step-left :title="$t('Address')" :desc="$t('Kind, Street, Country, Region, City')"
        icon="Map/Position.svg" />
      <wizard-nav-step-left :title="$t('Fiscal Code')" :desc="$t('Enter fiscal code information')"
        icon="Communication/Clipboard-check.svg" />
      <wizard-nav-step-left :title="$t('Vat Information')" :desc="$t('Enter VAT identification number')"
        icon="Communication/Clipboard-check.svg" />
      <wizard-nav-step-left :title="$t('Contact Person')" :desc="$t('Select contact person and company role')"
        icon="General/User.svg" />
      <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
    </template>

    <template #default="{ goPrev, getStep }">
      <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
        <validation-observer ref="validator-1">
          <wizard-step-left class="custom-wizard-step">
            <div class="form row">
              <div class="col-12">
                <h4 class="mb-10 font-weight-bold text-dark">{{ $t('Legal person Details') }}</h4>
              </div>
              <div class="col-12 mb-3">
                <validation-provider v-slot="{ errors }" :name="$t('Logo')" vid="logo_tus">
                  <avatar-input is-legal :error-messages="errors" class="mt-5" @change="tusId => {
                    form.logo_tus = tusId;
                  }
                    "></avatar-input>
                </validation-provider>
              </div>
              <div class="col-12 mb-3">
                <div class="checkbox-inline">
                  <label class="checkbox checkbox-lg">
                    <input v-model="form.secondment_client" type="checkbox">
                    <span></span>
                    {{ $t("Add as client") }}
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input v-model="form.secondment_contractor" type="checkbox">
                    <span></span>
                    {{ $t("Add as contractor") }}
                  </label>
                  <label class="checkbox checkbox-lg">
                    <input v-model="form.secondment_partner" type="checkbox">
                    <span></span>
                    {{ $t("Add as supplier") }}
                  </label>
                </div>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Name')" vid="name" rules="required">
                  <euro-input v-model="form.name" :label="`${$t('Name')}*`" :error-messages="errors"></euro-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Country of establishment')"
                  vid="country_of_establishment" rules="required">
                  <euro-select v-model="form.country_of_establishment" :label="`${$t('Country of establishment')}*`"
                    :error-messages="errors" :options="countriesSelectable" searchable></euro-select>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Place of establishment')"
                  vid="place_of_establishment">
                  <div v-if="googleMapCall == true">
                    <euro-place-input v-model="form.place_of_establishment" :label="$t('Place of establishment')"
                      :restriction="{ country: form.country_of_establishment || '' }" :error-messages="errors"
                      :disabled="placeDisable"></euro-place-input>
                  </div>
                </validation-provider>
              </div>
              <div class="col-6">
                <validation-provider v-slot="{ errors }" :name="$t('Date of establishment')" vid="">
                  <date-picker v-model="form.date_of_establishment" :overrides="{ maxDate: moment() }"
                    :error-messages="errors" placeholder="YYYY-MM-DD" :label="$t('Date of establishment')">
                  </date-picker>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('method of establishment')"
                  vid="method_of_establishment">
                  <euro-select v-model="form.method_of_establishment" :label="$t('Entity legal form(ELF)')"
                    :error-messages="errors" searchable :options="methodsOfEstablishment"></euro-select>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Group')" vid="in_group">
                  <euro-radio v-model="form.add_to_group" :label="$t('Is this company part of your group?')" size="lg"
                    :error-messages="errors" :options="[
                      { text: $t('Yes'), value: true },
                      { text: $t('No'), value: false },
                    ]"></euro-radio>
                </validation-provider>
              </div>
            </div>
          </wizard-step-left>
        </validation-observer>
        <validation-observer ref="validator-2">
          <wizard-step-left class="custom-wizard-step">
            <div class="form row">
              <div class="col-12">
                <h4 class="mb-10 font-weight-bold text-dark">
                  {{ $t('Contact Information') }}
                </h4>
              </div>
              <div class="col-12 mb-5">
                <validation-provider v-slot="{ errors }" vid="telcontact_set">
                  <multi-telephones-input v-model="form.telcontact_set" :label="$t('Enter phone numbers')"
                    :error-messages="errors" :options="TELEPHONE_KINDS"
                    :default-country="form.country_of_establishment"></multi-telephones-input>
                </validation-provider>
              </div>
              <div class="col-12 mb-5">
                <validation-provider v-slot="{ errors }" vid="extraemail_set">
                  <multi-emails-input v-model="form.extraemail_set" :label="$t('Enter email address')"
                    :error-messages="errors" :options="EMAIL_KINDS"></multi-emails-input>
                </validation-provider>
              </div>
              <div class="col-12 mb-5">
                <validation-provider v-slot="{ errors }" vid="social_contacts">
                  <multi-social-input :key="multiSocialInputKey" v-model="socialContactsCpy"
                    :label="$t('Enter social media profiles')" :options="SOCIAL_KINDS"
                    :error-messages="errors"></multi-social-input>
                </validation-provider>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" name="Website" vid="Website" rules="url">
                  <euro-input v-model="website" :label="$t('Enter website')" :error-messages="errors"
                    :placeholder="$t('https://www.example.com  or  www.example.com')"></euro-input>
                </validation-provider>
              </div>
            </div>
          </wizard-step-left>
        </validation-observer>
        <validation-observer ref="validator-3">
          <wizard-step-left class="custom-wizard-step">
            <div class="row">
              <div class="col-12">
                <h3 class="mb-10 font-weight-bold text-dark">{{ $t("Address") }}</h3>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Description')" vid="address_description">
                  <euro-input v-model="form.companyAddress.description" :label="$t('Description')"
                    :errors="errors"></euro-input>
                </validation-provider>
              </div>
              <div class="col-8">
                <validation-provider v-slot="{ errors }" :name="$t('Kind')" vid="address_kind">
                  <euro-select v-model="form.companyAddress.kind" :label="$t('Kind')" :errors="errors"
                    :options="addressKindsSelectable"></euro-select>
                </validation-provider>
              </div>
              <div class="col-4">
                <div class="form-group mb-0 mt-12">
                  <div class="d-flex align-items-center justify-content-end">
                    <label class="col-form-label">{{ $t('Main address') }}</label>
                    <div class="ml-3">
                      <span class="switch switch-sm switch-icon">
                        <label>
                          <input v-model="form.companyAddress.main" type="checkbox" />
                          <span></span>
                        </label>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <validation-provider v-slot="{ errors }" :name="$t('Address')" vid="address_address" rules="required">
                  <div v-if="googleMapCall == true">
                    <AddressInput ref="addressInput" v-model="form.companyAddress.address" :label="$t('Address')"
                      :manual-address="form.companyAddress.manual_address" :active-step="getStep() === 3"
                      :restriction="{ country: form.country_of_establishment || '' }" :error-messages="errors"
                      :address-center="addressCenter" @change-manual-address="changeManualAddress"
                      @update-composed-address="updateComposedAddress">
                    </AddressInput>
                  </div>
                </validation-provider>
              </div>
            </div>
          </wizard-step-left>
        </validation-observer>
        <validation-observer ref="validator-4">
          <wizard-step-left class="custom-wizard-step">
            <h3 class="mb-10 font-weight-bold text-dark text-capitalize">
              {{ $t("Fiscal Code") }}
              <span class="text-muted text-lowercase small">
                ({{ $t("Not Required") }})
              </span>
            </h3>
            <legal-person-fiscal-form ref="fiscalForm" :countries="countries" optional is-not-required is-creating
              :administrative-structures="administrativeStructures" :default-country="form.country_of_establishment"
              @input="onLegalPersonFiscalFormInput"></legal-person-fiscal-form>
          </wizard-step-left>
        </validation-observer>
        <validation-observer ref="validator-5">
          <wizard-step-left class="custom-wizard-step">
            <h3 class="mb-10 font-weight-bold text-dark text-capitalize">
              {{ $t("Vat Information") }}
              <span class="text-muted text-lowercase small">
                ({{ $t("Not Required") }})
              </span>
            </h3>
            <legal-person-vat-form ref="vatForm" :countries="countries" optional is-not-required
              :administrative-structures="administrativeStructures" :default-country="form.country_of_establishment"
              @input="onLegalPersonVatFormInput"></legal-person-vat-form>
          </wizard-step-left>
        </validation-observer>
        <validation-observer ref="validator-6">
          <wizard-step-left class="custom-wizard-step">
            <legal-person-contact-person-form :roles="contactRoles"
              @input="onContactPersonFormInput"></legal-person-contact-person-form>
          </wizard-step-left>
        </validation-observer>

        <wizard-step-left class="custom-wizard-step">
          <div>
            <div>
              <h6 class="font-weight-bolder mb-3">{{ $t("General Information") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>{{ $t("Name") }}: {{ form.name }}</div>
                <div>
                  <span>{{ $t("Country of establishment") }}: </span>
                  <span>{{ form.country_of_establishment }}</span>
                </div>
                <div v-if="form.place_of_establishment?.formatted_address">
                  <span>{{ $t("Place of establishment") }}: </span>
                  <span>{{ form.place_of_establishment.formatted_address }}</span>
                </div>
                <div v-if="form.date_of_establishment">
                  <span>{{ $t("Date of establishment") }}: </span>
                  <span>{{ DateService.format(form.date_of_establishment, dateFormat) }}</span>
                </div>
                <div v-if="form.method_of_establishment">
                  <span>{{ $t("Entity legal form(ELF)") }}: </span>
                  <span>{{ selectedElf }}</span>
                </div>
                <div v-if="website">
                  <span>{{ $t("Enter website") }}: </span>
                  <span>{{ website }}</span>
                </div>
              </div>
            </div>
            <div v-if="contact.name">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Contact Person") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Name") }}: </span>
                  <span>{{ contact.name }}</span>
                </div>
                <div v-if="contact.surname">
                  <span>{{ $t("Surname") }}: </span>
                  <span>{{ contact.surname }}</span>
                </div>
                <div v-if="contact.gender">
                  <span> {{ $t("GENDER") }}: </span>
                  <span>{{ contact.gender }}</span>
                </div>
                <div v-for="(emailContact, idx) in contact?.extraemail_set?.filter(el => el.email != '')"
                  :key="`mail-${idx}`">
                  <span>{{ EMAIL_KINDS.find(el => el.value == emailContact.kind)?.text }}: </span>
                  <span>{{ emailContact.email }}</span>
                </div>
                <div v-for="(telContact, idx) in contact?.telcontact_set?.filter(el => el.number != '')"
                  :key="`phone-${idx}`">
                  <span>{{ TELEPHONE_KINDS.find(el => el.value == telContact.kind)?.text }}: </span>
                  <span>
                    {{ telContact.number }}
                    {{ telContact.main ? "- Main" : "" }}
                  </span>
                </div>
                <div v-if="contact.role">
                  <span> {{ $t("Role") }}: </span>
                  <span>{{ contact.roleName }}</span>
                </div>
              </div>
            </div>
            <div v-if="form.telcontact_set.filter(el => el.number != '').length">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Phone Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-for="(telContact, idx) in form.telcontact_set.filter(el => el.number != '')" :key="idx">
                  <span>{{ TELEPHONE_KINDS.find(el => el.value == telContact.kind)?.text }}: </span>
                  <span>
                    {{ telContact.number }}
                    {{ telContact.main ? `- ${$t("Main")}` : "" }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="form.extraemail_set.filter(el => el.email != '').length">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Email Contacts") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-for="(emailContact, idx) in form.extraemail_set.filter(el => el.email != '')" :key="idx">
                  <span>{{ EMAIL_KINDS.find(el => el.value == emailContact.kind)?.text }}: </span>
                  <span>
                    {{ emailContact.email }}
                    {{ emailContact.main ? `- ${$t("Main")}` : "" }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="form.companyAddress?.address?.formatted_address">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">Address</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-if="form.companyAddress.manual_address">
                  <span>{{ form.companyAddress.composed_address.formatted }}</span>
                  <span>{{ form.companyAddress.description }}</span>
                </div>
                <div v-else>
                  <span>{{ form.companyAddress?.address?.formatted_address ?? "No address found" }}</span>
                  <span>{{ form.companyAddress.description }}</span>
                </div>
              </div>
            </div>
            <div v-if="form.fiscalData?.fiscalid">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Fiscal Code") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Fiscal Code") }}:</span>
                  <span>{{ form.fiscalData.fiscalid }}</span>
                </div>
                <div>
                  <span>{{ $t("Valid From") }}: </span>
                  <span>{{
                    form.fiscalData?.from_date ? DateService.format(form.fiscalData?.from_date, dateFormat) : ""
                  }}</span>
                </div>
                <div>
                  <span>{{ $t("Valid To") }}:</span>
                  <span>{{
                    form.fiscalData?.to_date ? DateService.format(form.fiscalData?.to_date, dateFormat) : ""
                  }}</span>
                </div>
                <div class="mt-2">
                  <i v-if="form.fiscalData.attachment_tus" v-b-tooltip.hover title="File Attached"
                    class="fas fa-paperclip fa-2x mr-2"></i>
                </div>
              </div>
            </div>
            <div v-if="form.vatData?.vat">
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Vat Number") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Vat Number") }}:</span>
                  <span>{{ form.vatData.vat }}</span>
                </div>
                <div>
                  <span>{{ $t("Valid From") }}: </span>
                  <span>{{
                    form.vatData?.from_date ? DateService.format(form.vatData?.from_date, dateFormat) : ""
                  }}</span>
                </div>
                <div>
                  <span>{{ $t("Valid To") }}:</span>
                  <span>{{ form.vatData?.to_date ? DateService.format(form.vatData?.to_date, dateFormat) : "" }}</span>
                </div>
                <div class="mt-2">
                  <i v-if="form.vatData.attachment_tus" v-b-tooltip.hover title="File Attached"
                    class="fas fa-paperclip fa-2x mr-2"></i>
                </div>
              </div>
            </div>
          </div>
        </wizard-step-left>
        <wizard-actions-left>
          <div class="mr-2">
            <wizard-prev-btn-left @click="goPrev"></wizard-prev-btn-left>
          </div>
          <div>
            <wizard-submit-btn-left :disabled="loading" @click="handleSubmit(submit)">
              <i class="fab fa-telegram-plane"></i>
            </wizard-submit-btn-left>
            <wizard-next-btn-left @click="handleNextClick"></wizard-next-btn-left>
          </div>
        </wizard-actions-left>
      </validation-observer>
    </template>
  </wizard-left>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import axios from "axios";
import { mapState, mapGetters } from "vuex";
import CompanyContactRolesService from "@/core/services/company/company-contactroles.service";
import PhysicalPersonDetailService from "@/core/services/physical-person/physical-person-detail.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import CompanyEstablishmentMethodService from "@/core/services/company/company-establishmentmethod.service";
import CompanyTelephoneService from "@/core/services/company/company-telephone.service";
import CompanyEmailService from "@/core/services/company/company-email.service";
import CompanySocialService from "@/core/services/company/company-social.service";
import GroupService from "@/core/services/group/group.service";
import DateService from "@/core/services/date.service";
import LegalPersonContactPersonForm from "@/view/components/forms/legal-person/LegalPersonContactPersonForm.vue";
import LegalPersonFiscalForm from "@/view/components/forms/legal-person/LegalPersonFiscalForm.vue";
import LegalPersonVatForm from "@/view/components/forms/legal-person/LegalPersonVatForm.vue";
import datePicker from "@/view/components/datePicker/datePicker.vue";

export default {
  components: {
    LegalPersonFiscalForm,
    LegalPersonVatForm,
    datePicker,
    LegalPersonContactPersonForm,
  },

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      DateService,
      loading: false,
      methodsOfEstablishment: [],
      socialContactsCpy: [],
      multiSocialInputKey: 0,
      website: "",
      contactRoles: [],
      contact: {
        avatar_tus: null,
        name: null,
        surname: null,
        telcontact_set: [],
        extraemail_set: [],
        gender: "MA",
        groups: null,
      },
      form: {
        secondment_client: false,
        secondment_contractor: false,
        secondment_partner: false,
        add_to_group: false,
        logo_tus: null,
        name: null,
        country_of_establishment: null,
        place_of_establishment: null,
        date_of_establishment: null,
        method_of_establishment: null,
        telcontact_set: [],
        extraemail_set: [],
        companyAddress: {
          address: null,
          description: null,
          kind: "0",
          main: true,
          manual_address: false,
          composed_address: null
        },
        fiscalData: null,
        vatData: null,
      },
      addressKinds: [],
      TELEPHONE_KINDS: [],
      EMAIL_KINDS: [],
      SOCIAL_KINDS: [],
      GENDERS: [],
      GROUPS: [],
      groups: "",
      administrativeStructures: [],
      addressCenter: null,
      googleMapCall: false,
      partialAddress: false
    };
  },

  computed: {
    ...mapState("constants", ["countries"]),
    ...mapGetters("user", ["dateFormat"]),
    selectedElf() {
      return this.methodsOfEstablishment.find(el => el.value === this.form.method_of_establishment)?.text || "";
    },
    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    observerErrors() {
      return this.$refs.mainValidationObserver.errors;
    },

    vatValidity() {
      return !!(
        moment(this.form.vatData.to_date).isAfter(moment()) &&
        moment(this.form.vatData.to_date).isAfter(moment(this.form.vatData.from_date))
      );
    },

    fiscalValidity() {
      return !!(
        moment(this.form.fiscalData.to_date).isAfter(moment()) &&
        moment(this.form.fiscalData.to_date).isAfter(moment(this.form.fiscalData.from_date))
      );
    },

    groupsSelectProps() {
      return {
        options: this.GROUPS,
        "track-by": "id",
        label: "name",
        multiple: false,
        "allow-empty": true,
        searchable: true,
      };
    },

    requestBody() {
      let body = {
        name: this.form.name,
        logo_tus: this.form.logo_tus,
        country_of_establishment: this.form.country_of_establishment,
        place_of_establishment: this.form.place_of_establishment,
        date_of_establishment: this.form.date_of_establishment,
        method_of_establishment: this.form.method_of_establishment,
        telcontactscompany_set: this.form.telcontact_set.filter(el => el.number != ''),
        emailcompany_set: this.form.extraemail_set.filter(el => el.email != ''),
        companyaddress_set: [],
        extracompanyvat_set: [],
        companyfiscalid_set: [],
        websitecompany_set: [],
        kind: 0,
        verified: true,
        from_api: true,
        company_group: [],
        add_to_group: this.form.add_to_group,
        secondment_client: this.form.secondment_client,
        secondment_contractor: this.form.secondment_contractor,
        secondment_partner: this.form.secondment_partner,
      };

      if (this.website) {
        body.websitecompany_set = [
          {
            website: this.website,
          },
        ];
      }

      if (this.form.vatData && this.form.vatData.vat) {
        body.extracompanyvat_set.push(this.form.vatData);
      }

      if (this.form.fiscalData && this.form.fiscalData.fiscalid) {
        body.companyfiscalid_set.push(this.form.fiscalData);
      }

      if (this.form.companyAddress.address) {
        body.companyaddress_set.push(this.form.companyAddress);
      }

      if (this.socialContactsCpy.length) {
        body.socialcompany_set = this.socialContactsCpy;
      }

      return body;
    },

    addressKindsSelectable() {
      return this.addressKinds.map(el => ({ value: el.value, text: el.display_name }));
    },

    computedMsg() {
      let msg = this.$t("Something went wrong, please check your data");
      for (const el in this.observerErrors) {
        if (this.observerErrors[el].length) {
          msg = this.observerErrors[el][0];
          break;
        }
      }
      return msg;
    },
    placeDisable() {
      return !this.form.country_of_establishment;
    }
  },

  watch: {
    errorMessages: {
      deep: true,
      handler(val) {
        const formObs = this.$refs.mainValidationObserver;
        const newErrors = {
          logo_tus: [...(val?.logo_tus ?? [])],
          name: [...(val?.name ?? [])],
          country_of_establishment: [...(val?.country_of_establishment ?? [])],
          vat: [...(val?.vat ?? [])],
          date_of_establishment: [...(val?.date_of_establishment ?? [])],
          fiscal_id_country: [...(val?.fiscal_id?.country ?? [])],
          fiscal_id: [...(val?.fiscal_id?.fiscal_id ?? [])],
          fiscal_id_attachment: [...(val?.fiscal_id?.attachment ?? [])],
          address_address: [...(val?.companyaddress_set[0]?.address ?? [])],
          address_description: [...(val?.companyaddress_set[0]?.description ?? [])],
          address_kind: [...(val?.companyaddress_set[0]?.kind ?? [])],
        };

        if (newErrors?.emailcompany_set.length) {
          newErrors.emailcompany_set.forEach((el, idx) => {
            el.email ? (newErrors[`email[${idx}]`] = el.email[0]) : {};
          });
        }

        if (newErrors?.telcontactscompany_set.length) {
          newErrors.telcontactscompany_set.forEach((el, idx) => {
            el.number ? (newErrors[`phone[${idx}]`] = el.number[0]) : {};
          });
        }

        formObs.setErrors({ ...newErrors });
      },
    },

    "form.country_of_establishment"(val) {
      this.loadMethodsOfEstablishment(val);
      this.geolocate(val);
      this.form.place_of_establishment = null;
    },

    website(val) {
      if (val.trim().length > 8 && !val.trim().startsWith("http://") && !val.trim().startsWith("https://")) {
        this.website = this.formatWebsite();
      }
    },
  },

  async mounted() {
    try {
      this.googleMapCall = false;
      const [
        telephoneKinds,
        emailKinds,
        addressKinds,
        // companies,
        socialKinds,
        genders,
        groups,
        roles,
      ] = await Promise.all([
        CompanyTelephoneService.getKinds(),
        CompanyEmailService.getKinds(),
        CompanyAddressService.getKinds(),
        CompanySocialService.getKinds(),
        PhysicalPersonDetailService.getGenders(),
        GroupService.getAllNotPaginated(),
        CompanyContactRolesService.getAll({ perPage: 100, page: 1, fields: "id,name,description,public" }),
      ]);

      this.GENDERS = genders.map(el => ({ value: el.value, text: el.display_name }));
      this.TELEPHONE_KINDS = telephoneKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.EMAIL_KINDS = emailKinds.map(x => ({ value: x.value, text: x.display_name }));
      this.SOCIAL_KINDS = socialKinds.map(el => ({ value: el.value, text: el.display_name }));
      this.addressKinds = addressKinds;
      this.administrativeStructures = null; // to eventually add when counterparts will be used
      this.GROUPS = groups.results;
      this.contactRoles = roles.results;
      this.googleMapCall = true;
    } catch (err) {
      console.error(err);
    }
  },

  methods: {
    moment,
    onContactPersonFormInput(val) {
      this.contact = val?.id || !!val?.name ? { ...val } : null;
    },
    formatWebsite() {
      const https = "https://";
      const http = "http://";
      let site = this.website.trim();
      if (!site.startsWith(https) && !site.startsWith(http)) site = https + site;

      return site;
    },
    async geolocate(countryCode) {
      if (!countryCode) {
        this.addressCenter = null;
        return;
      }
      const country = await this.countriesSelectable.find(el => el.value === countryCode);
      if (!country) return;
      const countryName = country.text;
      const res = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${countryName}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`,
      );
      this.addressCenter = res.data.results[0].geometry.location;
    },

    async handleNextClick() {
      const validator = this.$refs[`validator-${this.$refs.wizard.getStep()}`];

      if (validator) {
        await validator.reset();
        const isValid = await validator.validate();
        if (!isValid) {
          if (this.$refs.wizard.getStep() === 3) {
            if (this.partialAddress) {
              Swal.fire({
                icon: 'info',
                title: this.$t('Enter the required fields'),
                text: this.$t('Please enter a valid address to continue'),
              })
              this.$nextTick();
              return;
            } else {
              Swal.fire({
                icon: 'info',
                title: this.$t('No address was added'),
                text: this.$t('Please enter a valid address to continue'),
              })
              this.$nextTick();
              return;
            }
          } else {
            this.$nextTick();
            return;
          }
        } else {
          if (this.$refs.wizard.getStep() === 3 && this.partialAddress) {
            await Swal.fire({
              title: this.$t("Caution"),
              text: this.$t("Check that the marker is in the correct place before confirming to continue"),
              icon: "warning",
              confirmButtonClass: "btn btn-primary",
              confirmButtonText: this.$t("Yes"),
              showCancelButton: true,
              reverseButtons: true,
              cancelButtonText: this.$t("No"),
              cancelButtonClass: "btn",
            }).then(res => {
              if (res.isConfirmed) {
                this.$refs.wizard.goTo(4);
              }
            });
            this.$nextTick();
            return;
          }
        }
      }
      this.$refs.mainValidationObserver.handleSubmit(this.$refs.wizard.goNext());
    },
    submit: function () {
      this.$refs.fiscalForm.updateForm()
      this.$refs.vatForm.updateForm()
      this.loading = true;
      if (this.contact?.id || this.contact?.name) {
        if (this.contact.hasOwnProperty('telcontact_set')) {
          this.contact.telcontact_set = this.contact.telcontact_set.filter(el => el.number != '');
        }
        if (this.contact.hasOwnProperty('extraemail_set')) {
          this.contact.extraemail_set = this.contact.extraemail_set.filter(el => el.email != '');
        }

      }
      this.$emit("submit", {
        ...this.requestBody,
        physical_person_contact_list: this.contact?.id || this.contact?.name ? { ...this.contact } : null,
      });
    },

    async loadMethodsOfEstablishment() {
      const r = await CompanyEstablishmentMethodService.getByCountry(this.form.country_of_establishment);

      if (!r.results.length) {
        this.form.method_of_establishment = "";
      }

      this.methodsOfEstablishment = r.results.map(el => ({
        text: el.abbreviations
          ? el.abbreviations.split(";")[0] + " - " + el.name_transliterated
          : el.name_transliterated,
        value: el.id,
      }));
    },

    onLegalPersonFiscalFormInput(form) {
      this.form.fiscalData = form;
    },

    onLegalPersonVatFormInput(form) {
      this.form.vatData = form;
    },

    changeManualAddress(val) {
      this.form.companyAddress.manual_address = val
    },
    updateComposedAddress(val) {
      this.form.companyAddress.composed_address = val;
    }
  },
};
</script>

<style scoped>
.custom-wizard-step {
  max-width: 600px;
  margin: 0 auto;
}

.striped-field {
  background-color: #f5f9ff;
}

.step-title {
  font-size: 1.8rem;
  position: absolute;
  top: 0;
  left: 0;
  translate: 1em -50%;
  background-color: white;
  display: inline-block;
  padding: 0 0.5em;
}

.review-field {
  font-size: 1.2rem;
  border-radius: 5px;
}

.review-field span {
  min-width: 25ch;
  text-align: left;
}

.outlined-box {
  border: 1px solid #414656;
  border-radius: 5px;
  padding: 1em;
  padding-top: 2em;
  position: relative;
}
</style>
