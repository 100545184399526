var render = function render(){
  var _vm$supplier;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('secondment-entity-card', {
    staticClass: "h-100",
    attrs: {
      "name": _vm.supplier.name,
      "notify": _vm.supplier.notify,
      "avatar-url": (_vm$supplier = _vm.supplier) === null || _vm$supplier === void 0 ? void 0 : _vm$supplier.logo,
      "avatar-backup": _vm.supplier.name[0],
      "disabled": _vm.disabled
    },
    on: {
      "update:notify": function updateNotify($event) {
        return _vm.$set(_vm.supplier, "notify", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "bottom",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 mb-3 d-flex justify-content-start"
        }, [_c('btn-icon', {
          staticClass: "ml-4",
          attrs: {
            "id": "show-action-".concat(_vm.supplierUniqueId),
            "tooltip": _vm.$t('Open Gantt Chart'),
            "icon": _vm.icons.chart,
            "disabled": !_vm.supplier.date_start || !_vm.supplier.date_end
          },
          on: {
            "click": function click($event) {
              return _vm.$bvModal.show(_vm.ganttModalId);
            }
          }
        })], 1), _c('validation-provider', {
          ref: "datesProvider-".concat(_vm.supplierUniqueId),
          staticClass: "w-100",
          attrs: {
            "vid": "datesProvider-".concat(_vm.supplierUniqueId),
            "name": _vm.$t('Dates'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref) {
              var errors = _ref.errors;
              return [_c('euro-double-date-picker', {
                staticClass: "w-100 mx-0 mb-4 font-weight-bold",
                attrs: {
                  "date-from": _vm.supplier.date_start,
                  "date-to": _vm.supplier.date_end,
                  "error-messages": errors,
                  "disabled": _vm.disabled,
                  "customized-dates": _vm.customizedDates
                },
                on: {
                  "date-from-to-selected": _vm.onDateFromToSelected
                }
              })];
            }
          }])
        }), _vm._t("bottom"), _c('secondment-supplier-gantt-modal', {
          attrs: {
            "id": _vm.ganttModalId,
            "secondment-supplier": _vm.supplier,
            "secondment-end-date": _vm.secondmentDateEnd,
            "secondment-start-date": _vm.secondmentDateStart,
            "disabled": _vm.disabled
          },
          on: {
            "close": function close($event) {
              return _vm.$bvModal.hide(_vm.ganttModalId);
            },
            "secondment-supplier-change": _vm.onSupplierGanttChange
          }
        })];
      },
      proxy: true
    }], null, true)
  });

}
var staticRenderFns = []

export { render, staticRenderFns }