import { render, staticRenderFns } from "./SecondmentSuppliersList.vue?vue&type=template&id=39ba33b0&scoped=true"
import script from "./SecondmentSuppliersList.vue?vue&type=script&lang=js"
export * from "./SecondmentSuppliersList.vue?vue&type=script&lang=js"
import style0 from "./SecondmentSuppliersList.vue?vue&type=style&index=0&id=39ba33b0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ba33b0",
  null
  
)

export default component.exports