var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-overlay', {
    attrs: {
      "show": !_vm.loaded,
      "opacity": "0.3"
    }
  }, [!_vm.loaded ? _c('div', {
    staticClass: "min-vh-60 d-flex flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "text-center mb-20"
  }, [_c('h4', [_vm._v(_vm._s(_vm.$t("Loading assignment wizard...")))])])]) : _vm._e()]), _vm.loaded ? _c('wizard', {
    ref: "secondmentCreateWizard",
    attrs: {
      "clickable-steps": false
    }
  }, [_c('wizard-nav', _vm._l(_vm.wizardSteps, function (wizardStep) {
    return _c('wizard-nav-step', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !wizardStep.skippable,
        expression: "!wizardStep.skippable"
      }],
      key: wizardStep.id,
      attrs: {
        "title": wizardStep.title,
        "number": "",
        "desc": wizardStep.desc
      }
    });
  }), 1), _c('wizard-body', [_c('validation-observer', [_vm._l(_vm.wizardSteps, function (wizardStep) {
    return _c('wizard-step', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !wizardStep.skippable,
        expression: "!wizardStep.skippable"
      }],
      key: wizardStep.id
    }, [_c(wizardStep.stepComponent, _vm._g(_vm._b({
      ref: "wizardStepRef",
      refInFor: true,
      tag: "component",
      class: {
        'opacity-50': _vm.isSaving
      }
    }, 'component', wizardStep.props, false), wizardStep.events))], 1);
  }), _c('wizard-actions', [_c('div', {
    staticClass: "mr-2"
  }, [_c('wizard-prev-btn', {
    attrs: {
      "show-spinner": _vm.isSaving,
      "disabled": _vm.isSaving
    },
    on: {
      "click": _vm.handlePrev
    }
  })], 1), _c('div', [_c('div', [_c('wizard-submit-btn', {
    staticClass: "mr-2",
    attrs: {
      "label": _vm.$t('Save as draft'),
      "disabled": _vm.isSaving
    },
    on: {
      "click": function click($event) {
        return _vm.submit('draft');
      }
    }
  }, [_c('i', {
    class: _vm.icons.saveDraft
  })]), _c('wizard-submit-btn', {
    attrs: {
      "label": _vm.past ? _vm.$t('Save as Confirmed (past)') : _vm.started ? _vm.$t('Save as Confirmed (started)') : _vm.$t('Save as Confirmed'),
      "disabled": _vm.isSaving
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('i', {
    class: _vm.icons.save
  })])], 1), _c('wizard-next-btn', {
    attrs: {
      "label": _vm.$t('Save and Continue'),
      "disabled": _vm.isSaving,
      "show-spinner": _vm.isSaving
    },
    on: {
      "click": function click($event) {
        return _vm.handleFormStep(_vm.handleNext);
      }
    }
  })], 1)])], 2)], 1)], 1) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }