<template>
  <div class="card mb-4">
    <div class="card-body p-2 d-flex align-items-center">
      <div>
        <avatar :avatar-image="avatarUrl" :avatar-text="avatarBackup" size="70px"></avatar>
      </div>
      <div class="ml-4">
        <div class="font-weight-bolder h6 text-center"> {{ name }}</div>
        <div v-if="telephone" class="text-muted">Phone: {{ telephone }}</div>
        <div v-if="email" class="text-muted">Email: {{ email }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    avatarUrl: {
      type: String,
      required: true,
    },
    avatarBackup: {
      type: String,
      required: true,
    },
    telephone: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.min-h {
  min-height: 3rem;
}
</style>