var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-4"
  }, [_c('country-flag', {
    staticClass: "mr-4",
    attrs: {
      "country-iso": _vm.selectedCountry
    }
  }), _c('h2', {
    staticClass: "font-weight-bolder text-dark mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignment in")) + " " + _vm._s(_vm.getCountryName) + " ")])], 1), _c('div', {
    staticClass: "text-dark-50 font-size-lg mb-1"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Creation Date")) + ":")]), _vm._v(" " + _vm._s(_vm.today) + " ")]), _c('div', {
    staticClass: "text-dark-50 font-size-lg"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(_vm._s(_vm.$t("Number")) + ":")]), _vm._v(" " + _vm._s(_vm.id) + " ")]), _c('div', {
    staticClass: "separator separator-dashed my-10"
  }), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Duration")))]), _c('div', [_c('b-btn', {
    attrs: {
      "variant": "light-primary btn-sm"
    },
    on: {
      "click": function click($event) {
        return _vm.jump(1);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Design/Edit.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Edit Duration")))])])], 1)]), _c('div', [_c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "font-size-lg"
  }, [_vm._v(_vm._s(_vm.$t("Start of assignment")) + " ")]), _c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-secondary mx-2"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Right-2.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder font-size-h5"
  }, [_vm._v(" " + _vm._s(_vm.dateStart ? _vm.DateService.format(_vm.dateStart, _vm.dateFormat) : _vm.dateStart) + " ")])]), _c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "font-size-lg"
  }, [_vm._v(_vm._s(_vm.$t("End of assignment")))]), _c('span', {
    staticClass: "svg-icon svg-icon-md svg-icon-secondary mx-2"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Navigation/Right-2.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder font-size-h5"
  }, [_vm._v(" " + _vm._s(_vm.dateEnd ? _vm.DateService.format(_vm.dateEnd, _vm.dateFormat) : _vm.dateEnd) + " ")])]), _c('div', {
    staticClass: "d-flex align-items-center mb-1"
  }, [_c('span', {
    staticClass: "font-size-lg mr-3"
  }, [_vm._v(_vm._s(_vm.$t("Assignment Duration ")) + " (" + _vm._s(_vm.$t("Days")) + "):")]), _c('span', {
    staticClass: "font-weight-bolder font-size-h5"
  }, [_vm._v(_vm._s(_vm.secondmentSumDays))])]), _vm.description != '' ? _c('div', [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Description")))]), _c('div', [_vm._v(_vm._s(_vm.description))])]) : _vm._e()]), _vm._l(_vm.clientsData, function (item, i) {
    return _c('div', {
      key: i
    }, [_c('div', {
      staticClass: "separator separator-dashed my-10"
    }), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mt-8 mb-1"
    }, [_c('h4', {
      staticClass: "font-weight-bolder mb-0"
    }, [_vm._v(_vm._s(_vm.$t("Activities")))]), _c('div', [_c('b-btn', {
      attrs: {
        "variant": "light-primary btn-sm"
      },
      on: {
        "click": function click($event) {
          return _vm.jump(1);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon menu-icon"
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/Design/Edit.svg"
      }
    })], 1), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(_vm.$t("Edit") + " " + _vm.$t("Activities")))])])], 1)]), _c('div', [_c('div', {
      staticClass: "font-size-lg mb-2"
    }, [_vm._v(" " + _vm._s(_vm.$t("Activities carried out at the customer's site")) + ": ")]), _c('ul', {
      staticClass: "ml-6"
    }, _vm._l(item.extendedSelectedNacecodes, function (activity) {
      return _c('li', {
        key: activity.id,
        staticClass: "font-weight-bolder font-size-lg mb-2"
      }, [_vm._v(" " + _vm._s(activity.name) + " ")]);
    }), 0), item.noService ? _c('div', [_vm._v(_vm._s(item.noService))]) : _vm._e()]), item.selectedContractor ? _c('div', [item.service == 'contract_work' && item.selectedContractor ? _c('div', [_c('div', {
      staticClass: "separator separator-dashed my-10"
    }), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mt-8 mb-1"
    }, [_c('h4', {
      staticClass: "font-weight-bolder mb-0"
    }, [_vm._v(_vm._s(_vm.$t("Contractor")) + ":")]), _c('div', [_c('b-btn', {
      attrs: {
        "variant": "light-primary btn-sm"
      },
      on: {
        "click": function click($event) {
          return _vm.jump(4);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon menu-icon"
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/Design/Edit.svg"
      }
    })], 1), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(_vm.$t("Edit") + " " + _vm.$t("Contractor")))])])], 1)]), _c('div', {
      staticClass: "text-dark-50 font-weight-bold line-height-lg"
    }, [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": "), item.selectedContractor ? _c('span', [_vm._v(" " + _vm._s(item.selectedContractor.name) + " ")]) : _vm._e()]), _c('div', {
      staticClass: "text-dark-50 font-weight-bold line-height-lg"
    }, [_vm._v(" " + _vm._s(_vm.$t("Registered office")) + ": "), _vm._l(item.selectedContractor.addresses, function (contractor_address) {
      return _c('span', {
        key: contractor_address.id
      }, [contractor_address.kind == 0 ? _c('span', [_vm._v(" " + _vm._s(contractor_address.address.formatted) + " ")]) : _vm._e()]);
    })], 2)]) : _vm._e()]) : _vm._e()]);
  })], 2), _c('div', {
    staticClass: "col-6"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-1"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Client")))]), _c('div', [_c('b-btn', {
    attrs: {
      "variant": "light-primary btn-sm"
    },
    on: {
      "click": function click($event) {
        return _vm.jump(1);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Design/Edit.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Edit Client")))])])], 1)]), _vm.showAccordion ? _c('div', _vm._l(_vm.clientsData, function (item, index) {
    return _c('div', {
      key: index
    }, [_c('div', {
      staticClass: "font-size-lg mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t("Name")) + ": "), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(item.client.name))])]), _vm.getClientFiscalId(item.client) ? _c('div', {
      staticClass: "font-size-lg mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t("Fiscal ID")) + ": "), _c('span', {
      staticClass: "font-size-sm text-uppercase"
    }, [_vm._v(" " + _vm._s(_vm.getClientFiscalId(item.client)) + " ")])]) : _vm._e(), _vm.getClientVat(item.client) ? _c('div', {
      staticClass: "font-size-lg mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t("VAT")) + ": "), _c('span', {
      staticClass: "font-size-sm text-uppercase"
    }, [_vm._v(" " + _vm._s(_vm.getClientVat(item.client)) + " ")])]) : _vm._e(), item.secondmentClient.registered_office ? _c('div', {
      staticClass: "font-size-lg mb-1"
    }, [_vm._v(" " + _vm._s(_vm.$t("Registered office")) + ": "), _c('ul', {
      staticClass: "ml-6 font-size-sm"
    }, _vm._l(item.secondmentClient.registered_office, function (office) {
      return _c('li', {
        key: office.id
      }, [_vm._v(" " + _vm._s(office.address.formatted) + " ")]);
    }), 0)]) : _vm._e(), _c('div', {
      staticClass: "font-size-lg"
    }, [_vm._v(_vm._s(_vm.$t("Address")) + ": "), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(_vm._s(_vm.getExtendedClientAddress(item)))])]), _c('div', {
      staticClass: "border mt-4"
    }, [_c('address-map', {
      attrs: {
        "lat": _vm.getClientAddressCenter(item).lat,
        "lng": _vm.getClientAddressCenter(item).lng,
        "height": "350"
      }
    })], 1)]);
  }), 0) : _c('b-card', {
    staticClass: "mt-2",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-header', {
    staticClass: "p-1"
  }, [_c('b-button', {
    attrs: {
      "block": "",
      "variant": "primary"
    }
  }, [_vm._v(_vm._s(_vm.$t("No client found")))])], 1)], 1)], 1), _vm._m(0), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-4"
  }, [_c('h4', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Employees")))]), _c('div', [_c('b-btn', {
    attrs: {
      "variant": "light-primary btn-sm"
    },
    on: {
      "click": function click($event) {
        return _vm.jump(1);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon menu-icon"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Design/Edit.svg"
    }
  })], 1), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Edit") + " " + _vm.$t("Employees")))])])], 1)])]), _c('div', {
    staticClass: "col-12 mb-4 font-size-lg"
  }, [_vm._v(" " + _vm._s(_vm.$t("Selected Manager")) + ": "), _c('span', {
    staticClass: "label label-light-info label-inline label-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.managerInfo))])]), _c('div', {
    staticClass: "col-12"
  }, [_vm.selectedEmployees ? _c('div', [_c('datatable', {
    attrs: {
      "items-provider": _vm.employees,
      "fields": _vm.tableEmployees.fields,
      "per-page": _vm.tableEmployees.perPage,
      "per-page-options": _vm.tableEmployees.perPageOptions,
      "current-page": _vm.tableEmployees.currentPage
    },
    on: {
      "update:perPage": function updatePerPage($event) {
        return _vm.$set(_vm.tableEmployees, "perPage", $event);
      },
      "update:per-page": function updatePerPage($event) {
        return _vm.$set(_vm.tableEmployees, "perPage", $event);
      },
      "update:currentPage": function updateCurrentPage($event) {
        return _vm.$set(_vm.tableEmployees, "currentPage", $event);
      },
      "update:current-page": function updateCurrentPage($event) {
        return _vm.$set(_vm.tableEmployees, "currentPage", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell.name",
      fn: function fn(_ref) {
        var _item$persona, _item$persona2, _item$persona2$name, _item$persona3, _item$persona3$surnam;
        var item = _ref.item;
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('Avatar', {
          attrs: {
            "avatar-image": item === null || item === void 0 ? void 0 : (_item$persona = item.persona) === null || _item$persona === void 0 ? void 0 : _item$persona.avatar_display,
            "avatar-text": "".concat(item === null || item === void 0 ? void 0 : (_item$persona2 = item.persona) === null || _item$persona2 === void 0 ? void 0 : (_item$persona2$name = _item$persona2.name) === null || _item$persona2$name === void 0 ? void 0 : _item$persona2$name[0]).concat(item === null || item === void 0 ? void 0 : (_item$persona3 = item.persona) === null || _item$persona3 === void 0 ? void 0 : (_item$persona3$surnam = _item$persona3.surname) === null || _item$persona3$surnam === void 0 ? void 0 : _item$persona3$surnam[0])
          }
        }), _c('div', {
          staticClass: "ml-3"
        }, [_c('div', {
          staticClass: "font-weight-bolder",
          style: {
            wordBreak: 'break-word'
          }
        }, [_vm._v(" " + _vm._s(item.persona.name + " " + item.persona.surname) + " ")]), item.employee ? _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.$t("Employee")) + " ")]) : _c('div', {
          staticClass: "text-muted"
        }, [_vm._v(" " + _vm._s(_vm.$t("Standard person")) + " ")])])], 1)];
      }
    }, {
      key: "cell.dates",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$t("From")) + ": " + _vm._s(_vm.getEmployeeDates(item).split(" / ")[0]) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.$t("To")) + ": " + _vm._s(_vm.getEmployeeDates(item).split(" / ")[1]) + " ")])];
      }
    }, {
      key: "cell.reversal",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(_vm.costalReversalCompany(item)) + " ")];
      }
    }, {
      key: "cell.info",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex"
        }, [!_vm.isAfterSale ? _c('EmployeeSalaryChip', {
          attrs: {
            "icon-only": "",
            "tooltip-placement": "left",
            "salary": item.salary_adjustment
          },
          on: {
            "add-contract": function addContract($event) {
              var _item$persona4;
              return _vm.$emit('add-contract', item === null || item === void 0 ? void 0 : (_item$persona4 = item.persona) === null || _item$persona4 === void 0 ? void 0 : _item$persona4.id);
            }
          }
        }) : _vm._e(), _c('EmployeeAvailabilityChip', {
          staticClass: "pointer ml-2",
          attrs: {
            "availability": _vm.getAvailabilityEnum(item),
            "hover-msg": _vm.$t('View Gantt'),
            "icon": _vm.icons.chart,
            "icon-only": "",
            "tooltip-placement": "right"
          },
          on: {
            "click": function click($event) {
              return _vm.onAvailabilityClick(item);
            }
          }
        })], 1)];
      }
    }, {
      key: "cell.documents",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('EmployeeDocumentsAvailabilityChip', {
          attrs: {
            "item": item,
            "get-secondment-date": _vm.getSecondmentDate,
            "get-employees-id": _vm.getEmployeesId,
            "check-license-props": _vm.checkLicenseProps
          }
        })];
      }
    }, {
      key: "cell.services",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm.moduleA1Enable ? _c('div', [!_vm.hasModuleA13(item.employee) ? _c('div', {
          staticClass: "form-group mb-3 mt-2"
        }, [_c('div', {
          staticClass: "radio-inline mt-2"
        }, [_c('label', {
          staticClass: "radio"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.requestA1,
            expression: "item.requestA1"
          }],
          attrs: {
            "type": "radio"
          },
          domProps: {
            "value": true,
            "checked": _vm._q(item.requestA1, true)
          },
          on: {
            "change": [function ($event) {
              return _vm.$set(item, "requestA1", true);
            }, _vm.productRequest]
          }
        }), _c('span'), _vm._v(" " + _vm._s(_vm.$t('Purchase the service')) + " ")])]), item.requestA1 ? _c('div', {
          staticClass: "ml-6"
        }, [_c('div', {
          staticClass: "radio-inline"
        }, [_c('label', {
          class: _vm.selectedCountry == 'IT' ? 'radio radio-danger' : 'radio'
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.artKind,
            expression: "item.artKind"
          }],
          attrs: {
            "type": "radio",
            "value": "ART12"
          },
          domProps: {
            "checked": _vm._q(item.artKind, "ART12")
          },
          on: {
            "change": [function ($event) {
              return _vm.$set(item, "artKind", "ART12");
            }, _vm.productRequest]
          }
        }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Article 12")) + " ")]), _c('label', {
          class: item.formA13IsEmpty ? 'radio radio-danger' : 'radio'
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.artKind,
            expression: "item.artKind"
          }],
          attrs: {
            "type": "radio",
            "value": "ART13"
          },
          domProps: {
            "checked": _vm._q(item.artKind, "ART13")
          },
          on: {
            "change": [function ($event) {
              return _vm.$set(item, "artKind", "ART13");
            }, function ($event) {
              return _vm.article13edit(item);
            }]
          }
        }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Article 13")) + " ")]), _c('b-btn', {
          attrs: {
            "variant": "light-primary btn-xs mb-2 ml-2",
            "disabled": item.artKind == 'ART12'
          },
          on: {
            "click": function click($event) {
              return _vm.article13edit(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.$t("Edit")))])])], 1)]) : _vm._e(), _c('div', {
          staticClass: "radio-inline mt-2"
        }, [_c('label', {
          staticClass: "radio"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: item.requestA1,
            expression: "item.requestA1"
          }],
          attrs: {
            "type": "radio"
          },
          domProps: {
            "value": false,
            "checked": _vm._q(item.requestA1, false)
          },
          on: {
            "change": [function ($event) {
              return _vm.$set(item, "requestA1", false);
            }, _vm.productRequest]
          }
        }), _c('span'), _vm._v(" " + _vm._s(_vm.$t('Manage the A1 model independently')) + " ")])])]) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('span', {
          staticClass: "font-weight-bold pr-3 form-label mt-2"
        }, [_vm._v(_vm._s(_vm.$t("Transparency decree letter")))]), _c('b-form-checkbox', {
          attrs: {
            "switch": "",
            "size": "lg"
          },
          on: {
            "change": _vm.productRequest
          },
          model: {
            value: item.TRASP,
            callback: function callback($$v) {
              _vm.$set(item, "TRASP", $$v);
            },
            expression: "item.TRASP"
          }
        })], 1)];
      }
    }], null, true)
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "col-12 mb-8"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  }), _c('h4', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Services")))]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select the services required for the assignment")) + " ")])]), _c('div', {
    staticClass: "col-12"
  }, [_c('h6', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.$t("ASSESSMENT OF COMMUNITY REQUIREMENTS FOR EU + CH + SEE COUNTRIES")) + " ")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("ASSESSMENT OF COMMUNITY REQUIREMENTS FOR EU + CH + SEE COUNTRIES")) + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("ASSESSMENT_DESCRIPTION")))]), _c('ul', {
    staticClass: "ml-10"
  }, [_c('li', [_vm._v(" " + _vm._s(_vm.$t("Declaration of Transnational Service Performance")) + "; ")]), _c('li', [_vm._v(_vm._s(_vm.$t("Appointment of a contact person")) + ";")]), _c('li', [_vm._v(" " + _vm._s(_vm.$t("Compliance with minimum working and wage conditions in the foreign country")) + "; ")]), _c('li', [_vm._v(_vm._s(_vm.$t("Request for Model A1")) + ".")])]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("In the event that one or more obligations do not emerge from a preliminary analysis, the consultants will report it to the company's contact persons, along with the obligations that must nevertheless be complied with")) + ". ")])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.declarationOfDetachment,
      callback: function callback($$v) {
        _vm.declarationOfDetachment = $$v;
      },
      expression: "declarationOfDetachment"
    }
  })], 1)])])]), _vm._m(1), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("VISA")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("VISA_SERVICE_DESCRIPTION")))])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.visaService,
      callback: function callback($$v) {
        _vm.visaService = $$v;
      },
      expression: "visaService"
    }
  })], 1)])])]), _vm._m(2), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("ASSESSMENT IMMIGRATION")))]), _c('p', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.$t("ASSESSMENT_IMMIGRATION_DESCRIPTION")))]), _vm.immAsseMandatory ? _c('p', {
    staticClass: "mt-3"
  }, [_vm._v(" " + _vm._s(_vm.immAsseMandatoryText) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg",
      "disabled": _vm.immAsseMandatory
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.immigrationAssessment,
      callback: function callback($$v) {
        _vm.immigrationAssessment = $$v;
      },
      expression: "immigrationAssessment"
    }
  })], 1)])]), _vm.moduleA1Enable ? _c('div', {
    staticClass: "row"
  }, [_vm._m(3), _c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Schengen limits alert")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified when the Schengen limits are reached for the whole Schengen Area")) + " ")])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.schengenLimitAlert))
  }, [_vm.schengenLimitAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(4)]) : _vm._e(), _vm.moduleA1Enable ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Schengen country limits alert")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified when the Schengen limits are reached for the assignment country")))])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.schengenCountryLimitAlert))
  }, [_vm.schengenCountryLimitAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(5)]) : _vm._e()]), _vm._m(6), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("RISK ASSESSMENT")))]), _c('p', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.$t("RISK_ASSESSMENT_SERVICE_DESCRIPTION")))])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.riskAssessment,
      callback: function callback($$v) {
        _vm.riskAssessment = $$v;
      },
      expression: "riskAssessment"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_vm.isMaxRiskLevel ? _c('p', [_c('span', {
    staticClass: "label label-light-danger label-inline label-lg font-weight-bold"
  }, [_vm._v(_vm._s(_vm.maxRiskLevelText))])]) : _vm._e(), _vm.exceedPersonalFreedom ? _c('p', [_c('span', {
    staticClass: "label label-light-danger label-inline label-lg font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.exceededPersonalFreedomText) + " ")])]) : _vm._e()])]), _c('div', {
    staticClass: "row"
  }, [_vm._m(7), _c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Risk Alert")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified if the risk level of the assigment change over time")))])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This service is included with RiskMap subscription.'),
      expression: "$t('This service is included with RiskMap subscription.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(true))
  }, [_c('span', [_vm._v(_vm._s(_vm.$t("Active")))])])])]), _vm._m(8)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Natural disasters notification service")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified in case a natural disaster occurs during the assignment period")))])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.naturalDisastersAlert))
  }, [_vm.naturalDisastersAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(9)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Freedom Index monitoring service")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("FREEDOM_INDEX_DESCRIPTION")) + " (" + _vm._s(_vm.freedomIndexAlertLevel) + ")")])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.freedomIndexAlert))
  }, [_vm.freedomIndexAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(10)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Air Quality Index notification service")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified if the Air Quality Index report a level of pollution higher than notification threshold")) + " (" + _vm._s(_vm.pollutionLevelSetting) + ")")])]), _c('div', {
    staticClass: "col-1 d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.pollutionLevelAlert))
  }, [_vm.pollutionLevelAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(11)])]), _vm._m(12), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("HEALTCARE REPORT")))]), _c('p', {
    staticClass: "mb-5"
  }, [_vm._v(_vm._s(_vm.$t("HEALTCARE_REPORT_SERVICE_DESCRIPTION")))])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.healthcareReport,
      callback: function callback($$v) {
        _vm.healthcareReport = $$v;
      },
      expression: "healthcareReport"
    }
  })], 1)])]), _c('div', {
    staticClass: "row"
  }, [_vm._m(13), _c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Mandatory vaccinations alert")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified if any mandatory vaccination is required to enter the country")))])]), _c('div', {
    staticClass: "col-1 col-offset-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.mandatoryVaccinationsAlert))
  }, [_vm.mandatoryVaccinationsAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(14)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11 pl-10"
  }, [_c('p', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Recommended vaccinations alert")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Get notified if the number of recommended vaccinations is higher than the threshold in company settings")) + " (" + _vm._s(_vm.complementaryVaccinationsAlertLevel) + ")")])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end"
  }, [_c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.$t('This setting is managed by the company, administrators can manage it in company settings.'),
      expression: "$t('This setting is managed by the company, administrators can manage it in company settings.')",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "label label-lg label-inline font-weight-bold py-4",
    class: "label-light-".concat(_vm.getCheckboxClass(_vm.complementaryVaccinationsAlert))
  }, [_vm.complementaryVaccinationsAlert ? _c('span', [_vm._v(_vm._s(_vm.$t("Active")))]) : _c('span', [_vm._v(_vm._s(_vm.$t("Not Active")))])])])]), _vm._m(15)])]), _vm._m(16), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-11"
  }, [_c('h6', {
    staticClass: "font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("GOVERNMENT TRAVEL COMMUNICATION SERVICE")))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("SERVICE_COMMUNICATION_DESCRIPTION")))])]), _c('div', {
    staticClass: "col-1"
  }, [_c('div', {
    staticClass: "font-weight-bold d-flex align-items-center justify-content-end mb-3"
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg"
    },
    on: {
      "change": _vm.productRequest
    },
    model: {
      value: _vm.governmentTravelCommunicationService,
      callback: function callback($$v) {
        _vm.governmentTravelCommunicationService = $$v;
      },
      expression: "governmentTravelCommunicationService"
    }
  })], 1)]), _c('div', {
    staticClass: "col-12"
  })])]), _vm._l(_vm.clientsData, function (item, i) {
    return _c('div', {
      key: i,
      staticClass: "col-12"
    }, [item.secondmentSuppliers.length > 0 ? _c('div', [_c('div', {
      staticClass: "separator separator-dashed my-10"
    }), _c('div', {
      staticClass: "d-flex justify-content-between align-items-center mt-8 mb-1"
    }, [_c('h4', {
      staticClass: "font-weight-bolder mb-0"
    }, [_vm._v(_vm._s(_vm.$t("Suppliers")) + ":")]), _c('div', [_c('b-btn', {
      attrs: {
        "variant": "light-primary btn-sm"
      },
      on: {
        "click": function click($event) {
          return _vm.jump(5);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon menu-icon"
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/Design/Edit.svg"
      }
    })], 1), _c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(_vm.$t("Edit") + " " + _vm.$t("Suppliers")) + " ")])])], 1)]), _c('secondment-suppliers-list', {
      attrs: {
        "secondment-suppliers": item.secondmentSuppliers
      },
      scopedSlots: _vm._u([{
        key: "secondmentSupplier",
        fn: function fn(_ref7) {
          var secondmentSupplier = _ref7.secondmentSupplier;
          return [_c('secondment-supplier-card', {
            attrs: {
              "disabled": "",
              "value": secondmentSupplier,
              "secondment-dates-all": _vm.secondmentDatesAll
            }
          })];
        }
      }], null, true)
    })], 1) : _vm._e()]);
  }), _vm._m(17), _c('validation-observer', {
    ref: "validationObserverArt",
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var handleSubmit = _ref8.handleSubmit;
        return [_c('b-modal', {
          attrs: {
            "id": "article-13",
            "size": "lg",
            "hide-footer": ""
          },
          on: {
            "hidden": _vm.onA13ModalHidden
          },
          scopedSlots: _vm._u([{
            key: "modal-header",
            fn: function fn() {
              return [_c('div', {
                staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
              }, [_c('h4', {
                staticClass: "mb-0"
              }, [_vm._v(_vm._s(_vm.$t("Request module A1 article 13")))]), _c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-button', {
                staticClass: "mr-2",
                attrs: {
                  "variant": "success"
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.onA13Submit);
                  }
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")]), _c('b-button', {
                on: {
                  "click": _vm.closeArticle13Modal
                }
              }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
            },
            proxy: true
          }], null, true)
        }, [_c('div', {
          staticClass: "form row"
        }, [_c('div', {
          staticClass: "col-12 mb-3"
        }, [_c('div', {
          staticClass: "alert alert-info",
          attrs: {
            "role": "alert"
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Request module a13 for the state of ")) + " "), _c('strong', [_vm._v(_vm._s(_vm.getCountryName))]), _vm._v(", " + _vm._s(_vm.$t('select additional countries if necessary')) + " ")])]), _c('div', {
          staticClass: "col-12"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('Posting country'),
            "vid": "countries",
            "rules": {
              a1_art13_secondment: _vm.selectedCountry
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref9) {
              var errors = _ref9.errors;
              return [_c('euro-select', {
                attrs: {
                  "label": "".concat(_vm.$t('Posting country'), "*"),
                  "error-messages": errors,
                  "multiselect-props": {
                    options: _vm.a1ModuleCountries,
                    'track-by': 'iso',
                    label: 'name',
                    searchable: true,
                    multiple: true
                  }
                },
                model: {
                  value: _vm.form.posting_country,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "posting_country", $$v);
                  },
                  expression: "form.posting_country"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('validation-provider', {
          attrs: {
            "vid": "from_date",
            "name": _vm.$t('From date'),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('date-picker', {
                attrs: {
                  "overrides": _vm.fromDateOverrides,
                  "error-messages": errors,
                  "label": _vm.$t('From date') + '*'
                },
                model: {
                  value: _vm.form.from_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "from_date", $$v);
                  },
                  expression: "form.from_date"
                }
              })];
            }
          }], null, true)
        })], 1), _c('div', {
          staticClass: "col-6"
        }, [_c('validation-provider', {
          attrs: {
            "name": _vm.$t('To date'),
            "vid": "to_date",
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref11) {
              var errors = _ref11.errors;
              return [_c('date-picker', {
                attrs: {
                  "overrides": _vm.toDateOverrides,
                  "error-messages": errors,
                  "label": _vm.$t('To date') + '*'
                },
                model: {
                  value: _vm.form.to_date,
                  callback: function callback($$v) {
                    _vm.$set(_vm.form, "to_date", $$v);
                  },
                  expression: "form.to_date"
                }
              })];
            }
          }], null, true)
        })], 1)])])];
      }
    }])
  })], 2), _vm.gantt.secondmentEmployee ? _c('secondment-participation-modal', {
    attrs: {
      "id": _vm.gantModalId,
      "availability": _vm.gantt.availability,
      "secondment-employee": _vm.gantt.secondmentEmployee,
      "overlapping-secondments": _vm.gantt.overlappingSecondments,
      "secondment-end-date": _vm.dateEnd,
      "secondment-start-date": _vm.dateStart,
      "disable-edits": ""
    },
    on: {
      "close": function close($event) {
        return _vm.$bvModal.hide(_vm.gantModalId);
      },
      "hidden": _vm.onGanttModalHidden
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mb-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid my-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mt-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mb-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid my-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid my-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid my-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mt-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mb-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid my-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-solid mt-3"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "separator separator-dashed my-10"
  })]);

}]

export { render, staticRenderFns }