<template>
  <div v-if="secondmentStart && secondmentEnd" :id="`license-${uid}`" style="min-width: 39px"
    class="font-weight-bold label label-inline m-1 py-4" :class="backgroundColor">
    <i v-b-tooltip.hover :class="icons.car"
      :style="{ cursor: 'pointer', color: badgeColor, display: 'inline-block' }"></i>
    <b-tooltip :target="`license-${uid}`" triggers="hover">
      <ul v-if="licenses?.length">
        <li v-for="(license, idx) in licenses" :key="idx" class="d-flex flex-column">
          <strong>Category: {{ license.category }}</strong>
          <span><strong>From:</strong> {{ DateService.format(license.date_from, dateFormat) }}</span>
          <span><strong>To:</strong> {{ DateService.format(license.date_to, dateFormat) }}</span>
          <b-badge v-if="license.status" class="mt-2" :variant="license.status === 'valid' ? 'success' : 'danger'">{{
            license.status
          }}</b-badge>
        </li>
      </ul>
      <span v-else>No License found</span>
    </b-tooltip>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import DateService from "@/core/services/date.service";
import { mapGetters } from 'vuex'

export default {
  //   components: { Datatable },
  props: {
    entity: {
      type: Object,
      required: true,
    },
    secondmentStart: {
      type: [String, null],
      default: null,
    },
    secondmentEnd: {
      type: [String, null],
      default: null,
    },
  },

  data() {
    return {
      DateService,
      icons,
      fields: [
        { key: "category", sortable: false },
        { key: "date_from", sortable: false },
        { key: "date_to", sortable: false },
        { key: "status", sortable: false },
      ],
    };
  },

  computed: {
    ...mapGetters('user', ['dateFormat']),

    licenses() {
      return this?.entity?.persona?.europeandrivinglicense_set[0]?.category_set || [];
    },

    backgroundColor() {
      if (!this.licenses?.length) return "label-light";
      if (this.licenses.every((el) => el.status === "valid")) return "label-light-success";
      if (this.licenses.every((el) => el.status === "expired")) return "label-light-danger";
      return "label-light-warning";
    },

    badgeColor() {
      if (!this.licenses?.length) return "grey";
      if (this.licenses.every((el) => el.status === "valid")) return "green";
      if (this.licenses.every((el) => el.status === "expired")) return "red";
      return "orange";
    },

    uid() {
      return this._uid;
    },
  },
};
</script>

<style scoped>
.tableTooltip {
  min-width: 300px;
}
</style>
