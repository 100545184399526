<template>
  <secondment-entity-card :name="supplier.name" :notify.sync="supplier.notify" :avatar-url="supplier?.logo"
    :avatar-backup="supplier.name[0]" class="h-100" :disabled="disabled">
    <template #bottom>
      <div class="w-100 mb-3 d-flex justify-content-start">
        <btn-icon :id="`show-action-${supplierUniqueId}`" :tooltip="$t('Open Gantt Chart')" class="ml-4"
          :icon="icons.chart" :disabled="!supplier.date_start || !supplier.date_end"
          @click="$bvModal.show(ganttModalId)"></btn-icon>
      </div>
      <validation-provider :ref="`datesProvider-${supplierUniqueId}`" v-slot="{ errors }"
        :vid="`datesProvider-${supplierUniqueId}`" :name="$t('Dates')" rules="required" class="w-100">
        <euro-double-date-picker :date-from="supplier.date_start" :date-to="supplier.date_end"
          class="w-100 mx-0 mb-4 font-weight-bold" :error-messages="errors" :disabled="disabled"
          :customized-dates="customizedDates" @date-from-to-selected="onDateFromToSelected"></euro-double-date-picker>
      </validation-provider>
      <slot name="bottom"></slot>
      <secondment-supplier-gantt-modal :id="ganttModalId" :secondment-supplier="supplier"
        :secondment-end-date="secondmentDateEnd" :secondment-start-date="secondmentDateStart" :disabled="disabled"
        @close="$bvModal.hide(ganttModalId)"
        @secondment-supplier-change="onSupplierGanttChange"></secondment-supplier-gantt-modal>
    </template>
  </secondment-entity-card>
</template>

<script>
import SecondmentEntityCard from "@/view/components/cards/SecondmentEntityCard.vue";
import icons from "@/core/config/icons";
import SecondmentSupplierGanttModal from "@/view/components/modals/SecondmentSupplierGanttModal.vue";
export default {
  components: {
    SecondmentEntityCard,
    SecondmentSupplierGanttModal,
  },

  props: {
    value: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      icons,
    };
  },

  computed: {
    supplier: {
      get() {
        return this.value;
      },
      set(supplier) {
        this.$emit("input", supplier);
      },
    },

    customizedDates() {
      return [{ cssClass: "secondment", dates: [...this.secondmentDatesAll] }];
    },

    supplierUniqueId() {
      return `${this.supplier.partner_object_id}-${this.supplier.partner_content_type}`;
    },

    ganttModalId() {
      return `gantt-${this._uid}`;
    },

    secondmentDateEnd() {
      return this.secondmentDatesAll[this.secondmentDatesAll.length - 1];
    },

    secondmentDateStart() {
      return this.secondmentDatesAll[0];
    },
  },
  mounted() {
    this.initialValidation()
  },
  methods: {
    async initialValidation() {
      const date = { from: this.supplier.date_start, to: this.supplier.date_end }
      await this.$refs[`datesProvider-${this.supplierUniqueId}`].validate(date.from);
      await this.$refs[`datesProvider-${this.supplierUniqueId}`].validate(date.to);
    },
    async onDateFromToSelected(date) {
      this.supplier = { ...this.supplier, date_start: date.from, date_end: date.to };
    },
    onSupplierGanttChange(ganttSupplier) {
      const { date_end, date_start } = ganttSupplier;
      this.supplier = {
        ...this.supplier,
        date_start,
        date_end,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .asd__month .asd__week .asd__day--secondment {
  background-color: #ffd27d;
}
</style>
