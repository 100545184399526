var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-modal', _vm._g(_vm._b({
    attrs: {
      "hide-footer": "",
      "hide-header": ""
    }
  }, 'b-modal', Object.assign({}, _vm.$attrs, _vm.commonAttrs), false), _vm.$listeners), [_c('div', {
    staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
  }, [_c('h3', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', [_c('b-button', {
    on: {
      "click": function click($event) {
        return _vm.$emit('close');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('Close')) + " ")])], 1)]), _vm.organizationType == 'company' ? _c('LegalPersonCreateWizard', {
    attrs: {
      "error-messages": _vm.legalPersonErrorMessages
    },
    on: {
      "submit": _vm.onLegalPersonSubmit
    }
  }) : _vm._e(), _vm.organizationType == 'physical_person' ? _c('PhysicalPersonCreateWizard', {
    attrs: {
      "nations": _vm.countries,
      "error-messages": _vm.physicalPersonErrorMessages
    },
    on: {
      "submit": _vm.onPhysicalPersonSubmit
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }