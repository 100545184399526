<template>
  <div>
    <span v-if="plan.employees.length" v-b-tooltip.hover :title="makeTooltip"><slot></slot></span>
    <span v-else v-b-tooltip.hover :title="$t('Travel plans with no employees will be deleted.')" class="text-danger">
      <slot></slot>
    </span>
    <b-dropdown :disabled="readonly" size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
      <template #button-content>
        <i :class="[icons.ellipsisV, !readonly ? 'text-dark' : '']"></i>
      </template>
      <b-dropdown-item :disabled="readonly" @click.stop="onCloneBtnClick(plan)">{{
        $t("Clone")
      }}</b-dropdown-item>
      <!-- <b-dropdown-item :disabled="readonly" @click.stop="onNewBtnClick()"> New From Employee</b-dropdown-item> -->
      <b-dropdown-item :disabled="readonly || plan.employees.length > 0" @click.stop="onDeleteBtnClick(plan)">
        {{ $t("Delete") }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      icons,
    };
  },

  computed: {
    makeTooltip() {
      return this.plan.employees.map((emp) => emp?.name || emp).join(", ");
    },
  },

  methods: {
    onNewBtnClick() {
      this.$emit("create-travel-plan");
    },

    onCloneBtnClick(plan) {
      this.$emit("clone-travel-plan", plan);
    },

    onDeleteBtnClick(plan) {
      if (plan.employees.length) return;
      this.$emit("delete-travel-plan", plan);
    },
  },
};
</script>
