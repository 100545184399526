/**
 *
 * @param {Object} day a travel plan day
 * @param {Object} client Google place of client
 * @returns {String[]} lat,lng strings ordered by day order
 */
function getDayPath(day /*client = null*/) {
  if (!day) return [];

  const { places } = day;
  if (!places) return [];

  const directions = places
    .map((el) => ({ val: el.place, order: el.order }))
    .filter((el) => el.val)
    .sort((a, b) => a.order - b.order);

  // const r = [
  //   {
  //     val: placeOfArrival,
  //     order: placeOfArrivalOrder,
  //   },
  //   {
  //     val: housing,
  //     order: housingOrder,
  //   },
  //   {
  //     val: client,
  //     order: clientOrder,
  //   },
  //   {
  //     val: lunchPlace,
  //     order: lunchPlaceOrder,
  //   },
  //   {
  //     val: dinnerPlace,
  //     order: dinnerPlaceOrder,
  //   },
  //   {
  //     val: placeOfDeparture,
  //     order: placeOfDepartureOrder,
  //   },
  // ]
  // .filter((el) => el.val)
  // .sort((a, b) => a.order - b.order);

  // List of directions lat,lng strings which will be used to draw the directions of the map.
  const directionsLatLng = [];
  // Populate the directionsLatLng list with all the directions that exist.
  for (let i = 0; i <= directions.length - 1; i++) {
    const direction = directions[i].val;
    if (direction) {
      let latLng = "";
      // Special case for the clientCoordinates object.

      let lat = direction.latitude;
      let lng = direction.longitude;

      if (!lat)
        lat =
          typeof direction.geometry.location?.lat == "function"
            ? direction.geometry.location?.lat()
            : direction.geometry.location?.lat;

      if (!lng)
        lng =
          typeof direction.geometry.location?.lng == "function"
            ? direction.geometry.location?.lng()
            : direction.geometry.location?.lng;

      latLng = `${lat},${lng}`;
      directionsLatLng.push(latLng);
    }
  }

  return directionsLatLng;
}

export { getDayPath };
