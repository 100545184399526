var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "nationValidationObs"
  }, [_c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5 pb-4 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select Nation")) + " ")]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Where does the secondments site take place")) + "? ")]), _c('div', {
    staticClass: "mb-0 mt-2"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t("Creating a new assignment requires a subscription to the risk map for the associated country.")) + " ")]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.$t("If you have not yet purchased this subscription, please click on the button")) + " "), _c('strong', [_vm._v(_vm._s(_vm.$t("Subscribe Risk Map")))]), _vm._v(". ")])])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "d-flex items-align-center py-3"
  }, [_c('validation-provider', {
    attrs: {
      "name": "country",
      "vid": "country",
      "rules": "required",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('euro-select', {
          staticClass: "country-select-container mb-0",
          attrs: {
            "disabled": _vm.wizardMode === 'edit',
            "placeholder": _vm.$t('Select assignment Country'),
            "options": _vm.countriesSelectable,
            "searchable": "",
            "error-messages": errors
          },
          model: {
            value: _vm.secondmentSelectedCountry,
            callback: function callback($$v) {
              _vm.secondmentSelectedCountry = $$v;
            },
            expression: "secondmentSelectedCountry"
          }
        })];
      }
    }])
  }), _c('div', [_c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase ml-4",
    on: {
      "click": _vm.swalShopRiskMap
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe Risk Map")) + " ")])])], 1)]), _c('div', {
    staticClass: "col-12"
  }, [_c('secondment-world-map-chart', {
    attrs: {
      "selected-country": _vm.secondmentSelectedCountry,
      "highlighted-countries": _vm.highlightedCountries
    },
    on: {
      "click": _vm.onMapClick
    }
  })], 1), _c('div', {
    staticClass: "col-12 mt-6"
  }, [_c('country-statistics-card-horizontal', {
    attrs: {
      "b-card-bindings": {
        'border-variant': 'transparent'
      },
      "country-iso": _vm.selectedCountry,
      "home-country-iso": _vm.getUserExpandedMainAddressCountryIso,
      "active-subs": _vm.activeSubs,
      "pending-subs": _vm.pendingSubs
    },
    on: {
      "max-risk-alert": _vm.showMaxRiskSwal,
      "go-to-shop": function goToShop(e) {
        return _vm.$emit('go-to-shop', e);
      },
      "update-selected-color": _vm.handleUpdateSelectedColor,
      "swal-shop-risk-map": _vm.swalShopRiskMap
    }
  })], 1), _vm.suggestReport ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-light-warning",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-warning"
  })]), _c('div', {
    staticClass: "alert-text"
  }, [_c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.$t("Dangerous country")) + "!")]), _vm._v(" " + _vm._s(_vm.$t("Buy risk reports for this country")) + " ")]), _c('div', {
    staticClass: "alert-close"
  }, [_c('button', {
    staticClass: "btn btn-success font-weight-bolder text-uppercase",
    on: {
      "click": function click($event) {
        return _vm.addToCartReport(_vm.selectedCountry);
      }
    }
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Shopping/Cart1.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Subscribe risk report")) + " ")])])])]) : _vm._e(), _vm.suggestService ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-light-info",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-info"
  })]), _c('div', {
    staticClass: "alert-text font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.suggestServiceMessage))])])]) : _vm._e(), _vm.dateAlert ? _c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "alert alert-custom alert-light-danger",
    attrs: {
      "role": "alert"
    }
  }, [_c('div', {
    staticClass: "alert-icon"
  }, [_c('i', {
    staticClass: "flaticon-danger"
  })]), _c('div', {
    staticClass: "alert-text font-weight-bolder"
  }, [_c('span', [_vm._v(_vm._s(_vm.dateAlertMessage))]), _vm._v(". "), _c('span', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.dateAlert))])])])]) : _vm._e(), _c('div', {
    staticClass: "col-6 mt-12"
  }, [_c('div', {
    staticClass: "pb-8"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Select Expected Assignment Duration")) + " "), _vm.suggestedDate ? _c('i', {
    class: _vm.icons.infoCircle,
    attrs: {
      "id": "suggestedDate"
    }
  }) : _vm._e()]), _vm.suggestedDate ? _c('span', {
    staticClass: "label label-lg label-inline label-light-info font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t("Suggested start date")) + ": *" + _vm._s(_vm.suggestedDate) + "* ")]) : _vm._e()])]), _c('div', {
    staticClass: "col-3 mt-10"
  }, [_c('validation-provider', {
    ref: "datesProvider",
    attrs: {
      "name": "dates",
      "rules": _vm.doublePickerRules,
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [!_vm.selectedCountry ? _c('euro-input', {
          attrs: {
            "disabled": "",
            "placeholder": "".concat(_vm.$t('Select a country first'), "*")
          }
        }) : _c('euro-double-date-picker', {
          attrs: {
            "date-from": _vm.dateFrom,
            "date-to": _vm.dateTo,
            "class-name": {
              'required-sugggest': _vm.requireSuggested
            }
          },
          on: {
            "date-from-to-selected": _vm.onDateFromToSelected
          }
        }), errors.length ? _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]) : _vm._e()];
      }
    }])
  })], 1), _c('div', {
    staticClass: "col-6 mt-8"
  }, [_c('div', {
    staticClass: "pb-8"
  }, [_c('h3', [_vm._v(_vm._s(_vm.$t('Assignment Description')))])])]), _c('div', {
    staticClass: "col-6 mt-7"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Description",
      "rules": "max:5000",
      "tag": "div"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('euro-text-area', {
          attrs: {
            "error-messages": errors,
            "rows": 3
          },
          model: {
            value: _vm.secondmentDescription,
            callback: function callback($$v) {
              _vm.secondmentDescription = $$v;
            },
            expression: "secondmentDescription"
          }
        })];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "separator separator-dashed my-10"
  }), _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-8 pb-8 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignment type")) + "* ")]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select assignment type")) + " ")])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('SecondmentActivitiesServicesForm', {
    attrs: {
      "selected-nacecodes": _vm.selectedNacecodesComp,
      "selected-service": _vm.selectedServiceComp,
      "client": _vm.selectedClient
    },
    on: {
      "update:selectedNacecodes": function updateSelectedNacecodes($event) {
        _vm.selectedNacecodesComp = $event;
      },
      "update:selected-nacecodes": function updateSelectedNacecodes($event) {
        _vm.selectedNacecodesComp = $event;
      },
      "update:selectedService": function updateSelectedService($event) {
        _vm.selectedServiceComp = $event;
      },
      "update:selected-service": function updateSelectedService($event) {
        _vm.selectedServiceComp = $event;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "separator separator-dashed my-10"
  }), _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-8 pb-8 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(_vm._s(_vm.$t("Select Final Client")))])])]), _c('div', {
    staticClass: "col-12"
  }, [_c('secondment-create-wizard-client-step', {
    attrs: {
      "countries": _vm.countries,
      "countries-selectable": _vm.countriesSelectable,
      "default-country-filter": _vm.selectedCountry,
      "is-intragroup-work": _vm.isIntragroupWork,
      "secondment-client": _vm.secondmentClient,
      "secondment-dates-all": _vm.secondmentDatesAll,
      "selected-client": _vm.selectedClient,
      "service": _vm.service,
      "wizard-mode": _vm.wizardMode,
      "actors": _vm.actors
    },
    on: {
      "clients-change": function clientsChange($event) {
        return _vm.updateClients($event);
      },
      "selected-client-change": function selectedClientChange($event) {
        return _vm.$emit('selected-client-change', $event);
      },
      "secondment-client-change": function secondmentClientChange($event) {
        return _vm.$emit('secondment-client-change', $event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "separator separator-dashed my-10"
  }), _c('div', {
    staticClass: "form row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-8 pb-8 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(" " + _vm._s(_vm.$t("Select Employees")) + " ")])])]), _vm.dateFrom && _vm.dateTo ? _c('div', {
    staticClass: "col-4"
  }) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('validation-provider', {
    attrs: {
      "name": "employees",
      "vid": "employees",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [errors ? _c('p', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]) : _vm._e(), _vm.dateFrom && _vm.dateTo ? _c('SecondmentEmployeeAvailabilityTable', {
          ref: "employeeTable",
          attrs: {
            "manager": _vm.manager,
            "date-from": _vm.dateFrom,
            "date-to": _vm.dateTo,
            "selected-first": _vm.wizardMode === 'edit',
            "documents-data": _vm.employeesDocuments,
            "selected-service-comp": _vm.selectedServiceComp,
            "secondment-employees": _vm.secondmentEmployees,
            "intragroup-clients": _vm.companiesGroup,
            "cost-company-group": _vm.costCompanyGroup,
            "selected-country": _vm.selectedCountry
          },
          on: {
            "update:selectedServiceComp": function updateSelectedServiceComp($event) {
              _vm.selectedServiceComp = $event;
            },
            "update:selected-service-comp": function updateSelectedServiceComp($event) {
              _vm.selectedServiceComp = $event;
            },
            "edit-employee": _vm.onEditEmployee,
            "manager-change": function managerChange($event) {
              return _vm.$emit('manager-change', $event);
            },
            "secondment-employee-change": function secondmentEmployeeChange($event) {
              return _vm.$emit('secondment-employee-change', $event);
            },
            "add-contract": _vm.onAddContract,
            "change-cost-reversal": function changeCostReversal($event) {
              return _vm.$emit('change-cost-reversal', $event);
            }
          },
          scopedSlots: _vm._u([{
            key: "topbar-end",
            fn: function fn() {
              return [_c('b-btn', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newPerson",
                  modifiers: {
                    "newPerson": true
                  }
                }],
                attrs: {
                  "variant": "light-primary",
                  "block": ""
                }
              }, [_c('span', {
                staticClass: "svg-icon menu-icon"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Code/Plus.svg"
                }
              })], 1), _c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(_vm.$t("Add new employee")))])]), _c('b-modal', {
                ref: "newPerson",
                attrs: {
                  "id": "newPerson",
                  "size": "xl",
                  "hide-footer": ""
                },
                scopedSlots: _vm._u([{
                  key: "modal-header",
                  fn: function fn(_ref5) {
                    var close = _ref5.close;
                    return [_c('div', {
                      staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
                    }, [_c('h3', {
                      staticClass: "mb-0"
                    }, [_vm._v(" " + _vm._s(_vm.$t("New person")) + " ")]), _c('b-button', {
                      on: {
                        "click": close
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)];
                  }
                }], null, true)
              }, [_c('PhysicalPersonCreateWizard', {
                attrs: {
                  "can-add-as-employee": "",
                  "is-employee": "",
                  "nested": "",
                  "scroll": false
                },
                on: {
                  "submit-as-employee": _vm.onSubmitAsEmployee
                }
              })], 1), _c('b-modal', {
                ref: "newEmployee",
                attrs: {
                  "id": "newEmployee",
                  "size": "xl",
                  "hide-footer": ""
                },
                scopedSlots: _vm._u([{
                  key: "modal-header",
                  fn: function fn(_ref6) {
                    var close = _ref6.close;
                    return [_c('div', {
                      staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
                    }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("Add New Contract")) + " ")]), _c('b-button', {
                      on: {
                        "click": close
                      }
                    }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)];
                  }
                }], null, true)
              }, [_c('AssociateContractWizard', {
                attrs: {
                  "disable-pp-creation": "",
                  "pp-id": _vm.selectedEmployee,
                  "nested": true
                },
                on: {
                  "submitted": _vm.refreshEmployeeTable
                }
              })], 1)];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.employees,
            callback: function callback($$v) {
              _vm.employees = $$v;
            },
            expression: "employees"
          }
        }) : _c('euro-alert', {
          attrs: {
            "show": "",
            "variant": "danger"
          }
        }, [_c('p', {
          staticClass: "mb-0 text-center"
        }, [_c('i', {
          staticClass: "mr-2",
          class: _vm.icons.infoCircle,
          style: {
            color: '#fff'
          }
        }), _vm._v(" " + _vm._s(_vm.$t("Select the country and the assignment dates to see all the available Employees")) + " ")])])];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "separator separator-dashed my-5"
  }), _vm.dateFrom && _vm.dateTo ? _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h4', {
    staticClass: "my-5 font-weight-bold text-dark"
  }, [_vm._v(" " + _vm._s(_vm.$t("Assignment Manager")) + " ")])]), _c('div', {
    staticClass: "col-3"
  }, [_c('div', {
    staticClass: "font-weight-bold mt-3"
  }, [_vm._v(_vm._s(_vm.$t("Selected Manager")) + "*:")])]), _c('div', {
    staticClass: "col-6"
  }, [_c('validation-provider', {
    attrs: {
      "vid": "manager",
      "name": "manager",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('euro-select', {
          attrs: {
            "error-messages": errors,
            "options": _vm.selectedEmployeesManager,
            "disabled": _vm.selectedEmployees.length == 1,
            "horizontal": "",
            "class-name": "mb-0"
          },
          model: {
            value: _vm.managerComputed,
            callback: function callback($$v) {
              _vm.managerComputed = $$v;
            },
            expression: "managerComputed"
          }
        })];
      }
    }], null, false, 2969671478)
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }