var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('wizard-left', {
    ref: "wizard",
    attrs: {
      "clickable-steps": false
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('General Information'),
            "desc": _vm.$t('Name, Country, ELF'),
            "icon": "Home/Building.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Contacts'),
            "desc": _vm.$t('Phone, Email, Social, Website'),
            "icon": "Communication/Call.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Address'),
            "desc": _vm.$t('Kind, Street, Country, Region, City'),
            "icon": "Map/Position.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Fiscal Code'),
            "desc": _vm.$t('Enter fiscal code information'),
            "icon": "Communication/Clipboard-check.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Vat Information'),
            "desc": _vm.$t('Enter VAT identification number'),
            "icon": "Communication/Clipboard-check.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Contact Person'),
            "desc": _vm.$t('Select contact person and company role'),
            "icon": "General/User.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var goPrev = _ref.goPrev,
          getStep = _ref.getStep;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var _vm$form$place_of_est, _vm$contact, _vm$contact$extraemai, _vm$contact2, _vm$contact2$telconta, _vm$form$companyAddre, _vm$form$companyAddre2, _vm$form$companyAddre3, _vm$form$companyAddre4, _vm$form$companyAddre5, _vm$form$fiscalData, _vm$form$fiscalData2, _vm$form$fiscalData3, _vm$form$fiscalData4, _vm$form$fiscalData5, _vm$form$vatData, _vm$form$vatData2, _vm$form$vatData3, _vm$form$vatData4, _vm$form$vatData5;
              var handleSubmit = _ref2.handleSubmit;
              return [_c('validation-observer', {
                ref: "validator-1"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t('Legal person Details')))])]), _c('div', {
                staticClass: "col-12 mb-3"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Logo'),
                  "vid": "logo_tus"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('avatar-input', {
                      staticClass: "mt-5",
                      attrs: {
                        "is-legal": "",
                        "error-messages": errors
                      },
                      on: {
                        "change": function change(tusId) {
                          _vm.form.logo_tus = tusId;
                        }
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12 mb-3"
              }, [_c('div', {
                staticClass: "checkbox-inline"
              }, [_c('label', {
                staticClass: "checkbox checkbox-lg"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_client,
                  expression: "form.secondment_client"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_client) ? _vm._i(_vm.form.secondment_client, null) > -1 : _vm.form.secondment_client
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_client,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_client", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_client", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_client", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as client")) + " ")]), _c('label', {
                staticClass: "checkbox checkbox-lg"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_contractor,
                  expression: "form.secondment_contractor"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_contractor) ? _vm._i(_vm.form.secondment_contractor, null) > -1 : _vm.form.secondment_contractor
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_contractor,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_contractor", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_contractor", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_contractor", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as contractor")) + " ")]), _c('label', {
                staticClass: "checkbox checkbox-lg"
              }, [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.secondment_partner,
                  expression: "form.secondment_partner"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.secondment_partner) ? _vm._i(_vm.form.secondment_partner, null) > -1 : _vm.form.secondment_partner
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.secondment_partner,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form, "secondment_partner", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form, "secondment_partner", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form, "secondment_partner", $$c);
                    }
                  }
                }
              }), _c('span'), _vm._v(" " + _vm._s(_vm.$t("Add as supplier")) + " ")])])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Name'),
                  "vid": "name",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": "".concat(_vm.$t('Name'), "*"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "name", $$v);
                        },
                        expression: "form.name"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Country of establishment'),
                  "vid": "country_of_establishment",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Country of establishment'), "*"),
                        "error-messages": errors,
                        "options": _vm.countriesSelectable,
                        "searchable": ""
                      },
                      model: {
                        value: _vm.form.country_of_establishment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "country_of_establishment", $$v);
                        },
                        expression: "form.country_of_establishment"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Place of establishment'),
                  "vid": "place_of_establishment"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref6) {
                    var errors = _ref6.errors;
                    return [_vm.googleMapCall == true ? _c('div', [_c('euro-place-input', {
                      attrs: {
                        "label": _vm.$t('Place of establishment'),
                        "restriction": {
                          country: _vm.form.country_of_establishment || ''
                        },
                        "error-messages": errors,
                        "disabled": _vm.placeDisable
                      },
                      model: {
                        value: _vm.form.place_of_establishment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "place_of_establishment", $$v);
                        },
                        expression: "form.place_of_establishment"
                      }
                    })], 1) : _vm._e()];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Date of establishment'),
                  "vid": ""
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "overrides": {
                          maxDate: _vm.moment()
                        },
                        "error-messages": errors,
                        "placeholder": "YYYY-MM-DD",
                        "label": _vm.$t('Date of establishment')
                      },
                      model: {
                        value: _vm.form.date_of_establishment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "date_of_establishment", $$v);
                        },
                        expression: "form.date_of_establishment"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('method of establishment'),
                  "vid": "method_of_establishment"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref8) {
                    var errors = _ref8.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": _vm.$t('Entity legal form(ELF)'),
                        "error-messages": errors,
                        "searchable": "",
                        "options": _vm.methodsOfEstablishment
                      },
                      model: {
                        value: _vm.form.method_of_establishment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "method_of_establishment", $$v);
                        },
                        expression: "form.method_of_establishment"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Group'),
                  "vid": "in_group"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref9) {
                    var errors = _ref9.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "label": _vm.$t('Is this company part of your group?'),
                        "size": "lg",
                        "error-messages": errors,
                        "options": [{
                          text: _vm.$t('Yes'),
                          value: true
                        }, {
                          text: _vm.$t('No'),
                          value: false
                        }]
                      },
                      model: {
                        value: _vm.form.add_to_group,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "add_to_group", $$v);
                        },
                        expression: "form.add_to_group"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])])], 1), _c('validation-observer', {
                ref: "validator-2"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(" " + _vm._s(_vm.$t('Contact Information')) + " ")])]), _c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "telcontact_set"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref10) {
                    var errors = _ref10.errors;
                    return [_c('multi-telephones-input', {
                      attrs: {
                        "label": _vm.$t('Enter phone numbers'),
                        "error-messages": errors,
                        "options": _vm.TELEPHONE_KINDS,
                        "default-country": _vm.form.country_of_establishment
                      },
                      model: {
                        value: _vm.form.telcontact_set,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "telcontact_set", $$v);
                        },
                        expression: "form.telcontact_set"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "extraemail_set"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref11) {
                    var errors = _ref11.errors;
                    return [_c('multi-emails-input', {
                      attrs: {
                        "label": _vm.$t('Enter email address'),
                        "error-messages": errors,
                        "options": _vm.EMAIL_KINDS
                      },
                      model: {
                        value: _vm.form.extraemail_set,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "extraemail_set", $$v);
                        },
                        expression: "form.extraemail_set"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12 mb-5"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "social_contacts"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref12) {
                    var errors = _ref12.errors;
                    return [_c('multi-social-input', {
                      key: _vm.multiSocialInputKey,
                      attrs: {
                        "label": _vm.$t('Enter social media profiles'),
                        "options": _vm.SOCIAL_KINDS,
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.socialContactsCpy,
                        callback: function callback($$v) {
                          _vm.socialContactsCpy = $$v;
                        },
                        expression: "socialContactsCpy"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": "Website",
                  "vid": "Website",
                  "rules": "url"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref13) {
                    var errors = _ref13.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": _vm.$t('Enter website'),
                        "error-messages": errors,
                        "placeholder": _vm.$t('https://www.example.com  or  www.example.com')
                      },
                      model: {
                        value: _vm.website,
                        callback: function callback($$v) {
                          _vm.website = $$v;
                        },
                        expression: "website"
                      }
                    })];
                  }
                }], null, true)
              })], 1)])])], 1), _c('validation-observer', {
                ref: "validator-3"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('div', {
                staticClass: "row"
              }, [_c('div', {
                staticClass: "col-12"
              }, [_c('h3', {
                staticClass: "mb-10 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Address")))])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Description'),
                  "vid": "address_description"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref14) {
                    var errors = _ref14.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "label": _vm.$t('Description'),
                        "errors": errors
                      },
                      model: {
                        value: _vm.form.companyAddress.description,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.companyAddress, "description", $$v);
                        },
                        expression: "form.companyAddress.description"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-8"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Kind'),
                  "vid": "address_kind"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref15) {
                    var errors = _ref15.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": _vm.$t('Kind'),
                        "errors": errors,
                        "options": _vm.addressKindsSelectable
                      },
                      model: {
                        value: _vm.form.companyAddress.kind,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.companyAddress, "kind", $$v);
                        },
                        expression: "form.companyAddress.kind"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-4"
              }, [_c('div', {
                staticClass: "form-group mb-0 mt-12"
              }, [_c('div', {
                staticClass: "d-flex align-items-center justify-content-end"
              }, [_c('label', {
                staticClass: "col-form-label"
              }, [_vm._v(_vm._s(_vm.$t('Main address')))]), _c('div', {
                staticClass: "ml-3"
              }, [_c('span', {
                staticClass: "switch switch-sm switch-icon"
              }, [_c('label', [_c('input', {
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.companyAddress.main,
                  expression: "form.companyAddress.main"
                }],
                attrs: {
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.form.companyAddress.main) ? _vm._i(_vm.form.companyAddress.main, null) > -1 : _vm.form.companyAddress.main
                },
                on: {
                  "change": function change($event) {
                    var $$a = _vm.form.companyAddress.main,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && _vm.$set(_vm.form.companyAddress, "main", $$a.concat([$$v]));
                      } else {
                        $$i > -1 && _vm.$set(_vm.form.companyAddress, "main", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.$set(_vm.form.companyAddress, "main", $$c);
                    }
                  }
                }
              }), _c('span')])])])])])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Address'),
                  "vid": "address_address",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref16) {
                    var errors = _ref16.errors;
                    return [_vm.googleMapCall == true ? _c('div', [_c('AddressInput', {
                      ref: "addressInput",
                      attrs: {
                        "label": _vm.$t('Address'),
                        "manual-address": _vm.form.companyAddress.manual_address,
                        "active-step": getStep() === 3,
                        "restriction": {
                          country: _vm.form.country_of_establishment || ''
                        },
                        "error-messages": errors,
                        "address-center": _vm.addressCenter
                      },
                      on: {
                        "change-manual-address": _vm.changeManualAddress,
                        "update-composed-address": _vm.updateComposedAddress
                      },
                      model: {
                        value: _vm.form.companyAddress.address,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form.companyAddress, "address", $$v);
                        },
                        expression: "form.companyAddress.address"
                      }
                    })], 1) : _vm._e()];
                  }
                }], null, true)
              })], 1)])])], 1), _c('validation-observer', {
                ref: "validator-4"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('h3', {
                staticClass: "mb-10 font-weight-bold text-dark text-capitalize"
              }, [_vm._v(" " + _vm._s(_vm.$t("Fiscal Code")) + " "), _c('span', {
                staticClass: "text-muted text-lowercase small"
              }, [_vm._v(" (" + _vm._s(_vm.$t("Not Required")) + ") ")])]), _c('legal-person-fiscal-form', {
                ref: "fiscalForm",
                attrs: {
                  "countries": _vm.countries,
                  "optional": "",
                  "is-not-required": "",
                  "is-creating": "",
                  "administrative-structures": _vm.administrativeStructures,
                  "default-country": _vm.form.country_of_establishment
                },
                on: {
                  "input": _vm.onLegalPersonFiscalFormInput
                }
              })], 1)], 1), _c('validation-observer', {
                ref: "validator-5"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('h3', {
                staticClass: "mb-10 font-weight-bold text-dark text-capitalize"
              }, [_vm._v(" " + _vm._s(_vm.$t("Vat Information")) + " "), _c('span', {
                staticClass: "text-muted text-lowercase small"
              }, [_vm._v(" (" + _vm._s(_vm.$t("Not Required")) + ") ")])]), _c('legal-person-vat-form', {
                ref: "vatForm",
                attrs: {
                  "countries": _vm.countries,
                  "optional": "",
                  "is-not-required": "",
                  "administrative-structures": _vm.administrativeStructures,
                  "default-country": _vm.form.country_of_establishment
                },
                on: {
                  "input": _vm.onLegalPersonVatFormInput
                }
              })], 1)], 1), _c('validation-observer', {
                ref: "validator-6"
              }, [_c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('legal-person-contact-person-form', {
                attrs: {
                  "roles": _vm.contactRoles
                },
                on: {
                  "input": _vm.onContactPersonFormInput
                }
              })], 1)], 1), _c('wizard-step-left', {
                staticClass: "custom-wizard-step"
              }, [_c('div', [_c('div', [_c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("General Information")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_vm._v(_vm._s(_vm.$t("Name")) + ": " + _vm._s(_vm.form.name))]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Country of establishment")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.form.country_of_establishment))])]), (_vm$form$place_of_est = _vm.form.place_of_establishment) !== null && _vm$form$place_of_est !== void 0 && _vm$form$place_of_est.formatted_address ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Place of establishment")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.form.place_of_establishment.formatted_address))])]) : _vm._e(), _vm.form.date_of_establishment ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Date of establishment")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.DateService.format(_vm.form.date_of_establishment, _vm.dateFormat)))])]) : _vm._e(), _vm.form.method_of_establishment ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Entity legal form(ELF)")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.selectedElf))])]) : _vm._e(), _vm.website ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Enter website")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.website))])]) : _vm._e()])]), _vm.contact.name ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Contact Person")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Name")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.contact.name))])]), _vm.contact.surname ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Surname")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.contact.surname))])]) : _vm._e(), _vm.contact.gender ? _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("GENDER")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.contact.gender))])]) : _vm._e(), _vm._l((_vm$contact = _vm.contact) === null || _vm$contact === void 0 ? void 0 : (_vm$contact$extraemai = _vm$contact.extraemail_set) === null || _vm$contact$extraemai === void 0 ? void 0 : _vm$contact$extraemai.filter(function (el) {
                return el.email != '';
              }), function (emailContact, idx) {
                var _vm$EMAIL_KINDS$find;
                return _c('div', {
                  key: "mail-".concat(idx)
                }, [_c('span', [_vm._v(_vm._s((_vm$EMAIL_KINDS$find = _vm.EMAIL_KINDS.find(function (el) {
                  return el.value == emailContact.kind;
                })) === null || _vm$EMAIL_KINDS$find === void 0 ? void 0 : _vm$EMAIL_KINDS$find.text) + ": ")]), _c('span', [_vm._v(_vm._s(emailContact.email))])]);
              }), _vm._l((_vm$contact2 = _vm.contact) === null || _vm$contact2 === void 0 ? void 0 : (_vm$contact2$telconta = _vm$contact2.telcontact_set) === null || _vm$contact2$telconta === void 0 ? void 0 : _vm$contact2$telconta.filter(function (el) {
                return el.number != '';
              }), function (telContact, idx) {
                var _vm$TELEPHONE_KINDS$f;
                return _c('div', {
                  key: "phone-".concat(idx)
                }, [_c('span', [_vm._v(_vm._s((_vm$TELEPHONE_KINDS$f = _vm.TELEPHONE_KINDS.find(function (el) {
                  return el.value == telContact.kind;
                })) === null || _vm$TELEPHONE_KINDS$f === void 0 ? void 0 : _vm$TELEPHONE_KINDS$f.text) + ": ")]), _c('span', [_vm._v(" " + _vm._s(telContact.number) + " " + _vm._s(telContact.main ? "- Main" : "") + " ")])]);
              }), _vm.contact.role ? _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("Role")) + ": ")]), _c('span', [_vm._v(_vm._s(_vm.contact.roleName))])]) : _vm._e()], 2)]) : _vm._e(), _vm.form.telcontact_set.filter(function (el) {
                return el.number != '';
              }).length ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Phone Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, _vm._l(_vm.form.telcontact_set.filter(function (el) {
                return el.number != '';
              }), function (telContact, idx) {
                var _vm$TELEPHONE_KINDS$f2;
                return _c('div', {
                  key: idx
                }, [_c('span', [_vm._v(_vm._s((_vm$TELEPHONE_KINDS$f2 = _vm.TELEPHONE_KINDS.find(function (el) {
                  return el.value == telContact.kind;
                })) === null || _vm$TELEPHONE_KINDS$f2 === void 0 ? void 0 : _vm$TELEPHONE_KINDS$f2.text) + ": ")]), _c('span', [_vm._v(" " + _vm._s(telContact.number) + " " + _vm._s(telContact.main ? "- ".concat(_vm.$t("Main")) : "") + " ")])]);
              }), 0)]) : _vm._e(), _vm.form.extraemail_set.filter(function (el) {
                return el.email != '';
              }).length ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Email Contacts")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, _vm._l(_vm.form.extraemail_set.filter(function (el) {
                return el.email != '';
              }), function (emailContact, idx) {
                var _vm$EMAIL_KINDS$find2;
                return _c('div', {
                  key: idx
                }, [_c('span', [_vm._v(_vm._s((_vm$EMAIL_KINDS$find2 = _vm.EMAIL_KINDS.find(function (el) {
                  return el.value == emailContact.kind;
                })) === null || _vm$EMAIL_KINDS$find2 === void 0 ? void 0 : _vm$EMAIL_KINDS$find2.text) + ": ")]), _c('span', [_vm._v(" " + _vm._s(emailContact.email) + " " + _vm._s(emailContact.main ? "- ".concat(_vm.$t("Main")) : "") + " ")])]);
              }), 0)]) : _vm._e(), (_vm$form$companyAddre = _vm.form.companyAddress) !== null && _vm$form$companyAddre !== void 0 && (_vm$form$companyAddre2 = _vm$form$companyAddre.address) !== null && _vm$form$companyAddre2 !== void 0 && _vm$form$companyAddre2.formatted_address ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v("Address")]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm.form.companyAddress.manual_address ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.form.companyAddress.composed_address.formatted))]), _c('span', [_vm._v(_vm._s(_vm.form.companyAddress.description))])]) : _c('div', [_c('span', [_vm._v(_vm._s((_vm$form$companyAddre3 = (_vm$form$companyAddre4 = _vm.form.companyAddress) === null || _vm$form$companyAddre4 === void 0 ? void 0 : (_vm$form$companyAddre5 = _vm$form$companyAddre4.address) === null || _vm$form$companyAddre5 === void 0 ? void 0 : _vm$form$companyAddre5.formatted_address) !== null && _vm$form$companyAddre3 !== void 0 ? _vm$form$companyAddre3 : "No address found"))]), _c('span', [_vm._v(_vm._s(_vm.form.companyAddress.description))])])])]) : _vm._e(), (_vm$form$fiscalData = _vm.form.fiscalData) !== null && _vm$form$fiscalData !== void 0 && _vm$form$fiscalData.fiscalid ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Fiscal Code")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Fiscal Code")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.form.fiscalData.fiscalid))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid From")) + ": ")]), _c('span', [_vm._v(_vm._s((_vm$form$fiscalData2 = _vm.form.fiscalData) !== null && _vm$form$fiscalData2 !== void 0 && _vm$form$fiscalData2.from_date ? _vm.DateService.format((_vm$form$fiscalData3 = _vm.form.fiscalData) === null || _vm$form$fiscalData3 === void 0 ? void 0 : _vm$form$fiscalData3.from_date, _vm.dateFormat) : ""))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid To")) + ":")]), _c('span', [_vm._v(_vm._s((_vm$form$fiscalData4 = _vm.form.fiscalData) !== null && _vm$form$fiscalData4 !== void 0 && _vm$form$fiscalData4.to_date ? _vm.DateService.format((_vm$form$fiscalData5 = _vm.form.fiscalData) === null || _vm$form$fiscalData5 === void 0 ? void 0 : _vm$form$fiscalData5.to_date, _vm.dateFormat) : ""))])]), _c('div', {
                staticClass: "mt-2"
              }, [_vm.form.fiscalData.attachment_tus ? _c('i', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "fas fa-paperclip fa-2x mr-2",
                attrs: {
                  "title": "File Attached"
                }
              }) : _vm._e()])])]) : _vm._e(), (_vm$form$vatData = _vm.form.vatData) !== null && _vm$form$vatData !== void 0 && _vm$form$vatData.vat ? _c('div', [_c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Vat Number")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Vat Number")) + ":")]), _c('span', [_vm._v(_vm._s(_vm.form.vatData.vat))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid From")) + ": ")]), _c('span', [_vm._v(_vm._s((_vm$form$vatData2 = _vm.form.vatData) !== null && _vm$form$vatData2 !== void 0 && _vm$form$vatData2.from_date ? _vm.DateService.format((_vm$form$vatData3 = _vm.form.vatData) === null || _vm$form$vatData3 === void 0 ? void 0 : _vm$form$vatData3.from_date, _vm.dateFormat) : ""))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid To")) + ":")]), _c('span', [_vm._v(_vm._s((_vm$form$vatData4 = _vm.form.vatData) !== null && _vm$form$vatData4 !== void 0 && _vm$form$vatData4.to_date ? _vm.DateService.format((_vm$form$vatData5 = _vm.form.vatData) === null || _vm$form$vatData5 === void 0 ? void 0 : _vm$form$vatData5.to_date, _vm.dateFormat) : ""))])]), _c('div', {
                staticClass: "mt-2"
              }, [_vm.form.vatData.attachment_tus ? _c('i', {
                directives: [{
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover",
                  modifiers: {
                    "hover": true
                  }
                }],
                staticClass: "fas fa-paperclip fa-2x mr-2",
                attrs: {
                  "title": "File Attached"
                }
              }) : _vm._e()])])]) : _vm._e()])]), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn-left', {
                on: {
                  "click": goPrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn-left', {
                attrs: {
                  "disabled": _vm.loading
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_c('i', {
                staticClass: "fab fa-telegram-plane"
              })]), _c('wizard-next-btn-left', {
                on: {
                  "click": _vm.handleNextClick
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  });

}
var staticRenderFns = []

export { render, staticRenderFns }