<template>
  <div class="card card-custom">
    <div class="card-body">
      <b-overlay :show="!loaded" opacity="0.3">
        <div v-if="!loaded" class="min-vh-60 d-flex flex-column align-items-center justify-content-center">
          <div class="text-center mb-20">
            <h4>{{ $t("Loading assignment wizard...") }}</h4>
          </div>
        </div>
      </b-overlay>
      <wizard v-if="loaded" ref="secondmentCreateWizard" :clickable-steps="false">
        <wizard-nav>
          <wizard-nav-step v-for="wizardStep in wizardSteps" v-show="!wizardStep.skippable" :key="wizardStep.id"
            :title="wizardStep.title" number="" :desc="wizardStep.desc"></wizard-nav-step>
        </wizard-nav>
        <wizard-body>
          <validation-observer>
            <wizard-step v-for="wizardStep in wizardSteps" v-show="!wizardStep.skippable" :key="wizardStep.id">
              <component :is="wizardStep.stepComponent" ref="wizardStepRef" v-bind="wizardStep.props"
                :class="{ 'opacity-50': isSaving }" v-on="wizardStep.events"></component>
            </wizard-step>

            <wizard-actions>
              <div class="mr-2">
                <wizard-prev-btn :show-spinner="isSaving" :disabled="isSaving" @click="handlePrev"></wizard-prev-btn>
              </div>

              <div>
                <div>
                  <wizard-submit-btn class="mr-2" :label="$t('Save as draft')" :disabled="isSaving"
                    @click="submit('draft')">
                    <i :class="icons.saveDraft"></i>
                  </wizard-submit-btn>
                  <wizard-submit-btn :label="past ? $t('Save as Confirmed (past)') : (started ? $t('Save as Confirmed (started)') : $t('Save as Confirmed'))" :disabled="isSaving" @click="submit">
                    <i :class="icons.save"></i>
                    </wizard-submit-btn>
                </div>
                <wizard-next-btn :label="$t('Save and Continue')" :disabled="isSaving" :show-spinner="isSaving"
                  @click="handleFormStep(handleNext)"></wizard-next-btn>
              </div>
            </wizard-actions>
          </validation-observer>
        </wizard-body>
      </wizard>
    </div>
  </div>
</template>

<script>
import SecondmentCreateWizardNationStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardNationStep.vue";
import SecondmentCreateWizardContractorStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardContractorStep.vue";
import SecondmentCreateWizardSuppliersStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardSuppliersStep.vue";
import SecondmentCreateWizardReviewStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardReviewStep.vue";
import SecondmentCreateWizardEmployeeStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardEmployeeStep.vue";
import SecondmentCreateWizardPlannerStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardPlannerStep.vue";
import SecondmentService from "@/core/services/secondment/secondment.service";
import AddressService from "@/core/services/address.service";
import SecondmentEmployeeService from "@/core/services/secondment/secondment-employee.service";
import DateService from "@/core/services/date.service";
import icons from "@/core/config/icons.js";
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";
import _ from "lodash";
import SecondmentTravelPlanService from "@/core/services/secondment/secondment-travelplan.service";
import Swal from "sweetalert2";
import AvailabilityService from "@/core/services/availability.service";
import { SERVICE_TYPE } from "@/core/abstractions/secondment";
import {
  moveTravelPlansStartAndEndDates,
  updateSecondmentEmployeeTravelPlanDates,
} from "@/core/abstractions/travelPlans";
import SecondmentActorsService from "@/core/services/secondment/secondment-actors.service";
import { successToast } from '@/core/helpers';
import {backendErrorSwal, ART13_EmptySwal, ART12_SamePostingCountry, confirmationSwal} from "@/core/helpers/swal";

const DEFAULT_DATA = {
  legalPersonWizardErrors: null,
  // Array of employees as Employee object.
  selectedEmployees: [],
  country: "",
  dateStart: "",
  dateEnd: "",
  description: "",
  // The id of the secondment.
  id: "",
  status: "draft",
  past: false,
  started: false,
  // Array of employees as SecondmentEmployee object.
  secondmentEmployees: [],
  service: "client",
  // Array of nacecodes id.
  selectedNacecodes: [],
  // Client as Company object.
  selectedClient: null,
  // Client as SecondmentCompany object.
  secondmentClient: null,
  // Contractor as Company object.
  selectedContractor: null,
  // Suppliers as Company object.
  selectedSuppliers: [],
  // Suppliers as SecondmentPartner object.
  secondmentSuppliers: [],
  employeesSalary: {},
  defaultEmployeesAvailability: {},
  customEmployeesAvailability: {},
  employeesDocumentsStatus: {},
  manager: null,
  // Array containing client data except the current. Used for making the final request/add more client loop.
  clientsData: [],
  travelPlans: [],
  isSaving: false,
  intragroupClients: [],
  secondmentClientId: null,
  products: [],
  missingProducts: [],
};

export default {
  props: {
    resume: {
      type: Boolean,
      default: false,
    },
    multiClient: {
      type: Boolean,
      default: false,
    },
    nacecodes: {
      type: Array,
      default: () => [],
    },
    countries: {
      type: Array,
      default: () => [],
    },
    missingTravelPlan: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    ...DEFAULT_DATA,
    oldSecondmentEmployees: [],
    newSecondmentEmployees: [],
    activeOrPendingSubs: {},
    pendingSubs: {},
    activeSubs: {},
    errors: [],
    loaded: false,
    actors: {},
    checkDateStart: {},
  }),

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompany"]),
    ...mapState("user", ["user"]),
    ...mapState("wizard", ["secondmentDraft", "resumeStep"]),
    ...mapState("addresses", ["apiAddresses"]),
    ...mapState("capabilities", ["subscriptions", "pending", "risk_map"]),
    ...mapState("constants", ["PRODUCT_TYPES", "POSTING_CERTIFICATE_KIND"]),

    wizardMode() {
      return this.$route.query.mode || "create";
    },

    isWizardEditMode() {
      return this.wizardMode === "edit";
    },

    icons() {
      return icons;
    },

    wizardSteps() {
      return [
        {
          id: "nation-step",
          title: this.$t("Nation"),
          desc: this.$t("Nation details"),
          stepComponent: SecondmentCreateWizardNationStep,
          props: {
            pendingSubs: this.pendingSubs,
            activeSubs: this.activeSubs,
            countriesSelectable: this.countriesSelectable,
            countriesToPurchase: this.countriesToPurchase,
            highlightedCountries: this.highlightedCountries,
            selectedEmployees: this.selectedEmployees,
            dateFrom: this.dateStart,
            dateTo: this.dateEnd,
            description: this.description,
            selectedCountry: this.country,
            past: this.past,
            started: this.started,
            employeesDocumentsStatus: this.employeesDocumentsStatus,
            secondmentEmployees: this.secondmentEmployees,
            manager: this.manager,
            secondmentDatesAll: this.secondmentDatesAll,
            selectedClient: this.selectedClient,
            secondmentClient: this.secondmentClient,
            countries: this.countries,
            isIntragroupWork: this.isIntragroupWork,
            nacecodes: this.nacecodes,
            selectedNacecodes: this.selectedNacecodes,
            service: this.service,
            commonActivities: this.commonActivities,
            wizardMode: this.wizardMode,
            actors: this.actors,
            status: this.status,
          },
          events: {
            "employees-change": this.onEmployeesChange,
            "manager-change": this.onManagerChange,
            "date-to-change": this.onDateToChange,
            "date-from-change": this.onDateFromChange,
            "date-from-to-selected": this.onDatesFromToChange,
            "description-change": this.onSecondmentDescriptionChange,
            "selected-country-change": this.onSelectedCountryChange,
            "past-change": this.onPastChange,
            "started-change": this.onStartedChange,
            "edit-employee": this.onNestedEdit,
            "secondment-employee-change": this.onSecondmentEmployeeChange,
            "selected-client-change": this.onSelectedClientChange,
            "secondment-client-change": this.onSecondmentClientChange,
            "selected-nacecodes-change": this.onSelectedNacecodesChange,
            "service-change": this.onServiceChange,
            "update-capabilities": this.getCapabilities,
            "go-to-shop": this.goToShop,
            "change-cost-reversal": this.changeCostReversal,
            "set-intragroup-clients": this.setIntragroupClients,
            "update-employee-documents": this.updateEmployeeDocuments,
            "check-date-start": this.dateStartChecks,
          },
          skippable: false,
        },
        {
          id: "employees-step",
          title: this.$t("Employees"),
          desc: this.$t("Add employees"),
          stepComponent: SecondmentCreateWizardEmployeeStep,
          props: {
            clientAddress: this.clientAddress,
            clientCoordinates: this.clientCoordinates,
            employees: this.selectedEmployees,
            secondmentEmployees: this.secondmentEmployees,
            secondmentDatesAll: this.secondmentDatesAll,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            secondmentId: this.id,
            employeesDocumentsStatus: this.employeesDocumentsStatus,
            country: this.country,
          },
          events: {
            "secondment-employees-change": this.onSecondmentEmployeesChange,
            "edit-employee": this.onNestedEdit,
            "secondment-employee-change": this.onSecondmentEmployeeChange,
          },
          skippable: this.wizardMode === "edit" && !this.missingTravelPlan,
        },
        {
          id: "planner-step",
          title: this.$t("Planner"),
          desc: this.$t("Planner details"),
          stepComponent: SecondmentCreateWizardPlannerStep,
          props: {
            travelPlans: this.travelPlans,
            secondmentEmployees: this.secondmentEmployees,
            country: this.country,
            clientAddressId: this.secondmentClient?.address,
            client: this.secondmentClient,
            wizardMode: this.wizardMode,
          },
          events: {
            "delete-travel-plan": this.deleteTravelPlan,
            "clone-travel-plan": this.cloneTravelPlan,
          },
          skippable: false,
        },
        {
          id: "contractor-step",
          title: this.$t("Contractor"),
          desc: this.$t("Contractor details"),
          stepComponent: SecondmentCreateWizardContractorStep,
          props: {
            selectedContractor: this.selectedContractor,
            countries: this.countries,
            countriesSelectable: this.countriesSelectable,
            defaultCountryFilter: this.country,
            actors: this.actors,
          },
          events: {
            "selected-contractor-change": this.onSelectedContractorChange,
          },
          skippable: !this.isContractWork,
        },
        {
          id: "suppliers-step",
          title: this.$t("Suppliers"),
          desc: this.$t("Suppliers details"),
          stepComponent: SecondmentCreateWizardSuppliersStep,
          props: {
            selectedSuppliers: this.selectedSuppliers,
            secondmentSuppliers: this.secondmentSuppliers,
            countries: this.countries,
            countriesSelectable: this.countriesSelectable,
            secondmentDatesAll: this.secondmentDatesAll,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            defaultCountryFilter: this.country,
            actors: this.actors,
          },
          events: {
            "selected-suppliers-change": this.onSelectedSuppliersChange,
            "secondment-supplier-change": this.onSecondmentSupplierChange,
            "secondment-suppliers-change": this.onSecondmentSuppliersChange,
            "edit-supplier": this.onNestedEdit,
          },
          skippable: false,
        },
        {
          id: "review-step",
          title: this.$t("Complete"),
          desc: this.$t("Review secondment"),
          stepComponent: SecondmentCreateWizardReviewStep,
          props: {
            service: this.service,
            clientsData: this.clientsDataComputed,
            selectedCountry: this.country,
            dateStart: this.dateStart,
            dateEnd: this.dateEnd,
            description: this.description,
            id: this.id,
            status: this.status,
            past: this.past,
            started: this.started,
            secondmentEmployees: this.secondmentEmployeesWithProducts(),
            productDeclarationOfDetachment: this.hasDeclarationOfDetachment,
            selectedEmployees: this.selectedEmployees,
            extendedNacecodes: this.extendedSelectedNacecodes,
            isContractWork: this.isContractWork,
            isIntragroupWork: this.isIntragroupWork,
            selectedClient: this.selectedClient,
            secondmentClient: this.secondmentClient,
            selectedContractor: this.selectedContractor,
            suppliers: this.secondmentSuppliers,
            countriesSelectable: this.countriesSelectable,
            multiClient: this.multiClient,
            secondmentDatesAll: this.secondmentDatesAll,
            employeesDocumentsStatus: this.employeesDocumentsStatus,
            intragroupClients: this.intragroupClients
          },
          events: {
            jump: this.jumpToStep,
            "add-client": this.onAddClient,
            "modify-client": this.onModifyClient,
            "update:products": this.onUpdateProducts,
            "update:missingProducts": this.onUpdateMissingProducts,
          },
          skippable: false,
        },
      ];
    },

    hasDeclarationOfDetachment() {
      return true;
      // return this.products.some(el => el.product_type === this.PRODUCT_TYPES.DECLARATION_OF_DETACHMENT);
    },

    countriesSelectable() {
      return this.countries.filter(el => this.risk_map.includes(el.iso)).map(x => ({
        text: this.$t(x.name),
        value: x.iso,
        elaborationTime: x?.secondments?.elaboration_time,
      })).sort((a, b) => a.text.localeCompare(b.text));
    },

    countriesToPurchase() {
      const countriesNotPurchased = this.countries.filter(el => !this.risk_map.includes(el.iso)).map(x => ({
        text: this.$t(x.name),
        value: x.iso,
      })).sort((a, b) => a.text.localeCompare(b.text));
      const countries = {};
      countriesNotPurchased.forEach(x => {
        countries[x.value] = x.text;
      });
      return countries;
    },

    highlightedCountries() {
      return this.countries.filter(el => this.risk_map.includes(el.iso));
    },

    extendedSelectedNacecodes() {
      const comparator = (arrVal, othVal) => {
        return arrVal.id === othVal;
      };
      return _.intersectionWith(this.nacecodes, this.selectedNacecodes, comparator);
    },

    minDate() {
      if (!this.dateStart) {
        return null;
      }

      return moment(this.dateStart).toDate();
    },

    maxDate() {
      if (!this.dateEnd) {
        return null;
      }

      return moment(this.dateEnd).toDate();
    },

    secondmentDatesAll() {
      if (!this.minDate || !this.maxDate) {
        return [];
      }

      return DateService.enumerateDates(this.minDate, this.maxDate);
    },

    // currentStep starts from 1.
    currentStep() {
      return this.$refs.secondmentCreateWizard.getStep();
    },

    currentStepId() {
      return this.wizardSteps[this.currentStep - 1].id;
    },

    // Helper conputed that returns if the current service is contract work.
    isContractWork() {
      return this.service === SERVICE_TYPE.CONTRACT_WORK;
    },

    // Helper conputed that returns if the current service is intragroup work.
    isIntragroupWork() {
      return this.service === SERVICE_TYPE.INTRAGROUP;
    },

    // The current client. It's the object that will go inside clientsData array.
    currentClient() {
      const clientID = this.selectedClient?.id;
      return {
        clientID,
        client: this.selectedClient,
        selectedClient: this.selectedClient,
        selectedNacecodes: this.selectedNacecodes,
        extendedSelectedNacecodes: this.extendedSelectedNacecodes,
        secondmentSuppliers: this.secondmentSuppliers,
        selectedSuppliers: this.selectedSuppliers,
        selectedContractor: this.selectedContractor,
        secondmentClient: this.secondmentClient,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
        service: this.service,
      };
    },

    // Returns all the clientsData included the currentClient in first position.
    clientsDataComputed() {
      let clients = [...this.clientsData];

      if (this.currentClient?.clientID) {
        clients = [this.currentClient, ...clients];
      }

      return clients;
    },

    clientAddress() {
      if (!this.selectedClient || !this.secondmentClient) {
        return null;
      }
      let addresses = [];
      if (this.selectedClient.kind == "Company") {
        addresses = this.selectedClient.companyaddress_set;
      } else {
        addresses = this.selectedClient.physicalpersonaddress_set;
      }
      const addr = addresses.find(
        addr => addr?.id == this.secondmentClient.address || addr?.address?.id == this.secondmentClient.address,
      );
      if (!addr) {
        return null;
      }
      return addr;
    },

    clientCoordinates() {
      let lat = null;
      let lng = null;
      if (this.clientAddress) {
        lat = this.clientAddress.address.latitude;
        lng = this.clientAddress.address.longitude;
      }

      return { lat, lng };
    },

    employeesAvailability() {
      //return only the default, when you change the date, this will use the default + custom value
      for (var key in this.customEmployeesAvailability) {
        if (this.customEmployeesAvailability.hasOwnProperty(key) && this.customEmployeesAvailability[key] === undefined) {
          return { ...this.defaultEmployeesAvailability };
        }
      }
      //return default + custom values
      return { ...this.defaultEmployeesAvailability, ...this.customEmployeesAvailability };
    },

    notAvailableSecondmentEmployees() {
      const notAvailableSecondmentEmployees = [];
      Object.keys(this.customEmployeesAvailability).forEach(empId => {
        const secEmp = this.secondmentEmployees.find(se => se.employee == empId);
        if (secEmp && this.customEmployeesAvailability[secEmp.employee] == AvailabilityService.Enum.NOT_AVAILABLE) {
          notAvailableSecondmentEmployees.push(secEmp);
        }
      });

      return notAvailableSecondmentEmployees;
    },
  },

  watch: {
    async "secondmentClient.address"(val) {
      if (val) {
        if (!this?.travelPlans?.travel_days) {
          return;
        }

        const clientAddress = await AddressService.getGooglePlaceFromApi(val);

        this.travelPlans = this.travelPlans.map(tp => {
          tp.travel_days = tp.travel_days.map(td => {
            td.places = td.places.map(p => {
              if (p.place_kind == "client") {
                p.place = clientAddress;
              }
              return p;
            });
            return td;
          });
          return tp;
        });
      }
    },

    service(service) {
      this.$emit("service-change", service);
    },

    secondmentEmployees: {
      deep: true,
      handler(newSecondmentEmployees, oldSecondmentEmployees) {
        if (newSecondmentEmployees.length != oldSecondmentEmployees.length) {
          this.updateSecondmentEmployeesAvailabilities();
        }

        this.newSecondmentEmployees = newSecondmentEmployees;
        this.oldSecondmentEmployees = oldSecondmentEmployees;
        // Start building travel plans.

        //in edit mode we don't want to auto add new employees to the travel plan
        if (this.wizardMode === "edit") return;

        this.manageTravelPlans();
      },
    },

    "$route.query": {
      deep: true,
      handler(_, oldVal) {
        if (oldVal?.mode === "edit" || oldVal?.pending) {
          this.reset();
        }
      },
    },

    selectedEmployees(val) {
      if (val.length == 1) {
        this.manager = val[0].id;
      }
    },
  },

  async mounted() {
    if (this.$route.query.resume) {
      this.jumpToStep(this.resumeStep);
      for (const [key, value] of Object.entries(this.$store.state.wizard.secondmentDraft)) {
        this[key] = value;
      }
    }

    if (this.wizardMode === "edit") {
      const draft = this.$store.state.wizard.secondmentEdit;
      Object.entries(draft).forEach(([key, val]) => {
        if (draft[key]) {
          this.$data[key] = val;
        }
      });
      this.secondmentClientId = this.secondmentClient.id;

      this.secondmentEmployees.forEach(employee => {
        // controlla chi è il manager
        if (employee.manager) {
          this.manager = employee.employee
        }
      })
    } else {
      this.$route.query.mode = "create";
      this.$route.query.resume = false;
    }

    this.$store.commit("wizard/resetEdit");
    this.$store.commit("wizard/resetDraft");
    await this.getCapabilities();
    this.actors = await SecondmentActorsService.getAll({
      perPage: 10,
      page: 1,
      sortBy: "name",
      sortDesc: true,
    });
    this.loaded = true;
  },

  methods: {
    moment,
    ...mapActions("addresses", ["getGoogleAddressFromApi", "getApiAddress"]),

    errorCatch(err) {
      this.errors = [];
      const errorList = err.response.data;
      for (const key in errorList) {
        for (const err in errorList[key]) {
          this.errors.push(errorList[key][err]);
        }
      }
    },

    goToShop({ country, product }) {
      const route = {
        name: 'reports',
        params: country,
        query: { iso: country }
      };

      this.$store.commit("wizard/setDraft", this.$data);
      this.$store.commit("wizard/setResumeStep", this.$refs.secondmentCreateWizard.getStep());
      route.query = { pending: true, edit: this.wizardMode === "edit", product, country };
      this.$router.push(route);
    },

    getCapabilities() {
      this.subscriptions.forEach(sub => {
        if (sub.active) {
          const categories = sub.querytemplate.categories;
          const countries = sub.querytemplate.post_countries;
          categories.forEach(cat => {
            if (!this?.activeSubs[cat]) {
              this.activeSubs[cat] = countries;
            } else {
              const catSet = new Set([...this.activeSubs[cat], ...countries]);
              this.activeSubs[cat] = Array.from(catSet);
            }
          });
        }
      });

      this.pending.forEach(sub => {
        const categories = sub.categories.map(cate => cate.id);
        const country = sub.country;
        categories.forEach(cat => {
          if (!this?.pendingSubs[cat]) {
            this.pendingSubs[cat] = country;
          } else {
            const pending =
              typeof this.pendingSubs[cat] == "string" ? [this.pendingSubs[cat]] : this.pendingSubs[cat];
            const catSet = new Set([...pending, country]);
            this.pendingSubs[cat] = Array.from(catSet);
          }
        });
      });
    },

    async onDatesFromToChange({ from, to }) {
      if (this.status != "draft" && DateService.isBefore(this.dateStart, moment()) && !moment(from).isSame(this.dateStart)) {
        Swal.fire({
          title: "Error",
          icon: "error",
          text: "You can't update the starting date if the secondment is in progress",
        });

        return;
      }
      if (_.isEmpty(this.travelPlans)) {
        this.dateStart = from;
        this.dateEnd = to;
        this.secondmentEmployees = this.secondmentEmployees.map(emp => ({
          ...emp,
          date_start: this.dateStart,
          date_end: this.dateEnd,
        }));
        return;
      }

      if (this.dateEnd === to && this.dateStart === from) return;

      if (!this.dateEnd && !this.dateStart) {
        this.dateStart = from;
        this.dateEnd = to;
        return;
      }

      if (this.wizardMode === "create") {
        await Swal.fire({
          icon: "warning",
          text: "This will delete all travel plan data, do you want to continue?",
          confirmButtonClass: "btn btn-primary",
          confirmButtonText: this.$t("Continue"),
          showCancelButton: true,
          cancelButtonText: this.$t("Cancel"),
          cancelButtonClass: "btn",
        }).then(res => {
          if (res.isConfirmed) {
            this.dateStart = from;
            this.dateEnd = to;
            this.reset({
              country: this.country,
              selectedEmployees: this.selectedEmployees,
              dateStart: this.dateStart,
              dateEnd: this.dateEnd,
              description: this.description,
              // The id of the secondment.
              id: this.id,
              // Array of employees as SecondmentEmployee object.
              secondmentEmployees: this.secondmentEmployees.map(emp => ({
                ...emp,
                date_start: this.dateStart,
                date_end: this.dateEnd,
              })),
              service: this.service,
              // Array of nacecodes id.
              selectedNacecodes: this.selectedNacecodes,
              // Client as Company object.
              selectedClient: this.selectedClient,
              // Client as SecondmentCompany object.
              secondmentClient: this.secondmentClient,
              // Contractor as Company object.
              selectedContractor: this.selectedContractor,
              // Suppliers as Company object.
              selectedSuppliers: this.selectedSuppliers,
              // Suppliers as SecondmentPartner object.
              secondmentSuppliers: this.secondmentSuppliers,
              employeesSalary: this.employeesSalary,
              // Array containing client data except the current. Used for making the final request/add more client loop.
              employeesDocumentsStatus: {},
            });
          }
        });
        return;
      }

      this.dateStart = from;
      this.dateEnd = to;

      if (this.isWizardEditMode && this.travelPlans.length) {
        this.travelPlans = moveTravelPlansStartAndEndDates(this.travelPlans, from, to);

        this.secondmentEmployees.forEach(secondmentEmployee => {
          if (DateService.isBefore(secondmentEmployee.date_start, this.dateStart)) {
            secondmentEmployee.date_start = this.dateStart;
          }

          if (DateService.isAfter(secondmentEmployee.date_end, this.dateEnd)) {
            secondmentEmployee.date_end = this.dateEnd;
          }
        });

        Swal.fire({
          text: "Some travel plans might have changed. Make sure to check them again.",
        });
      }
    },
    createTravelPlanDays(employee) {
      const dates = DateService.enumerateDates(employee.date_start, employee.date_end);
      const first = employee.places.find(el => el.place_kind === "arrival" && el.place != undefined);
      const last = employee.places.find(el => el.place_kind === "departure" && el.place != undefined);
      const commons = employee.places.filter(
        el => !["arrival", "departure"].includes(el.place_kind) && el.place != undefined,
      );

      return dates.map(date => {
        const day = {
          date: date,
          places: [],
        };

        if (first && date == employee.date_start) {
          day.places.push(first)
        }

        commons.forEach(common => {
          day.places.push(common)
        })

        day.places.push({
          place_kind: "client",
          place: this.secondmentClient?.address,
        });

        if (last && date == employee.date_end) {
          day.places.push(last)
        }

        // Order by sorting
        day.places = day.places.map((el, idx) => ({ ...el, order: el?.order ?? idx }));

        return day;
      });
    },
    manageTravelPlans() {
      const days = [];

      this.newSecondmentEmployees.forEach(employee => {
        // cerco un giorno già inserito
        const day = days.find(day => day.date_start == employee.date_start && day.date_end == employee.date_end);
        if (day) {
          day.employees.push(employee)
        } else {
          days.push({
            date_start: employee.date_start,
            date_end: employee.date_end,
            employees: [employee],
            days: this.createTravelPlanDays(employee)
          })
        }
      })

      this.travelPlans = [];

      this.travelPlans = days.map(el => el);
    },
    // Generate the submit/update request body.
    async makeRequestBody() {
      let clients = this.clientsDataComputed;

      const makeActivities = client => {
        const activities = {
          id: this.secondmentClient.activities?.[0]?.id || null,
          activities: client.selectedNacecodes,
          description: "",
          type: this.service,
        };

        return [activities];
      };

      const makeContractors = client => {
        return client.selectedContractor
          ? [
            {
              id: this.secondmentClient.contractors?.[0]?.id || null,
              description: "",
              contractor_object_id: client.selectedContractor.id,
              contractor_content_type: client.selectedContractor.content_type,
              date_start: client.dateStart,
              date_end: client.dateEnd,
            },
          ]
          : [];
      };

      const makeClients = clients =>
        clients.map(client => {
          const activities = makeActivities(client);
          const contractors = makeContractors(client);
          const partners = client.secondmentSuppliers;
          if (this.secondmentClient.partners) {
            this.secondmentClient.partners.forEach(partner => {
              const foundPartner = partners.find(p => p.partner_object_id === partner.partner_object_id);
              if (foundPartner) {
                foundPartner.id = partner.id;
              }
            });
          }
          return {
            id: this.secondmentClientId,
            partners,
            contractors,
            activities,
            description: client.secondmentClient?.description,
            notify: client.secondmentClient?.notify,
            client_object_id: client.secondmentClient?.client_object_id,
            client_content_type: client.secondmentClient?.client_content_type,
            address: client.secondmentClient?.address,
            date_start: client.secondmentClient?.date_start ? client.secondmentClient?.date_start : this.dateStart,
            date_end: client.secondmentClient?.date_end ? client.secondmentClient?.date_end : this.dateEnd,
          };
        });



      let body = {
        country: this.country,
        date_start: this.dateStart,
        date_end: this.dateEnd,
        name: "",
        description: this.description,
        status: this.status,
        past: this.past,
        started: this.started,
        company: this.getCurrentManagedCompany.company,
        created_by: this.user.id,
        employees: this.secondmentEmployees,
        clients: makeClients(clients),
        travel_plans: await SecondmentTravelPlanService.makeTravelPlansRequestBody(this.travelPlans),
      };

      if (this.products.length) {
        body.products = { items: this.products };
      }

      return body
    },

    // Clears all the data relative to the current client.
    clearCurrentClient() {
      this.selectedNacecodes = [];
      this.selectedClient = null;
      this.secondmentClient = null;
      this.selectedContractor = null;
      this.selectedSuppliers = [];
      this.secondmentSuppliers = [];
      this.service = "client";
    },

    // Loads the correct client data when a new client is added from the review step.
    onModifyClient(payload) {
      this.selectedClient = payload.selectedClient;
      this.selectedNacecodes = payload.selectedNacecodes;
      this.secondmentSuppliers = payload.secondmentSuppliers;
      this.selectedSuppliers = payload.selectedSuppliers;
      this.selectedContractor = payload.selectedContractor;
      this.secondmentClient = payload.secondmentClient;
      this.dateStart = payload.dateStart;
      this.dateEnd = payload.dateEnd;
      this.service = payload.service;
    },

    onAddClient() {
      this.clientsData.push(this.currentClient);
      this.clearCurrentClient();
      this.jumpToStep(3);
    },

    // Called when an edit action button is clicked on a table. It backs up the current wizard so it can be resumed later.
    onNestedEdit(route) {
      this.handleFormStep(true, route);
    },

    // custom prev function for wizard because of hidden steps.
    handlePrev() {
      const prevStep = this.wizardSteps[this.currentStep - 2];

      if (prevStep.skippable) {
        this.$refs.secondmentCreateWizard.goTo(this.currentStep - 2);
        return;
      }

      this.$refs.secondmentCreateWizard.goTo(this.currentStep - 1);
    },

    // custom next function for wizard because of hidden steps.
    handleNext() {
      const nextStep = this.wizardSteps[this.currentStep];

      if (nextStep.skippable) {
        this.$refs.secondmentCreateWizard.goTo(this.currentStep + 2);
        return;
      }

      this.$refs.secondmentCreateWizard.goTo(this.currentStep + 1);
    },

    jumpToStep(num) {
      this.$refs.secondmentCreateWizard.goTo(num);
      this.$store.commit("wizard/setResumeStep", null);
    },

    // Performs validation and create/update before going to the next wizard step.
    async handleFormStep(next, redirect = null) {
      const currentStepRef = this.$refs.wizardStepRef[this.currentStep - 1];
      const isValid = await currentStepRef.validate();

      if (this.currentStepIs("planner-step")) {
        const plannedEmployees = [];
        this.travelPlans.forEach(plan => {
          plan.employees.forEach(emp => {
            plannedEmployees.push(emp.id);
          });
        });
        const secondmentEmployeesId = this.secondmentEmployees.map(emp => emp.id);

        if (plannedEmployees.length !== secondmentEmployeesId.length) {
          Swal.fire({
            icon: "warning",
            text: "You must Enter all employees in a travel plan",
          });
          return;
        }
      }

      if (!isValid) {
        // find ValidationObserver in current step
        const stepValidationObserver = currentStepRef.$children.find(child => child.$options.name === "ValidationObserver");
        // get errors from ValidationObserver
        const emptyFields = stepValidationObserver?.errors;
        if (emptyFields) {
          let errorsList = Object.values(emptyFields)
            .filter(errorArray => errorArray.length > 0)  // Filter out fields without errors
            .map(errorArray => errorArray[0]);  // Map to array of messages

          await Swal.fire({
            icon: "warning",
            title: this.$t('Missing Required Fields'),
            html: errorsList.join('<br>'),
          });
        }
        return;
      }

      if (this.currentStepIs("planner-step")) {
        this.travelPlans = this.travelPlans.filter(plan => plan?.employees?.length);
      }

      if (this.currentStepIs("employees-step") && this.wizardMode === "edit") {
        this.manageTravelPlans()
      }

      if (this.notAvailableSecondmentEmployees.length) {
        const { isConfirmed } = await Swal.fire({
          title: this.$t("Some employees are not available"),
          text: `${this.notAvailableSecondmentEmployees.map(x => x.name).join(" ")}
                  ${this.notAvailableSecondmentEmployees.length > 1 ? "are" : "is"}
                  ${this.$t("not available, do you wish to continue anyway?")}`,
          icon: "warning",
          confirmButtonText: this.$t("Yes, Continue"),
          showCancelButton: true,
          cancelButtonText: this.$t("No, do NOT continue"),
        });

        if (!isConfirmed) return;
      }

      this.isSaving = true;
      let res = null;
      this.secondmentEmployees.forEach(el => {
        if (el.employee == this.manager) {
          el.manager = true;
        } else {
          el.manager = false;
        }
      });
      const body = await this.makeRequestBody();
      if (this.currentStepIs("nation-step") && !this.id) {
        await SecondmentService.create(body, { returnConvertedTravelPlans: false })
          .then(result => {
            res = result;
            this.secondmentClient.activities = result.clients[0].activities;
            this.secondmentClientId = result.clients[0].id;
          })
          .catch(err => {
            this.errorCatch(err);
          });
      } else {
        await SecondmentService.update(body, this.id, {
          returnConvertedTravelPlans: false,
        }).then(result => {
          res = result;
          this.secondmentClientId = result.clients[0].id;
          this.secondmentClient.activities = result.clients[0].activities;
          if (result.clients?.[0]?.contractors?.length > 0) {
            this.secondmentClient.contractors = result.clients[0].contractors;
          }
          if (result.clients?.[0]?.partners?.length > 0) {
            this.secondmentClient.partners = result.clients[0].partners;
          }
          if (!this.travelPlans.length) {
            this.manageTravelPlans()
          }
        }).catch(err => {
          console.log('@handleFormStep SecondmentService.update', err);
          this.errorCatch(err);
        });
      }

      if (res == null) {
        this.isSaving = false;
        Swal.fire({
          title: "",
          html: this.errors.join("<br/>"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      this.id = res.id;

      // Updates the secondmentEmployees IDs with what's coming from the API.
      res.employees.forEach(resEmp => {
        const secEmp = this.secondmentEmployees.find(secEmp => resEmp.employee == secEmp.employee);
        resEmp.manager = this.manager == secEmp.employee ? true : false;
        secEmp.id = resEmp.id;
      });

      if (!this.currentStepIs("nation-step") && !this.currentStepIs("employees-step")) {
        this.travelPlans = res.travel_plans.map(tp => {
          tp.travel_days = tp.travel_days.map(td => {
            td.places = td.places.sort((a, b) => a.order - b.order);
            return td;
          });
          tp.employees = this.secondmentEmployees.filter(emp => tp.employees.includes(emp.id))
          return tp;
        });
      }

      this.isSaving = false;
      if (redirect) {
        this.$store.commit("wizard/setSecondmentId", this.id);
        this.$store.commit("wizard/setResumeStep", this.$refs.secondmentCreateWizard.getStep());
        redirect.query = { pending: true, edit: this.wizardMode === "edit" };
        this.$router.push(redirect);
      } else {
        next();
      }
    },

    async submit(status = "confirmed") {
      if (this.past) {
        // Await the result of the confirmation Swal
        let swal_text = this.$t("PAST_SECONDMENT_WARNING_TEXT");
        swal_text += "<br/>" + this.$t("PAST_SECONDMENT_WARNING_ELABORATION_TIME", { elaboration_time: this.checkDateStart.elaboration_time, min_start_date: this.checkDateStart.min_start_date });
        swal_text += "<br/>" + this.$t("Do you want to continue?")
        const pastSecondmentConfirm = await confirmationSwal({
          text: swal_text,
          title: this.$t("Confirm Past Secondment"),
          isHtml: true,
        });
        if (!pastSecondmentConfirm.isConfirmed) {
          return;
        }
      }
      if(this.started){
        let swal_text = this.$t("STARTED_SECONDMENT_WARNING_TEXT");
        swal_text += "<br/>" + this.$t("PAST_SECONDMENT_WARNING_ELABORATION_TIME", { elaboration_time: this.checkDateStart.elaboration_time, min_start_date: this.checkDateStart.min_start_date });
        swal_text += "<br/>" + this.$t("Do you want to continue?")
        const startedSecondmentConfirm = await confirmationSwal({
          text: swal_text,
          title: this.$t("Confirm Started Secondment"),
          isHtml: true,
        });
        if (!startedSecondmentConfirm.isConfirmed) {
          return;
        }
      }
      if (this.missingProducts.length) {
        let firstMissingProduct = this.missingProducts[0];
        if (firstMissingProduct.product_type == this.PRODUCT_TYPES.POSTING_CERTIFICATE && firstMissingProduct?.attributes?.certificate_kind == this.POSTING_CERTIFICATE_KIND.ART13) {
          await ART13_EmptySwal(firstMissingProduct.attributes.name, firstMissingProduct.attributes.surname);
          return;
        }
        if (firstMissingProduct.product_type == this.PRODUCT_TYPES.POSTING_CERTIFICATE && firstMissingProduct?.attributes?.certificate_kind == this.POSTING_CERTIFICATE_KIND.ART12) {
          await ART12_SamePostingCountry(firstMissingProduct.attributes.name, firstMissingProduct.attributes.surname);
          return;
        }
      }
      this.isSaving = true;
      this.status = status;
      const body = await this.makeRequestBody();
      await SecondmentService.update(body, this.id).then(() => {
        successToast(this.$t("Assignment created successfully"));
        this.$router.push({ name: "manage-secondment" });
      }).catch(err => {
        this.errorCatch(err);
        Swal.fire({
          title: "",
          html: this.errors.join("<br/>"),
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
      }).finally(() => {
        this.isSaving = false;
      });
    },

    onManagerChange(employeeId) {
      this.manager = employeeId;
    },

    // Generates a SecondmentEmployee array given an Employee employees array.
    onEmployeesChange(employees) {
      this.selectedEmployees = employees;
      if (employees.length == 1) {
        this.onManagerChange(employees[0].id);
      } else if (employees.length == 0) {
        this.onManagerChange(null);
      }
      this.secondmentEmployees = employees.reduce((acc, curr) => {
        const secondmentEmployee = _.find(this.secondmentEmployees, emp =>
          typeof emp == "object" ? emp.employee == curr.id : emp == curr.id,
        );
        if (secondmentEmployee) {
          acc.push(secondmentEmployee);
        } else {
          acc.push({
            cost_reversal: null,
            id: null,
            date_start: this.dateStart,
            date_end: this.dateEnd,
            description: "",
            employee: curr.id,
            manager: this.secondmentManager == curr.id ? true : false,
            name: `${curr.persona.name} ${curr.persona.surname}`,
            avatar: curr.persona.avatar ?? "",
            places: [
              {
                place_kind: "arrival",
                place: curr?.places?.find(el => el.place_kind === "arrival"),
              },
              {
                place_kind: "accommodation",
                place: curr?.places?.find(el => el.place_kind === "accommodation"),
              },
              {
                place_kind: "departure",
                place: curr?.places?.find(el => el.place_kind === "departure"),
              },
            ],
            place_of_arrival: curr.place_of_arrival,
            place_of_departure: curr.place_of_departure,
            housing: curr.housing,
            lunch_place: curr.lunch_place,
            dinner_place: curr.dinner_place,
            notify: true,
            availability_data: curr.availability_data,
            salary_adjustment: curr.salary_adjustment,
          });
        }
        return acc;
      }, []);

      //updates the plan when an employee is removed
      if (this.wizardMode === "edit") {
        const secondmentEmployeesId = this.secondmentEmployees.map(emp => emp.id);
        this.travelPlans.forEach(plan => {
          plan.employees.forEach(emp => {
            const id = typeof emp == "object" ? emp.id : emp;
            if (!secondmentEmployeesId.includes(id)) {
              plan.employees = plan.employees.filter(em => (typeof em == "object" ? em.id !== id : em !== id));
            }
          });
        });
      }
    },

    onSecondmentDescriptionChange(description) {
      this.description = description;
    },

    async onDateToChange(dateTo) {
      this.dateEnd = dateTo;
    },

    onDateFromChange(dateFrom) {
      this.dateStart = dateFrom;
    },

    onSelectedCountryChange(country) {
      if (!this.country) {
        this.country = country;
        return;
      }

      Swal.fire({
        icon: "warning",
        text: this.$t("Changing nation will delete all the assignment data. Do you want to continue?"),
        confirmButtonClass: "btn btn-primary",
        confirmButtonText: this.$t("Continue"),
        showCancelButton: true,
        cancelButtonText: this.$t("Cancel"),
        cancelButtonClass: "btn",
      }).then(res => {
        if (res.isConfirmed) {
          this.reset({ country, id: this.id, dateStart: this.dateStart, dateEnd: this.dateEnd });
        }
      });
    },

    onPastChange(past) {
      this.past = past;
    },
    
    onStartedChange(started) {
      this.started = started;
    },

    onSecondmentEmployeesChange(secondmentEmployees) {
      this.secondmentEmployees = secondmentEmployees;
    },

    onSelectedNacecodesChange(nacecodes) {
      this.selectedNacecodes = nacecodes;
    },

    onServiceChange(service) {
      this.service = service;
    },

    // Generate a SecondmentClient given a Company.
    onSelectedClientChange(client) {
      this.selectedClient = client;
      if (client) {
        let defaultAddressId = null;
        if (client.addresses.length) {
          const companyAddress = client.addresses.find(x => !!x.main);
          if (!companyAddress) {
            defaultAddressId = client.addresses?.[0]?.address?.id || client.addresses?.[0]?.id;
          } else {
            defaultAddressId = companyAddress.address.id;
          }
        }

        this.selectedClient.addresses = this.selectedClient.addresses.map(ad => ad?.address || ad);

        this.secondmentClient = {
          description: "",
          date_start: this.dateStart,
          date_end: this.dateEnd,
          client_object_id: client.id,
          client_content_type: client.content_type,
          address: defaultAddressId,
          notify: client.notify ?? false,
        };
      } else {
        this.secondmentClient = null;
      }
    },

    onSecondmentClientChange(secondmentClient) {
      this.secondmentClient = secondmentClient;
    },

    onSelectedContractorChange(contractor) {
      this.selectedContractor = contractor;
    },

    // Generates SecondmentSupplier array given a Company array
    onSelectedSuppliersChange(suppliers) {
      this.selectedSuppliers = suppliers;

      this.secondmentSuppliers = suppliers.reduce((acc, curr) => {
        const secondmentSuppliers = _.find(this.secondmentSuppliers, { partner: curr.id, kind: curr.kind });
        if (secondmentSuppliers) {
          acc.push(secondmentSuppliers);
        } else {
          acc.push({
            date_start: this.dateStart,
            date_end: this.dateEnd,
            description: "",
            partner_object_id: curr.id,
            partner_content_type: curr.content_type,
            name: curr.name,
            notify: true,
            logo: curr.logo,
          });
        }

        return acc;
      }, []);
    },

    onSecondmentSuppliersChange(secondmentSuppliers) {
      this.secondmentSuppliers = secondmentSuppliers;
    },

    async getSalaries(country) {
      const employeesSalary = {};
      if (!country) return;
      try {
        const { employees_salary_adjustment } = await SecondmentEmployeeService.employeesSalary(country);
        employees_salary_adjustment.forEach(x => {
          employeesSalary[x.employee] = { ...x.salary_adjustment };
        });
        this.employeesSalary = employeesSalary;
      } catch (err) {
        console.error(err);
        backendErrorSwal(this.$t("Failed to get salary data."));
      }
    },

    async getAvailabilities(dateFrom, dateTo) {
      const defaultEmployeesAvailability = {};
      const documentsStatus = {};
      const employeesBusyDates = {};
      const employeeSchengen = {}
      if (!dateFrom || !dateTo || DateService.isBefore(dateTo, dateFrom)) return;
      try {
        const { employees_availability } = await SecondmentEmployeeService.employeesAvailabilityCountry(
          dateFrom,
          dateTo,
          this.country,
        );
        employees_availability.forEach(x => {
          defaultEmployeesAvailability[x.employee] = x.partial_availability
            ? AvailabilityService.Enum.PARTIAL
            : x.is_available
              ? AvailabilityService.Enum.AVAILABLE
              : AvailabilityService.Enum.NOT_AVAILABLE;
          documentsStatus[x.employee] = x.documents;
          employeesBusyDates[x.employee] = x.busy_dates;
          employeeSchengen[x.employee] = x.schengen_check;
        });
        this.defaultEmployeesAvailability = defaultEmployeesAvailability;
        this.employeesDocumentsStatus = documentsStatus;
        this.employeesBusyDates = employeesBusyDates;
        this.employeeSchengen = employeeSchengen;
      } catch (err) {
        console.error(err);
        backendErrorSwal(this.$t("Failed to get availability data."));
      }
    },

    deleteTravelPlan(travelPlan) {
      const idx = this.travelPlans.findIndex(tp => tp == travelPlan);
      if (idx < 0) return;
      this.travelPlans.splice(idx, 1);
    },

    cloneTravelPlan(travelPlan) {
      const newPlan = _.cloneDeep(travelPlan);
      delete newPlan.id;
      newPlan.employees = [];
      newPlan.travel_days = newPlan.travel_days.map(d => {
        d.id = null;
        d.places = d.places.sort((a, b) => a.order - b.order);
        return d;
      });
      this.travelPlans.push(newPlan);
    },

    onSecondmentEmployeeChange(secondmentEmployee) {
      const oldSecEmp = this.secondmentEmployees.find(s => s.employee == secondmentEmployee.employee);
      const selEmp = this.selectedEmployees.find(el => el.id == secondmentEmployee.employee);
      if (secondmentEmployee.date_start !== oldSecEmp.date_start || secondmentEmployee.date_end !== oldSecEmp.date_end) {
        const availability = AvailabilityService.checkAvailability(secondmentEmployee.date_start, secondmentEmployee.date_end, selEmp.availability_data.busy_dates);
        this.customEmployeesAvailability = {
          ...this.customEmployeesAvailability,
          [secondmentEmployee.employee]: availability,
        };
        oldSecEmp.date_start = secondmentEmployee.date_start;
        oldSecEmp.date_end = secondmentEmployee.date_end;

        if (this.isWizardEditMode) {
          this.travelPlans = updateSecondmentEmployeeTravelPlanDates(this.travelPlans, secondmentEmployee);

          Swal.fire({
            text: "Some travel plans might have changed. Make sure to check them again.",
          });
        }
      }
    },

    async updateSecondmentEmployeesAvailabilities() {
      const out = {};
      this.secondmentEmployees.forEach(se => {
        out[se.employee] = this.customEmployeesAvailability[se.employee];
      });

      this.customEmployeesAvailability = out;
    },

    currentStepIs(currentStepStringId) {
      const arrId = this.wizardSteps.findIndex(ws => ws.id == currentStepStringId);
      if (arrId < 0) return false;

      return arrId + 1 == this.currentStep;
    },

    onSecondmentSupplierChange(secondmentSupplier) {
      const ssIdx = this.secondmentSuppliers.findIndex(
        ss =>
          ss.partner_content_type == secondmentSupplier.partner_content_type &&
          ss.partner_object_id == secondmentSupplier.partner_object_id,
      );
      if (ssIdx < 0) return;

      this.secondmentSuppliers.splice(ssIdx, 1, secondmentSupplier);
    },

    reset(valuesToKeep = {}) {
      const reset = { ...DEFAULT_DATA, ...valuesToKeep };
      Object.assign(this.$data, reset);
    },

    changeCostReversal(event) {
      this.secondmentEmployees.forEach(el => {
        if (event.cost_reversal == this.getCurrentManagedCompany.company) {
          el.cost_reversal = null;
          return;
        }
        if (el.employee == event.employee_id) {
          el.cost_reversal = event.cost_reversal;
        }
      });
    },

    setIntragroupClients(clients) {
      this.intragroupClients = clients;
    },

    onUpdateProducts(products) {
      this.products = products
    },

    onUpdateMissingProducts(missingProducts) {
      this.missingProducts = missingProducts
    },

    secondmentEmployeesWithProducts() {
      // Logica da applicare nel caso di edit del cantiere con dei prodotti presenti
      let secondmentEmployees = this.secondmentEmployees;

      if (this.products.length) {
        this.products.forEach(product => {
          switch (product.product_type) {
            // viene gestita solo la possibilità di moduli a1 12 o in alternativa il 13 mai entrambi
            case this.PRODUCT_TYPES.POSTING_CERTIFICATE: {
              if (product.attributes.certificate_kind == this.POSTING_CERTIFICATE_KIND.ART12) {
                // Qui ho il secondment_employee e dovrebbe bastare
                secondmentEmployees.forEach(secondmentEmployee => {
                  if (secondmentEmployee.id == product.attributes.secondment_employee) {
                    secondmentEmployee.requestA1 = true;
                    secondmentEmployee.artKind = "ART12";
                  }
                })
              } else if (product.attributes.certificate_kind == this.POSTING_CERTIFICATE_KIND.ART13) {
                // Qui ho la persona, da cui devo prendere l'employee
                secondmentEmployees.find(secondmentEmployee => {
                  if (product.attributes.secondment_employee == secondmentEmployee.id) {
                    secondmentEmployee.requestA1 = true;
                    secondmentEmployee.artKind = "ART13";
                    secondmentEmployee.formA13 = {
                      from_date: product.attributes.from_date,
                      to_date: product.attributes.to_date,
                      posting_country: product.attributes.posting_country
                    }
                  }
                })
              }
              break;
            }
            case this.PRODUCT_TYPES.TRANSPARENCY_LETTER: {
              // Qui ho il secondment_employee e dovrebbe bastare
              secondmentEmployees.find(secondmentEmployee => {
                if (secondmentEmployee.id == product.attributes.secondment_employee) {
                  secondmentEmployee.TRASP = true;
                }
              })
              break;
            }
          }
        })
      }
      return secondmentEmployees
    },

    updateEmployeeDocuments(documents) {
      this.employeesDocumentsStatus = documents;
    },
    dateStartChecks(data) {
      this.checkDateStart = data;
    },
  },
};
</script>

<style lang="scss" scoped>
.service-title {
  font-size: 1.1rem;
}

.min-vh-60 {
  min-height: 60vh;
}
</style>
