var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('form', [_c('draggable', {
    attrs: {
      "draggable": ".grabbable:not(.undraggable)",
      "options": {
        disabled: _vm.readonly
      }
    },
    on: {
      "start": function start($event) {
        _vm.drag = true;
      },
      "end": function end($event) {
        _vm.drag = false;
      }
    },
    model: {
      value: _vm.orderedPlaces,
      callback: function callback($$v) {
        _vm.orderedPlaces = $$v;
      },
      expression: "orderedPlaces"
    }
  }, _vm._l(_vm.day.places, function (place, idx) {
    return _c('div', {
      key: idx,
      staticClass: "grabbable",
      class: {
        undraggable: ['arrival', 'departure'].includes(place.place_kind)
      }
    }, [place.place_kind ? _c('TravelPlanInput', {
      ref: "place-".concat(idx),
      refInFor: true,
      attrs: {
        "date": _vm.day.date,
        "secondment-employee": _vm.day,
        "field": place.place_kind,
        "title": place.place_kind,
        "map-letter": idx,
        "with-risks": "",
        "disabled": _vm.readonly
      },
      on: {
        "set-place": function setPlace(_ref) {
          var newPlace = _ref.place;
          return _vm.setTravelPlanDayProperty({
            place: place,
            newPlace: newPlace,
            idx: idx
          });
        },
        "open-map": _vm.openPoiMap,
        "remove-place": function removePlace($event) {
          return _vm.$emit('remove-place', $event);
        },
        "get-risk": _vm.onGetRisk
      }
    }) : _vm._e(), !_vm.readonly && !['arrival', 'departure'].includes(place.place_kind) ? _c('div', {
      staticClass: "drag-element text-center"
    }, [_vm._v(" … ")]) : _vm._e()], 1);
  }), 0), _c('b-modal', {
    attrs: {
      "id": "points-of-interest-modal-".concat(_vm._uid),
      "size": "xl",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center"
        }, [_c('h3', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.$t("Select a place")) + " ")]), _c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-button', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showSaveBotton,
            expression: "showSaveBotton"
          }],
          staticClass: "mr-4",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.saveChanges
          }
        }, [_vm._v(_vm._s(_vm.$t("Save Changes")))]), _c('b-button', {
          on: {
            "click": _vm.cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('google-maps-points-of-interest', {
    ref: "childComponentRef",
    attrs: {
      "country": _vm.country,
      "field": _vm.field,
      "map-center": _vm.poiCenter,
      "vehicle": _vm.activeVehicle
    },
    on: {
      "selected-place": _vm.handlePlaceSelect,
      "marker-dragend": _vm.handleDrag
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }