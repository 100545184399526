var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('datatable', {
    ref: "datatableRef",
    attrs: {
      "table-props": _vm.tableProps,
      "total": _vm.total,
      "options": _vm.tableOptions,
      "per-page-options": _vm.perPageOptions,
      "tr-class": _vm.trClasses
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.tableOptions = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "topbar",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_vm.showToggle ? _c('div', [_c('label', {
          staticClass: "checkbox"
        }, [_c('input', {
          directives: [{
            name: "model",
            rawName: "v-model",
            value: _vm.selectedOnly,
            expression: "selectedOnly"
          }],
          attrs: {
            "type": "checkbox"
          },
          domProps: {
            "checked": Array.isArray(_vm.selectedOnly) ? _vm._i(_vm.selectedOnly, null) > -1 : _vm.selectedOnly
          },
          on: {
            "change": [function ($event) {
              var $$a = _vm.selectedOnly,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v);
                if ($$el.checked) {
                  $$i < 0 && (_vm.selectedOnly = $$a.concat([$$v]));
                } else {
                  $$i > -1 && (_vm.selectedOnly = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                }
              } else {
                _vm.selectedOnly = $$c;
              }
            }, _vm.refresh]
          }
        }), _c('span', {
          staticClass: "mr-3"
        }), _vm._v(" " + _vm._s(_vm.$t("Show only selected employees")) + " ")])]) : _vm._e(), _c('div', {
          staticClass: "d-flex"
        }, [_c('div', [_c('euro-input', {
          staticClass: "w-100 mb-0",
          style: _vm.searchBoxStyle,
          attrs: {
            "placeholder": _vm.$t('Search'),
            "debounce": 300
          },
          model: {
            value: _vm.searchFilter,
            callback: function callback($$v) {
              _vm.searchFilter = $$v;
            },
            expression: "searchFilter"
          }
        }, [_c('b-input-group-prepend', [_c('span', {
          staticClass: "input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"
        }, [_c('i', {
          class: "".concat(_vm.icons.search)
        })])])], 1)], 1), _vm.$slots['topbar-end'] ? _c('div', [_vm._t("topbar-end")], 2) : _vm._e()])])];
      },
      proxy: true
    }, {
      key: "header._selected",
      fn: function fn() {
        return [_c('div')];
      },
      proxy: true
    }, {
      key: "header.knownlanguage_set",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("Languages")) + " ")];
      },
      proxy: true
    }, !_vm.readOnly ? {
      key: "cell._selected",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('b-form-checkbox', {
          staticClass: "mt-3",
          attrs: {
            "size": "lg",
            "checked": _vm.isSelected(item.id)
          },
          on: {
            "change": function change($event) {
              return _vm.onCheckboxSelect(item);
            }
          }
        })];
      }
    } : null, {
      key: "cell.name",
      fn: function fn(_ref2) {
        var _item$persona, _item$persona2, _item$persona3, _item$persona4, _item$persona5, _item$persona6;
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('avatar', {
          attrs: {
            "avatar-image": (item === null || item === void 0 ? void 0 : (_item$persona = item.persona) === null || _item$persona === void 0 ? void 0 : _item$persona.avatar) || item.avatar,
            "avatar-text": (item === null || item === void 0 ? void 0 : (_item$persona2 = item.persona) === null || _item$persona2 === void 0 ? void 0 : _item$persona2.name[0]) || item.name[0]
          }
        }), _c('div', {
          staticClass: "ml-3 font-weight-bolder"
        }, [_vm.selectedEmployees.length !== 0 && _vm.manager == item.id ? _c('div', [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$persona3 = item.persona) === null || _item$persona3 === void 0 ? void 0 : _item$persona3.name) || item.name) + " " + _vm._s((item === null || item === void 0 ? void 0 : (_item$persona4 = item.persona) === null || _item$persona4 === void 0 ? void 0 : _item$persona4.surname) || item.surname) + " "), _c('i', {
          staticClass: "fas fa-hard-hat ml-3 text-primary",
          attrs: {
            "title": _vm.$t('Assignment Manager')
          }
        })]) : _c('div', [_vm._v(_vm._s((item === null || item === void 0 ? void 0 : (_item$persona5 = item.persona) === null || _item$persona5 === void 0 ? void 0 : _item$persona5.name) || item.name) + " " + _vm._s((item === null || item === void 0 ? void 0 : (_item$persona6 = item.persona) === null || _item$persona6 === void 0 ? void 0 : _item$persona6.surname) || item.surname))])])], 1)])];
      }
    }, {
      key: "cell.type",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "h-100"
        }, [item.agency == null ? _c('span', {
          staticClass: "font-weight-bold label label-inline label-light-success"
        }, [_vm._v(" " + _vm._s(_vm.$t("Employee")) + " ")]) : _c('span', {
          staticClass: "font-weight-bold label label-inline label-light-warning"
        }, [_vm._v(_vm._s(_vm.$t("Temporary Worker")))])])];
      }
    }, {
      key: "cell.availability",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('EmployeeAvailabilityChip', {
          staticClass: "pointer",
          attrs: {
            "availability": _vm.getEmployeeAvailability(item),
            "hover-msg": _vm.$t('View Gantt'),
            "dates": _vm.getEmployeeDates(item),
            "dates-tooltip": "",
            "tooltip-placement": "right"
          },
          on: {
            "click": function click($event) {
              return _vm.onAvailabilityClick(item);
            }
          }
        })];
      }
    }, !_vm.isAfterSale ? {
      key: "cell.salary",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('EmployeeSalaryChip', {
          attrs: {
            "icon-only": "",
            "tooltip-placement": "left",
            "salary": item.salary_adjustment
          },
          on: {
            "add-contract": function addContract($event) {
              var _item$persona7;
              return _vm.$emit('add-contract', item === null || item === void 0 ? void 0 : (_item$persona7 = item.persona) === null || _item$persona7 === void 0 ? void 0 : _item$persona7.id);
            }
          }
        })];
      }
    } : null, {
      key: "cell.cost_reversal",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [!_vm.readOnly ? _c('div', {
          staticClass: "d-flex"
        }, [_c('b-btn', {
          directives: [{
            name: "b-modal",
            rawName: "v-b-modal.changeCostReversal",
            modifiers: {
              "changeCostReversal": true
            }
          }],
          attrs: {
            "size": "sm",
            "variant": "light-primary",
            "disabled": !_vm.isSelected(item.id),
            "block": ""
          },
          on: {
            "click": function click($event) {
              return _vm.editCostReversal(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon menu-icon"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1), _c('span', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(_vm._s(_vm.costalReversalCompany(item.cost_reversal)))])])], 1) : _c('div', [_vm._v(_vm._s(_vm.costalReversalCompany(item.cost_reversal)))])];
      }
    }, {
      key: "cell.actions",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-light btn-hover-primary btn-sm",
          attrs: {
            "title": _vm.$t('Edit employee')
          },
          on: {
            "click": function click($event) {
              return _vm.onEditBtnClick(item);
            }
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md svg-icon-primary"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Design/Edit.svg"
          }
        })], 1)])];
      }
    }, {
      key: "cell.documents",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('EmployeeDocumentsAvailabilityChip', {
          attrs: {
            "item": item,
            "get-secondment-date": _vm.getSecondmentDate,
            "get-employees-id": _vm.getEmployeesId,
            "check-license-props": _vm.checkLicenseProps
          }
        })];
      }
    }, {
      key: "cell.schengen",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "btn btn-icon btn-sm",
          class: _vm.schengenInfo(item).status,
          attrs: {
            "title": _vm.schengenInfo(item).message
          }
        }, [_c('span', {
          staticClass: "svg-icon svg-icon-md"
        }, [_c('inline-svg', {
          attrs: {
            "src": "/media/svg/icons/Communication/Clipboard-check.svg"
          }
        })], 1)])];
      }
    }], null, true)
  }), _vm.gantt.secondmentEmployee ? _c('SecondmentParticipationModal', {
    attrs: {
      "id": _vm.gantModalId,
      "availability": _vm.gantt.availability,
      "secondment-employee": _vm.gantt.secondmentEmployee,
      "secondment-end-date": _vm.dateTo,
      "secondment-start-date": _vm.dateFrom,
      "disable-edits": _vm.disableEditGant,
      "overlapping-secondments": _vm.gantt.overlappingSecondments
    },
    on: {
      "close": function close($event) {
        return _vm.$bvModal.hide(_vm.gantModalId);
      },
      "hidden": _vm.onGanttModalHidden,
      "secondment-employee-change": _vm.onSecondmentEmployeeChange
    }
  }) : _vm._e(), !_vm.readOnly ? _c('b-modal', {
    attrs: {
      "id": "changeCostReversal",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn(_ref10) {
        var close = _ref10.close;
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('div', [_c('h4', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.$t("Cost reversal")))]), _c('span', {
          staticClass: "text-muted font-weight-bold font-size-sm mt-1"
        }, [_vm._v(" " + _vm._s(_vm.$t("Change the cost reversal for the selected user ")) + " ")])]), _c('div', [_c('b-button', {
          attrs: {
            "size": "sm"
          },
          on: {
            "click": close
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)])];
      }
    }], null, false, 643053590)
  }, [_c('euro-select', {
    staticClass: "m-0 p-0",
    attrs: {
      "multiselect-props": _vm.formattedIntragroupCompanies
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('change-cost-reversal', {
          employee_id: _vm.employeeToEdit.id,
          cost_reversal: $event
        });
      }
    },
    model: {
      value: _vm.employeeToEdit.cost_reversal,
      callback: function callback($$v) {
        _vm.$set(_vm.employeeToEdit, "cost_reversal", $$v);
      },
      expression: "employeeToEdit.cost_reversal"
    }
  })], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }