<template>
  <validation-observer ref="employeesValidationObs">
    <div class="row">
      <div class="col-12">
        <div class="pt-lg-0 pt-5 pb-15 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">{{ $t("Employees participating to the assignment") }}</h3>
          <div class="text-muted font-weight-bold font-size-h4">
            {{ $t("Enter arrivals departures and hosting of employees") }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <div v-for="secondmentEmployee in secondmentEmployees" :key="secondmentEmployee.employee" class="mb-5">
          <SecondmentEmployeeTravelPlanCard :key="secondmentEmployee.employee" :client-address="googleClientAddress"
            :client-coordinates="clientCoordinates" :value="secondmentEmployee"
            :employee="employeesMap[secondmentEmployee.employee]"
            :availability="getAvailabilityEnum(employeesMap[secondmentEmployee.employee])"
            :documents-status="employeesDocumentsStatus[secondmentEmployee.employee]"
            :salary="secondmentEmployee.salary_adjustment" :date-start="dateStart" :date-end="dateEnd"
            :secondment-days="secondmentDatesAll" :customized-days="customizedDays" :country="country"
            @input="onSecondmentEmployeeInput" @copy-to-all="onCopyToAll" @availability-click="onAvailabilityClick">
          </SecondmentEmployeeTravelPlanCard>
        </div>
      </div>
    </div>
    <secondment-participation-modal v-if="gantt.secondmentEmployee" :id="ganttModalId"
      :availability="gantt.availability"
      :secondment-employee="gantt.secondmentEmployee" :secondment-end-date="dateEnd" :secondment-start-date="dateStart"
      :overlapping-secondments="gantt.overlappingSecondments" @close="$bvModal.hide(ganttModalId)" @hidden="onGanttModalHidden"
      @secondment-employee-change="onSecondmentEmployeeChange"></secondment-participation-modal>
  </validation-observer>
</template>

<script>
import addressService from "@/core/services/address.service";
import SecondmentEmployeeTravelPlanCard from "@/view/components/cards/SecondmentEmployeeTravelPlanCard.vue";
import SecondmentParticipationModal from "@/view/components/modals/SecondmentParticipationModal.vue";
import _ from "lodash";
import AvailabilityService from "@/core/services/availability.service";

export default {
  components: {
    SecondmentEmployeeTravelPlanCard,
    SecondmentParticipationModal,
  },

  props: {
    /**
     * An array of secondment employees. These are the objects inside the "employees" array in the secondment object.
     */
    clientAddress: {
      type: Object,
      default: () => null,
    },
    clientCoordinates: {
      type: [Object, null],
      default: () => null,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    employees: {
      type: Array,
      required: true,
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
    secondmentId: {
      type: String,
      default: "",
    },
    dateStart: {
      type: String,
      required: true,
    },
    dateEnd: {
      type: String,
      required: true,
    },
    employeesDocumentsStatus: {
      type: Object,
      required: true,
    },
    country: {
      type: [String, null],
      default: () => null,
    },
  },

  data() {
    return {
      focusing: null,
      gantt: {
        secondmentEmployee: null,
      },
      googleClientAddress: null,
    };
  },

  computed: {
    employeesMap() {
      const out = {};
      this.employees.forEach((emp) => 
      {
        let secEmp= this.secondmentEmployees.find(el => (el.employee==emp.id));
        emp.date_start=secEmp.date_start;
        emp.date_end=secEmp.date_end;
        out[emp.id] = emp
      })
      return out;
    },

    customizedDays() {
      return [{ cssClass: "secondment", dates: [...this.secondmentDatesAll] }];
    },

    ganttModalId() {
      return `gantt-${this._uid}`;
    },
  },

  watch: {
    clientAddress: {
      deep: true,
      handler(val) {
        if (!val) {
          this.googleClientAddress = null;
          return;
        }
        addressService.getGeocodedPlaceFromApiAddress(val?.address || val).then((res) => {
          this.googleClientAddress = res;
        });
      },
    },
  },

  methods: {
    validate() {
      return this.$refs.employeesValidationObs.validate();
    },

    onSecondmentEmployeeInput(secondmentEmployee) {
      this.swapSecondmentEmployeeAndEmit(secondmentEmployee);
    },

    /**
     * Swap the given secondmentEmployee with the equivalent in the secondmentEmployees array.
     * Emits a "secondment-employees-change" event with new secondmentEmployees array when done.
     */
    swapSecondmentEmployeeAndEmit(secondmentEmployee) {
      const secondmentEmployees = [...this.secondmentEmployees];
      const idx = secondmentEmployees.findIndex((se) => se.employee == secondmentEmployee.employee);
      secondmentEmployees[idx] = secondmentEmployee;
      this.$emit("secondment-employees-change", secondmentEmployees);
    },

    onCopyToAll(secondmentEmployee) {
      const v = this.secondmentEmployees.map((x) => ({
        ...x,
        housing: secondmentEmployee.housing,
        places: _.cloneDeep(secondmentEmployee.places),
        place_of_arrival: secondmentEmployee.place_of_arrival,
        place_of_departure: secondmentEmployee.place_of_departure,
        lunch_place: secondmentEmployee.lunch_place,
        dinner_place: secondmentEmployee.dinner_place,
        notify: secondmentEmployee.notify,
      }));
      this.$emit("secondment-employees-change", v);
    },

    async onAvailabilityClick(secondmentEmployee) {
      let employee = this.employees.find(el => (el.id == secondmentEmployee.employee));
      this.gantt.secondmentEmployee = secondmentEmployee;
      this.gantt.availability = this.getAvailabilityEnum(employee);
      this.gantt.overlappingSecondments = AvailabilityService.checkOverlappingSecondments(this.dateStart, this.dateEnd, employee.availability_data.busy_dates);
      await this.$nextTick();
      this.$bvModal.show(this.ganttModalId);
    },

    onSecondmentEmployeeChange(secondmentEmployee) {
      let employee = this.employees.find(el => (el.id == secondmentEmployee.employee));
      employee.date_end = secondmentEmployee.date_end;
      employee.date_start = secondmentEmployee.date_start;
      this.gantt.secondmentEmployee = secondmentEmployee;
      this.gantt.availability = this.getAvailabilityEnum(employee);
      this.$emit("secondment-employee-change", secondmentEmployee);
    },

    onGanttModalHidden() {
      this.gantt.secondmentEmployee = null;
      this.gantt.availability = 0;
      this.gantt.overlappingSecondments = [];
    },

    getAvailabilityEnum(employee) {
      return AvailabilityService.checkAvailability(employee.date_start, employee.date_end, employee.availability_data.busy_dates);
    }
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}
</style>
