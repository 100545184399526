<template>
  <b-card no-body class="d-flex flex-column align-items-center">
    <h6 class="font-weight-bolder h6 text-center my-4" :style="{ wordBreak: 'break-word' }">
      {{ name }}
    </h6>
    <avatar :avatar-image="avatarUrl" :avatar-text="avatarBackup" size="120px" class="mb-3"></avatar>
    <div class="font-weight-bold d-flex align-items-center mb-3">
      <span class="pr-3 form-label">{{ $t("Notifications") }}:</span>
      <b-form-checkbox :disabled="disabled" :checked="notify" switch size="lg"
        @input="$emit('update:notify', $event)"></b-form-checkbox>
    </div>
    <slot name="bottom"></slot>
  </b-card>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    notify: {
      type: Boolean,
      required: false,
    },
    avatarUrl: {
      type: String,
      default: "",
    },
    avatarBackup: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
