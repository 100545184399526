var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('wizard-left', {
    ref: "Legwizard",
    attrs: {
      "clickable-steps": false
    },
    scopedSlots: _vm._u([{
      key: "wizard-nav-left",
      fn: function fn() {
        return [_c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('General information'),
            "desc": _vm.$t('Employer, Physical Person'),
            "icon": "Home/Building.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Renewal dates'),
            "desc": _vm.$t('Enter renewal dates'),
            "icon": "Media/Repeat.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Work location'),
            "desc": _vm.$t('Enter locations employee'),
            "icon": "Home/Building.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Bonuses'),
            "desc": _vm.$t('Salary, Bonuses, Reimbursement'),
            "icon": "Shopping/Euro.svg"
          }
        }), _c('wizard-nav-step-left', {
          attrs: {
            "title": _vm.$t('Completed!'),
            "desc": _vm.$t('Review and Submit'),
            "icon": "General/Like.svg"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn(_ref) {
        var getStep = _ref.getStep;
        return [_c('validation-observer', {
          ref: "mainValidationObserver",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var handleSubmit = _ref2.handleSubmit;
              return [_c('wizard-step-left', [_c('keep-alive', [getStep() === 1 ? _c('validation-observer', {
                ref: "validation-1"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12 mb-10"
              }, [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center"
              }, [_c('div', [_c('h4', {
                staticClass: "mb-0 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Physical Person")))])])])]), _c('div', {
                staticClass: "col-9"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Employee'),
                  "vid": "employee",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref3) {
                    var errors = _ref3.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "asynchronous": "",
                        "fetch-function": _vm.getPhysicalPersons,
                        "limit": 10,
                        "disabled": _vm.disablePpCreation,
                        "label": "".concat(_vm.$t('Physical person')),
                        "error-messages": errors,
                        "searchable": ""
                      },
                      on: {
                        "initial-options-loaded": _vm.selectPhysicalPersonById
                      },
                      model: {
                        value: _vm.form.employee,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "employee", $$v);
                        },
                        expression: "form.employee"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-3"
              }, [_c('div', [_c('b-button', {
                directives: [{
                  name: "b-modal",
                  rawName: "v-b-modal.newEmployeeModal",
                  modifiers: {
                    "newEmployeeModal": true
                  }
                }],
                attrs: {
                  "variant": "light-primary btn-md mt-12 font-weight-bolder",
                  "disabled": _vm.disablePpCreation
                }
              }, [_c('span', {
                staticClass: "svg-icon menu-icon"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/General/User.svg"
                }
              })], 1), _vm._v(" " + _vm._s(_vm.$t("New person")) + " ")])], 1)]), _c('div', {
                staticClass: "col-12"
              }, [_c('h4', {
                staticClass: "mt-10 mb-6 font-weight-bold text-dark"
              }, [_vm._v(" " + _vm._s(_vm.$t("Employee contract details")))])]), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Country'),
                  "vid": "jurisdiction",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref4) {
                    var errors = _ref4.errors;
                    return [_c('euro-select', {
                      ref: "physicalPersonSelect",
                      attrs: {
                        "label": "".concat(_vm.$t('Country'), "*"),
                        "error-messages": errors,
                        "options": _vm.countriesSelectable,
                        "searchable": ""
                      },
                      on: {
                        "input": _vm.onJurisdictionChange
                      },
                      model: {
                        value: _vm.form.jurisdiction,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "jurisdiction", $$v);
                        },
                        expression: "form.jurisdiction"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Language'),
                  "vid": "language",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref5) {
                    var errors = _ref5.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Language'), "*"),
                        "error-messages": errors,
                        "options": _vm.languagesSelectable,
                        "searchable": ""
                      },
                      model: {
                        value: _vm.form.language,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "language", $$v);
                        },
                        expression: "form.language"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-9"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('National contract'),
                  "vid": "national_contract",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref6) {
                    var errors = _ref6.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('National contract'), "*"),
                        "error-messages": [errors.concat(_vm.externalErrorsNationalContract).join(' - ')],
                        "options": _vm.nationalContractsSelectable,
                        "searchable": ""
                      },
                      on: {
                        "input": _vm.onNationalContractChange,
                        "open": _vm.onNationalContractOpen
                      },
                      model: {
                        value: _vm.form.national_contract,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "national_contract", $$v);
                        },
                        expression: "form.national_contract"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-3"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Level'),
                  "vid": "national_contract_level",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref7) {
                    var errors = _ref7.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Level')),
                        "error-messages": errors,
                        "options": _vm.nationalContractLevelsSelectable,
                        "searchable": ""
                      },
                      model: {
                        value: _vm.form.national_contract_level,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "national_contract_level", $$v);
                        },
                        expression: "form.national_contract_level"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-4"
              }, [_c('euro-radio', {
                attrs: {
                  "options": _vm.OPTIONS,
                  "label": "".concat(_vm.$t('Custom currency')),
                  "size": "lg"
                },
                model: {
                  value: _vm.currencyCustom,
                  callback: function callback($$v) {
                    _vm.currencyCustom = $$v;
                  },
                  expression: "currencyCustom"
                }
              })], 1), _c('div', {
                staticClass: "col-8"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Currency'),
                  "vid": "currency",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref8) {
                    var errors = _ref8.errors;
                    return [_c('euro-select', {
                      ref: "currencySelect",
                      attrs: {
                        "disabled": !_vm.currencyCustom,
                        "label": "".concat(_vm.$t('Currency'), "*"),
                        "error-messages": errors,
                        "asynchronous": "",
                        "fetch-function": _vm.getCurrencies,
                        "options": _vm.currenciesSelectable,
                        "searchable": ""
                      },
                      model: {
                        value: _vm.form.currency,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "currency", $$v);
                        },
                        expression: "form.currency"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Attachment'),
                  "vid": "attachment_tus"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref9) {
                    var errors = _ref9.errors;
                    return [_c('euro-tus-file-input', {
                      attrs: {
                        "label": _vm.$t('Attachment'),
                        "error-messages": errors,
                        "accept": "application/pdf",
                        "hint": _vm.$t('Accept .pdf - max size 20MB')
                      },
                      on: {
                        "change": function change(tusId) {
                          _vm.form.attachment_tus = tusId;
                        }
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Notes'),
                  "vid": "description",
                  "rules": "max:200"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref10) {
                    var errors = _ref10.errors;
                    return [_c('euro-text-area', {
                      attrs: {
                        "label": _vm.$t('Notes'),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.description,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "description", $$v);
                        },
                        expression: "form.description"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center"
              }, [_c('div', [_c('h4', {
                staticClass: "mb-0 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Select Worker Category")))])])]), _c('validation-provider', {
                attrs: {
                  "name": _vm.$t('WorkerType'),
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref11) {
                    var errors = _ref11.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "options": _vm.workerType,
                        "label": "".concat(_vm.$t('Worker category'), "*"),
                        "horizontal": "",
                        "error-messages": errors
                      },
                      on: {
                        "input": function input($event) {
                          _vm.form.worker === 'Temporary' ? _vm.selectWorkerType() : 'Employee';
                        }
                      },
                      model: {
                        value: _vm.form.worker,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "worker", $$v);
                        },
                        expression: "form.worker"
                      }
                    })];
                  }
                }], null, true)
              }), _vm.form.worker === 'Temporary' && _vm.form.jurisdiction ? _c('div', [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Counterpart'),
                  "vid": "counterpart",
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref12) {
                    var errors = _ref12.errors;
                    return [_c('euro-select', {
                      attrs: {
                        "label": "".concat(_vm.$t('Counterpart'), "*"),
                        "error-messages": errors,
                        "options": _vm.counterpartListSelectable,
                        "searchable": ""
                      },
                      model: {
                        value: _vm.form.counterpart,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "counterpart", $$v);
                        },
                        expression: "form.counterpart"
                      }
                    })];
                  }
                }], null, true)
              })], 1) : _vm._e(), _vm.form.worker === 'Employee' ? _c('validation-provider', {
                attrs: {
                  "name": _vm.$t('WorkerContract'),
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref13) {
                    var errors = _ref13.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "options": _vm.workerContract,
                        "label": "".concat(_vm.$t('Worker contract'), "*"),
                        "horizontal": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.workerContractSeleted,
                        callback: function callback($$v) {
                          _vm.workerContractSeleted = $$v;
                        },
                        expression: "workerContractSeleted"
                      }
                    })];
                  }
                }], null, true)
              }) : _vm._e()], 1), _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "vid": "start_date",
                  "name": _vm.$t('Employement start date'),
                  "rules": "required"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref14) {
                    var errors = _ref14.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "label": "".concat(_vm.$t('Employement start date'), "*"),
                        "overrides": {
                          useCurrent: false
                        },
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.start_date,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "start_date", $$v);
                        },
                        expression: "form.start_date"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _vm.workerContractSeleted == 'fixed-term' || _vm.form.worker == 'Temporary' ? _c('div', {
                staticClass: "col-6"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Termination date'),
                  "vid": "end_date",
                  "rules": _vm.terminationDateRule
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref15) {
                    var errors = _ref15.errors;
                    return [_c('date-picker', {
                      attrs: {
                        "label": "".concat(_vm.$t('Termination date'), "*"),
                        "error-messages": errors,
                        "overrides": _vm.endDateOverrides
                      },
                      model: {
                        value: _vm.form.end_date,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "end_date", $$v);
                        },
                        expression: "form.end_date"
                      }
                    })];
                  }
                }], null, true)
              })], 1) : _vm._e()])]) : _vm._e()], 1)], 1), _c('wizard-step-left', [_c('keep-alive', [getStep() === 2 ? _c('validation-observer', [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12 mb-10"
              }, [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center"
              }, [_c('div', [_c('h4', {
                staticClass: "mb-0 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Select Renewal Dates")))])]), !_vm.disablePpCreation ? _c('div', [_c('b-button', {
                attrs: {
                  "variant": "light-primary btn-sm"
                },
                on: {
                  "click": _vm.addRenewalDate
                }
              }, [_c('span', {
                staticClass: "svg-icon menu-icon"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Code/Plus.svg"
                }
              })], 1), _vm._v(" " + _vm._s(_vm.$t("Renewal date")) + " ")])], 1) : _vm._e()])])]), _vm._l(_vm.form.renewals, function (renewal, renewalIdx) {
                return _c('div', {
                  key: renewalIdx
                }, [renewalIdx > 0 ? _c('div', [_c('div', {
                  staticClass: "separator separator-dashed my-5"
                })]) : _vm._e(), _c('div', {
                  staticClass: "d-flex"
                }, [_c('div', {
                  staticClass: "flex-grow-1"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Employement start date'),
                    "vid": "date_valid_from[".concat(renewalIdx, "]"),
                    "rules": {
                      after: _vm.form.end_date
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref16) {
                      var errors = _ref16.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "label": _vm.$t('Employement start date'),
                          "error-messages": errors,
                          "overrides": _vm.renewalDateOverrides
                        },
                        model: {
                          value: renewal.renewal_date,
                          callback: function callback($$v) {
                            _vm.$set(renewal, "renewal_date", $$v);
                          },
                          expression: "renewal.renewal_date"
                        }
                      })];
                    }
                  }], null, true)
                })], 1), _c('div', {
                  staticClass: "flex-grow-1 px-6"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Termination date'),
                    "vid": "date_valid_to[".concat(renewalIdx, "]"),
                    "rules": {
                      after: renewal.start_date
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref17) {
                      var errors = _ref17.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "label": _vm.$t('Termination date'),
                          "overrides": {
                            useCurrent: false
                          },
                          "error-messages": errors
                        },
                        model: {
                          value: renewal.end_date,
                          callback: function callback($$v) {
                            _vm.$set(renewal, "end_date", $$v);
                          },
                          expression: "renewal.end_date"
                        }
                      })];
                    }
                  }], null, true)
                })], 1), _c('div', {
                  staticClass: "flex-grow-0"
                }, [_c('div', {
                  staticClass: "form-row"
                }, [_c('label', {
                  staticClass: "col-12 col-form-label"
                }, [_vm._v(" ")]), _c('b-btn', {
                  attrs: {
                    "variant": "light",
                    "disabled": _vm.form.renewals.length == 1
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeRenewalDate(renewalIdx);
                    }
                  }
                }, [_c('span', {
                  staticClass: "svg-icon mr-0"
                }, [_c('inline-svg', {
                  attrs: {
                    "src": "/media/svg/icons/Code/Minus.svg"
                  }
                })], 1)])], 1)])])]);
              })], 2) : _vm._e()], 1)], 1), _c('wizard-step-left', [_c('keep-alive', [getStep() === 3 ? _c('validation-observer', {
                ref: "validation-3"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12 mb-10"
              }, [_c('div', {
                staticClass: "d-flex justify-content-between align-items-center"
              }, [_c('div', [_c('h4', {
                staticClass: "mb-0 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Set work location")))])]), _c('div', [_c('b-button', {
                attrs: {
                  "variant": "light-primary btn-sm",
                  "block": ""
                },
                on: {
                  "click": _vm.addWorkLocation
                }
              }, [_c('span', {
                staticClass: "svg-icon"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Code/Plus.svg"
                }
              })], 1), _vm._v(" " + _vm._s(_vm.$t("Work location")) + " ")])], 1)])])]), _vm._l(_vm.form.work_locations, function (location, locationIdx) {
                return _c('div', {
                  key: locationIdx,
                  staticClass: "from row"
                }, [locationIdx > 0 ? _c('div', {
                  staticClass: "col-12"
                }, [_c('div', {
                  staticClass: "separator separator-dashed my-5"
                })]) : _vm._e(), _c('div', {
                  staticClass: "col-12"
                }, [_c('div', {
                  staticClass: "d-flex align-items-center"
                }, [_c('h6', {
                  staticClass: "mb-0 font-weight-bold text-dark"
                }, [_vm._v(_vm._s(_vm.$t("Location")))]), _vm.form.work_locations.length > 1 ? _c('b-button', {
                  attrs: {
                    "variant": "light-danger btn-sm ml-6",
                    "disabled": _vm.form.work_locations.length < 2
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeWorkLocation(locationIdx);
                    }
                  }
                }, [_c('span', {
                  staticClass: "svg-icon"
                }, [_c('inline-svg', {
                  attrs: {
                    "src": "/media/svg/icons/Code/Minus.svg"
                  }
                })], 1), _vm._v(" " + _vm._s(_vm.$t("Remove")) + " ")]) : _vm._e()], 1)]), _c('div', {
                  staticClass: "col-12"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Work location'),
                    "vid": "work_location[".concat(locationIdx, "]"),
                    "rules": "required"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref18) {
                      var errors = _ref18.errors;
                      return [_c('euro-select', {
                        staticClass: "mb-2",
                        attrs: {
                          "label": "".concat(_vm.$t('Work location'), "*"),
                          "error-messages": errors,
                          "options": _vm.companyAddressesSelectable,
                          "searchable": ""
                        },
                        model: {
                          value: location.work_location,
                          callback: function callback($$v) {
                            _vm.$set(location, "work_location", $$v);
                          },
                          expression: "location.work_location"
                        }
                      })];
                    }
                  }], null, true)
                }), _c('span', {
                  staticClass: "d-flex align-items-center"
                }, [_c('p', {
                  staticClass: "text-muted mt-4"
                }, [_vm._v(" " + _vm._s(_vm.$t("Can you not find the address?")) + " ")]), _c('b-btn', {
                  staticClass: "btn-xs ml-2",
                  attrs: {
                    "variant": "light-primary"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.showAddressModal(locationIdx);
                    }
                  }
                }, [_c('span', {
                  staticClass: "svg-icon menu-icon"
                }, [_c('inline-svg', {
                  attrs: {
                    "src": "/media/svg/icons/Code/Plus.svg"
                  }
                })], 1), _c('span', {
                  staticClass: "font-weight-bolder"
                }, [_vm._v(_vm._s(_vm.$t("Add New Address")))])])], 1)], 1), _c('div', {
                  staticClass: "col-12"
                }, [_c('h6', {
                  staticClass: "mb-5 mt-3 font-weight-bold text-dark"
                }, [_vm._v(_vm._s(_vm.$t("Type work")))])]), _c('div', {
                  staticClass: "col-12"
                }, [_c('euro-checkbox', {
                  attrs: {
                    "text": _vm.$t('Remote work'),
                    "is-switch": "",
                    "size": "lg"
                  },
                  model: {
                    value: location.remote_work,
                    callback: function callback($$v) {
                      _vm.$set(location, "remote_work", $$v);
                    },
                    expression: "location.remote_work"
                  }
                })], 1), _c('div', {
                  staticClass: "col-12"
                }, [_c('h6', {
                  staticClass: "mb-5 mt-3 font-weight-bold text-dark"
                }, [_vm._v(_vm._s(_vm.$t("Work location time period")))])]), _c('div', {
                  staticClass: "col-12"
                }, [_c('euro-checkbox', {
                  staticClass: "mt-2",
                  attrs: {
                    "text": _vm.$t('Permanent'),
                    "is-switch": "",
                    "size": "lg"
                  },
                  on: {
                    "change": function change($event) {
                      return _vm.onLocationPermanentChange(location);
                    }
                  },
                  model: {
                    value: location.is_permanent,
                    callback: function callback($$v) {
                      _vm.$set(location, "is_permanent", $$v);
                    },
                    expression: "location.is_permanent"
                  }
                })], 1), !_vm.isPermanent ? _c('div', {
                  staticClass: "col-12"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Valid from'),
                    "vid": "valid_from[".concat(locationIdx, "]")
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref19) {
                      var errors = _ref19.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "label": _vm.$t('Valid from'),
                          "disabled": location.is_permanent,
                          "error-messages": errors,
                          "overrides": _vm.locationValidFromDateOverrides
                        },
                        model: {
                          value: location.valid_from,
                          callback: function callback($$v) {
                            _vm.$set(location, "valid_from", $$v);
                          },
                          expression: "location.valid_from"
                        }
                      })];
                    }
                  }], null, true)
                })], 1) : _vm._e(), !_vm.isPermanent ? _c('div', {
                  staticClass: "col-12"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Valid to'),
                    "vid": "valid_to[".concat(locationIdx, "]"),
                    "rules": "after:@valid_from[".concat(locationIdx, "]")
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref20) {
                      var errors = _ref20.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "disabled": location.is_permanent,
                          "label": _vm.$t('Valid to'),
                          "overrides": {
                            useCurrent: false
                          },
                          "error-messages": errors
                        },
                        model: {
                          value: location.valid_to,
                          callback: function callback($$v) {
                            _vm.$set(location, "valid_to", $$v);
                          },
                          expression: "location.valid_to"
                        }
                      })];
                    }
                  }], null, true)
                })], 1) : _vm._e()]);
              })], 2) : _vm._e()], 1)], 1), _c('wizard-step-left', [_c('keep-alive', [getStep() === 4 ? _c('validation-observer', {
                ref: "validation-4"
              }, [_c('div', {
                staticClass: "form row"
              }, [_c('div', {
                staticClass: "col-12 mb-10"
              }, [_c('h4', {
                staticClass: "font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Salary conditions")))])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Monthly superminimum'),
                  "vid": "monthly_superminimum",
                  "tag": "div",
                  "rules": {
                    required: true,
                    min_value: 0
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref21) {
                    var errors = _ref21.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "type": "number",
                        "step": "any",
                        "horizontal": "",
                        "label": "".concat(_vm.$t('Monthly superminimum'), " (").concat(_vm.form.currency, ")"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.monthly_superminimum,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "monthly_superminimum", $$v);
                        },
                        expression: "form.monthly_superminimum"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Advanced seniority pay'),
                  "vid": "advanced_seniority_pay",
                  "rules": {
                    required: true,
                    min_value: 0
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref22) {
                    var errors = _ref22.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "type": "number",
                        "step": "any",
                        "horizontal": "",
                        "label": "".concat(_vm.$t('Advanced seniority pay'), " (").concat(_vm.form.currency, ")"),
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.advanced_seniority_pay,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "advanced_seniority_pay", $$v);
                        },
                        expression: "form.advanced_seniority_pay"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Daily compensation'),
                  "vid": "daily_posting_compensation",
                  "rules": {
                    required: true,
                    min_value: 0
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref23) {
                    var errors = _ref23.errors;
                    return [_c('euro-input', {
                      attrs: {
                        "type": "number",
                        "label": "".concat(_vm.$t('Daily compensation'), " (").concat(_vm.form.currency, ")"),
                        "step": "any",
                        "horizontal": "",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.daily_posting_compensation,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "daily_posting_compensation", $$v);
                        },
                        expression: "form.daily_posting_compensation"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('div', {
                staticClass: "separator separator-dashed mt-5 mb-10"
              })]), _c('div', {
                staticClass: "col-12"
              }, [_c('h5', {
                staticClass: "mb-4 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Travel and posting abroad reimbursement")))]), _c('p', {
                staticClass: "text-muted"
              }, [_vm._v(" " + _vm._s(_vm.$t("Is the employee entitled to reimbursement of expenses when travelling abroad?"))), _c('br')])]), _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Reimbursement'),
                  "vid": "reimbursment"
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref24) {
                    var errors = _ref24.errors;
                    return [_c('euro-radio', {
                      attrs: {
                        "error-messages": errors,
                        "options": _vm.reimbursmentSelectable
                      },
                      model: {
                        value: _vm.form.reimbursment,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "reimbursment", $$v);
                        },
                        expression: "form.reimbursment"
                      }
                    })];
                  }
                }], null, true)
              })], 1), _c('div', {
                staticClass: "col-12"
              }, [_c('div', {
                staticClass: "separator separator-dashed mt-0 mb-10"
              })]), _vm.form.reimbursment !== 'NOT' ? _c('div', {
                staticClass: "col-12"
              }, [_c('h5', {
                staticClass: "mb-4 font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Travel Compensation")))])]) : _vm._e(), _vm.form.reimbursment !== 'NOT' ? _c('div', {
                staticClass: "col-12"
              }, [_c('validation-provider', {
                attrs: {
                  "name": _vm.$t('Travel compensation amount'),
                  "vid": "travel_compensation_amount",
                  "rules": {
                    required: true,
                    min_value: 0,
                    max_value: 99999999
                  }
                },
                scopedSlots: _vm._u([{
                  key: "default",
                  fn: function fn(_ref25) {
                    var errors = _ref25.errors;
                    return [_c('euro-input', {
                      staticClass: "mr-5",
                      attrs: {
                        "disabled": _vm.form.reimbursment === 'NOT',
                        "label": "".concat(_vm.$t('Amount'), " (").concat(_vm.form.currency, ")"),
                        "type": "number",
                        "step": "any",
                        "error-messages": errors
                      },
                      model: {
                        value: _vm.form.travel_compensation_amount,
                        callback: function callback($$v) {
                          _vm.$set(_vm.form, "travel_compensation_amount", $$v);
                        },
                        expression: "form.travel_compensation_amount"
                      }
                    })];
                  }
                }], null, true)
              })], 1) : _vm._e(), _vm.form.reimbursment !== 'NOT' ? _c('div', {
                staticClass: "col-12"
              }, [_c('div', {
                staticClass: "separator separator-dashed mt-0 mb-10"
              })]) : _vm._e(), _c('div', {
                staticClass: "col-12 mb-4"
              }, [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', [_c('h5', {
                staticClass: "font-weight-bold text-dark"
              }, [_vm._v(_vm._s(_vm.$t("Bonus")))]), _c('p', {
                staticClass: "text-muted"
              }, [_vm._v(_vm._s(_vm.$t("An extra payment that can be awarded occasionally.")))])]), _c('div', [_c('b-btn', {
                attrs: {
                  "variant": "light-primary btn-sm ml-4"
                },
                on: {
                  "click": _vm.addBonus
                }
              }, [_c('span', {
                staticClass: "svg-icon"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Code/Plus.svg"
                }
              })], 1), _vm._v(" " + _vm._s(_vm.$t("Add Bonus")) + " ")])], 1)])]), _c('div', {
                staticClass: "col-12"
              }, _vm._l(_vm.form.bonuses, function (bonus, bonusIdx) {
                return _c('div', {
                  key: bonusIdx
                }, [bonusIdx > 0 ? _c('div', {
                  staticClass: "w-100"
                }, [_c('div', {
                  staticClass: "separator separator-dashed my-2"
                })]) : _vm._e(), _c('div', {
                  staticClass: "d-flex"
                }, [_c('div', {
                  staticClass: "flex-grow-1"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Bonus'),
                    "vid": "bonus[".concat(bonusIdx, "]"),
                    "rules": "required|min_value:0"
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref26) {
                      var errors = _ref26.errors;
                      return [_c('euro-input', {
                        attrs: {
                          "type": "number",
                          "step": "any",
                          "label": "".concat(_vm.$t('Amount'), " (").concat(_vm.form.currency, ")"),
                          "error-messages": errors
                        },
                        model: {
                          value: bonus.bonus,
                          callback: function callback($$v) {
                            _vm.$set(bonus, "bonus", $$v);
                          },
                          expression: "bonus.bonus"
                        }
                      })];
                    }
                  }], null, true)
                })], 1), _c('div', {
                  staticClass: "flex-grow-1 px-4"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Valid from'),
                    "vid": "bonus_valid_from[".concat(bonusIdx, "]"),
                    "rules": {
                      required: bonus.bonus
                    }
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref27) {
                      var errors = _ref27.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "label": _vm.$t('Valid from'),
                          "error-messages": errors,
                          "overrides": _vm.bonusValidFromDateOverrides
                        },
                        model: {
                          value: bonus.bonus_valid_from,
                          callback: function callback($$v) {
                            _vm.$set(bonus, "bonus_valid_from", $$v);
                          },
                          expression: "bonus.bonus_valid_from"
                        }
                      })];
                    }
                  }], null, true)
                })], 1), _c('div', {
                  staticClass: "flex-grow-1 pr-4"
                }, [_c('validation-provider', {
                  attrs: {
                    "name": _vm.$t('Valid to'),
                    "vid": "bonus_valid_to[".concat(bonusIdx, "]"),
                    "rules": "after:@bonus_valid_from[".concat(bonusIdx, "]")
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function fn(_ref28) {
                      var errors = _ref28.errors;
                      return [_c('date-picker', {
                        attrs: {
                          "label": _vm.$t('Valid to'),
                          "overrides": {
                            useCurrent: false
                          },
                          "error-messages": errors
                        },
                        model: {
                          value: bonus.bonus_valid_to,
                          callback: function callback($$v) {
                            _vm.$set(bonus, "bonus_valid_to", $$v);
                          },
                          expression: "bonus.bonus_valid_to"
                        }
                      })];
                    }
                  }], null, true)
                })], 1), _c('div', {
                  staticClass: "flex-grow-0"
                }, [_c('div', {
                  staticClass: "form-row"
                }, [_c('label', {
                  staticClass: "col-12 col-form-label"
                }, [_vm._v(" ")]), _c('b-btn', {
                  staticClass: "d-flex align-items-center",
                  attrs: {
                    "variant": "danger"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.removeBonus(bonusIdx);
                    }
                  }
                }, [_c('span', {
                  staticClass: "svg-icon mr-2"
                }, [_c('inline-svg', {
                  attrs: {
                    "src": "/media/svg/icons/Code/Minus.svg"
                  }
                })], 1), _c('span', [_vm._v(_vm._s(_vm.$t("Remove")))])])], 1)])])]);
              }), 0)])]) : _vm._e()], 1)], 1), _c('wizard-step-left', [getStep() === 5 ? _c('div', [_c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Subjects")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Employer")) + ": " + _vm._s(_vm.selectedEmployerExtended))])]), _vm.form.worker && _vm.selectedCounterpartExtended ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Counterpart")) + ": " + _vm._s(_vm.selectedCounterpartExtended))])]) : _vm._e(), _vm.getEmployeeName ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Employee")) + ": " + _vm._s(_vm.getEmployeeName))])]) : _vm._e()]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Contract")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_vm.form.name ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Notes")) + ": " + _vm._s(_vm.form.name))])]) : _vm._e(), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Country")) + ": " + _vm._s(_vm.selectedJurisdictionExtended))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("National contract")) + ": " + _vm._s(_vm.getNationalContractName))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Level")) + ": " + _vm._s(_vm.getNationalContractLevelName))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Currency")) + ": " + _vm._s(_vm.selectedCurrencyExtended))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Language")) + ": " + _vm._s(_vm.selectedLanguageExtended))])]), _c('div', [_vm.workerContractSeleted == 'permanent' ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Contract type: permanent")))])]) : _vm._e()])]), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Contract Dates")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Employement start date")) + ": " + _vm._s(_vm.DateService.format(_vm.form.start_date, _vm.dateFormat)))])]), _vm.form.end_date ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Termination date")) + ": " + _vm._s(_vm.form.end_date ? _vm.DateService.format(_vm.form.end_date, _vm.dateFormat) : ""))])]) : _vm._e()]), _vm.workerContractSeleted != 'permanent' ? _c('div', _vm._l(_vm.form.renewals, function (renewal, idx) {
                return _c('div', {
                  key: "renewal-".concat(idx)
                }, [_c('div', {
                  staticClass: "separator separator-dashed my-5"
                }), _c('h6', {
                  staticClass: "font-weight-bolder mb-3"
                }, [_vm._v(_vm._s(_vm.$t("Renewal date")) + " #" + _vm._s(idx + 1))]), _c('div', {
                  staticClass: "text-dark-50 line-height-lg"
                }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("From")) + ": " + _vm._s(renewal.renewal_date ? _vm.DateService.format(renewal.renewal_date, _vm.dateFormat) : ""))])]), _c('div', [_c('span', [_vm._v(" " + _vm._s(_vm.$t("To")) + ": " + _vm._s(renewal.end_date ? _vm.DateService.format(renewal.end_date, _vm.dateFormat) : _vm.$t("Permanent")))])])])]);
              }), 0) : _vm._e(), _vm._l(_vm.form.work_locations, function (location, idx) {
                return _c('div', {
                  key: "location-".concat(idx)
                }, [_c('div', {
                  staticClass: "separator separator-dashed my-5"
                }), _c('h6', {
                  staticClass: "font-weight-bolder mb-3"
                }, [_vm._v(_vm._s(_vm.$t("Work location")) + " #" + _vm._s(idx + 1))]), _c('div', {
                  staticClass: "text-dark-50 line-height-lg"
                }, [location.address ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Address")) + ": " + _vm._s(location.address))])]) : _vm._e(), location.remote_work ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Remote work")))])]) : _vm._e(), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid from")) + ": " + _vm._s(location.valid_from ? _vm.DateService.format(location.valid_from, _vm.dateFormat) : _vm.DateService.format(_vm.form.start_date, _vm.dateFormat)))])]), location.is_permanent ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Permanent working location")))])]) : _vm._e()])]);
              }), _c('div', {
                staticClass: "separator separator-dashed my-5"
              }), _c('h6', {
                staticClass: "font-weight-bolder mb-3"
              }, [_vm._v(_vm._s(_vm.$t("Bonuses")))]), _c('div', {
                staticClass: "text-dark-50 line-height-lg"
              }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Travel and Posting Abroad Reimbursement")) + ": " + _vm._s(_vm.selectedReimbursmentExtended))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Travel and Posting Abroad Compensation")) + ": " + _vm._s(_vm.form.travel_compensation_amount) + " " + _vm._s(_vm.form.currency))])])]), _vm._l(_vm.form.bonuses, function (bonus, idx) {
                return _c('div', {
                  key: "bonus-".concat(idx)
                }, [_c('div', {
                  staticClass: "separator separator-dashed my-5"
                }), _c('h6', {
                  staticClass: "font-weight-bolder mb-3"
                }, [_vm._v(_vm._s(_vm.$t("Bonus")) + " #" + _vm._s(idx + 1))]), _c('div', {
                  staticClass: "text-dark-50 line-height-lg"
                }, [_c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Amount")) + ": " + _vm._s(bonus.bonus) + " " + _vm._s(_vm.form.currency))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid from")) + ": " + _vm._s(_vm.DateService.format(bonus.bonus_valid_from, _vm.dateFormat)))])]), _c('div', [_c('span', [_vm._v(_vm._s(_vm.$t("Valid to")) + ": " + _vm._s(_vm.DateService.format(bonus.bonus_valid_to, _vm.dateFormat)))])])])]);
              })], 2) : _vm._e()]), _c('wizard-actions-left', [_c('div', {
                staticClass: "mr-2"
              }, [_c('wizard-prev-btn', {
                on: {
                  "click": _vm.handlePrev
                }
              })], 1), _c('div', [_c('wizard-submit-btn', {
                attrs: {
                  "disabled": _vm.isSaving,
                  "label": _vm.$t('Save')
                },
                on: {
                  "click": function click($event) {
                    return handleSubmit(_vm.submit);
                  }
                }
              }, [_c('span', {
                staticClass: "svg-icon svg-icon-md mr-2"
              }, [_c('inline-svg', {
                attrs: {
                  "src": "/media/svg/icons/Navigation/Check.svg"
                }
              })], 1)]), _c('wizard-next-btn', {
                on: {
                  "click": _vm.handleNext
                }
              })], 1)])];
            }
          }], null, true)
        })];
      }
    }])
  }), _c('b-modal', {
    ref: "newEmployeeModal",
    attrs: {
      "id": "newEmployeeModal",
      "size": "xl",
      "title": "Add New Employee",
      "hide-footer": ""
    },
    on: {
      "hidden": _vm.onPhysicalPersonModalHidden
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("New person")) + " ")]), _c('b-button', {
          on: {
            "click": function click() {
              return _vm.$refs.newEmployeeModal.hide();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('physical-person-create-wizard', {
    attrs: {
      "nations": _vm.countries,
      "error-messages": _vm.physicalPersonWizardErrors
    },
    on: {
      "submit": _vm.onPhysicalPersonCreateSubmit
    }
  })], 1), _vm._l(_vm.form.work_locations, function (location, locationIdx) {
    return [_c('b-modal', {
      key: locationIdx,
      ref: "modal-address-".concat(locationIdx),
      refInFor: true,
      attrs: {
        "hide-footer": "",
        "size": "lg"
      },
      on: {
        "hidden": _vm.onAddressModalHidden
      },
      scopedSlots: _vm._u([{
        key: "modal-header",
        fn: function fn() {
          return [_c('div', {
            staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
          }, [_c('h3', [_vm._v(" " + _vm._s(_vm.$t("Select an address")) + " ")]), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('b-button', {
            staticClass: "mr-2",
            attrs: {
              "variant": "success"
            },
            on: {
              "click": function click($event) {
                return _vm.onAddressSubmit(location, locationIdx);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("Save Changes")) + " ")]), _c('b-button', {
            on: {
              "click": function click($event) {
                return _vm.closeAddressModal(locationIdx);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)])];
        },
        proxy: true
      }], null, true)
    }, [_c('validation-observer', {
      ref: "newAddressValidator-".concat(locationIdx),
      refInFor: true
    }, [_c('validation-provider', {
      attrs: {
        "name": _vm.$t('Address'),
        "vid": "address",
        "rules": "required"
      },
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref29) {
          var errors = _ref29.errors;
          return [_c('AddressInput', {
            attrs: {
              "manual-address": _vm.addressForm.manual_address,
              "active-step": true,
              "error-messages": errors
            },
            on: {
              "change-manual-address": _vm.changeManualAddress,
              "update-composed-address": _vm.updateComposedAddress
            },
            model: {
              value: _vm.addressForm.address,
              callback: function callback($$v) {
                _vm.$set(_vm.addressForm, "address", $$v);
              },
              expression: "addressForm.address"
            }
          })];
        }
      }], null, true)
    })], 1)], 1)];
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }