var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "d-flex flex-column align-items-center",
    attrs: {
      "no-body": ""
    }
  }, [_c('h6', {
    staticClass: "font-weight-bolder h6 text-center my-4",
    style: {
      wordBreak: 'break-word'
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('avatar', {
    staticClass: "mb-3",
    attrs: {
      "avatar-image": _vm.avatarUrl,
      "avatar-text": _vm.avatarBackup,
      "size": "120px"
    }
  }), _c('div', {
    staticClass: "font-weight-bold d-flex align-items-center mb-3"
  }, [_c('span', {
    staticClass: "pr-3 form-label"
  }, [_vm._v(_vm._s(_vm.$t("Notifications")) + ":")]), _c('b-form-checkbox', {
    attrs: {
      "disabled": _vm.disabled,
      "checked": _vm.notify,
      "switch": "",
      "size": "lg"
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('update:notify', $event);
      }
    }
  })], 1), _vm._t("bottom")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }