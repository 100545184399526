var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "hide-footer": "",
      "size": "xl"
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.$emit('hidden', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Assignment participation Gantt chart")) + ": " + _vm._s(_vm.secondmentEmployee.name))]), _c('div', [!_vm.disableEdits && _vm.isChange ? _c('b-button', {
          staticClass: "mr-2",
          attrs: {
            "variant": "success"
          },
          on: {
            "click": _vm.save
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Change")) + " ")]) : _vm._e(), _c('b-button', {
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Close")) + " ")])], 1)])];
      },
      proxy: true
    }])
  }, [_c('SecondmentEmployeeGanttChart', {
    attrs: {
      "secondment-employee": _vm.secondmentEmployee,
      "secondment-start-date": _vm.secondmentStartDate,
      "secondment-end-date": _vm.secondmentEndDate,
      "secondment-employee-availability": _vm.availability,
      "disable-edits": _vm.disableEdits,
      "overlapping-secondments": _vm.overlappingSecondments
    },
    on: {
      "secondment-employee-change": _vm.editDays
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }