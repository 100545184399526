<template>
  <div v-b-tooltip.hover class="font-weight-bold label label-inline m-1 py-4" :class="labelClasses" :title="tooltipText"
    style="min-width: 39px">
    <i :class="selectedDocumentIcon" :style="iconStyle" @click="showGantt"></i>
    <b-modal ref="ganttModal" size="xl" hide-footer>
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
          <h3>{{ getDocumentText }}</h3>
          <b-button @click="closeModal">
            {{ $t("Cancel") }}
          </b-button>
        </div>
      </template>
      <gantt-chart v-if="ganttData" :data="ganttData"></gantt-chart>
    </b-modal>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
import GanttChart from "@/view/components/charts/GanttChart.vue";
export default {
  components: {
    GanttChart,
  },
  props: {
    documentType: {
      type: String,
      required: true,
    },
    document: {
      type: Object,
      default: () => { },
    },
    postingType: {
      type: Boolean,
      default: false,
    },
    postingValidity: {
      type: Array,
      default: () => [],
    },
    employeeDate: {
      type: Object,
      default: () => { }
    },
    secondmentDate: {
      type: Object,
      default: () => { }
    }
  },
  data() {
    return {
      icons,
      documentLabels: {
        certificate_suitability_for_work: "Certificate Suitability For Work",
        employment_contract: "Employee Contract",
        europeanhealthinsurancecard: "European Insurance",
        identity_document: "ID",
        last_paycheck: "Last Paycheck",
        passport: "Passport",
        postingcertificate: "A1"
      },
      documentIcons: {
        certificate_suitability_for_work: icons.fileBriefcaseInsurance,
        employment_contract: icons.fileContract,
        europeanhealthinsurancecard: icons.fileInsurance,
        identity_document: icons.idDocument,
        last_paycheck: icons.invoiceDollar,
        passport: icons.passport,
        postingcertificate: icons.fileAlt,
      },
      colors: {
        'active': '#00A9BD',
        'valid': '#00A9BD',
        'no-document': '#999',
        'expired': '#f44336',
        'expiring': '#ffa800'
      },
      ganttData: null
    };
  },
  computed: {
    getDocumentText() {
      return this.documentLabels[this.documentType] || "Document"
    },
    getValidity() {
      return this.document ? this.document.status : 'no-document';
    },
    checkValidity() {
      let validity = this.getValidity
      if (this.postingType) {
        this.postingValidity.forEach(element => {
          if (element.status == 'valid') {
            validity = element.status
          }
        });
      }
      return validity
    },
    labelClasses() {
      const colorsClass = {
        '#00A9BD': 'label-light-success',
        '#999': 'label-light',
        '#f44336': 'label-light-danger',
        '#ffa800': 'label-light-warning',
      };
      return {
        'font-weight-bold': true,
        'label': true,
        'label-inline': true,
        'm-1': true,
        'py-4': true,
        [colorsClass[this.getColor]]: true,
      };
    },
    tooltipText() {
      let validity = this.getValidity;
      if (this.postingType) {
        let resultA12 = null
        let resultA13 = null
        let resultA16 = null
        let label = []
        this.postingValidity.forEach((element, index) => {
          // i know this is not beautiful but is low effort solution
          // 0 position art 12
          // 1 position art 13
          // 2 position art 16
          if (index == 0) {
            resultA12 = (element.status == 'no-document' ? 'not found' : element.status)
            label.push(`Art 12: ${this.$t(resultA12)}`)
          }
          if (index == 1) {
            resultA13 = (element.status == 'no-document' ? 'not found' : element.status)
            label.push(`Art 13: ${this.$t(resultA13)}`)
          }
          if (index == 2) {
            resultA16 = (element.status == 'no-document' ? 'not found' : element.status)
            label.push(`Art 16: ${this.$t(resultA16)}`)
          }
        });
        return label.join(' - ')
      }

      const documentText = this.getDocumentText
      switch (validity) {
        case 'valid':
          return `Valid ${documentText}`;
        case 'expired':
          return `Expired ${documentText}`;
        case 'expiring':
          return `Expiring ${documentText}`;
        default:
          return `No ${documentText} found`;
      }
    },
    iconStyle() {
      return {
        cursor: 'pointer',
        color: this.getColor,
        display: 'inline-block',
      };
    },
    selectedDocumentIcon() {
      return this.documentIcons[this.documentType];
    },
    getColor() {
      return this.colors[this.checkValidity];
    }
  },
  methods: {
    getGantt() {
      this.ganttData = [
        {
          label: this.getDocumentText,
          dateStart: this.document.document.date_start,
          dateEnd: this.document.document.date_end,
          color: this.getColor,
        },
        {
          label: "Secondment",
          dateStart: this.employeeDate?.start ? this.employeeDate.start : this.secondmentDate.start,
          dateEnd: this.employeeDate?.end ? this.employeeDate.end : this.secondmentDate.end,
        },
      ];
    },
    showGantt() {
      if (!this.document || this.document.status == 'no-document' || this.documentType == 'employment_contract' || this.documentType == 'last_paycheck') {
        return
      }
      this.getGantt()
      this.$refs.ganttModal.show();
    },
    closeModal() {
      this.$refs.ganttModal.hide();
    },
  }
};
</script>
