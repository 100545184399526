var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.travelPlans.length ? _c('b-tabs', {
    attrs: {
      "id": "plans-table-tab",
      "content-class": "mt-3"
    },
    model: {
      value: _vm.tabIndex,
      callback: function callback($$v) {
        _vm.tabIndex = $$v;
      },
      expression: "tabIndex"
    }
  }, _vm._l(_vm.travelPlans, function (plan, i) {
    return _c('b-tab', {
      key: i,
      scopedSlots: _vm._u([{
        key: "title",
        fn: function fn() {
          return [_c('secondment-travel-planner-tab-title', {
            attrs: {
              "plan": plan,
              "readonly": !_vm.isEditMode
            },
            on: {
              "create-travel-plan": function createTravelPlan($event) {
                return _vm.$emit('create-travel-plan');
              },
              "clone-travel-plan": function cloneTravelPlan($event) {
                return _vm.$emit('clone-travel-plan', $event);
              },
              "delete-travel-plan": function deleteTravelPlan($event) {
                return _vm.$emit('delete-travel-plan', $event);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("Travel Plan")) + " " + _vm._s(i + 1) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('euro-select', {
      staticClass: "flex-grow-1",
      attrs: {
        "value": plan.employees,
        "multiselect-props": {
          options: _vm.secondmentEmployees,
          label: 'name',
          trackBy: 'employee',
          placeholder: _vm.$t('Select your employees'),
          multiple: true
        },
        "return-object": "",
        "custom-options": "",
        "disabled": !_vm.isEditMode
      },
      on: {
        "input": function input($event) {
          return _vm.onTravelPlanEmployeesInput($event, plan);
        }
      },
      scopedSlots: _vm._u([{
        key: "options",
        fn: function fn(_ref) {
          var option = _ref.props.option;
          return [_c('p', {
            staticClass: "mb-0",
            class: [{
              'unplanned-employee': _vm.unplanned.includes(option.employee)
            }]
          }, [_vm._v(" " + _vm._s(option.name) + " ")])];
        }
      }], null, true)
    })], 1), _c('SecondmentTravelPlannerPlan', {
      attrs: {
        "from-wizard": _vm.fromWizard,
        "plan": plan,
        "readonly": !_vm.isEditMode,
        "kml-layers-urls": _vm.kmlLayersUrls,
        "country": _vm.country,
        "google-client-address": _vm.googlePlaceClient
      }
    })], 1);
  }), 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }