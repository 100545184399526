<template>
  <b-container>
    <b-overlay :show="!planDay">
      <b-row>
        <b-col cols="12" md="6">
          <timeline v-if="planDay" :ref="`timeline-${currentDayIdx}`" columns-size="auto minmax(0, 0.2fr) minmax(0, 1fr)"
            class="timeline-row active">
            <template #marker>
              <SecondmentTravelPlannerTimelineMarker :day="currentDay" :risk="pathRisk" :class="{
                'my-pulse-animation': isLoadingPathRisk,
              }">
                {{ currentDayIdx + 1 }}
              </SecondmentTravelPlannerTimelineMarker>
            </template>

            <template #container-top>
              <div>
                <p class="font-weight-bolder mb-5">
                  {{ currentDay.date ? DateService.format(currentDay.date, dateFormat) : currentDay.date }}
                </p>
                <div class="d-flex justify-content-center align-items-center">
                  <risk-map-date-alert :date="computedAlertDate"></risk-map-date-alert>
                </div>
              </div>
            </template>

            <template #container-right>
              <SecondmentTravelPlannerTimelineForm v-if="planDay" :readonly="readonly" :day.sync="planDay"
                :country="country" :method-of-arrival="plan.method_of_arrival"
                :method-of-departure="plan.method_of_departure" :google-client-address="googleClientAddress"
                @update-orders="currentDay.places = $event"
                @update-place="({ newPlace, idx }) => (currentDay.places[idx].place = newPlace)"
                @remove-place="removePlace" @update:housing="
                  currentDay.housing = $event;
                planDay.housing = $event;
                " @update:place_of_arrival="currentDay.place_of_arrival = $event"
                @update:place_of_departure="currentDay.place_of_departure = $event"
                @update:lunch_place="currentDay.lunch_place = $event"
                @update:dinner_place="currentDay.dinner_place = $event"
                @update:housing_order="currentDay.accommodation_place_order = $event"
                @update:place_of_arrival_order="currentDay.place_of_arrival_order = $event"
                @update:place_of_departure_order="currentDay.place_of_departure_order = $event"
                @update:lunch_place_order="currentDay.lunch_place_order = $event"
                @update:dinner_place_order="currentDay.dinner_place_order = $event"
                @update:client_order="currentDay.client_order = $event"
                @update:client2_order="currentDay.client2_order = $event" @update-plan="updatePlan"
                @focus="onFocus(currentDayIdx)"></SecondmentTravelPlannerTimelineForm>
              <div v-if="!readonly" class="py-3 text-center">
                <b-dropdown dropup text="Add place" variant="outline-primary" class="m-2">
                  <b-dropdown-item @click="addPlace('client')">Client</b-dropdown-item>
                  <b-dropdown-item @click="addPlace('lunch')">Lunch</b-dropdown-item>
                  <b-dropdown-item @click="addPlace('dinner')">Dinner</b-dropdown-item>
                  <b-dropdown-item @click="addPlace('other')">Other</b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </timeline>
        </b-col>
        <b-col cols="12" md="6">
          <SecondmentTravelPlannerMap v-if="planDay" v-model="currentDayIdx" :plan="plan" :plan-day="planDay"
            :kml-layers-urls="kmlLayersUrls" :path="path" :client-address="googleClientAddress">
          </SecondmentTravelPlannerMap>
        </b-col>
      </b-row>
    </b-overlay>
  </b-container>
</template>

<script>
import Timeline from "@/view/components/timeline/Timeline.vue";
import SecondmentTravelPlannerMap from "@/view/components/secondment-travel-planner/SecondmentTravelPlannerMap.vue";
import SecondmentTravelPlannerTimelineForm from "@/view/components/secondment-travel-planner/SecondmentTravelPlannerTimelineForm.vue";
import SecondmentTravelPlannerTimelineMarker from "@/view/components/secondment-travel-planner/SecondmentTravelPlannerTimelineMarker.vue";
import { getDayPath } from "@/core/helpers/travelPlanner";
import RiskService from "@/core/services/risk/risk.service";
import TravePlanService from "@/core/services/secondment/secondment-travelplan.service";
import DateService from "@/core/services/date.service";
import { mapActions, mapGetters, mapState } from "vuex";
import RiskMapDateAlert from "@/view/components/alerts/RiskMapDateAlert.vue";
import icons from "@/core/config/icons";
import _ from "lodash";
import moment from "moment";

import AddressService from "@/core/services/address.service";
export default {
  components: {
    Timeline,
    SecondmentTravelPlannerMap,
    SecondmentTravelPlannerTimelineMarker,
    SecondmentTravelPlannerTimelineForm,
    RiskMapDateAlert,
  },

  props: {
    plan: {
      type: Object,
      required: true,
    },
    fromWizard: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: true,
    },
    kmlLayersUrls: {
      type: Array,
      default: () => [],
    },
    country: {
      type: String,
      default: "",
    },
    googleClientAddress: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      icons,
      currentDayIdx: 0,
      path: [],
      isLoadingPathRisk: false,
      pathRisk: 0,
      DateService,
      planDay: null,
      debouncedConversion: _.debounce(this.convertPlans, 200),
      convertedPlans: {},
    };
  },

  computed: {
    ...mapState("addresses", ["apiAddresses"]),
    ...mapState("capabilities", ["risk_map"]),
    ...mapGetters("user", ["dateFormat"]),

    computedAlertDate() {
      const date = this.currentDay?.date
        ? DateService.format(this.currentDay.date, this.dateFormat)
        : DateService.format(this.currentDay.travel_date, this.dateFormat);
      return moment(date, this.dateFormat).isAfter(moment()) ? DateService.format(moment(), this.dateFormat) : date;
    },

    currentDay() {
      return this.plan?.days ? this.plan?.days[this.currentDayIdx] : this.plan?.travel_days[this.currentDayIdx];
    },

    // Used only for watcher.
    currentDayWithClient() {
      return {
        ...this.currentDay,
        client: this.googleClientAddress,
      };
    },

    activeTimeline() {
      const timeline = this.$refs[`timeline-${this.currentDayIdx}`][0];
      return timeline?.$el;
    },
  },

  watch: {
    currentDayWithClient: {
      deep: true,
      immediate: true,
      handler(val) {
        this.debouncedConversion(val);
      },
    },

    currentDay: {
      deep: true,
      handler(val) {
        if (this.fromWizard) {
          let day = this?.plan?.day;

          if (day) {
            day[this.currentDayIdx] = _.cloneDeep(val);
          }
        }
      },
    },

    path: {
      deep: true,
      immediate: true,
      async handler(pathArr, oldPath) {
        if (!this.risk_map.includes(this.country)) {
          return
        }
        if (_.isEqual(pathArr, oldPath)) {
          return;
        }
        if (!pathArr || !pathArr.length) {
          return;
        }

        this.isLoadingPathRisk = true;
        this.pathRisk = 0;
        try {
          const body = pathArr.map((path) => {
            let [lat, lng] = path.split(",");
            if (lat) lat = parseFloat(lat).toFixed(2);
            if (lng) lng = parseFloat(lng).toFixed(2);
            return { lat, lng };
          });
          const riskData = await RiskService.getRouteRiskLevel(this.country, body, this.currentDay.date);
          this.pathRisk = riskData.risk;
        } catch (err) {
          console.error(err);
        } finally {
          this.isLoadingPathRisk = false;
        }
      },
    },
  },
  methods: {
    ...mapActions("addresses", ["getApiAddress"]),
    removePlace({ idx }) {
      const place = this.currentDay?.places?.[idx];

      if (place) {
        this.currentDay.places.splice(idx, 1);
      }
    },
    addPlace(kind) {
      if (kind == "client") {
        this.currentDay.places.push({
          place_kind: kind,
          place: this.googleClientAddress,
          order: this.currentDay?.places?.length,
        })
      } else {
        this.currentDay.places.push({
          place_kind: kind,
          place: null,
          order: this.currentDay?.places?.length,
        })
      }
    },
    async convertPlans(travelPlanDayWithClient) {
      const { client, ...day } = travelPlanDayWithClient;

      if (day.hasOwnProperty('date') || day.hasOwnProperty('travel_date')) {
        const { places, date, travel_date } = day;

        const convertedPlaces = await Promise.all(
          places.map(async (el) => {
            let address = el.place;
            if (typeof address == "number") {
              if (!this.apiAddresses[address]) {
                await this.getApiAddress(address);
              }
              address = this.apiAddresses[address];
            }
            return {
              place_kind: el.place_kind,
              place: address,
            };
          }),
        );

        const planDay = {
          places: convertedPlaces,
          date: date || travel_date,
        };
        this.planDay = planDay;
        if (!client) return;
        this.path = getDayPath(planDay, client);
      }
    },

    async convertAddress(id) {
      if (!id || typeof id === "object") return id;
      if (this.convertedPlans[id]) return this.convertedPlans[id];
      try {
        const address = await AddressService.getByID(id /*addressMap[addressId.id]*/);
        const geocoded = await AddressService.getGooglePlaceFromApi(address);
        this.convertedPlans[id] = geocoded;

        return geocoded;
      } catch (err) {
        console.log(err);
      }
    },

    onFocus(idx) {
      this.currentDayIdx = idx;
    },

    async updatePlan(method) {
      if (method) this.plan[`method_of_${method.type}`] = method.method;
      if (!this.fromWizard) {
        try {
          await TravePlanService.makeTravelPlansRequestBody([this.plan]);
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sticky-custom {
  position: sticky;
  top: 12em;
}

::v-deep .timeline-row.active {

  & .timeline-row-left,
  & .timeline-row-right {
    color: #408bfb;

    & i {
      color: #408bfb;
    }
  }

  .font-extra-small {
    font-size: 0.9rem;
  }
}
</style>
