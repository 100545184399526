<template>
  <ul class="suppliers-list-grid h-100">
    <li v-for="supplier in secondmentSuppliers" :key="`${supplier.partner_object_id}-${supplier.partner_content_type}`">
      <slot name="secondmentSupplier" :secondmentSupplier="supplier"></slot>
    </li>
  </ul>
</template>

<script>
export default {
  components: {},

  props: {
    secondmentSuppliers: {
      type: Array,
      required: true,
    },
    actorsSuppliers: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  list-style: none;
}

.suppliers-list-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
  gap: 2rem;
}

::v-deep .asd__month .asd__week .asd__day--secondment {
  background-color: #ffd27d;
}
</style>
