var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-inline-block",
    class: [_vm.salaryStatus === 'no-data' && 'pointer'],
    attrs: {
      "id": "salary-chip-".concat(_vm._uid)
    },
    on: {
      "click": _vm.handleClick
    }
  }, [_c('span', {
    staticClass: "label label-lg font-weight-bolder w-100 label-inline text-nowrap mt-2 text-uppercase",
    class: {
      'label-light-success': _vm.salaryStatus == 'success',
      'label-light-danger': _vm.salaryStatus == 'error',
      'label-warning': _vm.salaryStatus == 'warning',
      'label-light': _vm.salaryStatus == 'no-data'
    }
  }, [_c('i', {
    class: [_vm.icons.emptyBill, {
      'mr-2': !_vm.iconOnly
    }],
    style: _vm.iconStyle
  }), !_vm.iconOnly ? _c('span', [_vm._v(_vm._s(_vm.salaryStatus == "no-data" ? _vm.$t("No salary data") : _vm.salaryAdjustment))]) : _vm._e()]), _c('b-tooltip', {
    attrs: {
      "target": "salary-chip-".concat(_vm._uid),
      "noninteractive": "",
      "placement": _vm.tooltipPlacement
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.salaryMsg) + " "), _c('br'), _c('strong', [_vm._v(" " + _vm._s(_vm.salaryAdjustment) + " ")])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }