<template>
  <validation-observer ref="nationValidationObs">
    <div class="form row">
      <div class="col-12">
        <div class="pt-lg-0 pt-5 pb-4 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">
            {{ $t("Select Nation") }}
          </h3>
          <div class="text-muted font-weight-bold font-size-h4">
            {{ $t("Where does the secondments site take place") }}?
          </div>
          <div class="mb-0 mt-2">
            <p class="mb-0">
              {{ $t("Creating a new assignment requires a subscription to the risk map for the associated country.") }}
            </p>
            <p class="mb-0">{{ $t("If you have not yet purchased this subscription, please click on the button") }}
              <strong>{{ $t("Subscribe Risk Map") }}</strong>.
            </p>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex items-align-center py-3">
          <validation-provider v-slot="{ errors }" name="country" vid="country" rules="required" tag="div">
            <euro-select v-model="secondmentSelectedCountry" :disabled="wizardMode === 'edit'"
              :placeholder="$t('Select assignment Country')" :options="countriesSelectable" searchable
              :error-messages="errors" class="country-select-container mb-0"></euro-select>
          </validation-provider>
          <div>
            <button class="btn btn-success font-weight-bolder text-uppercase ml-4" @click="swalShopRiskMap">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
              </span>
              {{ $t("Subscribe Risk Map") }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-12">
        <secondment-world-map-chart :selected-country="secondmentSelectedCountry"
          :highlighted-countries="highlightedCountries" @click="onMapClick"></secondment-world-map-chart>
      </div>
      <div class="col-12 mt-6">
        <country-statistics-card-horizontal :b-card-bindings="{ 'border-variant': 'transparent' }"
          :country-iso="selectedCountry" :home-country-iso="getUserExpandedMainAddressCountryIso"
          :active-subs="activeSubs" :pending-subs="pendingSubs" @max-risk-alert="showMaxRiskSwal"
          @go-to-shop="(e) => $emit('go-to-shop', e)" @update-selected-color="handleUpdateSelectedColor"
          @swal-shop-risk-map="swalShopRiskMap">
        </country-statistics-card-horizontal>
      </div>
      <div v-if="suggestReport" class="col-12">
        <div class="alert alert-custom alert-light-warning" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text">
            <span class="font-weight-bolder">{{ $t("Dangerous country") }}!</span>
            {{ $t("Buy risk reports for this country") }}
          </div>
          <div class="alert-close">
            <button class="btn btn-success font-weight-bolder text-uppercase" @click="addToCartReport(selectedCountry)">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Shopping/Cart1.svg" />
              </span>
              {{ $t("Subscribe risk report") }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="suggestService" class="col-12">
        <div class="alert alert-custom alert-light-info" role="alert">
          <div class="alert-icon"><i class="flaticon-info"></i></div>
          <div class="alert-text font-weight-bolder">{{ suggestServiceMessage }}</div>
        </div>
      </div>
      <div v-if="dateAlert" class="col-12">
        <div class="alert alert-custom alert-light-danger" role="alert">
          <div class="alert-icon"><i class="flaticon-danger"></i></div>
          <div class="alert-text font-weight-bolder"><span>{{ dateAlertMessage }}</span>. <span
              class="font-weight-bolder">{{ dateAlert }}</span></div>
        </div>
      </div>
      <div class="col-6 mt-12">
        <div class="pb-8">
          <h3>{{ $t("Select Expected Assignment Duration") }}
            <i v-if="suggestedDate" id="suggestedDate" :class="icons.infoCircle"></i>
          </h3>
          <span v-if="suggestedDate" class="label label-lg label-inline label-light-info font-weight-bold">
            {{ $t("Suggested start date") }}: *{{ suggestedDate }}*
          </span>
        </div>
      </div>
      <div class="col-3 mt-10">
        <validation-provider ref="datesProvider" v-slot="{ errors }" name="dates" :rules="doublePickerRules" tag="div">
          <euro-input v-if="!selectedCountry" disabled :placeholder="`${$t('Select a country first')}*`"></euro-input>
          <euro-double-date-picker v-else :date-from="dateFrom" :date-to="dateTo"
            :class-name="{ 'required-sugggest': requireSuggested }"
            @date-from-to-selected="onDateFromToSelected"></euro-double-date-picker>
          <p v-if="errors.length" class="text-danger">{{ errors[0] }}</p>
        </validation-provider>
      </div>
      <div class="col-6 mt-8">
        <div class="pb-8">
          <h3>{{ $t('Assignment Description') }}</h3>
        </div>
      </div>
      <div class="col-6 mt-7">
        <validation-provider v-slot="{ errors }" name="Description" rules="max:5000" tag="div">
          <euro-text-area v-model="secondmentDescription" :error-messages="errors" :rows="3"></euro-text-area>
        </validation-provider>
      </div>
    </div>

    <div class="separator separator-dashed my-10"></div>

    <div class="form row">
      <div class="col-12">
        <div class="pt-8 pb-8 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">
            {{ $t("Assignment type") }}*
          </h3>
          <div class="text-muted font-weight-bold font-size-h4">
            {{ $t("Select assignment type") }}
          </div>
        </div>
      </div>
      <div class="col-12">
        <SecondmentActivitiesServicesForm :selected-nacecodes.sync="selectedNacecodesComp"
          :selected-service.sync="selectedServiceComp" :client="selectedClient">
        </SecondmentActivitiesServicesForm>
      </div>
    </div>

    <div class="separator separator-dashed my-10"></div>

    <div class="form row">
      <div class="col-12">
        <div class="pt-8 pb-8 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">{{ $t("Select Final Client") }}</h3>
        </div>
      </div>
      <div class="col-12">
        <secondment-create-wizard-client-step :countries="countries" :countries-selectable="countriesSelectable"
          :default-country-filter="selectedCountry" :is-intragroup-work="isIntragroupWork"
          :secondment-client="secondmentClient" :secondment-dates-all="secondmentDatesAll"
          :selected-client="selectedClient" :service="service" :wizard-mode="wizardMode" :actors="actors"
          @clients-change="updateClients($event)" @selected-client-change="$emit('selected-client-change', $event)"
          @secondment-client-change="$emit('secondment-client-change', $event)"></secondment-create-wizard-client-step>
      </div>
    </div>

    <div class="separator separator-dashed my-10"></div>

    <div class="form row">
      <div class="col-12">
        <div class="pt-8 pb-8 text-center text-md-left">
          <h3 class="font-weight-bolder text-dark font-size-h2">
            {{ $t("Select Employees") }}
          </h3>
        </div>
      </div>
      <div v-if="dateFrom && dateTo" class="col-4"></div>
      <div class="col-12">
        <validation-provider v-slot="{ errors }" name="employees" vid="employees" rules="required">
          <p v-if="errors" class="text-danger">{{ errors[0] }}</p>
          <SecondmentEmployeeAvailabilityTable v-if="dateFrom && dateTo" ref="employeeTable" v-model="employees"
            :manager="manager" :date-from="dateFrom" :date-to="dateTo" :selected-first="wizardMode === 'edit'"
            :documents-data="employeesDocuments" :selected-service-comp.sync="selectedServiceComp"
            :secondment-employees="secondmentEmployees" :intragroup-clients="companiesGroup"
            :cost-company-group="costCompanyGroup" :selected-country="selectedCountry" @edit-employee="onEditEmployee"
            @manager-change="$emit('manager-change', $event)"
            @secondment-employee-change="$emit('secondment-employee-change', $event)" @add-contract="onAddContract"
            @change-cost-reversal="$emit('change-cost-reversal', $event)">
            <template #topbar-end>
              <b-btn v-b-modal.newPerson variant="light-primary" block>
                <span class="svg-icon menu-icon">
                  <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                </span>
                <span class="font-weight-bolder">{{
              $t("Add new employee")
            }}</span>
              </b-btn>

              <b-modal id="newPerson" ref="newPerson" size="xl" hide-footer>
                <template #modal-header="{ close }">
                  <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
                    <h3 class="mb-0">
                      {{ $t("New person") }}
                    </h3>
                    <b-button @click="close">
                      {{ $t("Close") }}
                    </b-button>
                  </div>
                </template>
                <PhysicalPersonCreateWizard can-add-as-employee is-employee nested :scroll="false"
                  @submit-as-employee="onSubmitAsEmployee"></PhysicalPersonCreateWizard>
              </b-modal>

              <b-modal id="newEmployee" ref="newEmployee" size="xl" hide-footer>

                <template #modal-header="{ close }">
                  <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
                    <h3>
                      {{ $t("Add New Contract") }}
                    </h3>
                    <b-button @click="close">
                      {{ $t("Close") }}
                    </b-button>
                  </div>
                </template>
                <AssociateContractWizard disable-pp-creation :pp-id="selectedEmployee" :nested="true"
                  @submitted="refreshEmployeeTable"></AssociateContractWizard>
              </b-modal>
            </template>
          </SecondmentEmployeeAvailabilityTable>
          <euro-alert v-else show variant="danger">
            <p class="mb-0 text-center">
              <i :class="icons.infoCircle" class="mr-2" :style="{ color: '#fff' }"></i>
              {{ $t("Select the country and the assignment dates to see all the available Employees") }}
            </p>
          </euro-alert>
        </validation-provider>
      </div>
    </div>
    <div class="separator separator-dashed my-5"></div>

    <div v-if="dateFrom && dateTo" class="row">
      <div class="col-12">
        <h4 class="my-5 font-weight-bold text-dark">
          {{ $t("Assignment Manager") }}
        </h4>
      </div>
      <div class="col-3">
        <div class="font-weight-bold mt-3">{{ $t("Selected Manager") }}*:</div>
      </div>
      <div class="col-6">
        <validation-provider v-slot="{ errors }" vid="manager" name="manager" rules="required">
          <euro-select v-model="managerComputed" :error-messages="errors" :options="selectedEmployeesManager"
            :disabled="selectedEmployees.length == 1" horizontal class-name="mb-0"></euro-select>
        </validation-provider>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import SecondmentWorldMapChart from "@/view/components/charts/SecondmentWorldMapChart.vue";
import SecondmentEmployeeAvailabilityTable from "@/view/components/tables/SecondmentEmployeeAvailabilityTable.vue";
import AssociateContractWizard from "@/view/components/wizards/AssociateContractWizard.vue";
import PhysicalPersonCreateWizard from "@/view/components/wizards/PhysicalPersonCreateWizard.vue";
import SecondmentCreateWizardClientStep from "@/view/components/wizards/secondment-create-wizard/SecondmentCreateWizardClientStep.vue";
import CountryStatisticsCardHorizontal from "@/view/components/cards/CountryStatisticsCardHorizontal.vue";
import EuroAlert from "@/view/components/alerts/EuroAlert.vue";
import SecondmentActivitiesServicesForm from "@/view/components/forms/secondment/SecondmentActivitiesServicesForm.vue";
import { createPhysicalPerson } from "@/core/abstractions/physicalPerson";
import { createEmployee } from "@/core/abstractions/employee";
import DateService from "@/core/services/date.service";
import SecondmentService from "@/core/services/secondment/secondment.service";
import icons from "@/core/config/icons.js";
import Swal from "sweetalert2";
import OrdersService from "@/core/services/risk/orders.service";
import { backendErrorSwal } from "@/core/helpers/swal";
import { mapGetters, mapState, mapActions } from "vuex";
import eventBus from "@/core/services/event/eventBus.js";
import PostCategoryService from "@/core/services/post/post-category.service";
import { successToast } from "@/core/helpers";

const RISK_REPORT_ID = 109;
export default {
  components: {
    SecondmentWorldMapChart,
    SecondmentEmployeeAvailabilityTable,
    AssociateContractWizard,
    CountryStatisticsCardHorizontal,
    SecondmentCreateWizardClientStep,
    SecondmentActivitiesServicesForm,
    EuroAlert,
    PhysicalPersonCreateWizard,
  },

  props: {
    activeSubs: {
      type: Object,
      default: () => { },
    },
    pendingSubs: {
      type: Object,
      default: () => { },
    },
    wizardMode: {
      type: String,
      default: "create",
    },
    countriesSelectable: {
      type: Array,
      required: true,
    },
    countriesToPurchase: {
      type: Object,
      required: true,
    },
    highlightedCountries: {
      type: Array,
      default: () => [],
    },
    selectedEmployees: {
      type: Array,
      required: true,
    },
    dateFrom: {
      type: String,
      default: "",
    },
    dateTo: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    selectedCountry: {
      type: String,
      default: "",
    },
    past: {
      type: Boolean,
      default: false,
    },
    employeesDocumentsStatus: {
      type: Object,
      required: true,
    },
    secondmentEmployees: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    isIntragroupWork: {
      type: Boolean,
      default: false,
    },
    secondmentClient: {
      type: Object,
      default: null,
    },
    secondmentDatesAll: {
      type: Array,
      required: true,
    },
    selectedClient: {
      type: Object,
      default: null,
    },
    selectedNacecodes: {
      type: Array,
      required: true,
    },
    service: {
      type: String,
      required: true,
    },
    manager: {
      type: String,
      default: null,
    },
    actors: {
      type: Object,
      required: true,
    },
    status: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      icons,
      suggestedDate: null,
      enabledMaps: {},
      selectedEmployee: null,
      physicalPersonWizardErrors: {},
      dateAlert: false,
      dateAlertMessage: false,
      employeesList: "",
      clients: [],
      companiesGroup: [],
      costCompanyGroup: [],
      suggestReport: false,
      suggestService: false,
      suggestServiceMessage: '',
      reportCategories: [],
    };
  },

  computed: {
    ...mapGetters("capabilities", ["getActiveCapabilities", "getCapabilitiesForCountry"]),
    ...mapGetters("constants", ["countriesMap"]),
    ...mapGetters("user", ["getUserExpandedMainAddressCountryIso", "dateFormat", "getCurrentManagedCompanyId", "getCompanyGroups"]),
    ...mapState("capabilities", ["subscriptions", "risk_map"]),
    ...mapState("cart", ["productTypes"]),

    selectedEmployeesManager() {
      return this.secondmentEmployees.map((el) => ({
        value: el.employee,
        text: el.name,
      }));
    },

    doublePickerRules() {
      return {
        required: this.wizardMode !== "edit",
      };
    },

    employees: {
      get() {
        return this.selectedEmployees;
      },
      set(values) {
        this.$emit("employees-change", values);
      },
    },

    managerComputed: {
      get() {
        return this.manager;
      },
      set(values) {
        this.$emit("manager-change", values);
      },
    },

    secondmentDescription: {
      get() {
        return this.description;
      },
      set(val) {
        this.$emit("description-change", val);
      },
    },

    secondmentSelectedCountry: {
      get() {
        this.checkCompaniesGroup(this.selectedCountry);
        return this.selectedCountry;
      },
      set(val) {
        this.$emit("selected-country-change", val);
      },
    },

    selectedNacecodesComp: {
      get() {
        return this.selectedNacecodes;
      },
      set(selectedNacecodes) {
        if (selectedNacecodes == null) {
          this.$emit("selected-nacecodes-change", []);
        } else {
          this.$emit("selected-nacecodes-change", selectedNacecodes);
        }
      },
    },

    selectedServiceComp: {
      get() {
        return this.service;
      },
      set(service) {
        this.$emit("service-change", service);
      },
    },

    secondmentDates() {
      return {
        date_from: this.dateFrom,
        date_to: this.dateTo,
      };
    },

    secondmentDatesString() {
      const from = this.dateFrom ?? `from ${this.dateFrom}`;
      const to = this.dateTo ?? `to ${this.dateTo}`;
      return from && to ? `${from} / ${to}` : "Select the secondment dates";
    },

    requireSuggested() {
      return this.dateFrom == "" && this.dateTo == "";
    },

    riskReportItem() {
      return this.reportCategories.find(el => el.id == RISK_REPORT_ID);
    },

    employeesDocuments: {
      get() {
        return this.employeesDocumentsStatus;
      },
      set(val) {
        this.$emit("update-employee-documents", val);
      }
    }
  },

  watch: {
    secondmentSelectedCountry(val) {
      this.suggestReport = false;
      this.suggestService = false;
      if (val) {
        this.getSuggestedDate();
      } else {
        this.suggestedDate = null;
      }
    },
    selectedNacecodes: {
      deep: true,
      handler() {
        this.$nextTick();
      },
    },
    dateFrom() {
      this.alertIfPreexisting();
    }
  },

  async mounted() {
    if (this.selectedCountry) {
      await this.getSuggestedDate();
    }
    this.companiesGroup = this.getCompanyGroups.filter(el => el.id != this.getCurrentManagedCompanyId)
  },

  methods: {
    ...mapActions("capabilities", ["loadCapabilities"]),
    ...mapActions("cart", ["addItem", "addParentItem"]),
    updateClients(e) {
      this.clients = e;
    },
    async onSubmitAsEmployee(form) {
      try {
        const pp = await createPhysicalPerson(form);
        await createEmployee(pp.id, this.getCurrentManagedCompanyId, null);

        successToast(this.$t("Physical Person created successfully"));
        this.$bvModal.hide("newPerson");
        this.refreshEmployeeTable();
      } catch (err) {
        if (typeof err == "object") {
          this.physicalPersonWizardErrors = err;
          return;
        }
        backendErrorSwal(err);
      }
    },

    onAddContract(id) {
      this.selectedEmployee = id;
      this.$bvModal.show("newEmployee");
    },

    async showMaxRiskSwal() {
      const capability = this.getCapabilitiesForCountry(this.selectedCountry);
      let shopReport = true;
      if (capability && capability.categories.filter(el => el.id == RISK_REPORT_ID).length > 0) {
        shopReport = false
      }
      if (shopReport) {
        await PostCategoryService.getAllNotPaginated({ country: this.selectedCountry, tree_view: true }).then(res => {
          this.reportCategories = res;
        })
      }
      this.suggestReport = shopReport;
    },

    async onDateFromToSelected(dates) {
      const { valid } = await this.$refs.datesProvider.validate(dates);
      if (valid) {
        this.$emit("date-from-to-selected", dates);
      }
    },

    async getSuggestedDate() {
      this.suggestService = false
      try {
        const r = await SecondmentService.checkDateStart(this.selectedCountry);
        const date = r?.data?.min_start_date;
        if (date) {
          this.suggestedDate = DateService.format(date, this.dateFormat);
          this.alertIfPreexisting()
        }
        this.$emit("check-date-start", r.data);
      } catch (err) {
        this.suggestServiceMessage = err.response.data.message + ". " + this.$t("Please contact the staff after the secondment creation") ?? this.$t("Something went wrong!");
        this.suggestService = true;
      }
    },

    onEditEmployee(route) {
      this.$emit("edit-employee", route);
    },

    async alertIfPreexisting() {
      if (this.wizardMode === "edit" && this.status !== "draft") {
        this.dateAlert = false;
        return;
      }
      if (this.dateFrom && this.dateTo) {
        const {
          data: { status, message, min_start_date },
        } = await SecondmentService.checkDateStart(
          this.selectedCountry,
          this.dateFrom,
          this.dateTo
        );
        if (status === "error") {
          this.$emit("started-change", false);
          this.$emit("past-change", true);
          this.dateAlert = this.$t("The suggested start date is") + " " + DateService.format(min_start_date, this.dateFormat);
          this.dateAlertMessage = message;
        } else if(status === "warning") {
          this.$emit("started-change", true);
          this.$emit("past-change", false);
          this.dateAlert = this.$t("The suggested start date is") + " " + DateService.format(min_start_date, this.dateFormat);
          this.dateAlertMessage = message;
        } else {
          this.dateAlert = false;
          this.$emit("past-change", false);
        }
      }
    },

    refreshEmployeeTable() {
      this.$bvModal.hide("newEmployee");
      const table = this.$refs?.employeeTable;
      if (table) {
        table.refresh();
      }
    },

    onMapClick(val) {
      if (this.risk_map.includes(val)) {
        this.secondmentSelectedCountry = val;
        this.checkCompaniesGroup(val);
      }
    },

    validate() {
      return this.$refs.nationValidationObs.validate();
    },
    handleUpdateSelectedColor(risk_level) {
      // Emit a custom event to be listened by the SecondmentsMapChart component.
      this.$emit('update-color-in-map', risk_level);
    },

    checkCompaniesGroup(country) {
      const companies = this.companiesGroup.filter(el => { if (el.country_of_establishment == country) { return el; } })
      this.costCompanyGroup = companies;
    },

    swalShopRiskMap() {
      Swal.fire({
        title: this.$t("Subscribe a risk map"),
        text: this.$t("Select a country and proceed with the purchase, the risk maps will be renewed monthly"),
        icon: "info",
        input: 'select',
        inputOptions: this.countriesToPurchase,
        inputPlaceholder: this.$t("Select country"),
        showCancelButton: true,
        confirmButtonText: this.$t("Subscribe now"),
        cancelButtonText: this.$t("Cancel"),
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn",
          input: "form-control form-control-solid"
        },
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value != '') {
              resolve()
            } else {
              resolve(this.$t("You need to select country :)"))
            }
          })
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const body = {
            items: [{
              quantity: 1,
              attributes: {
                monitoring: true,
                countries: [result.value],
                date_range: 'monthly',
                duration: this.$t('monthly'),
                name: this.$t("Risk Map"),
                slug: "risk-map",
                type: "map"
              },
              status: "submitted",
              product_type: this.productTypes['map'],
            }],
          };
          OrdersService.checkout(body).then(() => {
            return this.loadCapabilities();
          }).then(() => {
            successToast(this.$t('Country map subscribed'));
            eventBus.$emit('update-color-map');
          }).catch(err => {
            console.error(err)
            backendErrorSwal(err);
          });
        }
      });
    },

    addToCartReport(country) {
      const item = this.reportCategories.find(el => el.id == RISK_REPORT_ID);
      let parentItems = {};
      const isParentWithChildren = !item.parent_id

      if (isParentWithChildren) {
        parentItems = this.reportCategories.filter(el => el.parent_id == item.id)
        if (parentItems.length) {
          let info = "<ul>";
          parentItems.forEach(el => {
            info += `<li>- ${el.name}</li>`;
          })
          info += '</ul>'
          Swal.fire({
            icon: "info",
            title: this.$t(`You are about to add the "${item.name}" report to your cart which includes:`),
            html: '<div style="text-align:left">' + info + '</div>',
          });
        }
      }

      const newItem = {
        name: item.name,
        slug: item.slug,
        type: 'report',
        price: item.report_price,
        countries: [country],
        categories: [item.id],
        id: item.id + country
      }
      if (isParentWithChildren) {
        this.addParentItem({ item: newItem, children: parentItems });
      } else {
        this.addItem(newItem)
      }
    }
  },
};
</script>

<style scoped>
.country-select-container {
  /* Make space for the toggle icon */
  max-width: 360px;
  min-width: 360px;
}
</style>

<style>
.form-control.form-control-solid.required-sugggest {
  border: solid #F44336 2px;
  background: #fad3d0;
}
</style>
