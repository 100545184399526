<template>
  <div>
    <validation-observer ref="contractorValidationObs">
      <div class="row">
        <div class="col-12">
          <div class="pt-lg-0 pt-5 pb-15 text-center text-md-left">
            <h3 class="font-weight-bolder text-dark font-size-h2">Select Contractor</h3>
            <div class="text-muted font-weight-bold font-size-h4">
              Define contractor for secondment
            </div>
          </div>
        </div>
        <div class="col-12">
          <validation-provider v-slot="{ errors }" name="contractor" vid="contractor" rules="required">
            <secondment-actor-table ref="contractorTable" v-model="selectedContractorComputed" :title="'Contractor'"
              :countries="countries" :actors="actors">
              <template #topbar-end>
                <b-dropdown no-caret right no-flip text="Document" variant="light-primary"
                  toggle-class="font-weight-bolder py-3">
                  <template #button-content>
                    <div class="dropdown-button">
                      <span class="svg-icon menu-icon">
                        <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                      </span>
                      <span class="font-weight-bolder">{{ $t("Add contractor")
                      }}</span>
                    </div>
                  </template>
                  <div class="navi navi-hover min-w-md-200px">
                    <b-dropdown-text class="navi-item" tag="div">
                      <button v-b-modal.newContractor class="w-100 text-left navi-link"
                        @click="setOrganizationType('company')">
                        <span class="navi-icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span class="navi-text">
                          {{ $t("Company") }}
                        </span>
                      </button>
                      <button v-b-modal.newContractor class="w-100 text-left navi-link"
                        @click="setOrganizationType('physical_person')">
                        <span class="navi-icon">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span class="navi-text">
                          {{ $t("Physical person") }}
                        </span>
                      </button>
                    </b-dropdown-text>
                  </div>
                </b-dropdown>
              </template>
            </secondment-actor-table>
            <p v-if="errors" class="text-danger">{{ errors[0] }}</p>
          </validation-provider>
        </div>
      </div>

      <SecondmentActorCreateModal id="newContractor" ref="newContractor" :title="$t('Add new contractor')"
        :text="$t('Please select the kind of contractor you would like to add')"
        :back-btn-tooltip="$t('Back to contractor type choice')" :organization-type="organizationType"
        @hidden="onSecondmentActorCreateModalHidden" @close="closeModal" @created="refreshTableAndCloseModal">
      </SecondmentActorCreateModal>
    </validation-observer>
  </div>
</template>

<script>
import SecondmentActorTable from "@/view/components/tables/SecondmentActorTable.vue";
import SecondmentActorCreateModal from "@/view/components/modals/secondment/SecondmentActorCreateModal.vue";

import icons from "@/core/config/icons";
import { successToast } from '@/core/helpers';
export default {
  components: {
    SecondmentActorTable,
    SecondmentActorCreateModal,
  },

  props: {
    selectedContractor: {
      type: Object,
      default: null,
    },
    countries: {
      type: Array,
      required: true,
    },
    countriesSelectable: {
      type: Array,
      required: true,
    },
    defaultCountryFilter: {
      type: String,
      default: "",
    },
    actors: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      icons,
      legalPersonWizardErrors: null,
      organizationType: ''
    };
  },

  computed: {
    selectedContractorComputed: {
      get() {
        return this.selectedContractor;
      },
      set(val) {
        this.$emit("selected-contractor-change", val);
      },
    },
  },

  methods: {
    onSecondmentActorCreateModalHidden() {
      this.$refs.newContractor.reset();
    },

    closeModal() {
      this.$bvModal.hide("newContractor");
    },

    refresh() {
      this.$refs.contractorTable.refresh();
    },

    refreshTableAndCloseModal() {
      successToast(this.$t("Contractor created"));
      this.closeModal();
      this.refresh();
    },

    async validate() {
      return this.$refs.contractorValidationObs.validate();
    },

    setOrganizationType(name) {
      this.organizationType = name
    }
  },
};
</script>
