<template>
  <div>
    <wizard-left ref="Legwizard" :clickable-steps="false">
      <template #wizard-nav-left>
        <wizard-nav-step-left :title="$t('General information')" :desc="$t('Employer, Physical Person')"
          icon="Home/Building.svg" />
        <wizard-nav-step-left :title="$t('Renewal dates')" :desc="$t('Enter renewal dates')" icon="Media/Repeat.svg" />
        <wizard-nav-step-left :title="$t('Work location')" :desc="$t('Enter locations employee')"
          icon="Home/Building.svg" />
        <wizard-nav-step-left :title="$t('Bonuses')" :desc="$t('Salary, Bonuses, Reimbursement')"
          icon="Shopping/Euro.svg" />
        <wizard-nav-step-left :title="$t('Completed!')" :desc="$t('Review and Submit')" icon="General/Like.svg" />
      </template>

      <template #default="{ getStep }">
        <validation-observer ref="mainValidationObserver" v-slot="{ handleSubmit }">
          <wizard-step-left>
            <keep-alive>
              <validation-observer v-if="getStep() === 1" ref="validation-1">
                <div class="form row">
                  <div class="col-12 mb-10">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0 font-weight-bold text-dark">{{ $t("Physical Person") }}</h4>
                      </div>
                    </div>
                  </div>
                  <div class="col-9">
                    <validation-provider v-slot="{ errors }" :name="$t('Employee')" vid="employee" rules="required">
                      <euro-select v-model="form.employee" asynchronous :fetch-function="getPhysicalPersons" :limit="10"
                        :disabled="disablePpCreation" :label="`${$t('Physical person')}`" :error-messages="errors"
                        searchable @initial-options-loaded="selectPhysicalPersonById"></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-3">
                    <div>
                      <b-button v-b-modal.newEmployeeModal variant="light-primary btn-md mt-12 font-weight-bolder"
                        :disabled="disablePpCreation">
                        <span class="svg-icon menu-icon">
                          <inline-svg src="/media/svg/icons/General/User.svg" />
                        </span>
                        {{ $t("New person") }}
                      </b-button>
                    </div>
                  </div>
                  <div class="col-12">
                    <h4 class="mt-10 mb-6 font-weight-bold text-dark"> {{ $t("Employee contract details") }}</h4>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Country')" vid="jurisdiction" rules="required">
                      <euro-select ref="physicalPersonSelect" v-model="form.jurisdiction" :label="`${$t('Country')}*`"
                        :error-messages="errors" :options="countriesSelectable" searchable
                        @input="onJurisdictionChange"></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Language')" vid="language" rules="required">
                      <euro-select v-model="form.language" :label="`${$t('Language')}*`" :error-messages="errors"
                        :options="languagesSelectable" searchable></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-9">
                    <validation-provider v-slot="{ errors }" :name="$t('National contract')" vid="national_contract"
                      rules="required">
                      <euro-select v-model="form.national_contract" :label="`${$t('National contract')}*`"
                        :error-messages="[errors.concat(externalErrorsNationalContract).join(' - ')]"
                        :options="nationalContractsSelectable" searchable @input="onNationalContractChange"
                        @open="onNationalContractOpen"></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-3">
                    <validation-provider v-slot="{ errors }" :name="$t('Level')" vid="national_contract_level"
                      rules="required">
                      <euro-select v-model="form.national_contract_level" :label="`${$t('Level')}`"
                        :error-messages="errors" :options="nationalContractLevelsSelectable" searchable></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-4">
                    <euro-radio v-model="currencyCustom" :options="OPTIONS" :label="`${$t('Custom currency')}`"
                      size="lg"></euro-radio>
                  </div>
                  <div class="col-8">
                    <validation-provider v-slot="{ errors }" :name="$t('Currency')" vid="currency" rules="required">
                      <euro-select ref="currencySelect" v-model="form.currency" :disabled="!currencyCustom"
                        :label="`${$t('Currency')}*`" :error-messages="errors" asynchronous
                        :fetch-function="getCurrencies" :options="currenciesSelectable" searchable></euro-select>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Attachment')" vid="attachment_tus">
                      <euro-tus-file-input :label="$t('Attachment')" :error-messages="errors" accept="application/pdf"
                        :hint="$t('Accept .pdf - max size 20MB')" @change="tusId => {
                          form.attachment_tus = tusId;
                        }
                          "></euro-tus-file-input>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Notes')" vid="description" rules="max:200">
                      <euro-text-area v-model="form.description" :label="$t('Notes')"
                        :error-messages="errors"></euro-text-area>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0 font-weight-bold text-dark">{{ $t("Select Worker Category") }}</h4>
                      </div>
                    </div>
                    <validation-provider v-slot="{ errors }" :name="$t('WorkerType')" rules="required">
                      <euro-radio v-model="form.worker" :options="workerType" :label="`${$t('Worker category')}*`"
                        horizontal :error-messages="errors"
                        @input="form.worker === 'Temporary' ? selectWorkerType() : 'Employee'"></euro-radio>
                    </validation-provider>
                    <div v-if="form.worker === 'Temporary' && form.jurisdiction">
                      <validation-provider v-slot="{ errors }" :name="$t('Counterpart')" vid="counterpart"
                        rules="required">
                        <euro-select v-model="form.counterpart" :label="`${$t('Counterpart')}*`" :error-messages="errors"
                          :options="counterpartListSelectable" searchable></euro-select>
                      </validation-provider>
                    </div>
                    <validation-provider v-if="form.worker === 'Employee'" v-slot="{ errors }"
                      :name="$t('WorkerContract')" rules="required">
                      <euro-radio v-model="workerContractSeleted" :options="workerContract"
                        :label="`${$t('Worker contract')}*`" horizontal :error-messages="errors"></euro-radio>
                    </validation-provider>
                  </div>
                  <div class="col-6">
                    <validation-provider v-slot="{ errors }" vid="start_date" :name="$t('Employement start date')"
                      :rules="`required`">
                      <date-picker v-model="form.start_date" :label="`${$t('Employement start date')}*`"
                        :overrides="{ useCurrent: false }" :error-messages="errors"></date-picker>
                    </validation-provider>
                  </div>
                  <div v-if="workerContractSeleted == 'fixed-term' || form.worker == 'Temporary'" class="col-6">
                    <validation-provider v-slot="{ errors }" :name="$t('Termination date')" :vid="`end_date`"
                      :rules="terminationDateRule">
                      <date-picker v-model="form.end_date" :label="`${$t('Termination date')}*`" :error-messages="errors"
                        :overrides="endDateOverrides"></date-picker>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
            </keep-alive>
          </wizard-step-left>

          <wizard-step-left>
            <keep-alive>
              <validation-observer v-if="getStep() === 2">
                <div class="form row">
                  <div class="col-12 mb-10">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0 font-weight-bold text-dark">{{ $t("Select Renewal Dates") }}</h4>
                      </div>
                      <div v-if="!disablePpCreation">
                        <b-button variant="light-primary btn-sm" @click="addRenewalDate">
                          <span class="svg-icon menu-icon">
                            <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                          </span>
                          {{ $t("Renewal date") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(renewal, renewalIdx) in form.renewals" :key="renewalIdx">
                  <div v-if="renewalIdx > 0">
                    <div class="separator separator-dashed my-5"></div>
                  </div>
                  <div class="d-flex">
                    <div class="flex-grow-1">
                      <validation-provider v-slot="{ errors }" :name="$t('Employement start date')"
                        :vid="`date_valid_from[${renewalIdx}]`" :rules="{ after: form.end_date }">
                        <date-picker v-model="renewal.renewal_date" :label="$t('Employement start date')"
                          :error-messages="errors" :overrides="renewalDateOverrides"></date-picker>
                      </validation-provider>
                    </div>
                    <div class="flex-grow-1 px-6">
                      <validation-provider v-slot="{ errors }" :name="$t('Termination date')"
                        :vid="`date_valid_to[${renewalIdx}]`" :rules="{ after: renewal.start_date }">
                        <date-picker v-model="renewal.end_date" :label="$t('Termination date')"
                          :overrides="{ useCurrent: false }" :error-messages="errors"></date-picker>
                      </validation-provider>
                    </div>
                    <div class="flex-grow-0">
                      <div class="form-row">
                        <label class="col-12 col-form-label">&nbsp;</label>
                        <b-btn variant="light" :disabled="form.renewals.length == 1"
                          @click="removeRenewalDate(renewalIdx)">
                          <span class="svg-icon mr-0">
                            <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                          </span>
                        </b-btn>
                      </div>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </keep-alive>
          </wizard-step-left>

          <wizard-step-left>
            <keep-alive>
              <validation-observer v-if="getStep() === 3" ref="validation-3">
                <div class="form row">
                  <div class="col-12 mb-10">
                    <div class="d-flex justify-content-between align-items-center">
                      <div>
                        <h4 class="mb-0 font-weight-bold text-dark">{{ $t("Set work location") }}</h4>
                      </div>
                      <div>
                        <b-button variant="light-primary btn-sm" block @click="addWorkLocation">
                          <span class="svg-icon">
                            <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                          </span>
                          {{ $t("Work location") }}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-for="(location, locationIdx) in form.work_locations" :key="locationIdx" class="from row">
                  <div v-if="locationIdx > 0" class="col-12">
                    <div class="separator separator-dashed my-5"></div>
                  </div>
                  <div class="col-12">
                    <div class="d-flex align-items-center">
                      <h6 class="mb-0 font-weight-bold text-dark">{{ $t("Location") }}</h6>
                      <b-button v-if="form.work_locations.length > 1" variant="light-danger btn-sm ml-6"
                        :disabled="form.work_locations.length < 2" @click="removeWorkLocation(locationIdx)">
                        <span class="svg-icon">
                          <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                        </span>
                        {{ $t("Remove") }}
                      </b-button>
                    </div>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Work location')"
                      :vid="`work_location[${locationIdx}]`" rules="required">
                      <euro-select v-model="location.work_location" :label="`${$t('Work location')}*`"
                        :error-messages="errors" :options="companyAddressesSelectable" class="mb-2" searchable>
                      </euro-select>
                    </validation-provider>
                    <span class="d-flex align-items-center">
                      <p class="text-muted mt-4">
                        {{ $t("Can you not find the address?") }}
                      </p>
                      <b-btn variant="light-primary" class="btn-xs ml-2" @click="showAddressModal(locationIdx)">
                        <span class="svg-icon menu-icon">
                          <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                        </span>
                        <span class="font-weight-bolder">{{ $t("Add New Address") }}</span>
                      </b-btn>
                    </span>
                  </div>
                  <div class="col-12">
                    <h6 class="mb-5 mt-3 font-weight-bold text-dark">{{ $t("Type work") }}</h6>
                  </div>
                  <div class="col-12">
                    <euro-checkbox v-model="location.remote_work" :text="$t('Remote work')" is-switch
                      size="lg"></euro-checkbox>
                  </div>
                  <div class="col-12">
                    <h6 class=" mb-5 mt-3 font-weight-bold text-dark">{{ $t("Work location time period") }}</h6>
                  </div>
                  <div class="col-12">
                    <euro-checkbox v-model="location.is_permanent" :text="$t('Permanent')" is-switch size="lg"
                      class="mt-2" @change="onLocationPermanentChange(location)"></euro-checkbox>
                  </div>
                  <div v-if="!isPermanent" class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Valid from')" :vid="`valid_from[${locationIdx}]`">
                      <date-picker v-model="location.valid_from" :label="$t('Valid from')"
                        :disabled="location.is_permanent" :error-messages="errors"
                        :overrides="locationValidFromDateOverrides"></date-picker>
                    </validation-provider>
                  </div>
                  <div v-if="!isPermanent" class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Valid to')" :vid="`valid_to[${locationIdx}]`"
                      :rules="`after:@valid_from[${locationIdx}]`">
                      <date-picker v-model="location.valid_to" :disabled="location.is_permanent" :label="$t('Valid to')"
                        :overrides="{ useCurrent: false }" :error-messages="errors"></date-picker>
                    </validation-provider>
                  </div>
                </div>
              </validation-observer>
            </keep-alive>
          </wizard-step-left>

          <wizard-step-left>
            <keep-alive>
              <validation-observer v-if="getStep() === 4" ref="validation-4">
                <div class="form row">
                  <div class="col-12 mb-10 ">
                    <h4 class="font-weight-bold text-dark">{{ $t("Salary conditions") }}</h4>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Monthly superminimum')" vid="monthly_superminimum"
                      tag="div" :rules="{ required: true, min_value: 0 }">
                      <euro-input v-model="form.monthly_superminimum" type="number" step="any" horizontal
                        :label="`${$t('Monthly superminimum')} (${form.currency})`" :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Advanced seniority pay')"
                      vid="advanced_seniority_pay" :rules="{ required: true, min_value: 0 }">
                      <euro-input v-model="form.advanced_seniority_pay" type="number" step="any" horizontal
                        :label="`${$t('Advanced seniority pay')} (${form.currency})`"
                        :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Daily compensation')"
                      vid="daily_posting_compensation" :rules="{ required: true, min_value: 0 }">
                      <euro-input v-model="form.daily_posting_compensation" type="number"
                        :label="`${$t('Daily compensation')} (${form.currency})`" step="any" horizontal
                        :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <div class="separator separator-dashed mt-5 mb-10"></div>
                  </div>
                  <div class="col-12">
                    <h5 class="mb-4 font-weight-bold text-dark">{{ $t("Travel and posting abroad reimbursement") }}</h5>
                    <p class="text-muted">
                      {{ $t("Is the employee entitled to reimbursement of expenses when travelling abroad?") }}<br />
                    </p>
                  </div>
                  <div class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Reimbursement')" vid="reimbursment">
                      <euro-radio v-model="form.reimbursment" :error-messages="errors"
                        :options="reimbursmentSelectable"></euro-radio>
                    </validation-provider>
                  </div>
                  <div class="col-12">
                    <div class="separator separator-dashed mt-0 mb-10"></div>
                  </div>
                  <div v-if="form.reimbursment !== 'NOT'" class="col-12">
                    <h5 class="mb-4 font-weight-bold text-dark">{{ $t("Travel Compensation") }}</h5>
                  </div>
                  <div v-if="form.reimbursment !== 'NOT'" class="col-12">
                    <validation-provider v-slot="{ errors }" :name="$t('Travel compensation amount')"
                      vid="travel_compensation_amount" :rules="{ required: true, min_value: 0, max_value: 99999999 }">
                      <euro-input v-model="form.travel_compensation_amount" :disabled="form.reimbursment === 'NOT'"
                        class="mr-5" :label="`${$t('Amount')} (${form.currency})`" type="number" step="any"
                        :error-messages="errors"></euro-input>
                    </validation-provider>
                  </div>
                  <div v-if="form.reimbursment !== 'NOT'" class="col-12">
                    <div class="separator separator-dashed mt-0 mb-10"></div>
                  </div>
                  <div class="col-12 mb-4">
                    <div class="d-flex align-items-center">
                      <div>
                        <h5 class="font-weight-bold text-dark">{{ $t("Bonus") }}</h5>
                        <p class="text-muted">{{ $t("An extra payment that can be awarded occasionally.") }}</p>
                      </div>
                      <div>
                        <b-btn variant="light-primary btn-sm ml-4" @click="addBonus">
                          <span class="svg-icon">
                            <inline-svg src="/media/svg/icons/Code/Plus.svg" />
                          </span>
                          {{ $t("Add Bonus") }}
                        </b-btn>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <div v-for="(bonus, bonusIdx) in form.bonuses" :key="bonusIdx">
                      <div v-if="bonusIdx > 0" class="w-100">
                        <div class="separator separator-dashed my-2"></div>
                      </div>
                      <div class="d-flex">
                        <div class="flex-grow-1">
                          <validation-provider v-slot="{ errors }" :name="$t('Bonus')" :vid="`bonus[${bonusIdx}]`"
                            rules="required|min_value:0">
                            <euro-input v-model="bonus.bonus" type="number" step="any"
                              :label="`${$t('Amount')} (${form.currency})`" :error-messages="errors"></euro-input>
                          </validation-provider>
                        </div>
                        <div class="flex-grow-1 px-4">
                          <validation-provider v-slot="{ errors }" :name="$t('Valid from')"
                            :vid="`bonus_valid_from[${bonusIdx}]`" :rules="{ required: bonus.bonus }">
                            <date-picker v-model="bonus.bonus_valid_from" :label="$t('Valid from')"
                              :error-messages="errors" :overrides="bonusValidFromDateOverrides"></date-picker>
                          </validation-provider>
                        </div>
                        <div class="flex-grow-1 pr-4">
                          <validation-provider v-slot="{ errors }" :name="$t('Valid to')"
                            :vid="`bonus_valid_to[${bonusIdx}]`" :rules="`after:@bonus_valid_from[${bonusIdx}]`">
                            <date-picker v-model="bonus.bonus_valid_to" :label="$t('Valid to')"
                              :overrides="{ useCurrent: false }" :error-messages="errors"></date-picker>
                          </validation-provider>
                        </div>
                        <div class="flex-grow-0">
                          <div class="form-row">
                            <label class="col-12 col-form-label">&nbsp;</label>
                            <b-btn variant="danger" class="d-flex align-items-center" @click="removeBonus(bonusIdx)">
                              <span class="svg-icon mr-2">
                                <inline-svg src="/media/svg/icons/Code/Minus.svg" />
                              </span>
                              <span>{{ $t("Remove") }}</span>
                            </b-btn>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </validation-observer>
            </keep-alive>
          </wizard-step-left>

          <wizard-step-left>
            <div v-if="getStep() === 5">
              <h6 class="font-weight-bolder mb-3">{{ $t("Subjects") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Employer") }}: {{ selectedEmployerExtended }}</span>
                </div>
                <div v-if="form.worker && selectedCounterpartExtended">
                  <span>{{ $t("Counterpart") }}: {{ selectedCounterpartExtended }}</span>
                </div>
                <div v-if="getEmployeeName">
                  <span>{{ $t("Employee") }}: {{ getEmployeeName }}</span>
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Contract") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div v-if="form.name">
                  <span>{{ $t("Notes") }}: {{ form.name }}</span>
                </div>
                <div>
                  <span>{{ $t("Country") }}: {{ selectedJurisdictionExtended }}</span>
                </div>
                <div>
                  <span>{{ $t("National contract") }}: {{ getNationalContractName }}</span>
                </div>
                <div>
                  <span>{{ $t("Level") }}: {{ getNationalContractLevelName }}</span>
                </div>
                <div>
                  <span>{{ $t("Currency") }}: {{ selectedCurrencyExtended }}</span>
                </div>
                <div>
                  <span>{{ $t("Language") }}: {{ selectedLanguageExtended }}</span>
                </div>
                <div>
                  <div v-if="workerContractSeleted == 'permanent'">
                    <span>{{ $t("Contract type: permanent") }}</span>
                  </div>
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Contract Dates") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Employement start date") }}: {{ DateService.format(form.start_date, dateFormat) }}</span>
                </div>
                <div v-if="form.end_date">
                  <span>{{ $t("Termination date") }}:
                    {{ form.end_date ? DateService.format(form.end_date, dateFormat) : "" }}</span>
                </div>
              </div>
              <div v-if="workerContractSeleted != 'permanent'">
                <div v-for="(renewal, idx) in form.renewals" :key="`renewal-${idx}`">
                  <div class="separator separator-dashed my-5"></div>
                  <h6 class="font-weight-bolder mb-3">{{ $t("Renewal date") }} #{{ idx + 1 }}</h6>
                  <div class="text-dark-50 line-height-lg">
                    <div>
                      <span>{{ $t("From") }}:
                        {{ renewal.renewal_date ? DateService.format(renewal.renewal_date, dateFormat) : "" }}</span>
                    </div>
                    <div>
                      <span>
                        {{ $t("To") }}:
                        {{
                          renewal.end_date ? DateService.format(renewal.end_date, dateFormat) : $t("Permanent")
                        }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-for="(location, idx) in form.work_locations" :key="`location-${idx}`">
                <div class="separator separator-dashed my-5"></div>
                <h6 class="font-weight-bolder mb-3">{{ $t("Work location") }} #{{ idx + 1 }}</h6>
                <div class="text-dark-50 line-height-lg">
                  <div v-if="location.address">
                    <span>{{ $t("Address") }}: {{ location.address }}</span>
                  </div>
                  <div v-if="location.remote_work">
                    <span>{{ $t("Remote work") }}</span>
                  </div>
                  <div>
                    <span>{{ $t("Valid from") }}:
                      {{
                        location.valid_from
                        ? DateService.format(location.valid_from, dateFormat)
                        : DateService.format(form.start_date, dateFormat)
                      }}</span>
                  </div>
                  <div v-if="location.is_permanent">
                    <span>{{ $t("Permanent working location") }}</span>
                  </div>
                </div>
              </div>
              <div class="separator separator-dashed my-5"></div>
              <h6 class="font-weight-bolder mb-3">{{ $t("Bonuses") }}</h6>
              <div class="text-dark-50 line-height-lg">
                <div>
                  <span>{{ $t("Travel and Posting Abroad Reimbursement") }}: {{ selectedReimbursmentExtended }}</span>
                </div>
                <div>
                  <span>{{ $t("Travel and Posting Abroad Compensation") }}: {{ form.travel_compensation_amount }}
                    {{ form.currency }}</span>
                </div>
              </div>
              <div v-for="(bonus, idx) in form.bonuses" :key="`bonus-${idx}`">
                <div class="separator separator-dashed my-5"></div>
                <h6 class="font-weight-bolder mb-3">{{ $t("Bonus") }} #{{ idx + 1 }}</h6>
                <div class="text-dark-50 line-height-lg">
                  <div>
                    <span>{{ $t("Amount") }}: {{ bonus.bonus }} {{ form.currency }}</span>
                  </div>
                  <div>
                    <span>{{ $t("Valid from") }}: {{ DateService.format(bonus.bonus_valid_from, dateFormat)
                    }}</span>
                  </div>
                  <div>
                    <span>{{ $t("Valid to") }}: {{ DateService.format(bonus.bonus_valid_to, dateFormat) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </wizard-step-left>

          <wizard-actions-left>
            <div class="mr-2">
              <wizard-prev-btn @click="handlePrev"></wizard-prev-btn>
            </div>
            <div>
              <wizard-submit-btn :disabled="isSaving" :label="$t('Save')" @click="handleSubmit(submit)">
                <span class="svg-icon svg-icon-md mr-2">
                  <inline-svg src="/media/svg/icons/Navigation/Check.svg" />
                </span>
              </wizard-submit-btn>
              <wizard-next-btn @click="handleNext"></wizard-next-btn>
            </div>
          </wizard-actions-left>
        </validation-observer>
      </template>
    </wizard-left>

    <b-modal id="newEmployeeModal" ref="newEmployeeModal" size="xl" title="Add New Employee" hide-footer
      @hidden="onPhysicalPersonModalHidden">
      <template #modal-header>
        <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
          <h3>
            {{ $t("New person") }}
          </h3>
          <b-button @click="() => $refs.newEmployeeModal.hide()">
            {{ $t("Close") }}
          </b-button>
        </div>
      </template>
      <physical-person-create-wizard :nations="countries" :error-messages="physicalPersonWizardErrors"
        @submit="onPhysicalPersonCreateSubmit"></physical-person-create-wizard>
    </b-modal>

    <template v-for="( location, locationIdx ) in  form.work_locations ">
      <b-modal :ref="`modal-address-${locationIdx}`" :key="locationIdx" hide-footer size="lg"
        @hidden="onAddressModalHidden">
        <template #modal-header>
          <div class="w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
            <h3>
              {{ $t("Select an address") }}
            </h3>
            <div class="d-flex align-items-center">
              <b-button variant="success" class="mr-2" @click="onAddressSubmit(location, locationIdx)">
                {{ $t("Save Changes") }}
              </b-button>
              <b-button @click="closeAddressModal(locationIdx)">
                {{ $t("Close") }}
              </b-button>
            </div>
          </div>
        </template>

        <validation-observer :ref="`newAddressValidator-${locationIdx}`">
          <validation-provider v-slot="{ errors }" :name="$t('Address')" vid="address" rules="required">
            <AddressInput v-model="addressForm.address" :manual-address="addressForm.manual_address" :active-step="true"
              :error-messages="errors" @change-manual-address="changeManualAddress"
              @update-composed-address="updateComposedAddress">
            </AddressInput>
          </validation-provider>
        </validation-observer>
      </b-modal>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters, mapState } from "vuex";
import {
  addressValidationSwal,
  backendErrorSwal,
  confirmationSwal,
  validationErrorSwal
} from "@/core/helpers/swal";

import NationCurrenciesService from "@/core/services/nation/nation-currencies.service";
import EmployeeNationalContractService from "@/core/services/employee/employee-nationalcontract.service";
import EmployeeNationalContractLevelService from "@/core/services/employee/employee-nationalcontract-level.service";
import CompanyAddressService from "@/core/services/company/company-address.service";
import PhysicalPersonService from "@/core/services/physical-person/physical-person.service";
import CompanyService from "@/core/services/company/company.service.js"
import EmployeeService from "@/core/services/employee/employee.service";
import DateService from "@/core/services/date.service";
import addressService from "@/core/services/address.service";

import PhysicalPersonCreateWizard from "@/view/components/wizards/PhysicalPersonCreateWizard.vue";
import DatePicker from "@/view/components/datePicker/datePicker.vue";
import { successToast } from '@/core/helpers';

export default {
  components: {
    PhysicalPersonCreateWizard,
    DatePicker,
  },

  props: {
    errorMessages: {
      type: Object,
      default: null,
    },
    ppId: {
      type: Number,
      default: null,
    },
    updateItem: {
      type: Object,
      default: null,
    },
    nested: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      DateService,
      canContinue: true,
      associateContractWizardErrors: null,
      physicalPersonWizardErrors: null,
      maxDate: today,
      employeeList: [],
      addressForm: {
        address: null,
        manual_address: false,
        composed_address: null
      },
      form: {
        daily_posting_compensation: 0,
        advanced_seniority_pay: 0,
        monthly_superminimum: 0,
        employee: null,
        employer: null,
        jurisdiction: "",
        start_date: null,
        end_date: null,
        no_ending: false,
        attachment: null,
        attachment_tus: null,
        description: "",
        national_contract: "",
        national_contract_level: "",
        language: "",
        currency: "",
        renewals: [
          {
            renewal_date: null,
            end_date: null,
          },
        ],
        work_locations: [
          {
            work_location: null,
            remote_work: false,
            valid_from: null,
            valid_to: null,
            is_permanent: true,
          },
        ],
        bonuses: [],
        reimbursment: "NOT",
        travel_compensation_amount: 0,
        no_travel_compensation: false,
        worker: "Employee",
        counterpart: "",
      },
      selectedLanguage: null,
      addExtraWorkLocationDialog: false,
      employerList: [],
      currencies: [],
      nationalContracts: [],
      nationalContractLevels: [],
      companyAddresses: [],
      reimbursmentSelectable: [
        { value: "FULL", text: this.$t("Full reimbursement") },
        { value: "PARTIAL", text: this.$t("Partial reimbursement") },
        { value: "NOT", text: this.$t("No reimbursement") },
      ],
      isSaving: false,
      isPermanent: true,
      addAsEmployee: false,
      addAsTemporaryWorker: false,
      workerType: [
        { value: "Employee", text: this.$t("Employee") },
        { value: "Temporary", text: this.$t("Temporary Worker") },
      ],
      workerContract: [
        { value: "permanent", text: this.$t("Permanent employee") },
        { value: "fixed-term", text: this.$t("Fixed-term employee") },
      ],
      workerContractSeleted: "permanent",
      counterpartLegal: [],
      counterpartLegalItaly: [],
      flagCounterpart: false,
      currencyCustom: null,
      OPTIONS: [],
      externalErrorsNationalContract: [],
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("constants", ["countries", "languages"]),
    ...mapGetters("user", ["getCurrentManagedCompanyId", "dateFormat"]),

    getEmployeeName() {
      return this.employeeList.find(em => em.value === this.form.employee)?.text ?? "";
    },

    getNationalContractName() {
      return this.nationalContractsSelectable.find(co => co.value === this.form.national_contract)?.text ?? "";
    },

    getNationalContractLevelName() {
      return (
        this.nationalContractLevelsSelectable.find(co => co.value === this.form.national_contract_level)?.text ?? ""
      );
    },

    stringMaxDate() {
      return moment(this.maxDate, true).format("YYYY-MM-DD");
    },

    selectedEmployerExtended() {
      const company = this.employerList.find(el => el.company === this.form.employer);
      if (!company) {
        return this.form.employer;
      }
      return company.company_name;
    },

    selectedJurisdictionExtended() {
      const jurisdiction = this.countriesSelectable.find(el => el.value === this.form.jurisdiction);
      if (!jurisdiction) {
        return this.form.jurisdiction;
      }
      return jurisdiction.text;
    },

    selectedCurrencyExtended() {
      const currency = this.currenciesSelectable.find(el => el.value === this.form.currency);
      if (!currency) {
        return this.form.currency;
      }
      return currency.text;
    },

    selectedLanguageExtended() {
      const lang = this.languagesSelectable.find(el => el.value === this.form.language);
      if (!lang) {
        return this.form.language;
      }
      return lang.text;
    },

    selectedReimbursmentExtended() {
      const reimb = this.reimbursmentSelectable.find(el => el.value === this.form.reimbursment);
      if (!reimb) {
        return this.form.reimbursment;
      }
      return reimb.text;
    },

    selectedCounterpartExtended() {
      const counter = this.counterpartListSelectable.find(el => el.value === this.form.counterpart);
      if (!counter) {
        return this.form.counterpart;
      }
      return counter.text;
    },

    employeeContractRenewalSet() {
      return this.form.renewals.filter(el => el.end_date && el.renewal_date);
    },

    employeeContractWorkLocationSet() {
      return this.form.work_locations
        .filter(el => !!el.work_location)
        .map(el => {
          return {
            work_location: this.companyAddresses.find(
              ad => ad.address.formatted === el.work_location || ad.address.raw === el.work_location,
            )?.address.id,
            remote_work: el.remote_work,
            valid_from: el.is_permanent || !el.valid_from ? this.form.start_date : el.valid_from,
            valid_to: el.is_permanent || !el.valid_to ? null : el.valid_to,
          };
        });
    },

    employeeContractBonusSet() {
      return this.form.bonuses
        .filter(el => !!el.bonus)
        .map(el => {
          return {
            bonus: el.bonus || 0,
            bonus_currency: this.form.currency,
            bonus_valid_from: el.bonus_valid_from,
            bonus_valid_to: el.bonus_valid_to,
          };
        });
    },

    requestBody() {
      const employee_contract = {
        employeecontractrenewal_set: this.employeeContractRenewalSet,
        employeecontractworklocation_set: this.employeeContractWorkLocationSet,
        employeecontractbonus_set: this.employeeContractBonusSet,
        contract_name: this.form.name,
        start_date: this.form.start_date,
        end_date: this.form.end_date ? this.form.end_date : null,
        travel_posting_reimbursement: this.form.reimbursment,
        travel_posting_compensation: this.form.reimbursment === "NOT" ? "" : this.form.travel_compensation_amount,
        travel_posting_compensation_currency: this.form.reimbursment === "NOT" ? "" : this.form.currency,
        national_contract_level: this.form.national_contract_level,
        language: this.form.language,
        attachment_tus: this.form.attachment_tus,
        monthly_superminimum: this.form.monthly_superminimum,
        monthly_superminimum_currency: this.form.currency,
        advanced_seniority_pay: this.form.advanced_seniority_pay,
        advanced_seniority_pay_currency: this.form.currency,
        daily_posting_compensation: this.form.daily_posting_compensation,
        daily_posting_compensation_currency: this.form.currency
      };
      return {
        persona: this.form.employee,
        company: this.form.employer,
        employeecontract_set: [employee_contract],
        agency: this.flagCounterpart ? this.form.counterpart : null
      };
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    languagesSelectable() {
      return this.languages.map(el => ({ value: el.iso_639_1, text: el.name_en }));
    },

    currenciesSelectable() {
      return this.currencies.map(el => ({ value: el.code, text: el.name }));
    },

    nationalContractsSelectable() {
      return this.nationalContracts.map(el => ({ value: el.id, text: el.name }));
    },

    nationalContractLevelsSelectable() {
      return this.nationalContractLevels.map(el => ({ value: el.id, text: el.level_name }));
    },

    companyAddressesSelectable() {
      return this.companyAddresses.map(el => ({
        value: el.address?.raw || el.address.formatted,
        text: el.address?.raw || el.address.formatted,
      }));
    },

    endDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date !== "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    renewalDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.end_date && this.form?.end_date !== "Invalid date") {
        out.minDate = moment(this.form.end_date).toDate();
      }

      return out;
    },

    locationValidFromDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date !== "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    bonusValidFromDateOverrides() {
      const out = {
        useCurrent: false,
      };

      if (this.form?.start_date && this.form?.start_date !== "Invalid date") {
        out.minDate = moment(this.form.start_date).toDate();
      }

      return out;
    },

    counterpartListSelectable() {
      return this.flagCounterpart ? this.counterpartLegalItaly : this.counterpartLegal;
    },
    disablePpCreation() {
      return this.ppId != null
    },
    terminationDateRule() {
      let rule = 'after:@start_date';
      if (this.form.worker == "Temporary" || this.workerContractSeleted == "fixed-term") {
        return rule + "|required"
      }
      return rule
    }

  },

  watch: {
    "form.jurisdiction"() {
      this.form.national_contract = "";
      this.form.national_contract_level = "";
    },

    "form.worker"() {
      if (this.form.worker == "Employee") {
        this.workerContractSeleted = "permanent"
      }
    },

    "form.national_contract"() {
      const iso = this.nationalContracts.find(el => el.id === this.form.national_contract)?.language ?? null;
      const lang = this.languagesSelectable.find(el => el.value === iso) ?? null;
      this.form.language = lang?.value ?? null;
    },

    "form.national_contract_level": {
      handler: function () {
        this.setCurrencyFromLevel();
      },
      immediate: true
    },

    currencyCustom(newVal) {
      if (newVal) {
        this.resetCurrencySearch();
        this.form.currency = null; // or Enter your default value
      } else {
        // set with the one from the level when currencyCustom changes to false
        this.setCurrencyFromLevel();
      }
    },

    updateItem: {
      immediate: true,
      handler(val) {
        if (val) {
          this.form = { ...this.updateItem };
        }
      },
    },
  },

  async mounted() {
    this.OPTIONS = [{
      value: true,
      text: "Yes"
    },
    {
      value: false,
      text: "No"
    }];
    this.currencyCustom = false;
    this.form.employer = this.getCurrentManagedCompanyId;
    this.employerList = [...this.user?.managedcompany_set];
    // await this.loadEmployees(this.form.employer);
    await this.loadCompanyAddress(this.form.employer);
    await this.loadCounterpartList();
    this.form.employee = this.ppId;
  },

  methods: {
    moment,
    showAddressModal(locationIdx) {
      const modal = this.$refs[`modal-address-${locationIdx}`];

      if (modal) modal[0].show();
    },

    handlePrev() {
      if (!this.form.end_date && this.$refs.Legwizard.getStep() === 3) {
        this.$refs.Legwizard.goTo(1);
        return;
      } else {
        this.$refs.Legwizard.goPrev();
      }
    },

    async handleNext() {
      const validator = this.$refs[`validation-${this.$refs.Legwizard.getStep()}`];
      if (validator) {
        const isValid = await validator.validate();
        if (!isValid) {
          await validationErrorSwal();
          return;
        }
      }
      if (!this.form.end_date && this.$refs.Legwizard.getStep() === 1) {
        this.$refs.Legwizard.goTo(3);
        return;
      }

      this.$refs.Legwizard.goNext();
    },
    async submit() {
      this.isSaving = true;
      await EmployeeService.associateContract(this.requestBody).then(() => {
        successToast();
        if (this.$route.query.redirect) {
          this.$emit("submitted", { path: this.$route.query.redirect });
        } else {
          this.$emit("submitted", { name: "employees-manage" });
        }
      }).catch(err => {
        let msg = "";
        if (err?.response?.status === 400) {
          this.associateContractWizardErrors = err.response.data;
          msg = err.response.data?.persona ? err.response.data?.persona[0] : this.$t("There are some validation errors. Please check the form validity.");
        } else {
          msg = err?.response?.data?.detail ?? this.$t("Something went wrong!");
        }
        backendErrorSwal("Api error", msg, "error");
      }).finally(() => {
        this.isSaving = false;
      });
    },

    removeRenewalDate(idx) {
      this.form.renewals.splice(idx, 1);
    },

    addRenewalDate() {
      this.form.renewals = [...this.form.renewals, { renewal_date: null, end_date: null }];
    },

    resetContractDates() {
      this.form.end_date = null;
    },

    removeWorkLocation(idx) {
      this.form.work_locations.splice(idx, 1);
    },

    addWorkLocation() {
      if (!this.form.work_locations[0].work_location) return;

      this.form.work_locations = [
        {
          work_location: null,
          remote_work: false,
          valid_from: null,
          valid_to: null,
          is_permanent: true,
        },
        ...this.form.work_locations,
      ];
    },

    resetWorkLocationDates(location) {
      location.valid_from = this.form.start_date;
      location.valid_to = this.form.end_date;
    },

    addBonus() {
      if (this.form.bonuses.length && !this.form.bonuses[0].bonus) return;
      this.form.bonuses = [
        {
          bonus_valid_from: null,
          bonus_valid_to: null,
          bonus: null,
        },
        ...this.form.bonuses,
      ];
    },

    removeBonus: function (idx) {
      this.form.bonuses.splice(idx, 1);
    },

    resetTravelCompensation() {
      this.form.travel_compensation_amount = "";
    },

    async getPhysicalPersons(query, perPage, page) {
      if (this.ppId) {
        const res = await PhysicalPersonService.getOne(this.ppId, false, "id,name,surname,user_email");
        let results = [{
          value: res.data.id,
          text: `${res.data.name} ${res.data.surname}` + (res.data.user_email ? ` (${res.data.user_email})` : '')
        }]
        this.employeeList = results
        return {
          count: 1,
          results: results
        };
      } else {
        const res = await PhysicalPersonService.getAll({
          perPage: perPage,
          page: page,
          search: query,
          fields: "id,name,surname,user_email",
          employeeFilter: true,
          employeeContract: false,
        })
        let results = res.results.map(el => ({
          value: el.id,
          text: `${el.name} ${el.surname}` + (el.user_email ? ` (${el.user_email})` : '')
        }))
        this.employeeList = results
        return {
          count: res.count,
          results: results
        };
      }
    },

    async selectPhysicalPersonById() {
      if (this.ppId) {
        this.form.employee = this.ppId;
      }
    },

    async getCurrencies(query, perPage, page) {
      const offset = (page - 1) * perPage;
      const res = await NationCurrenciesService.getAllCurrencies(true, perPage, offset, query);
      return {
        count: res.count,
        results: res.results.map(el => ({
          value: el.code,
          text: el.symbol ? `${el.symbol} - ${el.name}` : `${el.name}`
        }))
      };
    },

    async resetCurrencySearch() {
      this.$refs.currencySelect.asyncOptions = [];
      await this.$refs.currencySelect.searchChange("");
    },

    async setCurrencyFromLevel() {
      const level_currency = this.nationalContractLevels.find(el => el.id === this.form.national_contract_level)?.base_pay_currency ?? null;
      if (this.$refs.currencySelect && level_currency) {
        await this.$refs.currencySelect.searchChange(level_currency);
        await this.$nextTick();

        this.form.currency = level_currency;
      }
    },

    async loadNationalContract(country) {
      try {
        this.nationalContracts = await EmployeeNationalContractService.getAllNotPaginatedByCountry(country);
      } catch (err) {
        console.error(err);
        await backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    async loadNationalContractLevel(nationalContractTypeID) {
      try {
        this.nationalContractLevels = await EmployeeNationalContractLevelService.getAllByNationalContract(nationalContractTypeID);
      } catch (err) {
        console.error(err);
        await backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    async loadCompanyAddress(companyID) {
      try {
        const res = await CompanyAddressService.getAllByCompany(companyID);
        this.companyAddresses = res.data.results;
      } catch (err) {
        console.error(err);
        await backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    async onEmployerInput(companyID) {
      if (!this.form.employer) {
        this.form.employer = companyID;
        return;
      }

      if (this.form.employer === companyID) return;

      const { isConfirmed } = await confirmationSwal({
        text: this.$t("You are about to change employer. Are you sure?"),
        title: `${this.$t("Caution")}!`,
      });

      if (!isConfirmed) return;

      this.form.employer = companyID;
    },

    onJurisdictionChange(country) {
      this.loadNationalContract(country);
    },

    onNationalContractChange(nationalContractID) {
      this.loadNationalContractLevel(nationalContractID);
    },

    onNationalContractOpen(open) {
      if (open) {
        this.externalErrorsNationalContract = [];
        // if there are no contract for the nation or the nation is not selected, show error
        if (!this.form.jurisdiction) {
          this.externalErrorsNationalContract.push(this.$t('Please select the country first'));
        }
        if (this.form.jurisdiction && !this.nationalContracts.length) {
          this.externalErrorsNationalContract.push(this.$t('There are no national contracts for this country, contact support'));
        }

      }
    },

    onContractNoEndingChange() {
      this.resetContractDates();
    },

    onLocationPermanentChange(location) {
      this.resetWorkLocationDates(location);
      this.isPermanent = !this.isPermanent;
    },

    async onPhysicalPersonCreateSubmit(person) {
      try {
        await PhysicalPersonService.create(person);
        this.$bvModal.hide("newEmployeeModal");
        successToast(this.$t("Physical person created"));
      } catch (err) {
        if (err?.response?.status === 400) {
          this.physicalPersonWizardErrors = err.response.data;
        } else {
          await backendErrorSwal(err?.response?.data?.detail, err);
        }
      }
    },

    onPhysicalPersonModalHidden() {
      this.physicalPersonWizardErrors = null;
    },

    onNoTravelCompensationChange() {
      this.resetTravelCompensation();
    },

    onAddressModalHidden() {
      this.addressForm.address = null;
    },

    closeAddressModal(locationIdx) {
      const modal = this.$refs[`modal-address-${locationIdx}`];

      if (modal) modal[0].hide();
    },

    async onAddressSubmit(location, locationIdx) {
      this.canContinue = true;
      const validator = this.$refs[`newAddressValidator-${locationIdx}`];
      if (validator) {
        const isValid = await validator[0].validate();
        if (!isValid) {
          await addressValidationSwal().then(res => {
            if (!res.isConfirmed) {
              this.canContinue = false;
            }
          });
        }
      }
      if (this.canContinue) {
        const ad = await addressService.getOrCreateAddress([this.addressForm]);
        this.companyAddresses = [...this.companyAddresses, { address: ad[0] }];
        location.address = ad[0]["formatted"];
        location.route = ad[0]["formatted"];
        this.closeAddressModal(locationIdx);
      }
    },
    async loadCounterpartListItaly() {
      try {
        const res = await CompanyService.getAllNotPaginatedFilterable({ getTemporaryAgency: true, fields: 'id,name' });
        this.counterpartLegalItaly = res.results.map(el => { return { text: el.name, value: el.id } });
      } catch (err) {
        console.error(err);
        await backendErrorSwal(err?.response?.data?.detail, err);
      }
    },
    async loadCounterpartList() {
      try {
        const res = await CompanyService.getAllNotPaginatedFilterable({ getAdministrative: false, fields: 'id,name' });
        this.counterpartLegal = res.results.map(el => { return { text: el.name, value: el.id } });
      } catch (err) {
        console.error(err);
        await backendErrorSwal(err?.response?.data?.detail, err);
      }
    },

    selectWorkerType() {
      if (this.form.jurisdiction === "IT") {
        this.flagCounterpart = true;
        this.loadCounterpartListItaly();
      } else {
        this.flagCounterpart = false;
        this.loadCounterpartList();
      }

    },
    changeManualAddress(val) {
      this.addressForm.manual_address = val
    },
    updateComposedAddress(val) {
      this.addressForm.composed_address = val;
    }
  },
};
</script>

<style lang="scss" scoped>
.review-step {
  max-width: 600px;
  margin: 0 auto;

  .h3 {
    font-size: 1.8rem;
  }

  .review-list {
    padding: 0 0.5em;
    border-radius: 5px;

    strong {
      width: 20ch;
      white-space: break-spaces;
      margin-right: 50px;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }

    span {
      margin-left: auto;
      margin-top: 0.5em;
      margin-bottom: 0.5em;
    }
  }

  .striped-line {
    background-color: #ece9e6;
  }
}

.max-25ch {
  max-width: 25ch;
  text-align: justify;
}
</style>
