<template>
  <div :id="`salary-chip-${_uid}`" class="d-inline-block" :class="[salaryStatus === 'no-data' && 'pointer']"
    @click="handleClick">
    <span class="label label-lg font-weight-bolder w-100 label-inline text-nowrap mt-2 text-uppercase" :class="{
      'label-light-success': salaryStatus == 'success',
      'label-light-danger': salaryStatus == 'error',
      'label-warning': salaryStatus == 'warning',
      'label-light': salaryStatus == 'no-data',
    }">
      <i :class="[icons.emptyBill, { 'mr-2': !iconOnly }]" :style="iconStyle"></i>
      <span v-if="!iconOnly">{{ salaryStatus == "no-data" ? $t("No salary data") : salaryAdjustment }}</span>
    </span>
    <b-tooltip :target="`salary-chip-${_uid}`" noninteractive :placement="tooltipPlacement">
      <span>
        {{ salaryMsg }}
        <br />
        <strong>
          {{ salaryAdjustment }}
        </strong>
      </span>
    </b-tooltip>
  </div>
</template>

<script>
import icons from "@/core/config/icons";
export default {
  props: {
    salary: {
      type: [Object, null],
      default: null,
    },
    icon: {
      type: String,
      default: "",
    },
    showSalaryAdjustment: {
      type: Boolean,
      default: false,
    },
    iconOnly: {
      type: Boolean,
      default: false,
    },
    tooltipPlacement: {
      type: String,
      default: "top",
    },
  },

  data() {
    return {
      icons,
      colors: {
        'success': '#00A9BD',
        'no-data': '#999',
        'error': '#f44336',
        'warning': '#FFEECB'
      },
    };
  },

  computed: {
    salaryMsg() {
      let msg = this.salary?.message ?? this.$t("Could not find salary data");
      return msg;
    },
    salaryStatus() {
      return this.salary?.status ?? "no-data";
    },
    salaryAdjustment() {
      return this.salary?.status == "warning" ? `+ (${this.salary?.adjustment})` : "";
    },
    iconStyle() {
      return {
        cursor: 'pointer',
        color: this.colors[this.salaryStatus],
        display: 'inline-block',
      };
    },
  },

  methods: {
    handleClick() {
      if (this.salaryStatus === "no-data") {
        this.$emit("add-contract");
      }
    },
  },
};
</script>
