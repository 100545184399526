var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": _vm.id,
      "hide-footer": "",
      "size": "xl"
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.$emit('hidden', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1"
        }, [_c('h3', [_vm._v(_vm._s(_vm.$t("Assignment participation Gantt chart")) + ": " + _vm._s(_vm.secondmentSupplier.name))]), _c('b-button', {
          on: {
            "click": function click($event) {
              return _vm.$emit('close');
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")])], 1)];
      },
      proxy: true
    }])
  }, [_c('gantt-chart', {
    attrs: {
      "data": _vm.data
    }
  }), _c('vue-slider', {
    attrs: {
      "enable-cross": false,
      "min": _vm.minVal,
      "max": _vm.maxVal,
      "interval": _vm.interval,
      "tooltip-formatter": _vm.formatter,
      "tooltip": "always",
      "disabled": _vm.disabled
    },
    model: {
      value: _vm.secondmentSupplierValues,
      callback: function callback($$v) {
        _vm.secondmentSupplierValues = $$v;
      },
      expression: "secondmentSupplierValues"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }