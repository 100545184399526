var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('validation-observer', {
    ref: "employeesValidationObs"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "pt-lg-0 pt-5 pb-15 text-center text-md-left"
  }, [_c('h3', {
    staticClass: "font-weight-bolder text-dark font-size-h2"
  }, [_vm._v(_vm._s(_vm.$t("Employees participating to the assignment")))]), _c('div', {
    staticClass: "text-muted font-weight-bold font-size-h4"
  }, [_vm._v(" " + _vm._s(_vm.$t("Enter arrivals departures and hosting of employees")) + " ")])])]), _c('div', {
    staticClass: "col-12"
  }, _vm._l(_vm.secondmentEmployees, function (secondmentEmployee) {
    return _c('div', {
      key: secondmentEmployee.employee,
      staticClass: "mb-5"
    }, [_c('SecondmentEmployeeTravelPlanCard', {
      key: secondmentEmployee.employee,
      attrs: {
        "client-address": _vm.googleClientAddress,
        "client-coordinates": _vm.clientCoordinates,
        "value": secondmentEmployee,
        "employee": _vm.employeesMap[secondmentEmployee.employee],
        "availability": _vm.getAvailabilityEnum(_vm.employeesMap[secondmentEmployee.employee]),
        "documents-status": _vm.employeesDocumentsStatus[secondmentEmployee.employee],
        "salary": secondmentEmployee.salary_adjustment,
        "date-start": _vm.dateStart,
        "date-end": _vm.dateEnd,
        "secondment-days": _vm.secondmentDatesAll,
        "customized-days": _vm.customizedDays,
        "country": _vm.country
      },
      on: {
        "input": _vm.onSecondmentEmployeeInput,
        "copy-to-all": _vm.onCopyToAll,
        "availability-click": _vm.onAvailabilityClick
      }
    })], 1);
  }), 0)]), _vm.gantt.secondmentEmployee ? _c('secondment-participation-modal', {
    attrs: {
      "id": _vm.ganttModalId,
      "availability": _vm.gantt.availability,
      "secondment-employee": _vm.gantt.secondmentEmployee,
      "secondment-end-date": _vm.dateEnd,
      "secondment-start-date": _vm.dateStart,
      "overlapping-secondments": _vm.gantt.overlappingSecondments
    },
    on: {
      "close": function close($event) {
        return _vm.$bvModal.hide(_vm.ganttModalId);
      },
      "hidden": _vm.onGanttModalHidden,
      "secondment-employee-change": _vm.onSecondmentEmployeeChange
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }