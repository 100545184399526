var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "suppliers-list-grid h-100"
  }, _vm._l(_vm.secondmentSuppliers, function (supplier) {
    return _c('li', {
      key: "".concat(supplier.partner_object_id, "-").concat(supplier.partner_content_type)
    }, [_vm._t("secondmentSupplier", null, {
      "secondmentSupplier": supplier
    })], 2);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }