<template>
  <div>
    <datatable ref="table" :table-props="tableProps" :total="total" :fields="fields" :options.sync="tableOptions"
      :per-page-options="perPageOptions" :filter="tableFilters" :tr-class="trClasses">
      <template #topbar>
        <div class="d-flex justify-content-between">
          <div v-if="title" class="flex-grow-1">
            <h4>{{ title }}</h4>
          </div>
          <div class="col 16" :class="{ 'px-4': title }">
            <euro-select v-model="countryFilter" :multiselect-props="countryMultiselectProps"></euro-select>
          </div>
          <div class="col 16">
            <euro-select v-model="actorTypeFilter" :multiselect-props="actorTypeMultiselectProps"></euro-select>
          </div>
          <div class="col 16">
            <euro-select v-model="inGroupFilter" :disabled="inGroupFilter === true && service == SERVICE_TYPE.INTRAGROUP"
              :multiselect-props="inGroupMultiselectProps"></euro-select>
          </div>
          <div class="col 16">
            <div class="d-flex">
              <euro-input v-model="searchFilter" :placeholder="$t('Search')" class="w-100" :debounce="300">
                <b-input-group-prepend>
                  <span
                    class="input-group-text border-0 d-flex align-items-center justify-content-center py-0 pl-3 pr-1 m-0"><i
                      :class="`${icons.search}`"></i></span>
                </b-input-group-prepend>
              </euro-input>
            </div>
          </div>
          <euro-checkbox v-model="isClient" v-b-tooltip.hover :title="$t('Filter only entities tagged as clients')" :text="$t('Clients')" size="lg" is-switch class="mr-6 mt-3"
            @input="$refs.table.refresh()"></euro-checkbox>
          <div v-if="$slots['topbar-end']">
            <slot name="topbar-end"></slot>
          </div>
        </div>
      </template>

      <template #[`header._selected`]>
        <div></div>
      </template>
      <template #[`header.companyfiscalid_set`]>
        {{ $t("FISCAL ID") }}
      </template>
      <template #[`cell._selected`]="{ item }">
        <b-form-checkbox size="lg" :checked="isSelected(item)" @change="onSelecteCheckboxChange(item)"></b-form-checkbox>
      </template>
      <template #[`cell.countries`]="{ item }">
        <template v-if="item.countries && item.countries.length">
          <country-flag v-for="country in item.countries" :key="country" :country-iso="country"></country-flag>
        </template>
        <span v-else></span>
      </template>
      <template #[`cell.vat`]="{ item }">
        <div class="font-weight-bolder h-100 text-uppercase" :class="{
          'text-danger': getActorVat(item).isExpired,
        }">
          {{ getActorVat(item).vat }}
        </div>
      </template>
      <template #[`cell.fiscal_id`]="{ item }">
        <div class="font-weight-bolder h-100 text-uppercase" :class="{
          'text-danger': fiscalID(item).isExpired,
        }">
          {{ fiscalID(item).fiscalId }}
        </div>
      </template>
      <template #[`cell.name`]="{ item }">
        <div class="d-flex align-items-center">
          <avatar :avatar-image="item.logo" :avatar-text="item.name[0]"></avatar>
          <div class="ml-3">
            <div class="font-weight-bolder">{{ item.name }}</div>
            <div class="text-muted">
              {{ kindTranslation(item.kind) }}
            </div>
          </div>
        </div>
      </template>
      <template #[`cell.intragroup`]="{ item }">
        <b-form-checkbox v-model="item.intragroup" switch size="lg"></b-form-checkbox>
      </template>

      <template v-for="customCol in customColumns" v-slot:[`cell.${customCol.key}`]="{ item }">
        <slot :name="`cell.${customCol.key}`" :item="item"> </slot>
      </template>
    </datatable>
  </div>
</template>

<script>
import SecondmentActorsService from "@/core/services/secondment/secondment-actors.service";
import Datatable from "@/view/components/tables/Datatable.vue";
import icons from "@/core/config/icons.js";
import CountryFlag from "@/view/components/CountryFlag.vue";
import { backendErrorSwal } from "@/core/helpers/swal";
import { SERVICE_TYPE } from "@/core/abstractions/secondment";
import { mapGetters } from "vuex";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"
export default {
  components: {
    Datatable,
    CountryFlag,
  },

  props: {
    countries: {
      type: Array,
      required: true,
    },
    value: {
      required: true,
      validator: prop => typeof prop === "object" || prop === null,
    },
    defaultCountryFilter: {
      type: String,
      default: null,
    },
    customColumns: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: "",
    },
    actorId: {
      type: String,
      default: "id",
    },
    service: {
      type: String,
      required: true,
    },
    actors: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      SERVICE_TYPE,
      icons,
      isLoading: false,
      total: 0,
      tableOptions: tableOptions,
      perPageOptions: perPageOptions,
      searchFilter: "",
      countryFilter: "",
      actorTypeFilter: "",
      inGroupFilter: null,
      initialActors: {
        count: 0,
        result: []
      },
      isClient: true,
    };
  },

  computed: {
    ...mapGetters("user", ["getCurrentManagedCompanyId"]),


    tableProps() {
      return {
        items: this.itemProvider,
        fields: this.fields,
      };
    },


    actorTypeMultiselectProps() {
      const options = Object.keys(SecondmentActorsService.ActorKinds).map(k => ({
        text: SecondmentActorsService.ActorKinds[k],
        value: k,
      }));
      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("Type"),
      };
    },

    countryMultiselectProps() {
      const options = this.countries.map(el => ({ text: el.name, value: el.iso }));
      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("Country"),
        searchable: true,
      };
    },

    inGroupMultiselectProps() {
      const options = [
        { text: this.$t("Group Only"), value: true },
        { text: this.$t("Not in Group"), value: false },
      ];

      return {
        options: options,
        "track-by": "value",
        label: "text",
        multiple: false,
        "allow-empty": true,
        placeholder: this.$t("All"),
        searchable: true,
      };
    },

    tableFilters() {
      return [this.searchFilter, this.countryFilter, this.actorTypeFilter, this.inGroupFilter];
    },

    fields() {
      let f = [
        { key: "_selected", sortable: false, class: "align-middle" },
        { label: this.$t("Name"), key: "name", sortable: true, class: "align-middle" },
        {
          label: this.$t("Countries"),
          key: "countries",
          sortable: false,
          class: "align-middle",
        },
        { label: this.$t("VAT"), key: "vat", sortable: false, class: "align-middle" },
        {
          label: this.$t("FISCAL ID"),
          key: "fiscal_id",
          sortable: false,
          class: "align-middle",
        },
      ];

      if (this.customColumns.length) {
        f = [...f, ...this.customColumns];
      }

      return f;
    },

    countriesSelectable() {
      return this.countries
        .map((x) => ({ text: this.$t(x.name), value: x.iso }))
        .sort((a, b) => a.text.localeCompare(b.text));
    },

    selectedActor: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  watch: {
    service: {
      immediate: true,
      handler(val) {
        if (val == SERVICE_TYPE.INTRAGROUP) {
          this.inGroupFilter = true;
        } else {
          this.inGroupFilter = null;
        }
      }
    },

    defaultCountryFilter: {
      immediate: true,
      handler(val) {
        this.countryFilter = val;
      },
    },
  },

  methods: {
    itemProvider(ctx, callback) {
      if (!this.countryFilter && this.initialActors) {
        this.total = this.actors.count;
        let elements = this.initialActors.result;
        this.$emit('clients-change', elements);
        callback(elements);
        this.initialActors = null;
      } else {
        SecondmentActorsService.getAll({
          perPage: ctx.perPage,
          page: ctx.currentPage,
          sortBy: ctx.sortBy || "name",
          search: this.searchFilter,
          sortDesc: ctx.sortDesc,
          countryAddress: this.countryFilter,
          kind: this.actorTypeFilter,
          inGroup: this.inGroupFilter,
          isClientOnSecondments: this.isClient,
        })
          .then(res => {
            this.total = res.count;
            let elements = res.results;

            // Tira su i risultati ottenuti
            this.$emit('clients-change', elements);

            callback(elements);
          })
          .catch(err => {
            if (err.response.status == 500) {
              backendErrorSwal(err, err?.response?.data);
            }
            callback([]);
          });
      }

      return null;
    },

    fiscalID(actor) {
      const out = {
        fiscalId: "",
        isExpired: true,
      };
      if (!actor?.fiscal_id || !actor?.fiscal_id?.length) return out;

      const fiscalId = actor.fiscal_id.find(v => v.is_active == true);

      if (!fiscalId) {
        out.fiscalId = this.$t("expired");

        return out;
      }

      out.fiscalId = fiscalId.fiscalid;
      out.isExpired = false;

      return out;
    },

    refresh() {
      this.$refs.table.refresh();
    },

    async onSelecteCheckboxChange(actor) {
      if (this.isSelected(actor)) {
        this.selectedActor = null;
        return;
      }

      // if (
      //   actor.kind == CLIENT_TYPE.COMPANY &&
      //   this.service == SERVICE_TYPE.INTRAGROUP &&
      //   !(await isCompanyInGroup(this.getCurrentManagedCompanyId, actor.id))
      // ) {
      //   const { isConfirmed } = await Swal.fire({
      //     title: this.$t("Not in group!"),
      //     icon: "warning",
      //     text: this.$t("This company is not in your group!"),
      //     showConfirmButton: true,
      //     showCancelButton: true,
      //     confirmButtonText: this.$t("Add to my group"),
      //   });
      //   if (!isConfirmed) {
      //     this.refresh();
      //     return;
      //   }
      //   await addCompanyToGroup(this.getCurrentManagedCompanyId, actor.id);
      // }

      this.selectedActor = actor;
    },

    isSelected(actor) {
      if (!this.selectedActor) return false;
      return this.selectedActor[this.actorId] === actor.id && this.selectedActor.kind == actor.kind;
    },

    trClasses(actor) {
      if (!actor) return;
      return {
        "table-success": this.isSelected(actor),
      };
    },

    onNotifyCheckboxInput(notify, actor) {
      this.selectedActor = { ...actor, notify };
    },

    getActorVat(actor) {
      const out = {
        vat: "",
        isExpired: true,
      };
      if (!actor.vat || !actor.vat.length) return out;

      const vat = actor.vat.find(v => v.is_active == true);

      if (!vat) {
        out.vat = this.$t("expired");

        return out;
      }

      out.vat = vat.vat;
      out.isExpired = false;

      return out;
    },
    kindTranslation(kind) {
      return kind == "PhysicalPerson" ? this.$t('Physical Person') : this.$t('Company');
    }
  },
};
</script>
