<template>
  <b-modal :id="id" hide-footer size="xl" @hidden="$emit('hidden', $event)">
    <template #modal-header>
      <div class=" w-100 h-100 d-flex justify-content-between align-items-center px-4 py-1">
        <h3>{{ $t("Assignment participation Gantt chart") }}: {{ secondmentEmployee.name }}</h3>
        <div>
          <b-button v-if="!disableEdits && isChange" variant="success" class="mr-2" @click="save">
            {{ $t("Change") }}
          </b-button>
          <b-button @click="$emit('close')">
            {{ $t("Close") }}
          </b-button>
        </div>
      </div>
    </template>
    <SecondmentEmployeeGanttChart :secondment-employee="secondmentEmployee" :secondment-start-date="secondmentStartDate"
      :secondment-end-date="secondmentEndDate" :secondment-employee-availability="availability"
      :disable-edits="disableEdits" :overlapping-secondments="overlappingSecondments"
      @secondment-employee-change="editDays"></SecondmentEmployeeGanttChart>
  </b-modal>
</template>

<script>
import SecondmentEmployeeGanttChart from "@/view/components/charts/SecondmentEmployeeGanttChart.vue";
export default {
  components: {
    SecondmentEmployeeGanttChart,
  },

  props: {
    id: {
      type: String,
      required: true,
    },
    secondmentEmployee: {
      type: Object,
      required: true,
    },
    secondmentStartDate: {
      type: String,
      required: true,
    },
    secondmentEndDate: {
      type: String,
      required: true,
    },
    availability: {
      type: Number,
      required: true,
    },
    disableEdits: {
      type: Boolean,
      default: false,
    },
    overlappingSecondments: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      days: null,
      isChange: false,
    }
  },
  watch: {
    days() {
      this.secondmentEmployee.date_start === this.days.date_start && this.secondmentEmployee.date_end === this.days.date_end ?
        this.isChange = false : this.isChange = true
    },
  },
  methods: {
    editDays(days) {
      this.days = days
    },
    save() {
      this.$emit('secondment-employee-change', this.days);
      this.isChange = false;
    }
  }
};
</script>
